<pm-security
  [history]="history"
  [mainLocations]="mainLocationsOptions"
  [selectedMainLocation]="selectedMainLocationNumber"
  [associatedLocations]="associatedLocations"
  [approvedDomains]="approvedDomains"
  [approvedEmails]="approvedEmails"
  [readOnly]="isServiceAdmin"
  [validationErrorAddressMessage]="validationErrorAddressMessage"
  [validationErrorDomainMessage]="validationErrorDomainMessage"
  (onShowPage)="showPage($event)"
  (onSort)="onSort($event)"
  (onPageAmountChanged)="onPageAmountChanged($event)"
  (onSubmit)="processMainLocationUpdate($event)"
  (onMainLocationSelected)="onMainLocationSelect($event)"
  (onInputChanged)="onInputChanged($event)">
</pm-security>

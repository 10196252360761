import {
  Injectable
} from '@angular/core';
import {
  catchError,
  map
} from "rxjs/operators";
import {
  SwaggerClientService
} from "./http/swagger-client.service";
import {
  environment
} from "../../environments/environment";
import {
  Observable
} from "rxjs";
import {
  RequestOptions
} from "./http/request-options";
import {
  AppPermissionMinimalDtoSchema,
  IAppPermissionMinimalDto
} from "../../services/appPermission/IAppPermissionMinimalDto";
import {
  IAppPermissionApi
} from "../../services/appPermission/iappPermission-api.service";

@Injectable()
export class AppPermissionApi extends IAppPermissionApi {
  private swaggerClient: SwaggerClientService;

  private readonly baseUrl: string = environment.url;

  constructor(swaggerClient: SwaggerClientService) {
    super();
    this.swaggerClient = swaggerClient;
  }

  getFullAppPermissions(): Observable<IAppPermissionMinimalDto[]> {
    let url: string = this.baseUrl + "/api/services/app/AppPermission/GetFullAppPermissionList";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<string> = new RequestOptions();

    return this.swaggerClient.request("get", url, requestOptions, this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => AppPermissionMinimalDtoSchema.parse(dto))),
      catchError(error => {
        console.error("IAppPermissionMinimalDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getAppPermissions(): Observable<IAppPermissionMinimalDto[]> {
    let url: string = this.baseUrl + "/api/services/app/AppPermission/GetAppPermissionList";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<string> = new RequestOptions();

    return this.swaggerClient.request("get", url, requestOptions, this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => AppPermissionMinimalDtoSchema.parse(dto))),
      catchError(error => {
        console.error("IAppPermissionMinimalDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }
}

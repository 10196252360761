import {
  Observable,
} from "rxjs";
import {
  IexceptionHandlerService
} from "../exception/iexception-handler.service";
import {
  IAllianceDto
} from "./ialliance.dto";

export abstract class IAllianceService extends IexceptionHandlerService {
  abstract getList(): Observable<IAllianceDto[]>;
}

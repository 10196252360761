<pm-employee-create-hierarchy
  [wizardHeaderSteps]="wizardHeaderSteps"
  [identifier]="stepIdentifier"
  headingTitle="selectHierarchy|Select Hierarchy"
  i18n-headingTitle="selectHierarchy|Select Hierarchy"
  headingText="selectEmployeeHierarchyText|This step defines whether the new user will have access to the management or general employee level."
  i18n-headingText="selectEmployeeHierarchyText|This step defines whether the new user will have access to the management or general employee level."
  backButtonLabel="back|Back"
  i18n-backButtonLabel="back|Back"
  nextButtonLabel="nextBtn|nextBtn"
  i18n-nextButtonLabel="nextBtn|nextBtn"
  [selectionCardData]="selectionCardData"
  (onBackClicked)="onBackButtonClicked()"
  (onNextClicked)="onNextButtonClicked()"
  (onHierarchySelected)="onHierarchyInputChanged($event)"
>
</pm-employee-create-hierarchy>

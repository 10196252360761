import {
  Observable
} from "rxjs";
import {
  IContractRowOfFileDto,
} from "./icontract-import.dto";
import {
  ImportData
} from "hagebau-coremedia";
import {
  IContractDto
} from "./icontract.dto";
import {
  IContractBulkCreateResponseDto
} from "./icontract-bulk-create-response.dto";

export abstract class IContractImportService {
  abstract getContractsFromFile(file: File): Observable<IContractRowOfFileDto>;
  abstract importContracts(contracts: IContractDto[]): Observable<IContractBulkCreateResponseDto>;
  abstract convertResponseToFile(response: IContractBulkCreateResponseDto): void;
  abstract getDefaultImportData(): ImportData;
  abstract getImportDataByFile(template: ImportData, file: File): ImportData;
}




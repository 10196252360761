import {
  ColumnNamesContract,
  ColumnNamesDelAdmins,
  HeadingTexts
} from "hagebau-coremedia";

//table texts
const DEFAULT_DEL_ADMIN_COLUMN_NAMES: ColumnNamesDelAdmins  = {
  nameColumn: $localize`name|Name`,
  emailColumn: $localize`email|Email`,
  statusColumn: $localize`status|Status`
}
const ASSIGNED_CONTRACTS_TABLE_NAMES: HeadingTexts = {
  title: $localize`assigned-contract-table-view`,
  text: $localize`assigned-contract-table-view-sub`
}
const ALL_LOCATIONS_TABLE_NAMES: HeadingTexts = {
  title: $localize`all-location-table-view`,
  text: $localize`all-location-table-view-sub`
}
const ASSIGNED_CONTRACTS_TABLE_EDIT_NAMES: HeadingTexts = {
  title: $localize`assigned-contract-table-edit`,
  text: $localize`assigned-contract-table-edit-sub`
}
const ASSIGNED_LOCATIONS_TABLE_NAMES: HeadingTexts = {
  title: $localize`assigned-location-table-view`,
  text: $localize`assigned-location-table-view-sub`
}
const ASSIGNED_APP_PERMISSIONS_TABLE_NAMES: HeadingTexts = {
  title: $localize`assigned-app-permissions-table-view`,
  text: $localize`assigned-app-locations-table-view-sub`
}
const ALL_CONTRACTS_TABLE_NAMES: HeadingTexts = {
  title: $localize`all-contract-table-view`,
  text: $localize`all-contract-table-view-sub`
}
const ASSIGNED_LOCATIONS_TABLE_EDIT_NAMES: HeadingTexts = {
  title: $localize`assigned-location-table-edit`,
  text: $localize`assigned-location-table-edit-sub`
}
const COLUMN_NAMES_CONTRACT_ASSIGNMENT: ColumnNamesContract = {
  checkBoxColumn: '',
  nameColumn: $localize`name|Name`,
  categoryColumn: $localize`category|Category`,
};


export class TableSettings {
  public static readonly DEFAULT_DEL_ADMIN_COLUMN_NAMES: ColumnNamesDelAdmins = DEFAULT_DEL_ADMIN_COLUMN_NAMES;
  public static readonly ALL_LOCATIONS_TABLE_NAMES: HeadingTexts = ALL_LOCATIONS_TABLE_NAMES;
  public static readonly ASSIGNED_LOCATIONS_TABLE_NAMES: HeadingTexts = ASSIGNED_LOCATIONS_TABLE_NAMES;
  public static readonly ASSIGNED_APP_PERMISSIONS_TABLE_NAMES: HeadingTexts = ASSIGNED_APP_PERMISSIONS_TABLE_NAMES
  public static readonly ASSIGNED_LOCATIONS_TABLE_EDIT_NAMES: HeadingTexts = ASSIGNED_LOCATIONS_TABLE_EDIT_NAMES;
  public static readonly ALL_CONTRACTS_TABLE_NAMES: HeadingTexts = ALL_CONTRACTS_TABLE_NAMES;
  public static readonly ASSIGNED_CONTRACTS_TABLE_NAMES: HeadingTexts = ASSIGNED_CONTRACTS_TABLE_NAMES;
  public static readonly ASSIGNED_CONTRACTS_TABLE_EDIT_NAMES: HeadingTexts = ASSIGNED_CONTRACTS_TABLE_EDIT_NAMES;
  public static readonly COLUMN_NAMES_CONTRACT_ASSIGNMENT: ColumnNamesContract = COLUMN_NAMES_CONTRACT_ASSIGNMENT;
}

import {
  Injectable
} from '@angular/core';
import {
  merge,
  Observable,
  of,
  OperatorFunction
} from 'rxjs';
import {
  IBaseContractDto,
  IContractApi,
  IcontractBulkModifyResponseDto,
  IContractDto,
  IContractRequestDto,
  IContractService,
  IContractTableService,
  IModifyContractInput
} from './index';
import {
  IPaginationResponseDto
} from '../pagination/IPaginationResponseDto';
import {
  PaginationInfoDto
} from '../../network/api/pagination-api.service';
import {
  ILocationDto
} from '../location';
import {
  IContractDetailDto
} from './icontract-detail.dto';
import {
  ILdapResponseDto
} from '../ildapResponse.dto';
import {
  filter,
  map,
  switchMap
} from 'rxjs/operators';
import {
  LocationDummy
} from '../location/location.service';
import {
  PmInputData,
  AlertType
} from 'hagebau-coremedia';
import {
  ISearchService
} from '../search/isearch.service';
import {
  ISearchTermsDto
} from '../search/isearch-terms.dto';
import {
  IAuthService
} from '../account/iauth.service';
import {
  ContractState
} from './contract-state.enum';
import {
  ContractStatusUpdateRequestDto
} from './icontract-status-update-request.dto';
import {
  IPageFacadeService
} from '../ipage-facade';
import {
  AppSettings
} from '../appSettings/appSettings';
import {
  ValidationService
} from '../validation/validation.service';

const DUMMY_AMOUNT: number = 15;
const DUMMY_DETAIL_AMOUNT: number = 5;

export class ContractDummy implements IContractDto {
  cn: string = '';
  name: string = '';
  description: string = '';
  category: string = '';
  status: ContractState = ContractState.Inactive;
  locations: ILocationDto[] = [];
  createdAt: string = '';
  lastEditedAt: string = '';
}

class ContractDetailDummy implements IContractDetailDto {
  cn: string = '0';
  category: string = '';
  locations: ILocationDto[];
  name: string = '';
  status: ContractState = ContractState.Inactive;
  description: string = '';

  constructor(locations: ILocationDto[] = []) {
    this.locations = locations;
  }
}

@Injectable()
export class ContractService extends IContractService {
  private readonly contractApi: IContractApi;
  private readonly contractTableService: IContractTableService;
  private readonly searchService: ISearchService;
  private readonly authService: IAuthService;
  private readonly pageFacade: IPageFacadeService;

  constructor(
    contractApi: IContractApi,
    contractTableService: IContractTableService,
    searchService: ISearchService,
    authService: IAuthService,
    pageFacade: IPageFacadeService,
  ) {
    super();
    this.contractApi = contractApi;
    this.contractTableService = contractTableService;
    this.searchService = searchService;
    this.authService = authService;
    this.pageFacade = pageFacade;
  }

  public getList(requestDto: IContractRequestDto): Observable<IPaginationResponseDto<IContractDto>> {
    return merge(
      ContractService.createDummyData(),
      this.loadContracts(requestDto)
    );
  }

  public getListWithoutDummy(requestDto: IContractRequestDto, distinctKey: keyof IContractDto): Observable<IContractDto[]> {
    return this.checkAndThrowDefaultError(
      this.loadContracts(requestDto)
        .pipe(
          map(paginationResult => Array.from(paginationResult.pageResult)),
          map(contractArr => {
          //filter data to be unique values
            const keySet: Set<IContractDto[keyof IContractDto]> = new Set(contractArr.map(contract => contract[distinctKey]));
            const values: (IContractDto|undefined)[] = Array.from(keySet).map(value => contractArr.find(obj => obj[distinctKey] === value));
            return values.filter((value): value is IContractDto => value !== null);
          })
        )
    );
  }

  public getContract(contractNumber: string): Observable<IContractDetailDto> {
    return this.checkAndThrowDefaultError(
      merge(
        ContractService.createContractDetailDummyData(),
        this.contractApi.getContract(contractNumber)
      )
    );
  }

  public update(modifyInput: IModifyContractInput): Observable<ILdapResponseDto> {
    return this.checkAndThrowDefaultError(
      this.contractApi.updateContract(modifyInput)
    );
  }

  public delete(ids: string[]): Observable<IcontractBulkModifyResponseDto> {
    return this.checkAndThrowDefaultError(
      this.contractApi.deleteContracts(ids)
    );
  }

  public search(term: string): Observable<ISearchTermsDto> {
    return this.checkAndThrowDefaultError(
      of(term)
        .pipe(
          filter(ValidationService.isLdapConform),
          switchMap(this.contractApi.search.bind(this.contractApi))
        )
    )
  }

  public updateContractsState(updateRequest: ContractStatusUpdateRequestDto): Observable<ILdapResponseDto> {
    return this.checkAndThrowDefaultError(
      this.contractApi.updateContractsState({
        cns: updateRequest.cns,
        state: updateRequest.newState
      })
    );
  }

  public searchFiltered(requestDto: IContractRequestDto): Observable<ISearchTermsDto> {
    return this.checkAndThrowDefaultError(
      this.contractApi.searchFiltered(requestDto)
    );
  }

  public static createDummyData(amount: number = DUMMY_AMOUNT): Observable<IPaginationResponseDto<IContractDto>> {
    return of(<IPaginationResponseDto<IContractDto>>{
      pageResult: [...Array(amount).keys()]
        .map(_ => new ContractDummy()),
      paginationInfo: new PaginationInfoDto()
    });
  }

  public getTableStructure(): IContractTableService {
    return this.contractTableService;
  }

  public preProcessSearchInput(): OperatorFunction<string, string> {
    return this.searchService.preProcessSearchInput(AppSettings.MIN_SEARCH_LENGTH_DEFAULT);
  }

  public convertSearchResults(termsDto: ISearchTermsDto): PmInputData[] {
    return this.searchService.convertSearchResults(termsDto);
  }

  public isDummyData(contracts: IBaseContractDto[]): boolean {
    return contracts.filter(contract => contract.cn === '0').length === DUMMY_DETAIL_AMOUNT
  }

  public getAuthService(): IAuthService {
    return this.authService;
  }

  public emitAlert(message: string, alertType: AlertType){
    this.pageFacade.emitAlert({
      label: message,
      alertType: alertType
    });
  }

  private loadContracts(requestDto: IContractRequestDto): Observable<IPaginationResponseDto<IContractDto>> {
    return this.checkAndThrowDefaultError(
      this.contractApi.getContracts(requestDto)
    );
  }

  private static createContractDetailDummyData(): Observable<IContractDetailDto> {
    return of(new ContractDetailDummy(
      [...Array(DUMMY_DETAIL_AMOUNT).keys()].map(_ => new LocationDummy())
    ))
  }
}

import {
  Component,
  OnInit
} from '@angular/core';
import {
  IPageFacadeService
} from '../../services/ipage-facade';
import {
  WithLoading,
  WithModal,
  WithRx
} from '../mixins';
import {
  IEmployeeService
} from '../../services/employee';
import {
  PmInputData,
  IMainLocationEmailsUpdate,
  AlertType,
  SecurityHistoryData,
  Row,
  Cell,
  CellType,
  TextCell,
  ColumnSortEvent,
  SortOrder
} from 'hagebau-coremedia';
import {
  IBaseLocationDto,
  ILocationService,
} from '../../services/location';
import {
  IlocationMinimalDto
} from '../../services/location/ilocation-minimal.dto';
import {
  IAuthService
} from '../../services/account/iauth.service';
import {
  ILdapResponseDto
} from '../../services/ildapResponse.dto';
import {
  IMainLocationHistoryDto
} from '../../services/location/IMainLocationHistoryDto';
import {
  IPaginationResponseDto
} from '../../services/pagination/IPaginationResponseDto';
import {
  LdapSortDirection
} from '../../services/LdapSortDirection';
import {
  ACTION_NAMES,
  HISTORY_COLUMN_HEADERS
} from '../../services/security/security-data';
import * as locationConstants from '../../services/location/location-data';
import {
  PaginationService
} from '../../services/pagination/pagination.service';
import {
  IPaginationService
} from '../../services/pagination/ipagination.service';
import {
  Subject,
  Subscription
} from 'rxjs';

const DEFAULT_MAINLOCATION_NUMBER: string = '100100';

@Component({
  selector: 'security',
  templateUrl: './security.component.html',
})
export class SecurityComponent extends WithRx(WithModal(WithLoading())) implements OnInit {

  // services
  private readonly pageFacade: IPageFacadeService;
  private readonly employeeService: IEmployeeService;
  private readonly authService: IAuthService;
  private readonly locationService: ILocationService;
  private readonly paginationService: IPaginationService;

  // state
  mainLocations: IBaseLocationDto[] = [];
  mainLocationsOptions: PmInputData[] = [];
  selectedMainLocationNumber: string = '';
  associatedLocations: string[] = [];
  approvedDomains: string[] = [];
  approvedEmails: string[] = [];
  history: SecurityHistoryData = {
    columnHeaders: [],
    rows: [],
    loading: false,
    pageSizes: [],
    selectedPageSize: 0,
    pagination: PaginationService.PAGINATION_DEFAULT
  };
  sortDirection: LdapSortDirection = LdapSortDirection.Descending;

  validationErrorDomainMessage: string = $localize`Please enter a valid list of email domains|Please enter a valid list of email domains`;
  validationErrorAddressMessage: string = $localize`Please enter a valid list of email addresses|Please enter a valid list of email addresses`;
  isServiceAdmin: boolean = false;
  isDelAdminOnly: boolean = false;

  private mainLocationSearchSubject$ = new Subject<string>();
  private mainLocationSearchSubscription: Subscription = new Subscription();

  constructor(
    pageFacade: IPageFacadeService,
    employeeService: IEmployeeService,
    authService: IAuthService,
    locationService: ILocationService,
    paginationService: IPaginationService,
  ) {
    super();
    this.pageFacade = pageFacade;
    this.employeeService = employeeService;
    this.authService = authService;
    this.locationService = locationService;
    this.paginationService = paginationService;
  }

  ngOnInit(): void {
    this.history.columnHeaders = HISTORY_COLUMN_HEADERS;
    this.history.pageSizes = locationConstants.PAGE_SIZES;
    this.history.pagination = locationConstants.PAGINATION;
    this.history.selectedPageSize = parseInt(this.history.pageSizes[0].value);
    this.pageFacade.setSecurityBreadcrumbs();
    this.getEmployeeMainLocations();
    this.isServiceAdmin = this.authService.isServiceAdmin();
    this.isDelAdminOnly = this.authService.isDelAdminOnly();
    this.activateMainLocationSearchSubject();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.mainLocationSearchSubscription.unsubscribe();
  }

  loadInitialSelection(): void {
    if (this.isDelAdminOnly) {
      if (this.mainLocationsOptions.length > 0) {
        this.selectedMainLocationNumber = this.mainLocationsOptions[0].value;
        this.onMainLocationSelect(this.mainLocationsOptions[0].key);
      }
    } else {
      this.selectedMainLocationNumber = DEFAULT_MAINLOCATION_NUMBER;
      const defaultOption: PmInputData | undefined = this.mainLocationsOptions.find(option => option.value === DEFAULT_MAINLOCATION_NUMBER);
      if (defaultOption) {
        this.onMainLocationSelect(defaultOption.key);
      }
    }
  }

  onMainLocationSelect(mainLocationCn: string) {
    this.selectedMainLocationNumber = this.mainLocations.find(mainLocation => mainLocation.cn === mainLocationCn)?.number || '';
    this.getMainLocationMemberLocations(mainLocationCn);
    this.approvedDomains = this.mainLocations.find(mainLocation => mainLocation.cn === mainLocationCn)!.allowedEmailDomains;
    this.approvedEmails = this.mainLocations.find(mainLocation => mainLocation.cn === mainLocationCn)!.allowedEmailAddresses;
    this.loadMainLocationHistory();
  }

  onInputChanged(input: string) {
    this.mainLocationSearchSubject$.next(input);
  }

  private activateMainLocationSearchSubject() {
    this.mainLocationSearchSubscription = this.mainLocationSearchSubject$
      .pipe(
        this.locationService.preProcessSearchInput(0),
        this.switchMapCancelable(input => this.employeeService.GetCurrentEmployeeMainLocations(input))
      )
      .subscribe((mainLocations: IBaseLocationDto[]) => {
        this.mainLocationsOptions = mainLocations.map((mainLocation: IBaseLocationDto): PmInputData => ({
          key: mainLocation.cn,
          value: mainLocation.number
        }));
      }, (error: Error) => {
        this.pageFacade.emitAlert({
          label: error.message,
          alertType: AlertType.ERROR
        })
      });
  }

  processMainLocationUpdate(mainLocationUpdate: IMainLocationEmailsUpdate) {
    this.locationService.updateMainLocationEmailsAndDomains({
      mainLocationNumber: mainLocationUpdate.mainLocationNumber,
      domains: mainLocationUpdate.domains,
      addresses: mainLocationUpdate.emails
    })
      .subscribe((_: ILdapResponseDto) => {
        this.pageFacade.emitAlert({
          label: $localize`Data Update Success|Update successful.`, alertType: AlertType.SUCCESS
        });
        this.loadMainLocationHistory();
      }, (error: Error) => {
        this.pageFacade.emitAlert({
          label: error.message, alertType: AlertType.ERROR
        })
      }
      )
  }

  private getEmployeeMainLocations() {
    this.employeeService.GetCurrentEmployeeMainLocations('')
      .subscribe((mainLocations: IBaseLocationDto[]) => {
        this.mainLocations = mainLocations;
        this.mainLocationsOptions = mainLocations.map((mainLocation: IBaseLocationDto): PmInputData => ({
          key: mainLocation.cn,
          value: mainLocation.number
        }));
        this.loadInitialSelection();
      })
  }

  private loadMainLocationHistory() {
    this.history.loading = true;
    this.locationService.getMainLocationHistory({
      mainLocationNumber: this.selectedMainLocationNumber,
      Sorting: {
        SortDirection: this.sortDirection
      },
      Pagination: {
        Page: this.history.pagination.current, PageAmount: this.history.selectedPageSize
      }
    })
      .subscribe((result: IPaginationResponseDto<IMainLocationHistoryDto>) => {
        //map history to table data
        this.history.loading = false;
        this.history.rows = this.mapHistoryItemsToRows(Array.from(result.pageResult));
        this.paginationService.applyPaginationAttributes(this.history.pagination, result.paginationInfo);
      })
  }

  private mapHistoryItemsToRows(historyData: IMainLocationHistoryDto[]): Row[]{
    return historyData.map((historyItem: IMainLocationHistoryDto, index: number) => {
      return {
        rowId: index.toString(),
        checked: false,
        cells: this.mapHistoryItemToRow(historyItem),
        warning: false,
        inactive: false
      };
    });
  }

  public mapHistoryItemToRow(historyItem: IMainLocationHistoryDto): Cell[] {
    return [
      {
        text: historyItem.date,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: historyItem.value,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: this.getHistoryActionLabel(historyItem.action),
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: historyItem.editor,
        type: CellType.TEXT_CELL
      } as TextCell
    ];
  }

  private getMainLocationMemberLocations(mainLocationCn: string) {
    this.locationService.GetChildLocationsForSecurity(mainLocationCn)
      .subscribe((locations: IlocationMinimalDto[]) => {
        this.associatedLocations = locations.map(loc => loc.number);
      })
  }

  onPageAmountChanged(pageAmount: PmInputData) {
    this.history.selectedPageSize = parseInt(pageAmount.value);
    this.loadMainLocationHistory();
  }

  onSort(sortData: ColumnSortEvent){
    this.sortDirection = sortData.sortOrder === SortOrder.Descending ? LdapSortDirection.Descending : LdapSortDirection.Ascending;
    this.loadMainLocationHistory();
  }
  showPage(pageNumber: number) {
    this.history.pagination.current = pageNumber;
    this.loadMainLocationHistory();
  }

  public getHistoryActionLabel(action: string): string {
    return (() => {
      switch (action.toLowerCase()) {
      case ACTION_NAMES.ADD:
        return $localize`added|Added`;
      case ACTION_NAMES.REMOVE:
        return $localize`removed|Removed`;
      default:
        return action;
      }
    })();
  }
}

export enum FullApplicationName {
  DEBTOR = "debtor.read",
  CREDITOR = "creditor.read",
  SERVICE_BONUS_DEBTOR = "service_bonus.read",
  SERVICE_BONUS_CREDITOR = "service_bonus.write",
  PAYMENT_ADVICE = "payment_advice.read",
  SUPPLIER_DIRECTORY_READ = "supplier_directory.read",
  SUPPLIER_DIRECTORY_MARKETING_PROFIT = "supplier_directory.marketingprofit",
  VALUATION_OVERVIEW = "valuation_overview.read",
}

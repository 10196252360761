import {
  IPasswordRenewInputDto
} from "./ipassword-renew-input.dto";

export class PasswordRenewInputDto implements IPasswordRenewInputDto {
  userEmail: string;
  oldPassword: string;
  newPassword: string;
  newPassword2: string;

  constructor() {
    this.userEmail = '';
    this.oldPassword = '';
    this.newPassword = '';
    this.newPassword2 = '';
  }
}

import {
  IPaginationInfoDto
} from "../../services/pagination/IPaginationInfoDto";
import {
  z
} from "zod";

export function createPaginatedResponseSchema<ItemType extends z.ZodTypeAny>(itemSchema: ItemType) {
  return z.object({
    paginationInfo: z.object({
      pageSize: z.number(),
      pageNumber: z.number(),
      totalAmount: z.number(),
    }),
    pageResult: z.array(itemSchema),
  });
}


/**
 * @deprecated use PaginatedResponseSchema instead
 */
export class PaginationInfoDto implements IPaginationInfoDto {
  pageSize: number = 0;
  pageNumber: number = 0;
  totalAmount: number = 0;

  //eslint disabled because here we convert the object from the http response in a typed object
  init<Other extends { [key in keyof PaginationInfoDto]: any }>(_data?: Other): PaginationInfoDto { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (_data) {
      this.pageSize = _data.pageSize;
      this.pageNumber = _data.pageNumber;
      this.totalAmount = _data.totalAmount;
    }
    return this;
  }
}

import {
  z
} from "zod";
import {
  CreateContractResponseDtoSchema
} from "./icontract-import-response.dto";

export const ContractBulkCreateResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  contracts: z.array(CreateContractResponseDtoSchema)
});

export type IContractBulkCreateResponseDto = z.infer<typeof ContractBulkCreateResponseDtoSchema>;

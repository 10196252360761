import {
  NavigationElement
} from "hagebau-coremedia";
import {
  Observable
} from "rxjs";
import {
  Path
} from "../../app/app-routing.module";
import {
  QueryParameterSubscriptionData
} from "./query-parameter.service";

export abstract class INavigationService {
  abstract getLogoPath(): string;
  abstract getNavigation(): Observable<NavigationElement[]>;
  abstract shouldFullscreen(): Observable<boolean>;
  abstract updateQueryParameters<T>(queryParams : T): Observable<boolean>;
  abstract clearQueryParameters(): Observable<boolean>;
  abstract getQueryParameters<T>(): Observable<QueryParameterSubscriptionData<T>>;
  abstract navigateWithParams(path: Path, urlParams: Map<string, string>): void;
  abstract navigate(path: Path): void;
}

<pm-employee-create-applications
  [identifier]="stepIdentifier"
  [wizardHeaderSteps]="wizardHeaderSteps"
  [rows]="rows"
  [columnNames]="columnNames"
  [selectAllCheckbox]="selectAllCheckbox"
  [loading]="loading[HgbLoadingKeys.LOAD_PERMISSIONS] || loading[HgbLoadingKeys.LOAD_PERMISSION_LOCATIONS] === undefined
  ||loading[HgbLoadingKeys.LOAD_PERMISSION_LOCATIONS]"
  [loadingSearch]="loading[HgbLoadingKeys.LOAD_PERMISSION_LOCATIONS_FROM_SEARCH]"
  backButtonLabel="back"
  i18n-backButtonLabel="back"
  nextButtonLabel="nextBtn|nextBtn"
  i18n-nextButtonLabel="nextBtn|nextBtn"
  headingText="select employee applications|select employee applications"
  i18n-headingText="select employee applications|select employee applications"
  headingTitle="selectApplications|select applications"
  i18n-headingTitle="selectApplications|select applications"
  (onBackClicked)="onBackClicked.emit(stepIdentifier)"
  (onNextClicked)="onNextButtonClicked()"
  (onMultiselectChange)="onRowMultiselectChange($event)"
  (onMultiselectInputChanged)="onLocationSearchInput($event)"
></pm-employee-create-applications>

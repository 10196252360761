import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AlertType,
  ImageData,
  PasswordData
} from "hagebau-coremedia";
import {
  IAuthService
} from "../../../services/account/iauth.service";
import {
  INavigationService
} from "../../../services/navigation";
import {
  IPasswordResetOutputDto
} from "../../../services/account/password/ipassword-reset-output.dto";
import {
  Banner,
  Logo
} from "../imageData";
import {
  Observable,
  Subject
} from "rxjs";
import {
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from "rxjs/operators";
import {
  ActivatedRoute
} from "@angular/router";
import {
  IPasswordResetInputDto
} from "../../../services/account/password/ipassword-reset-input.dto";
import {
  PasswordResetInputDto
} from "../../../services/account/password/password-reset-input.dto";
import {
  Path
} from "../../app-routing.module";

@Component({
  selector: 'password-reset',
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  //services
  private authService : IAuthService;
  private navigationService : INavigationService;
  private activatedRoute: ActivatedRoute;

  //data
  banner: ImageData = Banner;
  logo: ImageData = Logo;

  model: IPasswordResetInputDto = new PasswordResetInputDto();

  //state
  alertLabel: string = "";
  alertIsSuccess: boolean = false;
  loading: boolean = false;

  //types
  readonly AlertType: typeof AlertType = AlertType;

  //rxjs
  resetClickSubject$ = new Subject<PasswordData>();
  private componentIsDestroyed$ = new Subject<boolean>();

  constructor(
    authService: IAuthService,
    navigationService: INavigationService,
    activatedRoute: ActivatedRoute
  ) {
    this.authService = authService;
    this.navigationService = navigationService;
    this.activatedRoute = activatedRoute;

    this.activateResetClickObserver();
  }

  ngOnInit(): void {
    this.getResetCode();
  }

  ngOnDestroy() {
    this.componentIsDestroyed$.next(true);
    this.componentIsDestroyed$.complete();
  }

  redirectToLogin(_: void) {
    this.navigationService.navigate(Path.LOGIN)
  }

  private getResetCode() {
    this.activatedRoute.queryParams.subscribe(routingParams => {
      if (!routingParams['c']) {
        this.setAlertText($localize`password reset error|Your password couldn't be reset. Please check your link.`, false);
      }

      this.model.code = routingParams['c'];
    });
  }

  private activateResetClickObserver(): void {
    this.resetClickSubject$
      .pipe(
        takeUntil(this.componentIsDestroyed$),
        tap(this.showLoading.bind(this)),
        map(PasswordResetComponent.comparePasswords),
        map(this.mapToPasswordResetData.bind(this)),
        switchMap(this.resetPassword.bind(this)),
        take(1),
        map(PasswordResetComponent.checkLdapResponse),
        finalize(this.activateResetClickObserver.bind(this)),
        finalize(this.hideLoading.bind(this))
      )
      .subscribe(_ => {
        this.navigationService.navigate(Path.LOGIN);
      }, passwordResetOutput => {
        let msg: string = $localize`password reset error|Your password couldn't be reset. Please check your link.`;
        if (passwordResetOutput) {
          passwordResetOutput.ldapResponse ?
            msg += '<br/>' + passwordResetOutput.ldapResponse :
            msg += '<br/>' + passwordResetOutput;
        }
        this.setAlertText(msg, false);
      })
  }

  private resetPassword(resetData: IPasswordResetInputDto): Observable<IPasswordResetOutputDto> {
    return this.authService.resetPassword(resetData.password, resetData.code)
  }

  private mapToPasswordResetData(passwordData: PasswordData): IPasswordResetInputDto {
    this.model.password = passwordData.password1;
    return this.model;
  }

  private static checkLdapResponse(response: IPasswordResetOutputDto) {
    if(response.ldapResponse) {
      throw new Error("Ldap Server responded with: " + response.ldapResponse);
    }
  }

  private static comparePasswords(passwords: PasswordData) {
    if(passwords.password1 !== passwords.password2) {
      throw new Error("password mismatch")
    }
    return passwords;
  }

  private showLoading() {
    this.loading = true;
  }

  private hideLoading() {
    this.loading = false;
  }

  private setAlertText(msg: string, success: boolean) {
    this.alertIsSuccess = success;
    this.alertLabel = msg;
  }
}

import {
  IFilterDto
} from "../IFilterDto";

export interface IContractFilterDto {
  Cn: IFilterDto[],
  Name: IFilterDto[],
  Description: IFilterDto[],
  Category: IFilterDto[],
  Locations: IFilterDto[],
  Status: IFilterDto[],
}

import {
  Component,
  OnInit
} from '@angular/core';
import {
  IAuthService
} from "../../../services/account/iauth.service";
import {
  INavigationService
} from "../../../services/navigation";
import {
  Path
} from "../../app-routing.module";

// this component is only used for navigation
// it's needed to prevent logout call (localstorage reset) for pending changes
@Component({
  selector: '',
  template: ''
})
export class LogoutComponent implements OnInit {
  private authService: IAuthService;
  private navigationService: INavigationService;

  constructor(
    authService: IAuthService,
    navigationService: INavigationService,
  ) {
    this.authService = authService;
    this.navigationService = navigationService;
  }

  ngOnInit() {
    this.authService.logout();
    this.navigationService.navigate(Path.LOGIN);
  }
}

import {
  Component, Output
} from "@angular/core";
import {
  Banner,
  Logo
} from "../imageData";
import {
  ImageData,
  ImprintSection
} from "hagebau-coremedia";
import {
  INavigationService
} from "../../../services/navigation";
import {
  IPageFacadeService
} from "../../../services/ipage-facade";
import {
  Path
} from "../../app-routing.module";

const LINEBREAK: string = '';

@Component({
  selector: 'login-imprint',
  templateUrl: './login-imprint.component.html'
})
export class LoginImprintComponent {
  //services
  navigationService : INavigationService;
  pageFacadeService : IPageFacadeService;

  //data
  banner:ImageData = Banner;
  logo :ImageData = Logo

  @Output()
    imprintSections: ImprintSection[] = [
      {
        heading: $localize`imprintProvider`,
        paragraphs: [
          $localize`imprintHagebau`,
          $localize`imprintCompanyInfo`,
          $localize`imprintAddress`,
          $localize`imprintPhoneNumber`,
          $localize`imprintEmail`,
          $localize`imprintRegisterInfo`,
          $localize`imprintRegisterNumber`,
          LINEBREAK,
          $localize`imprintGeneralPartner`,
          $localize`imprintAdministrativeCompany`,
          $localize`imprintAdministrativeCompanyInfo`,
          LINEBREAK,
          $localize`imprintExecutiveManagement`,
          $localize`imprintSupervisoryChairman`,
        ]
      }
    ];

  constructor(
    navigationService: INavigationService,
    pageFacadeService: IPageFacadeService
  ) {
    this.navigationService = navigationService;
    this.pageFacadeService = pageFacadeService;
  }

  redirectToLogin(_: void) {
    this.navigationService.navigate(Path.LOGIN);
  }

  redirectToPrivacy(_: void) {
    this.navigationService.navigate(Path.PRIVACY);
  }
}

import {
    string,
    z
} from "zod";

export const AppPermissionDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
    appIds: z.string(),
    locationIds: z.array(string()),
    permission: z.string()
});

export type IAppPermissionDto = z.infer<typeof AppPermissionDtoSchema>;

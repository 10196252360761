import {
  ContractState
} from "./contract-state.enum";
import {
  z
} from "zod";

export const BaseContractDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  name: z.string(),
  description: z.string(),
  category: z.string(),
  status: z.nativeEnum(ContractState),
  createdAt: z.string(),
  lastEditedAt:  z.string()
})

export type IBaseContractDto = z.infer<typeof BaseContractDtoSchema>;

import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AlertType,
  ImageData,
  PasswordRenewData
} from 'hagebau-coremedia';
import {
  IAuthService
} from '../../../services/account/iauth.service';
import {
  INavigationService
} from '../../../services/navigation';
import {
  IPasswordResetOutputDto
} from '../../../services/account/password/ipassword-reset-output.dto';
import {
  Banner,
  Logo
} from '../imageData';
import {
  Observable,
  Subject
} from 'rxjs';
import {
  finalize,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import {
  ActivatedRoute
} from '@angular/router';
import {
  Path
} from '../../app-routing.module';
import {
  IPasswordRenewInputDto
} from '../../../services/account/password/ipassword-renew-input.dto';
import {
  PasswordRenewInputDto
} from '../../../services/account/password/password-renew-input.dto';
import {
  WithRx
} from '../../mixins';
import {
  ValidationException
} from '../../../network/api/http/api-exception';
import {
  IExceptionTranslatorService
} from '../../../services/exception/iexception-translator.service';
import {
  IPageFacadeService
} from '../../../services/ipage-facade';

@Component({
  selector: 'password-renew',
  templateUrl: './password-renew.component.html',
})
export class PasswordRenewComponent extends WithRx() implements OnInit, OnDestroy {
  //data
  banner: ImageData = Banner;
  logo: ImageData = Logo;
  model: IPasswordRenewInputDto = new PasswordRenewInputDto();
  //state
  alertLabel: string = '';
  alertType: AlertType = AlertType.ERROR;
  loading: boolean = false;
  formError: boolean = false;
  errorMessage: string = '';

  //types
  readonly AlertType: typeof AlertType = AlertType;
  //rxjs
  renewClickSubject$ = new Subject<PasswordRenewData>();
  //services
  private authService: IAuthService;
  private navigationService: INavigationService;
  private activatedRoute: ActivatedRoute;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly pageFacade: IPageFacadeService;

  constructor(
    authService: IAuthService,
    navigationService: INavigationService,
    activatedRoute: ActivatedRoute,
    exceptionTranslatorService : IExceptionTranslatorService,
    pageFacade: IPageFacadeService
  ) {
    super();
    this.authService = authService;
    this.navigationService = navigationService;
    this.activatedRoute = activatedRoute;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.pageFacade = pageFacade;
    this.activateRenewClickObserver();
  }

  private static checkLdapResponse(response: IPasswordResetOutputDto) {
    if (response.ldapResponse) {
      throw new Error('Ldap Server responded with: ' + response.ldapResponse);
    }
  }

  private comparePasswords(passwords: PasswordRenewData) {
    if (passwords.newPassword !== passwords.newPasswordRepeated) {
      this.formError = true;
      this.errorMessage = $localize`password mismatch error|The passwords do not match.`
      throw new Error('password mismatch');
    }
    return passwords;
  }

  ngOnInit(): void {
    this.getEmail();
  }

  redirectToLogin(_: void) {
    this.navigationService.navigate(Path.LOGIN)
  }

  onInputChange(){
    this.formError = false;
  }

  private getEmail() {
    this.activatedRoute.url.subscribe(
      url => {
        const email: string = url[url.length - 1].path;
        if (this.model.userEmail !== null) {
          this.model.userEmail = email;
        } else {
          this.setAlertText($localize`password renew error|Your password couldn't be renewed. Please check your link.`, AlertType.ERROR);
        }
      }
    )

  }

  private activateRenewClickObserver(): void {
    this.renewClickSubject$
      .pipe(
        tap(this.showLoading.bind(this)),
        map(this.comparePasswords.bind(this)),
        map(this.mapToPasswordRenewData.bind(this)),
        switchMap(this.renewPassword.bind(this)),
        take(1),
        map(PasswordRenewComponent.checkLdapResponse),
        finalize(this.activateRenewClickObserver.bind(this)),
        finalize(this.hideLoading.bind(this))
      )
      .pipe(this.catchValidationException(this.showError.bind(this)),)
      .subscribe(_ => {
        this.navigationService.navigate(Path.LOGIN);
      }, passwordRenewOutput => {
        this.pageFacade.emitAlert({
          label: passwordRenewOutput ?? $localize`password renew error|Your password couldn't be renewed. Please check your link.`,
          alertType: AlertType.ERROR
        });      })
  }

  private renewPassword(renewData: IPasswordRenewInputDto): Observable<IPasswordResetOutputDto> {
    return this.authService.renewPassword(renewData)
  }

  private mapToPasswordRenewData(passwordData: PasswordRenewData): IPasswordRenewInputDto {
    this.model.oldPassword = passwordData.oldPassword;
    this.model.newPassword = passwordData.newPassword;
    this.model.newPassword2 = passwordData.newPasswordRepeated;
    return this.model;
  }

  private showError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(
      validationException.validationErrors[0].message,
      validationException.validationErrors[0].members ?? []
    );
  }

  private showLoading() {
    this.loading = true;
  }

  private hideLoading() {
    this.loading = false;
  }

  private setAlertText(msg: string, alertType: AlertType) {
    this.alertType = alertType;
    this.alertLabel = msg;
  }
}

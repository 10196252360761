<pm-employee-create-locations
  [headingText]="headingText"
  [headingTitle]="headingTitle"
  [identifier]="stepIdentifier"
  [wizardHeaderSteps]="wizardHeaderSteps"
  [locationsOverviewLoading]="loading[HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW]"
  [locationsAssignedLoading]="loading[HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS]"
  [allLocationTableText]="allLocationsTableText"
  [assignedLocationTableText]="assignedLocationsTableText"
  [columnNames]="columnNames"
  [allLocationRows]="allLocations.rows"
  [assignedLocationRows]="assignedLocations.rows"
  [allLocationPagination]="allLocations.paginationData.pagination"
  [assignedLocationPagination]="assignedLocations.paginationData.pagination"
  [allLocationPageSizes]="allLocations.paginationData.pageSizes"
  [assignedLocationPageSizes]="assignedLocations.paginationData.pageSizes"
  [selectedAllLocationPageSize]="allLocations.paginationData.currentPageSize"
  [selectedAssignedLocationPageSize]="assignedLocations.paginationData.currentPageSize"
  [searchSuggestionsAllVisible]="allLocations.searchSuggestionsVisible"
  [searchSuggestionsAssignedVisible]="assignedLocations.searchSuggestionsVisible"
  [searchSuggestionsAll]="allLocations.inputData"
  [searchSuggestionsAssigned]="assignedLocations.inputData"
  [activeSearchTermsAll]="allLocations.searchTerms"
  [activeSearchTermsAssigned]="assignedLocations.searchTerms"
  desktopLocationEditNotice="employeeCreateDesktopLocationsEditNotice|Locations can only be edited on desktop."
  i18n-desktopLocationEditNotice="employeeCreateDesktopLocationsEditNotice|Locations can only be edited on desktop."
  backButtonLabel="back|Back"
  i18n-backButtonLabel="back|Back"
  nextButtonLabel="nextBtn|nextBtn"
  i18n-nextButtonLabel="nextBtn|nextBtn"
  [filtersAll]="filtersAll"
  [filtersAssigned]="filtersAssigned"
  [showSearchInput]="showSearchInput"
  (onAllLocationPageAmountChanged)="allLocationPageAmountChanged($event)"
  (onAssignedLocationPageAmountChanged)="assignedLocationPageAmountChanged($event)"
  (onAllLocationShowPage)="allLocationShowPage($event)"
  (onAssignedLocationShowPage)="assignedLocationShowPage($event)"
  (onAllLocationsSearchInputChanged)="allLocationsSearchInputChangeHandler($event)"
  (onAssignedLocationsSearchInputChanged)="assignedLocationsSearchInputChangeHandler($event)"
  (onAllFilterChanged)="onAllLocationsFilterChanged($event)"
  (onAssignedFilterChanged)="onAssignedLocationsFilterChanged($event)"
  (onAllFilterInputChanged)="onAllFilterInputChanged($event)"
  (onAssignedFilterInputChanged)="onAssignedFilterInputChanged($event)"
  (onSelectedAllLocationSearchTermsChanged)="allLocationSearchChipsChangedHandler($event)"
  (onSelectedAssignedLocationSearchTermsChanged)="assignedLocationsSearchChipsChangedHandler($event)"
  (onAddLocations)="addLocationsHandler($event)"
  (onRemoveLocations)="removeLocationsHandler($event)"
  (onBackClicked)="onBackButtonClicked()"
  (onNextClicked)="onNextButtonClicked()">
</pm-employee-create-locations>

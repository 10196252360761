import {
  Injectable
} from "@angular/core";
import {
  iif,
  Observable,
  of
} from "rxjs";
import {
  map,
  switchMap
} from "rxjs/operators";
import {
  IAuthService
} from "../iauth.service";
import {
  IAuthApi
} from "./iauth-api.service";
import {
  IAuthenticationDto
} from "./iauthentication.dto";
import {
  ROLES,
  Roles,
  TOKEN_KEY,
  USER_ROLES
} from './auth.constants'
import {
  IPasswordResetOutputDto
} from "../password/ipassword-reset-output.dto";
import {
  IPasswordResetRequestDto
} from "../password/ipassword-reset-request.dto";
import {
  IPasswordRenewInputDto
} from "../password/ipassword-renew-input.dto";
import {
  IPingAuthenticateModel
} from "./iping-authenticate-model.dto";
import {
  IEmployeeDto
} from "src/services/employee";

export enum AuthenticationStatus {
  Success = 'Success',
  ShouldResetPassword = 'ShouldResetPassword',
}

export type CurrentEmployeeWithRoles = {
  employee: IEmployeeDto;
  roles: string[];
}

@Injectable()
export class AuthService extends IAuthService {
  private readonly authApi: IAuthApi;

  constructor(authApi: IAuthApi) {
    super();
    this.authApi = authApi;
  }

  authenticateWithPingIdToken(authData: IPingAuthenticateModel): Observable<AuthenticationStatus> {
    return this.authApi.pingAuthenticate(authData)
      .pipe(
        switchMap((authResult: IAuthenticationDto) => iif(
          () => authResult.shouldResetPassword,
          of(AuthenticationStatus.ShouldResetPassword),
          this.proceedAuthentication(authResult)
        ))
      );
  }

  proceedAuthentication(authData: IAuthenticationDto): Observable<AuthenticationStatus> {
    AuthService.trySaveUserRoles(authData);
    AuthService.validateAuthToken(authData);
    AuthService.saveAuthToken(AuthService.mapAuthData(authData));
    return of(AuthenticationStatus.Success);
  }

  requestPasswordReset(email: string): Observable<string> {
    return this.authApi
      .requestPasswordReset(email)
      .pipe(
        map((_: IPasswordResetRequestDto) => email)
      )
  }

  resetPassword(password: string, code: string): Observable<IPasswordResetOutputDto> {
    return this.authApi
      .passwordReset(password, code)
      .pipe(
        map(AuthService.validateOneElementExists),
        map(AuthService.loginAllowed),
        map(entries => entries[0])
      );
  }

  renewPassword(renewData: IPasswordRenewInputDto): Observable<IPasswordResetOutputDto> {
    return this.authApi
      .passwordRenew(renewData)
      .pipe(
        map(AuthService.validateOneElementExists),
        map(AuthService.loginAllowed),
        map(entries => entries[0])
      );
  }
  isTokenValid(): boolean {
    return !!localStorage.getItem(TOKEN_KEY);
  }

  getUserRoles(): Roles[] {
    const roles: string|null = localStorage.getItem(USER_ROLES);
    if (roles !== null) {
      return JSON.parse(roles);
    }
    return [];
  }

  isRouteValid(requiredRoles: Roles[]): boolean {
    return requiredRoles.length === 0 || this.userHasSomeRoles(requiredRoles);
  }

  userHasSomeRoles(requiredRoles: Roles[]): boolean {
    return this.getUserRoles().some(role => requiredRoles.includes(role));
  }

  isDelAdminOnly(): boolean {
    const userRoles: string[] = this.getUserRoles();
    return userRoles.length === 1 && userRoles.includes(ROLES.DELEGATED_ADMIN);
  }

  isServiceAdmin(): boolean {
    const userRoles: string[] = this.getUserRoles();
    return userRoles.length === 1 && userRoles.includes(ROLES.SERVICE_ADMIN);
  }

  isSuperAdmin(): boolean {
    const userRoles: string[] = this.getUserRoles();
    return userRoles.includes(ROLES.SUPER_ADMIN) || userRoles.includes(ROLES.ADMIN);
  }

  isGlobalAdmin(): boolean {
    const userRoles: string[] = this.getUserRoles();
    return userRoles.includes(ROLES.GLOBAL_ADMIN);
  }

  logout(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_ROLES);
  }

  private static validateAuthToken(authData: IAuthenticationDto): IAuthenticationDto {
    if (!authData.accessToken) {
      throw new Error("no valid token provided");
    }
    return authData;
  }

  private static loginAllowed(authData: IPasswordResetOutputDto[]): IPasswordResetOutputDto[] {
    if (!authData[0].canLogin) {
      throw new Error("login is not allowed");
    }
    return authData;
  }

  private static validateOneElementExists<T>(authData: T[]): T[] {
    if (authData.length < 1) {
      throw new Error("no valid element exists");
    }
    return authData;
  }

  private static mapAuthData(authData: IAuthenticationDto): string {
    return authData.accessToken
  }

  private static saveAuthToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token)
  }

  private static trySaveUserRoles(authData: IAuthenticationDto): void {
    localStorage.setItem(USER_ROLES, JSON.stringify(authData.userRoles));
  }
}

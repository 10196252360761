import {
  Injectable
} from '@angular/core';
import {
  IAuthApi
} from '../../../services/account/auth/iauth-api.service';
import {
  AuthConfig,
  OAuthService
} from 'angular-oauth2-oidc';
import {
  IExternalLoginProviderInfoModel
} from '../../../services/account/auth/iexternal-login-provider-info.dto';
import {
  Path
} from '../../app-routing.module';
import {
  INavigationService
} from '../../../services/navigation';
import {
  IAuthService
} from '../../../services/account/iauth.service';
import {
  IHeaderService
} from '../../../services/header';
import {
  AlertType
} from 'hagebau-coremedia';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private authApi: IAuthApi;
  private externalLoginProviders: IExternalLoginProviderInfoModel[] = [];

  static readonly OPENID: string = 'OpenIdConnect';

  private navigationService: INavigationService;
  private oauthService: OAuthService
  private authService: IAuthService;
  private headerService: IHeaderService;

  constructor(
    authApi: IAuthApi,
    oauthService: OAuthService,
    authService: IAuthService,
    headerService: IHeaderService,
    navigationService: INavigationService
  ) {
    this.authApi = authApi;
    this.oauthService = oauthService;
    this.navigationService = navigationService;
    this.authService = authService;
    this.headerService = headerService;
  }

  public startOpenIdConnectLogin() {
    this.authApi
      .getExternalAuthenticationProviders()
      .subscribe((providers: IExternalLoginProviderInfoModel[]) => {
        this.externalLoginProviders = providers;
        this.startPingPopupFlow();
      });
  }

  private startPingPopupFlow() {
    const openIdProvider: IExternalLoginProviderInfoModel | undefined = this.externalLoginProviders.find(provider => provider.name === LoginService.OPENID);
    if (!openIdProvider) {
      const errorMessage: string = $localize`LoginErrorProviderNotFound|The chosen login is not configured. Please contact the application administrator.`;
      this.headerService.emitAlert({
        label: errorMessage,
        alertType: AlertType.ERROR
      });
      return;
    }
    const authConfig: AuthConfig = this.getOpenIdConnectConfig(openIdProvider);
    this.oauthService.configure(authConfig);
    this.oauthService.initLoginFlowInPopup()
      .catch(_ => { //on popup closed
        if (this.authService.isTokenValid()) {
          this.navigationService.navigate(Path.PING_REDIRECT);
          return;
        }
        const errorMessage: string = $localize`LoginErrorCouldNotAuthenticate|Could not successfully authenticate.`;
        this.headerService.emitAlert({
          label: errorMessage,
          alertType: AlertType.ERROR
        });
      });
  }

  private getOpenIdConnectConfig(loginProvider: IExternalLoginProviderInfoModel): AuthConfig {
    const authConfig: AuthConfig = new AuthConfig();
    authConfig.loginUrl = loginProvider.additionalParams['LoginUrl'];
    authConfig.issuer = loginProvider.additionalParams['Authority'];
    authConfig.skipIssuerCheck = loginProvider.additionalParams['ValidateIssuer'] === 'false';
    authConfig.clientId = loginProvider.clientId;
    authConfig.responseType = 'id_token';
    authConfig.redirectUri = window.location.origin + '/' + Path.LOGIN_POPUP;
    authConfig.scope = 'openid profile email';
    authConfig.requestAccessToken = false;
    authConfig.showDebugInformation = false;
    return authConfig;
  }
}

import {
  BreadcrumbElement,
  AlertType
} from 'hagebau-coremedia';
import {
  Observable
} from 'rxjs';

export interface AlertElement {
  label: string;
  alertType: AlertType;
}

export abstract class IHeaderService {
  abstract getBreadcrumbs(): Observable<BreadcrumbElement[]>;
  abstract getTitle(): Observable<string>;
  abstract getAlert(): Observable<AlertElement>;
  abstract emitBreadcrumbs(breadcrumbs: BreadcrumbElement[]): void;
  abstract emitTitle(headerTitle: string): void;
  abstract emitAlert(alertData: AlertElement): void;
}

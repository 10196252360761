/* eslint-disable */
//angular enforces any here
import {
  Injectable
} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {
  Observable
} from "rxjs";
import {
  TOKEN_KEY
} from './auth.constants'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string|null = localStorage.getItem(TOKEN_KEY);

    if (token) {
      const cloned: HttpRequest<any> = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + token),
      });
      return next.handle(cloned);
    }
    else {
      return next.handle(req);
    }
  }
}

import {
  Observable
} from "rxjs";
import {
  IAllianceDto
} from "./ialliance.dto";

export abstract class IAllianceApi {
  abstract getAlliances(): Observable<IAllianceDto[]>;
}

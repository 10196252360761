import {
  z
} from "zod";

export enum ImportStatus
{
  Success,
  SuccessUpdate,
  InProgress,
  LdapError,
  CreateContractError,
  UpdateContractError,
  NoName,
  NoDescription,
  NoCategory,
  MultipleExistingContractsFound,
  UnknownError,
  NoIsActiveStatus
}

export const CreateContractResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  name: z.string(),
  description: z.string(),
  category: z.string(),
  isActive: z.boolean(),
  response: z.nativeEnum(ImportStatus)
});

export type ICreateContractResponseDto = z.infer<typeof CreateContractResponseDtoSchema>;

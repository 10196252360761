import {
  PmInputFieldData,
  WizardHeaderStepElement
} from "hagebau-coremedia";
import {
  IexceptionHandlerService
} from "../exception/iexception-handler.service";
import {
  Observable
} from "rxjs";
import {
  IEmployeeCreateOrUpdateDto
} from "./i-employee-create-or-update.dto";
import {
  EmployeeState
} from "../employee";
import {
  ILdapResponseDto
} from "../ildapResponse.dto";
import {
  ILocationTableService
} from "../location";
import {
  IEmployeeCreateTableService
} from "./iemployee-create-table.service";
import {
  EmployeeTitle
} from "../employee/employee-title.enum";
import {
  IEmployeeUpdateDto
} from "../employee/iemployee-update.dto";

export abstract class IEmployeeCreateService extends IexceptionHandlerService {
    abstract getWizardHeaderSteps(): WizardHeaderStepElement[];

    abstract getMasterDataFields(): PmInputFieldData[];

    abstract convertImageBase64(image: File): Observable<string>;

    abstract createEmployee(employee: IEmployeeCreateOrUpdateDto): Observable<ILdapResponseDto>;

    abstract updateEmployee(employee: IEmployeeUpdateDto): Observable<ILdapResponseDto>;

    abstract mapStringToEmployeeState(input: string): EmployeeState;

    abstract getLocationTableService(): ILocationTableService;

    abstract mapStringToEmployeeTitle(input: string): EmployeeTitle;

    abstract getTableStructure(): IEmployeeCreateTableService;

    abstract isDelAdminOnly(): boolean;
}

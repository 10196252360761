import {
  z
} from "zod";
import {
  EmployeeState
} from "./employee-state.enum";
import {
  EmployeeManagementLevel
} from "./employee-management-level.enum";

export const BaseEmployeeDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  uid: z.string(),
  number: z.string(),
  givenName: z.string(),
  surName: z.string(),
  email: z.string(),
  status: z.nativeEnum(EmployeeState),
  photo: z.string(),
  managementLevel: z.nativeEnum(EmployeeManagementLevel),
  isSupplier: z.boolean()
});

export type IBaseEmployeeDto = z.infer<typeof BaseEmployeeDtoSchema>;

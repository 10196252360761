import {
  z
} from "zod";
import {
  CreateLocationResponseDtoSchema
} from "./ilocation-import-response.dto";

export const LocationBulkCreateResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  locations: z.array(CreateLocationResponseDtoSchema),
})

export type ILocationBulkCreateResponseDto = z.infer<typeof LocationBulkCreateResponseDtoSchema>;

import {
  Observable
} from 'rxjs';
import {
  catchError,
  map
} from "rxjs/operators";
import {
  Injectable
} from '@angular/core';
import {
  SwaggerClientService
} from "./http/swagger-client.service";
import {
  environment
} from "../../environments/environment";
import {
  RequestOptions
} from "./http/request-options";
import {
  AuthenticationDtoSchema,
  IAuthenticationDto
} from "../../services/account/auth/iauthentication.dto";
import {
  IAuthApi
} from "../../services/account/auth/iauth-api.service";
import {
  IPasswordResetRequestDto
} from "../../services/account/password/ipassword-reset-request.dto";
import {
  IPasswordResetOutputDto,
  PasswordResetOutputDtoSchema
} from "../../services/account/password/ipassword-reset-output.dto";
import {
  IPasswordRenewInputDto
} from "../../services/account/password/ipassword-renew-input.dto";
import {
  ExternalLoginProviderInfoSchema,
  IExternalLoginProviderInfoModel
} from "../../services/account/auth/iexternal-login-provider-info.dto";
import {
  IPingAuthenticateModel
} from "../../services/account/auth/iping-authenticate-model.dto";

@Injectable()
export class AuthApi extends IAuthApi {
  private swaggerClient: SwaggerClientService;

  private readonly baseUrl: string = environment.url;
  public static readonly LOGIN_URL: string = "/api/TokenAuth/LdapAuthenticate";
  public static readonly EXTERNAL_AUTH_PROVIDERS_URL: string = "/api/TokenAuth/GetExternalAuthenticationProviders";
  public static readonly PING_AUTHENTICATE_URL: string = '/api/TokenAuth/PingAuthenticate';

  constructor(swaggerClient: SwaggerClientService) {
    super();
    this.swaggerClient = swaggerClient;
  }

  pingAuthenticate(body: IPingAuthenticateModel): Observable<IAuthenticationDto> {
    let url: string = this.baseUrl + AuthApi.PING_AUTHENTICATE_URL;
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IPingAuthenticateModel> = new RequestOptions();
    requestOptions.body = body;

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => AuthenticationDtoSchema.parse(response)),
      catchError(error => {
        console.error("IAuthenticationDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getExternalAuthenticationProviders(): Observable<IExternalLoginProviderInfoModel[]> {
    let url: string = this.baseUrl + AuthApi.EXTERNAL_AUTH_PROVIDERS_URL;
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<string> = new RequestOptions()

    return this.swaggerClient.request("get", url, requestOptions, this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => ExternalLoginProviderInfoSchema.parse(dto))),
      catchError(error => {
        console.error("IExternalLoginProviderInfoModel", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  requestPasswordReset(email: string): Observable<IPasswordResetRequestDto> {
    let url: string = this.baseUrl + "/api/services/app/Account/LdapSendPasswordResetCode ";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<{emailAddress: string}> = new RequestOptions()
    requestOptions.body = {
      "emailAddress": email
    };

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(_ => ({
      } as IPasswordResetRequestDto))
    );
  }

  passwordReset(password: string, code: string): Observable<IPasswordResetOutputDto[]> {
    let url: string = this.baseUrl + "/api/services/app/Account/LdapResetPassword";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<{c: string, password: string}> = new RequestOptions()
    requestOptions.body = {
      "c": code,
      "password": password,
    };

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => PasswordResetOutputDtoSchema.parse(dto))),
      catchError(error => {
        console.error("IPasswordResetOutputDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  passwordRenew(renewData: IPasswordRenewInputDto): Observable<IPasswordResetOutputDto[]> {
    let url: string = this.baseUrl + "/api/services/app/Account/LdapRenewPassword";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<{oldPassword: string, newPassword: string, userEmail: string}> = new RequestOptions()
    requestOptions.body = {
      "oldPassword": renewData.oldPassword,
      "newPassword": renewData.newPassword,
      "userEmail": renewData.userEmail
    };

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => PasswordResetOutputDtoSchema.parse(dto))),
      catchError(error => {
        console.error("IPasswordResetOutputDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }
}

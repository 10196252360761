import {
  TextColumnHeader
} from "hagebau-coremedia";

export const HISTORY_COLUMN_HEADERS: TextColumnHeader[] =  [
  {
    columnId: '1',
    type: 'TextColumnHeader',
    title: $localize`date|Date`,
    flex: 100,
    sortable: true,
  },
  {
    columnId: '2',
    type: 'TextColumnHeader',
    title: $localize`domains/address|Domains/Address`,
    flex: 200,
    sortable: false,
  },
  {
    columnId: '3',
    type: 'TextColumnHeader',
    title: $localize`action|Action`,
    flex: 100,
    sortable: false,
  },
  {
    columnId: '4',
    type: 'TextColumnHeader',
    title: $localize`editor|Editor`,
    flex: 200,
    sortable: false,
  }
]

export enum ACTION_NAMES {
  ADD = "add",
  REMOVE = "remove",
}

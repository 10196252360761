import {
  Injectable
} from '@angular/core';
import {
  IExceptionTranslatorService
} from "./iexception-translator.service";

@Injectable()
export class ExceptionTranslatorService implements IExceptionTranslatorService{

  //eslint suppression because cognitive complexity is low
  translate(errorCode: string, messages: string[]): string { // eslint-disable-line complexity
    switch (errorCode) {
    case "1":
      return $localize`LocationWithNumberAlreadyExists|A location with that number already exists`;
    case "2":
      return $localize`MainLocationIsNotAvailable|Main location could not be found`;
    case "3":
      return $localize`LocationIsNotAvailable|location could not be found`;
    case "4":
      return $localize`DeletionException|Not all entries could be deleted`;
    case "5":
      return $localize`DeletionExceptionSubLocations|The following locations are sub locations ${messages.join(', ')}`;
    case "6":
      return $localize`DeletionExceptionEmployees|The following employees are still working on the locations ${messages.join(', ')}`;
    case "7":
      return $localize`ContractIsNotAvailable|contract could not be found`;
    case "8":
      return $localize`LocationContractsUpdateException|The following errors occurred while updating the locations contracts ${messages.join(', ') }`;
    case "9":
      return $localize`InvalidLdapStringException|Invalid search or filter string. The following characters are not allowed: \',+\"\\<>;=\' }`;
    case "15":
      return $localize`UniqueFieldViolationException|Unique field violation: the following values already exist: ${messages.join(', ') }`;
    case "16":
      return $localize`UserIsInActiveException|The following users are inactive: ${messages.join(', ')}`;
    case "20":
      return $localize`UserWithMailNotFoundException|Users with mail couldn't be found: ${messages.join(', ')}`;
    case "21":
      return $localize`UserNotPermittedException|User is not permitted for this action`;
    case "22":
      return $localize`DelAdminManageEmployeeException|User is not allowed to manage the following employees: ${messages.join(', ')}`;
    case "27":
      return $localize`WrongCurrentPassword|the current password entered does not match user ${messages.join(', ')}`;
    case "29":
      return $localize`AllSelectedUsersInactive|All selected users are inactive`;
    case "37":
      return $localize`UniqueFieldViolationException|Unique field violation: the following values already exist: ${messages.join(', ') }`;
    default:
      return $localize`UnknownValidationError|An error occurred`;
    }
  }
}

import {
  Injectable
} from '@angular/core';
import {
  Observable
} from "rxjs";
import {
  IPageFacadeService
} from "./ipage-facade";
import {
  AlertElement,
  IBreadcrumbsService,
  IHeaderService
} from "./header";
import {
  INavigationService, QueryParameterSubscriptionData
} from "./navigation";
import {
  Path
} from "../app/app-routing.module";
import {
  IContractDetailDto
} from './contract';
import {
  ILocationDetailDto
} from "./location";
import {
  IEmployeeDetailDto
} from "./employee/iemployee-detail.dto";

@Injectable()
export class PageFacadeService extends IPageFacadeService {
  private readonly breadcrumbsService: IBreadcrumbsService;
  private readonly headerService: IHeaderService;
  private readonly navigationService: INavigationService;

  constructor(
    breadcrumbsService: IBreadcrumbsService,
    headerService: IHeaderService,
    navigationService: INavigationService
  ) {
    super();
    this.breadcrumbsService = breadcrumbsService;
    this.headerService = headerService;
    this.navigationService = navigationService;
  }

  emitAlert(alertData: AlertElement): void {
    this.headerService.emitAlert(alertData);
  }

  getQueryParameters<T>(): Observable<QueryParameterSubscriptionData<T>> {
    return this.navigationService.getQueryParameters();
  }

  navigateWithParams(path: Path, urlParams: Map<string, string>): void {
    this.navigationService.navigateWithParams(path, urlParams);
  }

  setContractOverviewBreadcrumbs(): void {
    this.breadcrumbsService.setContractOverviewBreadcrumbs();
  }

  setContractDetailsBreadcrumbs(contract: IContractDetailDto): void {
    this.breadcrumbsService.setContractDetailsBreadcrumbs(contract)
  }

  setLocationOverviewBreadcrumbs(): void {
    this.breadcrumbsService.setLocationOverviewBreadcrumbs();
  }

  setLocationDetailsBreadcrumbs(location: ILocationDetailDto): void {
    this.breadcrumbsService.setLocationDetailsBreadcrumbs(location);
  }

  updateQueryParameters<T>(queryParams: T): Observable<boolean> {
    return this.navigationService.updateQueryParameters(queryParams);
  }

  setEmployeeOverviewBreadcrumbs() : void {
    this.breadcrumbsService.setEmployeeOverviewBreadcrumbs();
  }

  setEmployeeDetailsBreadcrumbs(employee: IEmployeeDetailDto) : void {
    this.breadcrumbsService.setEmployeeDetailsBreadcrumbs(employee);
  }

  setSecurityBreadcrumbs(): void {
    this.breadcrumbsService.setSecurityBreadcrumbs();
  }
}

import {
  IPasswordResetInputDto
} from "./ipassword-reset-input.dto";

export class PasswordResetInputDto implements IPasswordResetInputDto {
  userId!: number;
  resetCode?: string;
  password!: string;
  returnUrl?: string;
  singleSignIn?: string;
  code!: string;
}

import {
  ActionEvent,
  ChipData,
  ColumnHeader,
  ContractColumnNames,
  Filter,
  FilterData,
  MoreButtonElementType,
  PaginationControlData,
  PmInputData,
  PmMenuActionData,
  PmMenuActionType,
  Row,
  AlertType,
  SortKeyChangedEvent,
  SortOrder,
  ModalActionType,
  ImportData
} from 'hagebau-coremedia';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  bufferCount,
  debounceTime,
  filter,
  finalize,
  map,
  mergeMap,
  pluck,
  tap
} from 'rxjs/operators';
import {
  HgbLoadingKeys,
  WithLoading,
  WithModal,
  WithRx
} from '../mixins';
import {IPaginationResponseDto} from '../../services/pagination/IPaginationResponseDto';
import {
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import {
  ContractSortKeys, IContractBulkCreateResponseDto,
  IContractDto,
  IContractService,
  IContractTableService,
  ICreateContractResponseDto
} from '../../services/contract';
import {LdapSortDirection} from '../../services/LdapSortDirection';
import {
  QueryParameters,
  QueryParameterSubscriptionData
} from '../../services/navigation';
import {AppSettings} from '../../services/appSettings/appSettings';
import {Path} from '../app-routing.module';
import {ValidationException} from '../../network/api/http/api-exception';
import {IExceptionTranslatorService} from '../../services/exception/iexception-translator.service';
import {IPaginationService} from '../../services/pagination/ipagination.service';
import {IPageFacadeService} from '../../services/ipage-facade';
import {PaginationService} from '../../services/pagination/pagination.service';
import {ContractTableService} from '../../services/contract/contract-table.service';
import {IContractImportService} from '../../services/contract/icontract-import.service';
import {ImportFileError} from '../../services/contract/contract-import.service';
import {ContractState} from '../../services/contract/contract-state.enum';
import {ContractStatusUpdateRequestDto} from '../../services/contract/icontract-status-update-request.dto';
import {ICurrentPageInfo} from '../../services/pagination/IPaginationInfoDto';
import {IAuthService} from '../../services/account/iauth.service';

@Component({
  selector: 'contracts',
  templateUrl: './contracts.component.html',
})
export class ContractsComponent extends WithRx(WithModal(WithLoading())) implements OnInit, OnDestroy {
  //services
  private readonly contractService: IContractService;
  private readonly contractTableService: IContractTableService;
  private readonly pageFacade: IPageFacadeService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly paginationService: IPaginationService;
  private readonly contractImportService: IContractImportService;
  private readonly authService: IAuthService;

  //state
  contractsToBeDeleted: string[] = [];
  selectedContracts: string[] = [];
  contracts: IContractDto[] = [];
  searchSuggestionsVisible: boolean = false;
  currentModalAction: ModalActionType = MoreButtonElementType.VIEW;
  isDelAdminOnly: boolean = true;
  isServiceAdmin: boolean = false;
  contractsForStatusUpdate: string[] = [];

  //table
  columnNames: ContractColumnNames = {
    checkBoxColumn: '',
    nameColumn: $localize`name`,
    descriptionColumn: $localize`description`,
    categoryColumn: $localize`category`,
    locationsColumn: $localize`locations`,
    lastEditedAtColumn: $localize`lastEditedAt`,
    createdAtColumn: $localize`createdAt`,
    actionColumn: '',
  };
  menuActions: PmMenuActionData[] = [];
  activeFilters: FilterData[] = [];
  contractFilters: Filter[] = [];
  currentFilterIdentifier: keyof IContractDto = 'cn';
  searchSuggestions: PmInputData[] = [];
  selectedSearchTerms: ChipData[] = [];
  activeSortKey: ContractSortKeys = ContractSortKeys.None;
  activeSortDirection: LdapSortDirection = LdapSortDirection.Ascending;
  pagination: PaginationControlData = PaginationService.PAGINATION_DEFAULT;
  pageSizes: PmInputData[] = [];
  currentPageSize: number = 0;
  currentPage: number = 1;
  columnHeaders: ColumnHeader[] = [];
  rows: Row[] = [];
  alwaysShowMoreButton: boolean = true;
  resetTableHeaderCheckboxWithPagination: boolean = true;

  //rxjs
  private searchPreviewSubject$ = new Subject<string>();
  private filterSuggestionsSubject$ = new Subject<FilterData>();
  private deleteContractsSubject$ = new Subject<string[]>();
  private contractsStatusUpdateSubject$ = new Subject<ContractStatusUpdateRequestDto>();

  //subscriptions
  navigationSubscription: Subscription = new Subscription();
  deleteSubscription: Subscription = new Subscription();
  filterSuggestionsSubscription: Subscription = new Subscription();
  contractsStatusUpdateSubscription: Subscription = new Subscription();

  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  importData: ImportData = <ImportData>{
  }

  /*eslint max-params: ["warn", 6]*/
  constructor(
    contractService: IContractService,
    contractTableService: IContractTableService,
    pageFacade: IPageFacadeService,
    exceptionTranslatorService: IExceptionTranslatorService,
    paginationService: IPaginationService,
    contractImportService: IContractImportService,
    authService: IAuthService
  ) {
    super();
    this.contractService = contractService;
    this.contractTableService = contractTableService;
    this.pageFacade = pageFacade;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.paginationService = paginationService;
    this.contractImportService = contractImportService;
    this.authService = authService;
  }

  ngOnInit(): void {
    this.activateDeleteContractsObserver();
    this.activateFilterSuggestionsSubject();
    this.activateContractsStatusUpdateObserver();
    this.activateSearchPreviewSubject();
    this.contractFilters = this.contractTableService.getFilters();
    this.pagination = this.contractTableService.getPagination();
    this.menuActions = this.contractTableService.getMenuActions();
    this.currentPage = this.pagination.current;
    this.pageSizes = this.contractTableService.getPageSizes();
    this.currentPageSize = parseInt(this.pageSizes[0].value);
    this.isDelAdminOnly = this.authService.isDelAdminOnly();
    this.isServiceAdmin = this.authService.isServiceAdmin();
    this.activateQueryParameterChangeHandler();
    this.pageFacade.setContractOverviewBreadcrumbs();
    this.setDefaultImportData();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.navigationSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.filterSuggestionsSubscription.unsubscribe();
    this.contractsStatusUpdateSubscription.unsubscribe();
  }

  /** ---------- filter, search and sorting handlers ------- **/
  searchPreviewHandler(term: string) {
    this.searchPreviewSubject$.next(term);
  }

  searchChipsChangedHandler(chips: ChipData[]) {
    this.currentPage = 0;
    this.selectedSearchTerms = chips;
    this.updateQueryParameters();
  }

  // eslint-disable-next-line complexity
  onSortKeyChanged(event: SortKeyChangedEvent) {
    switch (event.sortKey) {
    case 'name':
      this.activeSortKey = ContractSortKeys.Name;
      break;
    case 'description':
      this.activeSortKey = ContractSortKeys.Description;
      break;
    case 'category':
      this.activeSortKey = ContractSortKeys.Category;
      break;
    case 'createdAt':
      this.activeSortKey = ContractSortKeys.CreatedAt;
      break;
    case 'lastEditedAt':
      this.activeSortKey = ContractSortKeys.LastEditedAt;
      break;
    default:
      this.activeSortKey = ContractSortKeys.None;
    }
    this.activeSortDirection = event.sortOrder === SortOrder.Descending ? LdapSortDirection.Descending : LdapSortDirection.Ascending;
    this.updateQueryParameters();
  }


  /** ------------------- Action Handlers ---------------- **/
  rowActionHandler(action: ActionEvent) {
    this.currentModalAction = action.actionType;
    switch (action.actionType) {
    case MoreButtonElementType.EDIT:
      this.editContract(action.rowId);
      break;
    case MoreButtonElementType.VIEW:
      this.viewContract(action.rowId);
      break;
    case MoreButtonElementType.DELETE:
      this.deleteRowActionHandler(action.rowId);
      break;
    case MoreButtonElementType.ACTIVATE:
      this.openStatusUpdateModal([action.rowId], ContractState.Active);
      break;
    case MoreButtonElementType.DEACTIVATE:
      this.openStatusUpdateModal([action.rowId], ContractState.Inactive);
      break;
    }
  }

  selectionHandler(selection: string[]) {
    this.selectedContracts = selection;
  }

  menuActionHandler(selectedAction: PmMenuActionData) {
    this.currentModalAction = selectedAction.type;
    if (selectedAction.type === PmMenuActionType.DELETE) {
      this.openDeleteModal(this.selectedContracts);
    }
    if (selectedAction.type === PmMenuActionType.ACTIVATE) {
      this.openStatusUpdateModal(this.selectedContracts, ContractState.Active);
    }
    if (selectedAction.type === PmMenuActionType.DEACTIVATE) {
      this.openStatusUpdateModal(this.selectedContracts, ContractState.Inactive);
    }
  }

  deleteRowActionHandler(rowId: string) {
    this.openDeleteModal([rowId]);
  }

  onConfirmClick(action: ModalActionType) {
    if ([MoreButtonElementType.DELETE, PmMenuActionType.DELETE].includes(action)) {
      this.onConfirmDelete();
    }
    if ([MoreButtonElementType.DEACTIVATE, PmMenuActionType.DEACTIVATE].includes(action)) {
      this.onConfirmStatusUpdate(ContractState.Inactive);
    }
    if ([MoreButtonElementType.ACTIVATE, PmMenuActionType.ACTIVATE].includes(action)) {
      this.onConfirmStatusUpdate(ContractState.Active);
    }
  }
  onConfirmDelete() {
    this.deleteContractsSubject$.next(this.contractsToBeDeleted);
    this.contractsToBeDeleted = this.selectedContracts = [];
  }

  onConfirmStatusUpdate(state: ContractState) {
    this.contractsStatusUpdateSubject$.next({
      cns: this.contractsForStatusUpdate, newState: state
    });
    this.contractsForStatusUpdate = this.selectedContracts = [];
  }

  private openStatusUpdateModal(contractsForStatusUpdate: string[], newState: ContractState) {
    if (newState === ContractState.Active) {
      if (contractsForStatusUpdate.length > 1) {
        this.setModalTexts($localize`ContractsActivationTitle|Activate Contracts`, $localize`ContractsActivationText|Would you like to Activate these contracts`);
      } else {
        this.setModalTexts($localize`SingleContractActivationTitle|Activate Contract`, $localize`SingleContractActivationText|Would you like to Activate this contract`);
      }
    } else {
      if (contractsForStatusUpdate.length > 1) {
        this.setModalTexts($localize`ContractsDeactivationTitle|Deactivate Contracts`, $localize`ContractsDeactivationText|Would you like to Deactivate these contracts`);
      } else {
        this.setModalTexts($localize`SingleContractDeactivationTitle|Deactivate Contract`, $localize`SingleContractDeactivationText|Would you like to Deactivate this contract`);
      }
    }
    this.showModal();
    this.contractsForStatusUpdate = contractsForStatusUpdate;
  }

  private editContract(rowId: string) {
    const cn: string | null = this.getContractCn(rowId);
    if (!cn) {
      this.contractService.emitAlert($localize`Action could not be performed|Action could not be performed`, AlertType.ERROR);
      return;
    }
    this.navigateToContractPath(Path.CONTRACT_DETAILS_EDIT, cn)
  }

  private viewContract(rowId: string) {
    const cn: string | null = this.getContractCn(rowId);
    if (!cn) {
      this.contractService.emitAlert($localize`Action could not be performed|Action could not be performed`, AlertType.ERROR);
      return;
    }
    this.navigateToContractPath(Path.CONTRACT_DETAILS_VIEW, cn)
  }

  onFilterChanged(filters: FilterData[]) {
    this.currentPage = 0;
    this.activeFilters = filters;
    this.updateQueryParameters();
  }

  onFilterInputChanged(filterSnippet: FilterData) {
    this.filterSuggestionsSubject$.next(filterSnippet);
  }

  private activateFilterSuggestionsSubject() {
    this.filterSuggestionsSubscription = this.filterSuggestionsSubject$
      .pipe(
        map<FilterData, FilterData>(filterData => (<FilterData>{
          ...filterData, identifier: filterData.identifier.trim(), value: filterData.value.trim()
        })),
        tap((filterData: FilterData) => this.currentFilterIdentifier = filterData.identifier as keyof IContractDto),
        filter(filterSnippet => !!filterSnippet.value && filterSnippet.value.length > AppSettings.MIN_FILTER_LENGTH),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY),
        map(this.contractTableService.createSearchRequestDtoForFilterSuggestions.bind(this.contractTableService)),
        this.switchMapCancelable(contractRequestDto => this.contractService.getListWithoutDummy(contractRequestDto, this.currentFilterIdentifier)),
        map(this.mapToFilterSuggestions.bind(this)),
        finalize(this.resetCurrentFilterIdentifier.bind(this))
      )
      .subscribe((filterSuggestions: PmInputData[]) => {
        this.contractFilters.forEach((filterData: Filter) => {
          if (!filterData.isDropdown) {
            const isActive: boolean = filterData.identifier === this.currentFilterIdentifier;
            filterData.filterSuggestions = !isActive ? [] : filterSuggestions;
            filterData.filterSuggestionsVisible = isActive;
          }
        });
      });
  }

  private resetCurrentFilterIdentifier(): void {
    this.currentFilterIdentifier = 'cn';
  }

  private mapToFilterSuggestions(searchResult: IContractDto[]): PmInputData[] {
    return searchResult.map(contract => {
      return {
        key: contract.cn, // this could be any number, we choose cn for maintaining relation between contract and suggestion in case we need to debug
        value: contract[this.currentFilterIdentifier]
      } as PmInputData
    });
  }


  private activateSearchPreviewSubject() {
    this.searchPreviewSubject$
      .pipe(
        this.contractService.preProcessSearchInput(),
        this.switchMapCancelable(this.contractService.search.bind(this.contractService)),
        map(searchSuggestions => this.contractService.convertSearchResults(searchSuggestions)),
      )
      .subscribe(inputData => {
        this.searchSuggestions = inputData;
        this.searchSuggestionsVisible = this.searchSuggestions.length > 0;
      },
      _ => {
        this.contractService.emitAlert($localize`Data Loading Error|Couldn't load data.`, AlertType.ERROR);
      }
      )
  }

  /** -------------------- Import ------------------- **/
  //
  onBulkImportClicked() {
    this.importData.isModalOpen = true;
  }

  hideImportModal() {
    this.setDefaultImportData();
  }

  private setDefaultImportData(): void {
    this.importData = this.contractImportService.getDefaultImportData();
  }

  importFile() {
    const {uploadText} = this.importData;
    this.importData.uploadText = this.importData.fileName;
    this.importData.uploadDisabled = this.importData.saveDisabled = true;
    const importRes: ICreateContractResponseDto[][] = [];

    let amountOfChunks: number = 0;
    this.contractImportService.getContractsFromFile(this.importData.file)
      .pipe(
        tap(row => {amountOfChunks = Math.ceil(row.amountOfEntriesInFile/AppSettings.IMPORT_CHUNK_SIZE)}),
        pluck('contractRow'),
        bufferCount(AppSettings.IMPORT_CHUNK_SIZE),
        map(importDto => this.contractImportService.importContracts(importDto)),
        mergeMap(importResult => importResult), //flatten observables
        pluck('contracts'),
        tap(importRes.push.bind(importRes)),
        finalize(() => this.finalizeImport(importRes.flat(), uploadText)),
      )
      .subscribe(_ => {
        this.importData.uploadProgress = Math.ceil(importRes.length / amountOfChunks * 100);
      }, (error: Error) => {
        this.contractService.emitAlert(error instanceof ImportFileError ? error.message : $localize`Import Error|Import Error.`, AlertType.ERROR);
      }
      );
  }

  fileSelected(file: File) {
    if (!file)
      return;
    this.importData = this.contractImportService.getImportDataByFile(this.importData, file);
  }

  finalizeImport(importRes: ICreateContractResponseDto[], uploadText: string): void {
    importRes.sort((prev, next) => (prev.name > next.name) ? 1 : -1);
    this.contractImportService.convertResponseToFile({
      contracts: importRes
    } as IContractBulkCreateResponseDto);
    this.importData.uploadDisabled = false;
    this.importData.uploadText = uploadText;
  }

  /** -------------------- Pagination Handlers ------------------- **/
  onShowPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updateQueryParameters();
  }

  onPageAmountChanged(pageAmount: PmInputData) {
    this.currentPageSize = parseInt(pageAmount.value);
    this.updateQueryParameters();
  }

  onResetSearchSuggestions() {
    this.searchSuggestionsVisible = false;
  }

  //cognitive complexity is low
  // eslint-disable-next-line complexity
  private updateQueryParameters() {
    this.pageFacade.updateQueryParameters({
      filters: this.activeFilters.length > 0 ? this.activeFilters : null,
      search: this.selectedSearchTerms.length > 0 ? this.selectedSearchTerms.map(chip => chip.text) : null,
      sortKey: this.activeSortKey !== ContractSortKeys.None ? this.activeSortKey : null,
      sortDirection: this.activeSortDirection !== LdapSortDirection.Ascending ? this.activeSortDirection : null,
      page: this.currentPage > 1 ? this.currentPage : null,
      pageSize: this.currentPageSize !== parseInt(this.pageSizes[0].value) ? this.currentPageSize : null
    } as QueryParameters<ContractSortKeys>);
  }

  private activateQueryParameterChangeHandler() {
    this.navigationSubscription = this.pageFacade
      .getQueryParameters<QueryParameters<ContractSortKeys>>()
      .pipe(
        filter((queryParameters: QueryParameterSubscriptionData<QueryParameters<ContractSortKeys>>) => queryParameters.url.startsWith('/' + Path.CONTRACT_OVERVIEW)),
      )
      .subscribe((queryParameterSubscriptionData: QueryParameterSubscriptionData<QueryParameters<ContractSortKeys>>) => {
        this.activeFilters = queryParameterSubscriptionData.queryParams.filters ?? [];
        this.selectedSearchTerms = queryParameterSubscriptionData?.queryParams.search?.map((term, index) => ({
          key: index,
          text: term
        })) ?? [];
        const currentPageSize: number = this.parseCurrentPageSize(queryParameterSubscriptionData.queryParams?.pageSize);
        const currentPage: number = this.paginationService.parseCurrentPageNumber(queryParameterSubscriptionData.queryParams?.page ?? 1, currentPageSize, parseInt(this.pagination.resultTotal));
        this.activeSortKey = queryParameterSubscriptionData.queryParams?.sortKey ?? ContractSortKeys.None;
        this.activeSortDirection = queryParameterSubscriptionData.queryParams?.sortDirection ?? LdapSortDirection.Ascending;
        this.pageFacade.setContractOverviewBreadcrumbs();
        this.handleContractsResult(currentPage, currentPageSize);
      })
  }

  private handleContractsResult(currentPage: number, currentPageSize: number) {
    this.createCancelableSwitchMap(this.loadContracts.bind(this, {
      currentPage, currentPageSize
    }))
      .pipe(
        this.catchValidationException(this.translateError.bind(this)),
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_CONTRACTS_OVERVIEW)
      ).subscribe((contractData: [Row[], IPaginationResponseDto<IContractDto>]) => {
        this.rows = contractData[0];
        this.contracts = Array.from(contractData[1].pageResult);
        this.paginationService.applyPaginationAttributes(this.pagination, contractData[1].paginationInfo);
        this.currentPageSize = contractData[1].paginationInfo.pageSize;
        this.pageFacade.setContractOverviewBreadcrumbs();
      }, (err: Error) => {
        this.rows = [];
        this.contractService.emitAlert(err.message, AlertType.ERROR);
      }
      );
  }

  private loadContracts(pageInfo: ICurrentPageInfo): Observable<[Row[], IPaginationResponseDto<IContractDto>]> {
    return this.contractService.getList(
      ContractTableService.mapContractRequestDto(
        this.activeFilters,
        {
          searchTerms: this.selectedSearchTerms.map(chip => chip.text),
          searchFields: AppSettings.CONTRACT_SEARCH_FIELDS
        },
        {
          SortKey: this.activeSortKey,
          SortDirection: this.activeSortDirection
        },
        {
          currentPage: pageInfo.currentPage,
          currentPageSize: pageInfo.currentPageSize
        }
      )
    )
      .pipe(
        map(paginatedResponse => [this.contractTableService.mapContractsToRows(Array.from(paginatedResponse.pageResult)), paginatedResponse]),
      )
  }

  private navigateToContractPath(path: Path, cn: string) {
    this.pageFacade.navigateWithParams(path, new Map<string, string>([['id', cn]]));
  }

  private openDeleteModal(contractsToBeDeleted: string[]) {
    if (contractsToBeDeleted.length > 1) {
      this.setModalTexts($localize`Delete Contracts|Delete Contracts`, $localize`Would you like to delete these contracts|Would you like to delete these contracts`);
    } else {
      this.setModalTexts($localize`Delete Contract|Delete Contract`, $localize`Would you like to delete this contract|Would you like to delete this contract`);
    }
    this.showModal();
    this.contractsToBeDeleted = contractsToBeDeleted;
  }

  private activateContractsStatusUpdateObserver(): void {
    this.contractsStatusUpdateSubscription = this.contractsStatusUpdateSubject$
      .pipe(
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_CONTRACTS_OVERVIEW),
        this.hideModalOperator()
      )
      .pipe(
        this.switchMapCancelable(this.contractService.updateContractsState.bind(this.contractService)),
      )
      .pipe(
        this.catchValidationException(this.translateError.bind(this))
      )
      .subscribe(() => {
        this.contractService.emitAlert($localize`Data Update Success|Update successful.`, AlertType.SUCCESS);
        this.handleContractsResult(this.currentPage, this.currentPageSize);
      }, (error: Error) => {
        this.pageFacade.emitAlert({
          label: error.message,
          alertType: AlertType.ERROR
        })
        this.activateContractsStatusUpdateObserver();
        // we don't know if just one contract deactivate didn't work or all of them, in the first case we have to reload data to picture the current application state
        this.handleContractsResult(this.currentPage, this.currentPageSize);
      }
      );
  }

  private activateDeleteContractsObserver(): void {
    this.deleteSubscription = this.deleteContractsSubject$
      .pipe(
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_CONTRACTS_OVERVIEW),
        this.hideModalOperator()
      )
      .pipe(
        this.switchMapCancelable(this.contractService.delete.bind(this.contractService)),
      )
      .pipe(
        this.catchValidationException(this.translateError.bind(this))
      )
      .subscribe(() => {
        this.contractService.emitAlert($localize`Data Deletion Success|Deletion successful.`, AlertType.SUCCESS);
        this.handleContractsResult(this.currentPage, this.currentPageSize);
      }, (error: Error) => {
        this.contractService.emitAlert(error.message, AlertType.ERROR);
        this.activateDeleteContractsObserver();

        // we don't know if just one contract delete didn't work or all of them, in the first case we have to reload data to picture the current application state
        this.handleContractsResult(this.currentPage, this.currentPageSize);
      }
      );
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }

  private parseCurrentPageSize(pageSize: number | null | undefined): number {
    return Math.min(
      Math.max(
        parseInt(this.pageSizes[0].value), pageSize ?? parseInt(this.pageSizes[0].value)
      ),
      parseInt(this.pageSizes[this.pageSizes.length - 1].value)
    );
  }

  private getContractCn(rowId: string): string | null {
    return this.contracts?.find(contract => contract.cn === rowId)?.cn ?? null;
  }
}

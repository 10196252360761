<employee-create-basic-details-step
  [hidden]="currentStepIdentifier !== STEP_IDENTIFIERS.STEP_BASIC_DETAILS"
  [wizardHeaderSteps]="wizardHeaderSteps"
  [(employeeToBeCreated)]="employeeToBeCreatedOrEdited"
  [loading]="loading[HgbLoadingKeys.LOAD_DETAILS]"
  (onBackClicked)="handleStepBackButtonClick()"
  (onNextClicked)="handleStepNextButtonClick()"
  (employeeToBeCreatedChange)="updateEmployeeBasicDetails($event)"
></employee-create-basic-details-step>

<employee-create-locations-step
  [hidden]="currentStepIdentifier !== STEP_IDENTIFIERS.STEP_LOCATIONS"
  [wizardHeaderSteps]="wizardHeaderSteps"
  [initialLocationCns]="employeeToBeCreatedOrEdited.locationCns"
  (onBackClicked)="handleStepBackButtonClick()"
  (onNextClicked)="handleStepNextButtonClick()"
  (onLocationsUpdated)="updateEmployeeLocations($event)"
  (onLocationRowsSelected)="updateEmployeeSelectedLocationRows($event)"
></employee-create-locations-step>

<employee-create-hierarchy-step
  [hidden]="currentStepIdentifier !== STEP_IDENTIFIERS.STEP_HIERARCHY"
  [selectedEmployeeManagementLevel]="employeeToBeCreatedOrEdited.managementLevel"
  [wizardHeaderSteps]="wizardHeaderSteps"
  (onBackClicked)="handleStepBackButtonClick()"
  (onNextClicked)="handleStepNextButtonClick()"
  (onHierarchyUpdated)="updateEmployeeManagementLevel($event)"
></employee-create-hierarchy-step>

<employee-create-applications-step
  [hidden]="currentStepIdentifier !== STEP_IDENTIFIERS.STEP_APPLICATIONS"
  [wizardHeaderSteps]="wizardHeaderSteps"
  [initialPermissions]="employeeToBeCreatedOrEdited.appPermissions"
  [currentCreationStep]="currentStepIdentifier"
  (onBackClicked)="handleStepBackButtonClick()"
  (onNextClicked)="handleStepNextButtonClick()"
  (onAppPermissionsUpdated)="updateEmployeeAppPermissions($event)"
></employee-create-applications-step>

<employee-create-administration-step
  [hidden]="currentStepIdentifier !== STEP_IDENTIFIERS.STEP_ADMINISTRATION"
  [wizardHeaderSteps]="wizardHeaderSteps"
  [currentCreationStep]="currentStepIdentifier"
  [assignedLocationCns]="employeeToBeCreatedOrEdited.locationCns"
  [assignedRows]="selectedLocationRows"
  (onBackClicked)="handleStepBackButtonClick()"
  (onNextClicked)="handleStepNextButtonClick()"
  (onAdministrationUpdated)="updateEmployeeAdministration($event)"
></employee-create-administration-step>

<pm-modal
  *ngIf="isModalOpen"
  [headerText]="modalText.header"
  [subText]="modalText.text"
  [labelNo]="buttonLabel.negative"
  [labelYes]="buttonLabel.positive"
  (onCloseClicked)="hideModal()"
  (onConfirmClicked)="onConfirm()"
  (onCancelClicked)="hideModal()"
></pm-modal>

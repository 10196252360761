import {
  z
} from "zod";

export const PasswordResetOutputDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  canLogin: z.boolean(),
  userName: z.string(),
  ldapResponse:  z.optional(z.string())
});

export type IPasswordResetOutputDto = z.infer<typeof PasswordResetOutputDtoSchema>;

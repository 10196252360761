import {
  z
} from "zod";

export const LdapResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  success: z.boolean(),
  errorMessage: z.string().nullable(),
});

export type ILdapResponseDto = z.infer<typeof LdapResponseDtoSchema>;

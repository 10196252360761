import {
  ILocationDetailDto
} from "../location";
import {
  IContractDetailDto
} from "../contract";
import {
  IEmployeeDetailDto
} from "../employee/iemployee-detail.dto";

export abstract class IBreadcrumbsService {
  abstract setLocationOverviewBreadcrumbs(): void;
  abstract setLocationDetailsBreadcrumbs(location: ILocationDetailDto): void;
  abstract setEmployeeOverviewBreadcrumbs(): void;
  abstract setEmployeeDetailsBreadcrumbs(employee: IEmployeeDetailDto): void;
  abstract setContractOverviewBreadcrumbs(): void;
  abstract setContractDetailsBreadcrumbs(contract: IContractDetailDto): void;
  abstract setSecurityBreadcrumbs(): void;
}

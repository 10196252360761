import {
  z
} from "zod";
import {
  BaseContractDtoSchema
} from "./icontractBase.dto";
import {
  createPaginatedResponseSchema
} from "../../network/api/pagination-api.service";

export const ContractDtoSchema = BaseContractDtoSchema.extend({ // eslint-disable-line @typescript-eslint/typedef
  locations: z.array(z.object({
    cn: z.string(),
    number: z.string(),
    description: z.string(),
    branch: z.string(),
    mainLocation: z.string(),
    employeeCount: z.number(),
    isIncomplete: z.boolean()
  }))
})

export const PaginatedContractDtoSchema = createPaginatedResponseSchema(ContractDtoSchema) // eslint-disable-line @typescript-eslint/typedef


export type IContractDto = z.infer<typeof ContractDtoSchema>;
export type IPaginatedContractDto = z.infer<typeof PaginatedContractDtoSchema>;

import {
  EmployeeState
} from "./employee-state.enum";
import {
  EmployeeManagementLevel
} from "./employee-management-level.enum";
import {
  z
} from "zod";
import {
  BaseLocationDtoSchema, LocationDtoSchema
} from "../location";
import {
  AppPermissionDtoSchema,
} from "../appPermission/IAppPermissionDto";
import {
  EmployeeTitle
} from "./employee-title.enum";

export const EmployeeDetailDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  title: z.nativeEnum(EmployeeTitle),
  firstName: z.string(),
  surname: z.string(),
  email: z.string(),
  status: z.nativeEnum(EmployeeState),
  photo: z.string(),
  isSupplier: z.boolean(),
  phoneNumber: z.string(),
  mobileNumber: z.string(),
  isCheckedTermsAndConditions: z.boolean(),
  departmentFunction: z.string(),
  sector: z.string(),
  hgbUserNumber: z.string(),
  mainLocations: z.array(BaseLocationDtoSchema),
  isDelAdmin: z.boolean(),
  locations: z.array(LocationDtoSchema),
  delAdminLocations: z.array(LocationDtoSchema),
  managementLevel: z.nativeEnum(EmployeeManagementLevel),
  appPermissions: z.array(AppPermissionDtoSchema)
});

export type IEmployeeDetailDto = z.infer<typeof EmployeeDetailDtoSchema>;

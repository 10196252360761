import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ActionEvent,
  ChipData,
  EmployeeColumnNames,
  Filter,
  FilterData,
  MoreButtonElementType,
  PmInputData,
  PmInputFilterComponent,
  PmMenuActionData,
  PmMenuActionType,
  Row,
  SortKeyChangedEvent,
  SortOrder,
  ImportData,
  AlertType,
  TableData
} from 'hagebau-coremedia';
import {
  bufferCount,
  filter,
  finalize,
  map,
  mergeMap,
  pluck,
  tap
} from 'rxjs/operators';
import {
  HgbLoadingKeys,
  WithLoading,
  WithModal,
  WithRx
} from '../mixins';
import {IPaginationResponseDto} from '../../services/pagination/IPaginationResponseDto';
import {
  combineLatest,
  iif,
  Observable,
  of,
  Subject,
  Subscription
} from 'rxjs';
import {
  EmployeeSortKeys,
  EmployeeState,
  IEmployeeDto,
  IEmployeeService,
  IEmployeeTableService
} from '../../services/employee';
import {IPageFacadeService} from '../../services/ipage-facade';
import {IPaginationService} from '../../services/pagination/ipagination.service';
import {
  QueryParameters,
  QueryParameterSubscriptionData
} from '../../services/navigation';
import {Path} from '../app-routing.module';
import {LdapSortDirection} from '../../services/LdapSortDirection';
import {EmployeeTableService} from '../../services/employee/employee-table.service';
import {AppSettings} from '../../services/appSettings/appSettings';
import {IAppPermissionService} from '../../services/appPermission/iappPermission.service';
import {ILocationService} from '../../services/location';
import {ICurrentPageInfo} from '../../services/pagination/IPaginationInfoDto';
import {ImportFileError} from '../../services/employee/employee-import.service';
import {ICreateEmployeeResponseDto} from '../../services/employee/iemployee-import-response.dto';
import {IEmployeeBulkCreateResponseDto} from '../../services/employee/iemployee-bulk-create-response.dto';
import {
  EMPLOYEE_FILTER_IDENTIFIERS,
  employeeFilters,
  employeeOverviewTableColumnNames,
  employeeImportData,
  currentEmployeeDefaultData
} from '../../services/employee/data';
import {CurrentEmployeeWithRoles} from '../../services/account/auth/auth.service';
import {IAuthService} from '../../services/account/iauth.service';
import {defaultTableData} from '../../services/shared/table-data';
import {IEmployeePipeService} from '../../services/employee/i-employee-pipe.service';
import {IEmployeeImportService} from '../../services/employee/iemployee-import.service';

const EMPLOYEE_CREATE_DEFAULT_INDEX: string = '0';
type PaginationWithCurrentEmployee = { pageInfo: ICurrentPageInfo, currentEmployee: CurrentEmployeeWithRoles };

@Component({
  selector: 'employees',
  templateUrl: './employees.component.html',
})

/*eslint max-lines: ["error", {"max": 620, "skipBlankLines": true, "skipComments": true}]*/
export class EmployeesComponent extends WithRx(WithModal(WithLoading())) implements OnInit, OnDestroy {
  //services
  private readonly employeeService: IEmployeeService;
  private readonly pageFacade: IPageFacadeService;
  private readonly employeeTableService: IEmployeeTableService;
  private readonly paginationService: IPaginationService;
  private readonly appPermissionService: IAppPermissionService;
  private readonly locationService: ILocationService;
  private readonly authService: IAuthService;
  private readonly employeePipeService: IEmployeePipeService;
  private readonly employeeImportService: IEmployeeImportService;

  //state
  selectedEmployees: string[] = [];
  employees: IEmployeeDto[] = [];
  showWarning: boolean = false;
  searchSuggestionsVisible: boolean = false;
  currentEmployee: CurrentEmployeeWithRoles = currentEmployeeDefaultData;
  employeesToBeDeleted: string[] = [];
  isServiceAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  isGlobalAdmin: boolean = false;
  importData: ImportData = employeeImportData;

  employeeCreationButtonDisabled: boolean = false;
  employeeCreationButtonLabel: string = $localize`create-employee|Create employee`;

  //subscriptions
  navigationSubscription: Subscription = new Subscription();
  searchPreviewSubscription: Subscription = new Subscription();
  locationFilterSuggestionsSubscription: Subscription = new Subscription();
  mainLocationFilterSuggestionsSubscription: Subscription = new Subscription();
  deleteSubscription: Subscription = new Subscription();

  //table
  tableData: TableData = {
    ...defaultTableData,
    alwaysShowMoreButton: true
  };
  activeFilters: FilterData[] = [];
  activeSortKey: EmployeeSortKeys = EmployeeSortKeys.None;
  activeSortDirection: LdapSortDirection = LdapSortDirection.Ascending;
  columnNames: EmployeeColumnNames = employeeOverviewTableColumnNames;
  employeeFilters: Filter[] = [];
  menuActions: PmMenuActionData[] = [];
  searchSuggestions: PmInputData[] = [];
  selectedSearchTerms: ChipData[] = [];


  //rxjs
  private searchPreviewSubject$ = new Subject<string>();
  private locationFilterSuggestionsSubject$ = new Subject<FilterData>();
  private mainLocationFilterSuggestionsSubject$ = new Subject<FilterData>();
  private deleteEmployeesSubject$ = new Subject<string[]>();

  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  /*eslint max-params: ["warn", 6]*/
  constructor(
    employeeService: IEmployeeService,
    employeeTableService: IEmployeeTableService,
    pageFacade: IPageFacadeService,
    paginationService: IPaginationService,
    appPermissionService: IAppPermissionService,
    locationService: ILocationService,
    authService: IAuthService,
    employeePipeService: IEmployeePipeService,
    employeeImportService: IEmployeeImportService
  ) {
    super();
    this.employeeService = employeeService;
    this.employeeTableService = employeeTableService;
    this.pageFacade = pageFacade;
    this.paginationService = paginationService;
    this.appPermissionService = appPermissionService;
    this.locationService = locationService;
    this.authService = authService;
    this.employeePipeService = employeePipeService;
    this.employeeImportService = employeeImportService;
    this.setDefaultImportData();
  }

  ngOnInit(): void {
    this.activateSearchPreviewSubject();
    this.activateLocationFilterSuggestionsSubject();
    this.activateMainLocationFilterSuggestionsSubject();
    this.activateDeleteEmployeesObserver();
    this.employeeFilters = employeeFilters;
    this.setAppPermissionFilterOptions();
    this.tableData.selectedPageSize = parseInt(this.tableData.pageSizes[0].value);
    this.isServiceAdmin = this.authService.isServiceAdmin();
    this.isSuperAdmin = this.authService.isSuperAdmin();
    this.isGlobalAdmin = this.authService.isGlobalAdmin();
    this.menuActions = this.employeeService.getTableStructure().getMenuActions();
    this.activateQueryParameterChangeHandler();
    this.pageFacade.setEmployeeOverviewBreadcrumbs();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.searchPreviewSubscription.unsubscribe();
    this.locationFilterSuggestionsSubscription.unsubscribe();
    this.mainLocationFilterSuggestionsSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }

  /** ---------- Row Selection ----------- **/
  selectionHandler(selection: string[]) {
    this.selectedEmployees = selection;
  }

  /** -------------------- Pagination Handlers ------------------- **/
  onPageAmountChanged(pageAmount: PmInputData) {
    this.tableData.selectedPageSize = parseInt(pageAmount.value);
    this.updateQueryParameters();
  }

  onShowPage(pageNumber: number) {
    this.tableData.pagination.current = pageNumber;
    this.updateQueryParameters();
  }

  /** ------------------- Action Handlers ---------------- **/
  // eslint-disable-next-line complexity
  rowActionHandler(action: ActionEvent) {
    switch (action.actionType) {
    case MoreButtonElementType.ACTIVATE:
      this.setEmployeesActiveState([action.rowId], EmployeeState.Active);
      break;
    case MoreButtonElementType.DEACTIVATE:
      this.setEmployeesActiveState([action.rowId], EmployeeState.Inactive);
      break;
    case MoreButtonElementType.VIEW:
      this.viewEmployee(action.rowId);
      break;
    case MoreButtonElementType.EDIT:
      this.editEmployee(action.rowId);
      break;
    case MoreButtonElementType.RESET_PASSWORD:
      this.resetEmployeePasswords([action.rowId]);
      break;
    case MoreButtonElementType.DELETE:
      this.deleteRowActionHandler(action.rowId);
      break;
    }
  }

  menuActionHandler(selectedAction: PmMenuActionData) {
    switch (selectedAction.type) {
    case PmMenuActionType.ACTIVATE:
      this.setEmployeesActiveState(this.selectedEmployees, EmployeeState.Active);
      break;
    case PmMenuActionType.DEACTIVATE:
      this.setEmployeesActiveState(this.selectedEmployees, EmployeeState.Inactive);
      break;
    case PmMenuActionType.RESET_PASSWORD:
      this.resetEmployeePasswords(this.selectedEmployees);
      break;
    case PmMenuActionType.DELETE:
      this.openDeleteModal(this.selectedEmployees);
      break;
    }
  }

  private resetEmployeePasswords(rowIds: string[]): void {
    const mails: string[] = this.getEmployeesFromRow(rowIds).map(employee => employee.email);
    const employees: IEmployeeDto[] = this.getEmployeesFromRow(rowIds);
    if (this.shouldDenyActionThroughEmailSecurity(employees)) {
      this.emitAlert($localize`EmployeeHasForbiddenDomainOrEmail|Passwords for Employees with forbidden domains or email addresses can not be reset.`, AlertType.ERROR);
      return;
    }
    this.employeeService.resetUserPasswords({
      Mails: mails
    })
      .pipe(
        this.catchValidationException(this.employeeService.getTranslateError.bind(this.employeeService))
      )
      .subscribe(_ => {
        this.pageFacade.emitAlert({
          label: $localize`Password Reseted | Passwords succesfully reseted`,
          alertType: AlertType.SUCCESS
        })
      },
      (error: Error) => {
        this.emitAlert(error.message, AlertType.ERROR);
      });
  }

  private deleteRowActionHandler(rowId: string) {
    this.openDeleteModal([rowId]);
  }

  private openDeleteModal(employeesToBeDeleted: string[]) {
    const isCurrentEmployeeInSelection: boolean = employeesToBeDeleted.find(emp => emp === this.currentEmployee.employee.uid) !== undefined;
    if (isCurrentEmployeeInSelection) {
      this.emitAlert($localize`EmployeeMustNotEditThemselves|You must not edit yourself.`, AlertType.ERROR);
      return;
    }
    employeesToBeDeleted.length > 1
      ? this.setModalTexts($localize`Delete Employees|Delete Employees`, $localize`Would you like to delete these employees|Would you like to delete these employees`)
      : this.setModalTexts($localize`Delete Employee|Delete Employee`, $localize`Would you like to delete this employee|Would you like to delete this employee`);
    this.showModal();
    this.employeesToBeDeleted = employeesToBeDeleted;
  }

  onConfirmDelete() {
    this.deleteEmployeesSubject$.next(this.employeesToBeDeleted);
    this.employeesToBeDeleted = [];
    this.selectedEmployees = [];
  }

  private activateDeleteEmployeesObserver(): void {
    this.deleteSubscription = this.deleteEmployeesSubject$
      .pipe(
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_EMPLOYEE_OVERVIEW),
        this.hideModalOperator(),
        this.switchMapCancelable(this.employeeService.delete.bind(this.employeeService)),
        this.catchValidationException(this.employeeService.getTranslateError.bind(this.employeeService))
      )
      .subscribe(() => {
        this.emitAlert($localize`Data Deletion Success|Deletion successful.`, AlertType.SUCCESS);
        this.handleEmployeeResult(this.tableData.pagination.current, this.tableData.selectedPageSize);
      }, (error: Error) => {
        this.emitAlert(error.message, AlertType.ERROR);
        this.activateDeleteEmployeesObserver();
        // we don't know if just one employee delete didn't work or all of them,
        // in the first case we have to reload data to picture the current application state
        this.handleEmployeeResult(this.tableData.pagination.current, this.tableData.selectedPageSize);
      });
  }

  onEmployeeCreateClicked(): void {
    this.pageFacade.navigateWithParams(Path.EMPLOYEE_CREATE_VIEW, new Map<string, string>([['id', EMPLOYEE_CREATE_DEFAULT_INDEX]]));
  }

  /** ---------- Filter, Search and Sorting Handlers ------- **/
  onResetSearchSuggestions() {
    this.searchSuggestionsVisible = false;
  }

  searchPreviewHandler(term: string) {
    this.searchPreviewSubject$.next(term);
  }

  searchChipsChangedHandler(chips: ChipData[]) {
    this.tableData.pagination.current = 0;
    this.selectedSearchTerms = chips;
    this.updateQueryParameters();
  }

  onFilterChanged(newFilters: FilterData[]) {
    this.tableData.pagination.current = 0;
    this.activeFilters = newFilters;
    this.updateQueryParameters();
  }

  onFilterInputChanged(filterSnippet: FilterData) {
    if (PmInputFilterComponent.FILTERS_USING_KEY_AS_VALUE.includes(this.employeeService.getLocationFilterIdentifier(filterSnippet.identifier))) {
      this.mainLocationFilterSuggestionsSubject$.next(filterSnippet);
      return;
    }

    this.locationFilterSuggestionsSubject$.next(filterSnippet);
  }

  onSortKeyChanged(event: SortKeyChangedEvent) {
    switch (event.sortKey) {
    case 'name':
      this.activeSortKey = EmployeeSortKeys.Name;
      break;
    case 'email':
      this.activeSortKey = EmployeeSortKeys.Email;
      break;
    case 'status':
      this.activeSortKey = EmployeeSortKeys.Status;
      break;
    case 'managementLevel':
      this.activeSortKey = EmployeeSortKeys.ManagementLevel;
      break;
    }

    this.activeSortDirection = event.sortOrder === SortOrder.Descending ? LdapSortDirection.Descending : LdapSortDirection.Ascending;
    this.updateQueryParameters();
  }

  /** ---------- Import ----------- **/
  onBulkImportClicked() {
    this.importData.isModalOpen = true;
  }

  hideImportModal() {
    this.setDefaultImportData();
  }

  importFile() {
    const {uploadText} = this.importData;
    this.importData.uploadText = this.importData.fileName;
    this.importData.uploadDisabled = this.importData.saveDisabled = true;
    const importRes: ICreateEmployeeResponseDto[][] = [];

    let amountOfChunks: number = 0;
    this.employeeImportService.getEmployeesFromFile(this.importData.file)
      .pipe(
        tap(row => {amountOfChunks = Math.ceil(row.amountOfEntriesInFile/AppSettings.IMPORT_CHUNK_SIZE)}),
        pluck('employeeRow'),
        bufferCount(AppSettings.IMPORT_CHUNK_SIZE),
        mergeMap(importDto => this.employeeImportService.importEmployees(importDto), AppSettings.IMPORT_REQUEST_CONCURRENCY),
        pluck('employees'),
        tap(importRes.push.bind(importRes)),
        finalize(() => this.finalizeImport(importRes.flat(), uploadText)),
      )
      .subscribe(_ => {
        this.importData.uploadProgress = Math.ceil(importRes.length / amountOfChunks * 100);
      }, (error: Error) => {
        this.emitAlert(error instanceof ImportFileError ? error.message : $localize`Import Error|Import Error.`, AlertType.ERROR);
      }
      );
  }

  finalizeImport(importRes: ICreateEmployeeResponseDto[], uploadText: string): void {
    importRes.sort((prev, next) => (prev.email > next.email) ? 1 : -1);
    this.employeeImportService.convertResponseToFile({
      employees: importRes
    } as IEmployeeBulkCreateResponseDto);
    this.importData.uploadDisabled = false;
    this.importData.uploadText = uploadText;
  }

  fileSelected(file: File) {
    if (!file) return;
    this.importData = this.employeeImportService.getImportDataByFile(this.importData, file);
  }


  private setEmployeesActiveState(rowIds: string[], state: EmployeeState) {
    const employees: IEmployeeDto[] = this.getEmployeesFromRow(rowIds);
    if (!this.performPreUpdateChecks(employees)) return;

    this.employeeService.updateEmployeesState(employees.map(employee => employee.uid), state)
      .pipe(
        this.catchValidationException(this.employeeService.getTranslateError.bind(this.employeeService))
      ).subscribe(_ => {
        this.handleEmployeeResult(this.tableData.pagination.current, this.tableData.selectedPageSize);
        const employeeNames: string[] = employees.map((employee: IEmployeeDto) => `${employee.givenName} ${employee.surName}`);
        this.pageFacade.emitAlert({
          label: $localize`UpdatedEmployeeState|You successfully updated the status for the employees ${employeeNames.join(', ')}`,
          alertType: AlertType.SUCCESS
        })
      }, (error: Error) => {
        this.handleEmployeeResult(this.tableData.pagination.current, this.tableData.selectedPageSize);
        this.emitAlert(error.message, AlertType.ERROR);
      });
  }

  private performPreUpdateChecks(employees: IEmployeeDto[]): boolean {
    if (employees.length === 0) {
      this.emitAlert($localize`Action could not be performed|Action could not be performed`, AlertType.ERROR);
      return false;
    }

    if (this.isCurrentEmployeeInSelection(employees)) {
      this.emitAlert($localize`EmployeeMustNotEditThemselves|You must not edit yourself.`, AlertType.ERROR);
      return false;
    }

    if (!this.canModifyUser(employees)) {
      this.emitAlert($localize`EmployeeHasForbiddenDomainOrEmail|Employees with forbidden domains or email addresses can not be activated.`, AlertType.ERROR);
      return false;
    }

    return true;
  }

  private isCurrentEmployeeInSelection(employees: IEmployeeDto[]): boolean {
    return employees.some(emp => emp.number === this.currentEmployee.employee.number);
  }

  private canModifyUser(employees: IEmployeeDto[]): boolean {
    return !this.shouldDenyActionThroughEmailSecurity(employees);
  }

  private getEmployeesFromRow(rowIds: string[]): IEmployeeDto[] {
    return this.employees?.filter(employee => rowIds.includes(employee.uid)) ?? [];
  }

  private getPaginationWithEmployee(currentEmployee: CurrentEmployeeWithRoles, currentPage: number, currentPageSize: number) : PaginationWithCurrentEmployee {
    return {
      pageInfo: {
        currentPage,
        currentPageSize
      },
      currentEmployee: currentEmployee
    }
  }

  private handleEmployeeResult(currentPage: number, currentPageSize: number) {
    const currentEmployeeObservable: Observable<CurrentEmployeeWithRoles> = iif(
      () => this.currentEmployee.employee.number !== '',
      of(this.currentEmployee),
      this.employeeService.GetCurrentEmployee()
    );

    const loadedEmployeesObservable: Observable<[Row[], IPaginationResponseDto<IEmployeeDto>]> = currentEmployeeObservable.pipe(
      map(employee => this.getPaginationWithEmployee(employee, currentPage, currentPageSize)),
      this.switchMapCancelable(this.loadEmployees.bind(this))
    );

    combineLatest([currentEmployeeObservable, loadedEmployeesObservable]).pipe(
      this.catchValidationException(this.employeeService.getTranslateError.bind(this.employeeService)),
      this.useLoadingAnimation(HgbLoadingKeys.LOAD_EMPLOYEE_OVERVIEW)
    ).subscribe((employeeData: [CurrentEmployeeWithRoles, [Row[], IPaginationResponseDto<IEmployeeDto>]]) => {
      this.currentEmployee = employeeData[0];
      this.searchSuggestions = [];
      this.searchSuggestionsVisible = false;
      this.tableData.rows = employeeData[1][0];
      this.employees = Array.from(employeeData[1][1].pageResult);
      this.paginationService.applyPaginationAttributes(this.tableData.pagination, employeeData[1][1].paginationInfo);
      this.tableData.selectedPageSize = employeeData[1][1].paginationInfo.pageSize;
      this.pageFacade.setEmployeeOverviewBreadcrumbs();
    }, (err: Error) => {
      this.tableData.rows = [];
      this.emitAlert(err.message, AlertType.ERROR);
    });
  }

  private loadEmployees(paginationWithEmployee: PaginationWithCurrentEmployee): Observable<[Row[], IPaginationResponseDto<IEmployeeDto>]> {
    return this.employeeService.getList(
      EmployeeTableService.mapEmployeeRequestDto(
        this.activeFilters,
        {
          searchTerms: this.selectedSearchTerms.map(chip => chip.text),
          searchFields: AppSettings.EMPLOYEE_SEARCH_FIELDS
        },
        {
          SortKey: this.activeSortKey,
          SortDirection: this.activeSortDirection
        },
        paginationWithEmployee.pageInfo
      )
    ).pipe(
      map(employData => [this.employeeTableService.mapEmployeesToRows(Array.from(employData.pageResult), paginationWithEmployee.currentEmployee), employData]),
    )
  }

  private emitAlert(message: string, alertType: AlertType) {
    this.pageFacade.emitAlert({
      label: message,
      alertType: alertType
    });
  }

  private viewEmployee(rowId: string) {
    const hgbUserNumber: string | null = this.getHgbUserNumber(rowId);
    if (!hgbUserNumber) {
      this.emitAlert($localize`Action could not be performed|Action could not be performed`, AlertType.ERROR);
      return;
    }
    this.navigateToEmployeePath(Path.EMPLOYEE_DETAILS_VIEW, hgbUserNumber)
  }

  private editEmployee(rowId: string) {
    const employees: IEmployeeDto[] = this.getEmployeesFromRow([rowId,]);
    const hgbUserNumber: string | null = this.getHgbUserNumber(rowId);

    if (!hgbUserNumber) {
      this.emitAlert($localize`Action could not be performed|Action could not be performed`, AlertType.ERROR);
      return;
    }

    if (this.shouldDenyActionThroughEmailSecurity(employees)) {
      this.emitAlert($localize`EmployeeHasForbiddenDomainOrEmail|Employees with forbidden domains or email addresses can not be edited.`, AlertType.ERROR);
      return;
    }
    this.navigateToEmployeePath(Path.EMPLOYEE_EDIT_VIEW, hgbUserNumber)
  }

  private isTargetEmployeeSupplier(employee: IEmployeeDto): boolean {
    return employee.locations.every(location => AppSettings.isSupplierLocation(location.number));
  }

  private shouldDenyActionThroughEmailSecurity(employees: IEmployeeDto[]): boolean {
    // Allow all roles to edit invalid suppliers, with warning msg
    if (employees.every(employee => this.isTargetEmployeeSupplier(employee)) && this.employeeService.isEmailSecurityViolatedForSuppliers(employees)) {
      this.emitAlert($localize`Editing supplier with unauthorized email|You are editing a supplier with an unauthorized email!`, AlertType.WARNING);
      return false;
    }

    // Allow only Global and Super-Admins to edit invalid stakeholders, with warning msg
    if (this.isGlobalAdmin || this.isSuperAdmin) {
      if (this.employeeService.isEmailSecurityViolatedForStakeholders(employees)) {
        this.emitAlert($localize`Editing stakeholder with unauthorized email|You are editing a stakeholder with an unauthorized email!`, AlertType.WARNING);
      }
      return false;
    }

    // Accept/deny edit request for del. admins trying to edit stakeholders
    return this.employeeService.isEmailSecurityViolatedForStakeholders(employees);
  }

  private getHgbUserNumber(rowId: string): string | null {
    return this.employees?.find(employee => employee.uid === rowId)?.number ?? null;
  }

  private navigateToEmployeePath(path: Path, hgbUserNumber: string) {
    this.pageFacade.navigateWithParams(path, new Map<string, string>([['id', hgbUserNumber]]));
  }

  private setAppPermissionFilterOptions(): void {
    const appPermissionsFilter: Filter = this.employeeFilters.find((employeeFilter: Filter) => employeeFilter.identifier === EMPLOYEE_FILTER_IDENTIFIERS.APP_PERMISSION)
      ?? {
        filterId: 9999, name: '', identifier: '', text: '', filterSuggestions: [], filterSuggestionsVisible: false, isDropdown: false
      };
    this.appPermissionService.getFullList()
      .pipe(
        map(permissionsMinimal => this.employeePipeService.createAppPermissionFilterSuggestions(permissionsMinimal))
      ).subscribe(filters => {
        appPermissionsFilter.filterSuggestions = filters;
      });
  }

  private activateQueryParameterChangeHandler() {
    this.navigationSubscription = this.pageFacade
      .getQueryParameters<QueryParameters<EmployeeSortKeys>>()
      .pipe(
        filter((queryParameterSubscriptionData: QueryParameterSubscriptionData<QueryParameters<EmployeeSortKeys>>) => queryParameterSubscriptionData.url.startsWith('/' + Path.EMPLOYEE_OVERVIEW)),
      ).subscribe((queryParameterSubscriptionData: QueryParameterSubscriptionData<QueryParameters<EmployeeSortKeys>>) => {
        this.activeFilters = queryParameterSubscriptionData.queryParams.filters ?? [];
        this.selectedSearchTerms = queryParameterSubscriptionData?.queryParams.search?.map((term, index) => ({
          key: index,
          text: term
        })) ?? [];
        const currentPageSize: number = this.paginationService.parseCurrentPageSize(queryParameterSubscriptionData.queryParams?.pageSize, this.tableData.pageSizes);
        this.tableData.pagination.current = this.paginationService.parseCurrentPageNumber(queryParameterSubscriptionData.queryParams?.page, currentPageSize, parseInt(this.tableData.pagination.resultTotal));
        this.activeSortKey = queryParameterSubscriptionData.queryParams?.sortKey ?? EmployeeSortKeys.None;
        this.activeSortDirection = queryParameterSubscriptionData.queryParams?.sortDirection ?? LdapSortDirection.Ascending;
        this.pageFacade.setEmployeeOverviewBreadcrumbs();
        this.handleEmployeeResult(this.tableData.pagination.current, currentPageSize);
      })
  }

  // eslint-disable-next-line complexity
  private updateQueryParameters() {
    this.pageFacade.updateQueryParameters(<QueryParameters<EmployeeSortKeys>>{
      filters: this.activeFilters.length > 0 ? this.activeFilters : null,
      search: this.selectedSearchTerms.length > 0 ? this.selectedSearchTerms.map(chip => chip.text) : null,
      sortKey: this.activeSortKey !== EmployeeSortKeys.None ? this.activeSortKey : null,
      sortDirection: this.activeSortDirection !== LdapSortDirection.Ascending ? this.activeSortDirection : null,
      page: this.tableData.pagination.current > 1 ? this.tableData.pagination.current : null,
      pageSize: this.tableData.selectedPageSize !== parseInt(this.tableData.pageSizes[0].value) ? this.tableData.selectedPageSize : null
    });
  }

  private activateSearchPreviewSubject() {
    this.searchPreviewSubject$
      .pipe(
        this.employeeService.preProcessSearchInput(),
        this.switchMapCancelable(this.employeeService.search.bind(this.employeeService)),
        map(searchSuggestions => this.employeeService.convertSearchResults(searchSuggestions)),
      ).subscribe(inputData => {
        this.searchSuggestions = inputData;
        this.searchSuggestionsVisible = this.searchSuggestions.length > 0;
      },_ => {
        this.pageFacade.emitAlert({
          label: $localize`Data Loading Error|Couldn't load data.`,
          alertType: AlertType.ERROR
        })
      })
  }

  private activateLocationFilterSuggestionsSubject() {
    this.locationFilterSuggestionsSubscription = this.locationFilterSuggestionsSubject$
      .pipe(
        this.employeePipeService.locationFilterSuggestionsPipe()
      )
      .subscribe((suggestions: PmInputData[]) => {
        this.setActiveEmployeeFilter(suggestions, EMPLOYEE_FILTER_IDENTIFIERS.LOCATIONS);
      });
  }

  private activateMainLocationFilterSuggestionsSubject() {
    this.mainLocationFilterSuggestionsSubscription = this.mainLocationFilterSuggestionsSubject$
      .pipe(
        this.employeePipeService.mainLocationFilterSuggestionPipe()
      )
      .subscribe((suggestions: PmInputData[]) => {
        this.setActiveEmployeeFilter(suggestions, EMPLOYEE_FILTER_IDENTIFIERS.MAIN_LOCATION)
      });
  }

  private setActiveEmployeeFilter(filterSuggestions: PmInputData[], filterIdentifier: string) {
    this.employeeFilters = this.employeeFilters.map(employeeFilter => {
      if (!employeeFilter.isDropdown) {
        const isActive: boolean = employeeFilter.identifier === filterIdentifier;
        employeeFilter.filterSuggestions = !isActive ? [] : filterSuggestions;
        employeeFilter.filterSuggestionsVisible = isActive;
      }
      return employeeFilter;
    })
  }

  private setDefaultImportData(): void {
    this.importData = this.employeeImportService.getDefaultImportData();
  }
}

import {
  Injectable
} from "@angular/core";
import {
  from,
  fromEvent,
  Observable
} from "rxjs";
import {
  ICreateContractResponseDto, ImportStatus
} from "./icontract-import-response.dto";
import {
  switchMap,
  take
} from "rxjs/operators";
import {
  ImportData
} from "hagebau-coremedia";
import {
  IContractImportService
} from "./icontract-import.service";
import {
  IContractApi
} from "./icontract-api.service";
import {
  IContractRowOfFileDto
} from "./icontract-import.dto";
import {
  IContractDto
} from "./icontract.dto";
import {
  ContractState
} from "./contract-state.enum";
import {
  IContractBulkCreateResponseDto
} from "./icontract-bulk-create-response.dto";
import {
  AppSettings
} from "../appSettings/appSettings";
import {
  DELIMITER
} from "../shared/import-data";

export type ConvertLineToContractCallback = (line: string, colNames: string[]) => IContractDto;

export enum CSV_IDENTIFIERS {
  NAME = 'Name',
  DESCRIPTION = 'Bezeichnung',
  CATEGORY = 'Kategorie',
  ISACTIVE = 'Aktiv',
  RESULT = 'Ergebnis'
}

export class ImportFileError extends Error {
  constructor(errorMessage: string) {
    super(errorMessage);
  }
}

@Injectable()
export class ContractImportService extends IContractImportService {
  // services
  private readonly contractApi: IContractApi;

  // const
  private readonly RESPONSE_HEADER: CSV_IDENTIFIERS[] = Object.values(CSV_IDENTIFIERS);
  private readonly REQUIRED_FIELDS: CSV_IDENTIFIERS[] = [CSV_IDENTIFIERS.NAME, CSV_IDENTIFIERS.DESCRIPTION, CSV_IDENTIFIERS.CATEGORY, CSV_IDENTIFIERS.ISACTIVE];

  constructor(contractApi: IContractApi) {
    super();
    this.contractApi = contractApi;
  }

  public getContractsFromFile(file: File): Observable<IContractRowOfFileDto> {
    const reader: FileReader = new FileReader();
    reader.readAsText(file, AppSettings.CSV_IMPORT_ANSI_ENCODING);

    return fromEvent(reader, 'load').pipe(
      take(1),
      switchMap(_ => from(this.convertFileToContracts(<string>reader.result, this.convertLineToContract.bind(this))))
    );
  }

  public importContracts(contracts: IContractDto[]): Observable<IContractBulkCreateResponseDto> {
    return this.contractApi.createContracts({
      Contracts: contracts
    });
  }

  public convertResponseToFile(response: IContractBulkCreateResponseDto): void {
    const fileContent: string[] = this.convertContractsToFileContent(response.contracts);
    const doc: HTMLAnchorElement = document.createElement('a');
    const blobOptions: BlobPropertyBag = {
      type: 'text/csv'
    };
    const blob: Blob = new Blob(fileContent, blobOptions);

    doc.href = URL.createObjectURL(blob);
    doc.download = "ImportResult.csv";
    doc.click();
  }

  public getImportDataByFile(template: ImportData, file: File): ImportData {
    template.file = file;
    template.fileName = file.name;
    template.fileSize = file.size;
    template.progressBarHidden = false;
    template.saveDisabled = false;
    template.uploadProgress = 0;
    return template;
  }

  private convertContractsToFileContent(contracts: ICreateContractResponseDto[]): string[] {
    const fileContent: string[] = [];
    fileContent.push(this.convertHeadline(this.RESPONSE_HEADER) + "\n");
    contracts.forEach(contract =>
      //sorting according to CSV_IDENTIFIERS
      fileContent.push(
        [
          contract.name,
          contract.description,
          contract.category,
          contract.isActive,
          this.convertStatusToString(contract.response)
        ].join(DELIMITER) + "\n")
    );

    return fileContent;
  }

  public getDefaultImportData(): ImportData {
    const possibleCSVHeaders: string[] = Object.values(CSV_IDENTIFIERS).filter(identifier => identifier !== CSV_IDENTIFIERS.RESULT);
    const possibleCSVHeadersString: string = possibleCSVHeaders.join(", ");
    const csvUploadText: string = $localize`Text upload-area: Use drag & drop or click to choose csv file to upload|Text upload-area: Use drag & drop or click to choose csv file to upload.`;
    const csvHeadersHint: string = $localize`LocationImportFieldsText|The CSV can have the values for: ${possibleCSVHeadersString}`;
    return ({
      isModalOpen: false,
      file: <File>{
      },
      fileName: "test.csv",
      fileSize: 0,
      uploadProgress: 0,
      progressBarHidden: true,
      uploadText: csvUploadText + " " + csvHeadersHint,
      uploadDisabled: false,
      saveDisabled: true,
    })
  }

  private convertFileToContracts(fileContent: string, convertLineFunction: ConvertLineToContractCallback): IContractRowOfFileDto[] {
    const csvRows: string[] = fileContent.split(/\r\n|\r|\n/g);
    const headLine: string = csvRows[0].trim();
    const colNames: string[] = headLine.split(';');

    const missingColumns: CSV_IDENTIFIERS[] = this.REQUIRED_FIELDS.filter(identifier => !colNames.includes(identifier));
    if (missingColumns.length > 0) {
      throw new ImportFileError($localize`ImportFileMissingData|The given file is missing the fields: ${missingColumns}`);
    }

    const contracts: IContractDto[] = csvRows
      .filter(row => row.length > 0)
      .filter(row => !row.includes(headLine))
      .map(row => convertLineFunction(row, colNames));

    return contracts.map((contractData: IContractDto, index: number) => {
      return {
        amountOfEntriesInFile: contracts.length,
        contractRow: contractData,
        currentRow: index
      };
    });
  }

  // convert all import statuses
  // eslint-disable-next-line complexity
  private convertStatusToString(status: ImportStatus): string {
    switch (status) {
    case ImportStatus.Success:
      return $localize`Import successful|Import successful`;
    case ImportStatus.SuccessUpdate:
      return $localize`UpdateSuccessful|Update successful`;
    case ImportStatus.InProgress:
      return $localize`InProgress|Import couldn't be finished or is still in progress.`;
    case ImportStatus.NoName:
      return $localize`No description given|No ${CSV_IDENTIFIERS.NAME} given`;
    case ImportStatus.NoDescription:
      return $localize`No description given|No ${CSV_IDENTIFIERS.DESCRIPTION} given`;
    case ImportStatus.CreateContractError:
      return $localize`Could not create contract|Contract creation error`;
    case ImportStatus.UpdateContractError:
      return $localize`Could not update contract|Contract update error`;
    case ImportStatus.NoCategory:
      return $localize`No category given|No ${CSV_IDENTIFIERS.CATEGORY} given`;
    case ImportStatus.NoIsActiveStatus:
      return $localize`No active status given|No ${CSV_IDENTIFIERS.ISACTIVE} given`;
    case ImportStatus.MultipleExistingContractsFound:
      return $localize`Multiple contract found with this description/name|Contract not unique error`;
    case ImportStatus.LdapError:
      return $localize`Ldap error|Ldap error`;
    case ImportStatus.UnknownError:
      return $localize`UnknownError|An Error occurred during import`;
    default:
      return '';
    }
  }

  private convertLineToContract(line: string, colNames: string[]): IContractDto {
    const cols: string[] = line.split(";");
    return {
      cn: "",
      name: cols[colNames.indexOf(CSV_IDENTIFIERS.NAME)],
      description: cols[colNames.indexOf(CSV_IDENTIFIERS.DESCRIPTION)],
      category: cols[colNames.indexOf(CSV_IDENTIFIERS.CATEGORY)],
      status: cols[colNames.indexOf(CSV_IDENTIFIERS.ISACTIVE)].toLowerCase() === "true" ? ContractState.Active : ContractState.Inactive,
      createdAt: "", // set in backend
      lastEditedAt: "", // set in backend
      locations: []
    };
  }


  private convertHeadline(header: string[]) {
    return header.join(DELIMITER)
  }
}

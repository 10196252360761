import {
  Component, OnInit
} from '@angular/core';
import {
  AuthenticationStatus
} from "../../../../services/account/auth/auth.service";
import {
  HgbLoadingKeys, WithLoading, WithRx
} from "../../../mixins";
import {
  ActivatedRoute
} from "@angular/router";
import {
  IAuthService
} from "../../../../services/account/iauth.service";
import {
  IHeaderService
} from "../../../../services/header";
import {
  INavigationService
} from "../../../../services/navigation";
import {
  finalize
} from 'rxjs/operators';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html'
})
export class LoginPopupComponent extends WithRx(WithLoading()) implements OnInit {

  private route: ActivatedRoute;
  private authService: IAuthService;
  private headerService: IHeaderService;
  private navigationService: INavigationService;

  constructor(
    route: ActivatedRoute,
    authService: IAuthService,
    headerService: IHeaderService,
    navigationService: INavigationService) {
    super();
    this.route = route;
    this.authService = authService;
    this.headerService = headerService;
    this.navigationService =  navigationService;
  }

  ngOnInit() {
    //ping redirects inside the popup to this component with the id as a fragment in the url
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const urlParams: URLSearchParams = new URLSearchParams(fragment);
        const idToken: string | null = urlParams.get('id_token')
        if (idToken) {
          //start ASP backend auth
          this.authService.authenticateWithPingIdToken({
            idToken: idToken
          }).pipe(
            this.useLoadingAnimation(HgbLoadingKeys.LOAD_LOGIN),
            finalize(() => window.close())
          ).subscribe((_: AuthenticationStatus) => {
            //successful auth writes token to local storage
          });
        }
      }
    });
  }
}

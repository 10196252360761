import {
  ActionEvent,
  ChipData,
  ColumnNames,
  Filter,
  FilterData,
  MoreButtonElementType,
  PaginationControlData,
  PmInputData,
  PmInputFilterComponent,
  PmMenuActionData,
  PmMenuActionType,
  Row,
  SortKeyChangedEvent,
  SortOrder,
  ImportData,
  AlertType
} from 'hagebau-coremedia';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  Path
} from '../app-routing.module';
import {
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import {
  bufferCount,
  debounceTime,
  filter,
  finalize,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  QueryParameters,
  QueryParameterSubscriptionData
} from '../../services/navigation';
import {
  HgbLoadingKeys,
  WithLoading,
  WithModal,
  WithRx
} from '../mixins';
import {
  ICreateLocationResponseDto,
  ILocationBulkCreateResponseDto,
  ILocationDto,
  ILocationRequestDto,
  ILocationService,
  LocationSortKeys
} from '../../services/location';
import {
  IPaginationResponseDto
} from '../../services/pagination/IPaginationResponseDto';
import {
  AppSettings
} from '../../services/appSettings/appSettings';
import {
  ValidationException
} from '../../network/api/http/api-exception';
import {
  IExceptionTranslatorService
} from '../../services/exception/iexception-translator.service';
import {
  ILocationImportService
} from '../../services/location/ilocation-import.service';
import {
  LdapSortDirection
} from '../../services/LdapSortDirection';
import {
  IPaginationService
} from '../../services/pagination/ipagination.service';
import {
  LocationTableService
} from '../../services/location/location-table.service';
import {
  IPageFacadeService
} from '../../services/ipage-facade';
import {
  ImportFileError,
} from '../../services/location/location-import.service';
import {
  PaginationService
} from '../../services/pagination/pagination.service';
import * as locationConstants from '../../services/location/location-data';
import {
  IAuthService
} from '../../services/account/iauth.service';

@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
})
export class LocationsComponent extends WithRx(WithModal(WithLoading())) implements OnInit, OnDestroy {
  //services
  private readonly locationService: ILocationService;
  private readonly pageFacade: IPageFacadeService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly paginationService: IPaginationService;
  private readonly locationImportService: ILocationImportService;
  private readonly authService: IAuthService;

  //rxjs
  private deleteLocationsSubject$ = new Subject<string[]>();
  private searchPreviewSubject$ = new Subject<string>();
  private filterSuggestionsSubject$ = new Subject<FilterData>();
  private mainLocationFilterSuggestionSubject$ = new Subject<FilterData>();

  //table
  rows: Row[] = [];
  menuActions: PmMenuActionData[] = [];
  columnNames: ColumnNames = locationConstants.DEFAULT_COLUMN_NAMES;

  activeFilters: FilterData[] = [];
  locationFilters: Filter[] = [];
  searchSuggestions: PmInputData[] = [];
  selectedSearchTerms: ChipData[] = [];
  currentFilterIdentifier: keyof ILocationDto = 'cn';
  activeSortKey: LocationSortKeys = LocationSortKeys.None;
  activeSortDirection: LdapSortDirection = LdapSortDirection.Ascending;

  pagination: PaginationControlData = PaginationService.PAGINATION_DEFAULT;
  pageSizes: PmInputData[] = [];
  currentPageSize: number = 0;
  currentPage: number = 1;
  alwaysShowMoreButton: boolean = true;
  resetTableHeaderCheckboxWithPagination: boolean = true;

  //state
  locationsToBeDeleted: string[] = [];
  selectedLocations: string[] = [];
  searchSuggestionsVisible: boolean = false;
  showWarning: boolean = false;
  isDelAdminOnly: boolean = true;
  isServiceAdmin: boolean = false;
  locations: ILocationDto[] = [];
  isActive: boolean = false;

  //subscriptions
  navigationSubscription: Subscription = new Subscription();
  deleteSubscription: Subscription = new Subscription();
  searchPreviewSubscription: Subscription = new Subscription();
  filterSuggestionsSubscription: Subscription = new Subscription();
  mainLocationFilterSuggestionSubscription: Subscription = new Subscription();

  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  importData: ImportData = <ImportData>{
  }

  constructor(
    locationService: ILocationService,
    pageFacade: IPageFacadeService,
    exceptionTranslatorService: IExceptionTranslatorService,
    paginationService: IPaginationService,
    locationImportService: ILocationImportService,
    authService: IAuthService
  ) {
    super();
    this.locationService = locationService;
    this.pageFacade = pageFacade;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.paginationService = paginationService;
    this.locationImportService = locationImportService;
    this.authService = authService;
    this.setDefaultImportData();
  }

  ngOnInit(): void {
    this.activateDeleteLocationsObserver();
    this.activateSearchPreviewSubject();
    this.activateFilterSuggestionsSubject();
    this.activateMainLocationFilterSuggestionsSubject();
    this.locationFilters = locationConstants.FILTERS;
    this.menuActions = this.locationService.getTableStructure().getMenuActions();
    this.pagination = locationConstants.PAGINATION;
    this.currentPage = this.pagination.current;
    this.pageSizes = locationConstants.PAGE_SIZES;
    this.currentPageSize = parseInt(this.pageSizes[0].value);
    this.isDelAdminOnly = this.authService.isDelAdminOnly();
    this.isServiceAdmin = this.authService.isServiceAdmin();
    this.activateQueryParameterChangeHandler();
    this.pageFacade.setLocationOverviewBreadcrumbs();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.navigationSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.searchPreviewSubscription.unsubscribe();
    this.filterSuggestionsSubscription.unsubscribe();
    this.mainLocationFilterSuggestionSubscription.unsubscribe();
  }

  /** ---------- filter, search and sorting handlers ------- **/
  searchPreviewHandler(term: string) {
    this.searchPreviewSubject$.next(term);
  }

  searchChipsChangedHandler(chips: ChipData[]) {
    this.currentPage = 0;
    this.selectedSearchTerms = chips;
    this.updateQueryParameters();
  }

  onFilterChanged(filters: FilterData[]) {
    this.currentPage = 0;
    this.activeFilters = filters;
    this.updateQueryParameters();
  }

  onFilterInputChanged(filterSnippet: FilterData) {
    if (PmInputFilterComponent.FILTERS_USING_KEY_AS_VALUE.includes(filterSnippet.identifier)) {
      this.mainLocationFilterSuggestionSubject$.next(filterSnippet);
      return;
    }
    this.filterSuggestionsSubject$.next(filterSnippet);
  }

  onSortKeyChanged(event: SortKeyChangedEvent) {
    this.activeSortKey = this.locationService.mapSortKey(event.sortKey);
    this.activeSortDirection = event.sortOrder === SortOrder.Descending ? LdapSortDirection.Descending : LdapSortDirection.Ascending;
    this.updateQueryParameters();
  }

  resetSearchSuggestion() {
    this.searchSuggestionsVisible = false;
  }

  /** -------------------- Pagination Handlers ------------------- **/
  onShowPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updateQueryParameters();
  }

  onPageAmountChanged(pageAmount: PmInputData) {
    this.currentPageSize = parseInt(pageAmount.value);
    this.updateQueryParameters();
  }

  /** ------------------- Action Handlers ---------------- **/
  rowActionHandler(action: ActionEvent) {
    switch (action.actionType) {
    case MoreButtonElementType.DELETE:
      this.deleteRowActionHandler(action.rowId);
      break;
    case MoreButtonElementType.EDIT:
      this.editLocation(action.rowId);
      break;
    case MoreButtonElementType.VIEW:
      this.viewLocation(action.rowId);
      break;
    case MoreButtonElementType.ACTIVATE:
      this.setSelectedLocationsAndState([action.rowId], true);
      this.openActivateModal(true);
      break;
    case MoreButtonElementType.DEACTIVATE:
      this.setSelectedLocationsAndState([action.rowId], false);
      this.openActivateModal(false);
      break;
    }
  }

  menuActionHandler(selectedAction: PmMenuActionData) {
    switch (selectedAction.type) {
    case PmMenuActionType.DELETE:
      this.openDeleteModal(this.selectedLocations);
      break;
    case PmMenuActionType.DEACTIVATE:
      this.setSelectedLocationsAndState(this.selectedLocations, false);
      this.openActivateModal(false);
      break;
    case PmMenuActionType.ACTIVATE:
      this.setSelectedLocationsAndState(this.selectedLocations, true);
      this.openActivateModal(true);
      break;
    }
  }

  deleteRowActionHandler(rowId: string) {
    this.openDeleteModal([rowId]);
  }

  onConfirmDelete() {
    this.deleteLocationsSubject$.next(this.locationsToBeDeleted);
    this.locationsToBeDeleted = [];
    this.selectedLocations = [];
  }

  onConfirmStateChange() {
    this.setLocationsActiveState();
    this.selectedLocations = [];
    this.hideModal();
  }

  /** ---------- Import ----------- **/
  onBulkImportClicked() {
    this.importData.isModalOpen = true;
  }

  hideImportModal() {
    this.setDefaultImportData();
  }

  importFile() {
    const {uploadText} = this.importData;
    this.importData.uploadText = this.importData.fileName;
    this.importData.uploadDisabled = true;
    this.importData.saveDisabled = true;
    const importRes: ICreateLocationResponseDto[][] = [];
    let shouldUseFullLocationImport: boolean = false;
    let amountOfChunks: number = 0;
    this.locationImportService.shouldUseFullLocationImport()
      .pipe(
        tap(result => {
          shouldUseFullLocationImport = result;
        }),
        switchMap(_ =>  shouldUseFullLocationImport
          ? this.locationImportService.getFullLocationsFromFile(this.importData.file)
          : this.locationImportService.getLocationsFromFile(this.importData.file)
        ),
        tap(row => {amountOfChunks = Math.ceil(row.amountOfEntriesInFile/AppSettings.IMPORT_CHUNK_SIZE)}),
        pluck('locationRow'),
        bufferCount(AppSettings.IMPORT_CHUNK_SIZE),
        mergeMap(importDto => shouldUseFullLocationImport
          ? this.locationImportService.importFullLocations(importDto)
          : this.locationImportService.importLocations(importDto), AppSettings.IMPORT_REQUEST_CONCURRENCY),
        pluck('locations'),
        tap(importRes.push.bind(importRes)),
        finalize(() => this.finalizeImport(importRes.flat(), uploadText)),
      )
      .subscribe(_ => {
        this.importData.uploadProgress = Math.ceil(importRes.length / amountOfChunks * 100);
      }, (error: Error) => {
        this.emitAlert(error instanceof ImportFileError ? error.message : $localize`Import Error|Import Error.`, AlertType.ERROR);
      }
      );
  }

  finalizeImport(importRes: ICreateLocationResponseDto[], uploadText: string): void {
    importRes.sort((prev, next) => (prev.number > next.number) ? 1 : -1);
    this.locationImportService.convertResponseToFile({
      locations: importRes
    } as ILocationBulkCreateResponseDto);
    this.importData.uploadDisabled = false;
    this.importData.uploadText = uploadText;
  }

  fileSelected(file: File) {
    if (!file)
      return;
    this.importData = this.locationImportService.getImportDataByFile(this.importData, file);
  }

  /** ---------- Row Selection ----------- **/
  selectionHandler(selection: string[]) {
    this.selectedLocations = selection;
  }

  private activateSearchPreviewSubject() {
    this.searchPreviewSubscription = this.searchPreviewSubject$
      .pipe(
        this.locationService.preProcessSearchInput(AppSettings.MIN_SEARCH_LENGTH_DEFAULT),
        this.switchMapCancelable(this.locationService.search.bind(this.locationService)),
        map(this.locationService.convertSearchResults),
      )
      .subscribe((searchSuggestions: PmInputData[]) => {
        this.searchSuggestions = searchSuggestions;
        this.searchSuggestionsVisible = this.searchSuggestions.length > 0;
      }, _ => {
        this.emitAlert($localize`Data Loading Error|Couldn't load data.`, AlertType.ERROR);
      });
  }

  private activateFilterSuggestionsSubject() {
    this.filterSuggestionsSubscription = this.filterSuggestionsSubject$
      .pipe(
        map<FilterData, FilterData>(filterData => ({
          ...filterData,
          identifier: filterData.identifier.trim(),
          value: filterData.value.trim()
        } as FilterData)),
        tap((filterData: FilterData) => this.currentFilterIdentifier = filterData.identifier as keyof ILocationDto),
        filter((filterSnippet: FilterData) => !!filterSnippet.value && filterSnippet.value.length > AppSettings.MIN_FILTER_LENGTH),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY),
        map(this.locationService.createSearchRequestDtoForFilterSuggestions.bind(this.locationService)),
        this.switchMapCancelable(locationDto => this.locationService.getListWithoutDummy(locationDto, this.currentFilterIdentifier)),
        map(dto => this.locationService.mapToFilterSuggestions(dto, this.currentFilterIdentifier)),
        finalize(this.resetCurrentFilterIdentifier.bind(this))
      )
      .subscribe((filterSuggestions: PmInputData[]) => {
        this.locationFilters.forEach((filterData: Filter) => {
          const isActive: boolean = filterData.identifier === this.currentFilterIdentifier;
          filterData.filterSuggestions = !isActive ? [] : filterSuggestions;
          filterData.filterSuggestionsVisible = isActive;
        });
      });
  }

  private activateMainLocationFilterSuggestionsSubject() {
    this.mainLocationFilterSuggestionSubscription = this.mainLocationFilterSuggestionSubject$
      .pipe(
        map<FilterData, FilterData>(filterData => (<FilterData>{
          ...filterData, identifier: filterData.identifier.trim(), value: filterData.value.trim()
        })),
        tap((filterData: FilterData) => this.currentFilterIdentifier = filterData.identifier as keyof ILocationDto),
        filter((filterSnippet: FilterData) => !!filterSnippet.value && filterSnippet.value.length > AppSettings.MIN_FILTER_LENGTH),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY),
        map(this.createSearchRequestDtoForMainLocationFilterSuggestions.bind(this)),
        this.switchMapCancelable(this.locationService.getMainLocationListWithoutDummy.bind(this.locationService)),
        map(locationDto => this.locationService.mapMainLocationToFilterSuggestions(locationDto, 'description')),
        finalize(this.resetCurrentFilterIdentifier.bind(this))
      )
      .subscribe(
        (filterSuggestions: PmInputData[]) => {
          this.locationFilters.forEach((filterData: Filter) => {
            const isActive: boolean = filterData.identifier === this.currentFilterIdentifier;
            filterData.filterSuggestions = isActive ? filterSuggestions : [];
            filterData.filterSuggestionsVisible = isActive;
          });
        });
  }

  private resetCurrentFilterIdentifier(): void {
    this.currentFilterIdentifier = 'cn';
  }

  private createSearchRequestDtoForMainLocationFilterSuggestions(filterSnippet: FilterData): ILocationRequestDto {
    return LocationTableService.createSearchRequestDto([filterSnippet.value], AppSettings.MAIN_LOCATION_SEARCH_FIELDS);
  }

  private activateDeleteLocationsObserver(): void {
    this.deleteSubscription = this.deleteLocationsSubject$
      .pipe(
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW),
        this.hideModalOperator(),
        this.switchMapCancelable(this.locationService.delete.bind(this.locationService)),
        this.catchValidationException(this.translateError.bind(this))
      )
      .subscribe(() => {
        this.emitAlert($localize`Data Deletion Success|Deletion successful.`, AlertType.SUCCESS);
        this.handleLocationsResult(this.currentPage, this.currentPageSize);
      }, (error: Error) => {
        this.emitAlert(error.message, AlertType.ERROR);
        this.activateDeleteLocationsObserver();
        // we don't know if just one location delete didn't work or all of them,
        // in the first case we have to reload data to picture the current application state
        this.handleLocationsResult(this.currentPage, this.currentPageSize);
      });
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }

  //cognitive complexity is low
  // eslint-disable-next-line complexity
  private updateQueryParameters() {
    this.pageFacade.updateQueryParameters(<QueryParameters<LocationSortKeys>>{
      search: this.selectedSearchTerms.length > 0 ? this.selectedSearchTerms.map(chip => chip.text) : null,
      filters: this.activeFilters.length > 0 ? this.activeFilters : null,
      sortKey: this.activeSortKey !== LocationSortKeys.None ? this.activeSortKey : null,
      sortDirection: this.activeSortDirection !== LdapSortDirection.Ascending ? this.activeSortDirection : null,
      page: this.currentPage > 1 ? this.currentPage : null,
      pageSize: this.currentPageSize !== parseInt(this.pageSizes[0].value) ? this.currentPageSize : null
    });
  }

  private activateQueryParameterChangeHandler() {
    this.navigationSubscription = this.pageFacade
      .getQueryParameters<QueryParameters<LocationSortKeys>>()
      .pipe(
        filter((queryParameterSubscriptionData: QueryParameterSubscriptionData<QueryParameters<LocationSortKeys>>) => queryParameterSubscriptionData.url.startsWith('/' + Path.LOCATION_OVERVIEW)),
      )
      .subscribe((queryParameterSubscriptionData: QueryParameterSubscriptionData<QueryParameters<LocationSortKeys>>) => {
        this.activeFilters = queryParameterSubscriptionData.queryParams.filters ?? []
        this.selectedSearchTerms = queryParameterSubscriptionData.queryParams?.search?.map((term, index): ChipData => ({
          key: index,
          text: term
        })) ?? [];
        const currentPageSize: number = this.paginationService.parseCurrentPageSize(queryParameterSubscriptionData.queryParams?.pageSize, this.pageSizes);
        const currentPage: number = this.paginationService.parseCurrentPageNumber(queryParameterSubscriptionData.queryParams?.page, currentPageSize, parseInt(this.pagination.resultTotal));
        this.activeSortKey = queryParameterSubscriptionData.queryParams?.sortKey ?? LocationSortKeys.None;
        this.activeSortDirection = queryParameterSubscriptionData.queryParams?.sortDirection ?? LdapSortDirection.Ascending;
        this.pageFacade.setLocationOverviewBreadcrumbs();
        this.handleLocationsResult(currentPage, currentPageSize);
      })
  }

  private handleLocationsResult(currentPage: number, currentPageSize: number) {
    this.createCancelableSwitchMap(this.loadLocations.bind(this, currentPage, currentPageSize))
      .pipe(
        this.catchValidationException(this.translateError.bind(this)),
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW)
      )
      .subscribe((locationData: [Row[], IPaginationResponseDto<ILocationDto>]) => {
        this.searchSuggestions = [];
        this.searchSuggestionsVisible = false;
        this.rows = locationData[0];
        this.locations = Array.from(locationData[1].pageResult);
        this.showWarningIfNeeded(Array.from(locationData[1].pageResult));
        this.paginationService.applyPaginationAttributes(this.pagination, locationData[1].paginationInfo);
        this.currentPageSize = locationData[1].paginationInfo.pageSize;
        this.pageFacade.setLocationOverviewBreadcrumbs();
      }, (err: Error) => {
        this.rows = [];
        this.emitAlert(err.message, AlertType.ERROR);
      });
  }

  private loadLocations(currentPage: number, currentPageSize: number): Observable<[Row[], IPaginationResponseDto<ILocationDto>]> {
    return this.locationService.getList(
      this.locationService.getTableStructure().mapLocationRequestDto(
        this.activeFilters,
        {
          searchTerms: this.selectedSearchTerms.map(chip => chip.text),
          searchFields: AppSettings.LOCATION_SEARCH_FIELDS,
        },
        {
          SortKey:  this.activeSortKey,
          SortDirection: this.activeSortDirection,
        },
        {
          currentPage:  currentPage,
          currentPageSize: currentPageSize
        }
      )
    ).pipe(
      map(paginationResponse => [this.locationService.getTableStructure().mapLocationsToRows(Array.from(paginationResponse.pageResult)), paginationResponse]),
    )
  }

  private showWarningIfNeeded(locations: ILocationDto[]): void {
    this.showWarning = locations.filter(location => location.isIncomplete).length > 0;
  }

  private viewLocation(rowId: string) {
    const hgbNumber: string | null = this.getHgbNumber(rowId);
    if (!hgbNumber) {
      this.emitAlert($localize`Action could not be performed|Action could not be performed`, AlertType.ERROR);
      return;
    }
    this.navigateToLocationPath(Path.LOCATION_DETAILS_VIEW, hgbNumber)
  }

  private editLocation(rowId: string) {
    const hgbNumber: string | null = this.getHgbNumber(rowId);
    if (!hgbNumber) {
      this.emitAlert($localize`Action could not be performed|Action could not be performed`, AlertType.ERROR);
      return;
    }
    this.navigateToLocationPath(Path.LOCATION_DETAILS_EDIT, hgbNumber)
  }

  private navigateToLocationPath(path: Path, hgbNumber: string) {
    this.pageFacade.navigateWithParams(path, new Map<string, string>([['id', hgbNumber]]));
  }

  private getHgbNumber(rowId: string): string | null {
    return this.locations?.find(location => location.cn === rowId)?.number ?? null;
  }

  private emitAlert(message: string, alertType: AlertType) {
    this.pageFacade.emitAlert({
      label: message,
      alertType: alertType
    });
  }

  private openDeleteModal(locationsToBeDeleted: string[]) {
    if (locationsToBeDeleted.length > 1) {
      this.setModalTexts($localize`Delete Locations|Delete Locations`, $localize`Would you like to delete these locations|Would you like to delete these locations`);
    } else {
      this.setModalTexts($localize`Delete Location|Delete Location`, $localize`Would you like to delete this location|Would you like to delete this location`);
    }
    this.showModal();
    this.locationsToBeDeleted = locationsToBeDeleted;
  }

  private openActivateModal(isActive: boolean) {
    if (isActive) {
      if (this.selectedLocations.length > 1) {
        this.setModalTexts($localize`LocationsActivationTitle|Activate Locations`, $localize`LocationsActivationText|Would you like to Activate these locations`);
      } else {
        this.setModalTexts($localize`SingleLocationActivationTitle|Activate Location`, $localize`SingleLocationActivationText|Would you like to Activate this location`);
      }
    } else {
      if (this.selectedLocations.length > 1) {
        this.setModalTexts($localize`LocationsDeactivationTitle|Deactivate Locations`, $localize`LocationsDeactivationText|Would you like to Deactivate these locations`);
      } else {
        this.setModalTexts($localize`SingleLocationDeactivationTitle|Deactivate Location`, $localize`SingleLocationDeactivationText|Would you like to Deactivate this location`);
      }
    }
    this.showModal();
  }

  private setDefaultImportData(): void {
    this.locationImportService.getDefaultImportData().subscribe(importData => {
      this.importData = importData;
    });
  }

  private getLocationsFromRow(rowIds: string[]): ILocationDto[] {
    return this.locations?.filter(location => rowIds.includes(location.cn)) ?? [];
  }

  private setSelectedLocationsAndState (rowIds: string[], isActive: boolean): void {
    this.isActive = isActive;
    this.selectedLocations = rowIds;
  }

  public setLocationsActiveState(): void {
    const locations: ILocationDto[] = this.getLocationsFromRow(this.selectedLocations);
    const locationNames: string[] = locations.map((location: ILocationDto) => `${location.description}`);
    if (locations.length === 0) {
      this.emitAlert($localize`Action could not be performed|Action could not be performed`, AlertType.ERROR);
      return;
    }
    this.locationService.updateLocationsState(locations.map(location => location.cn), this.isActive)
      .pipe(
        this.catchValidationException(this.locationService.getTranslateError.bind(this.locationService))
      )
      .subscribe(_ => {
        this.handleLocationsResult(this.currentPage, this.currentPageSize);
        this.pageFacade.emitAlert({
          label: $localize`UpdatedLocationState|You successfully updated the status for the locations ${locationNames.join(', ')}`,
          alertType: AlertType.SUCCESS
        })
      }, (error: Error) => {
        this.handleLocationsResult(this.currentPage, this.currentPageSize);
        this.emitAlert(error.message, AlertType.ERROR);
      });
  }

}

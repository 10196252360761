import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges
} from "@angular/core";
import {
  PmCheckboxFieldData,
  PmCheckboxState,
  PmCheckboxType,
  PmInputFieldData,
  WizardHeaderStepElement,
} from "hagebau-coremedia";
import {
  IEmployeeCreateService
} from "../../../services/employee-create/iemployee-create.service";
import {
  IEmployeeCreateOrUpdateDto
} from "../../../services/employee-create/i-employee-create-or-update.dto";
import {
  EMPLOYEE_FIELDS_MASTERDATA
} from "../../../services/employee-create/employee-create.service";
import {
  EMPLOYEE_CREATE_STEP_IDENTIFIERS
} from "../employee-create.component";
import {
  localizedValueMapping
} from "../../../services/employee/employee-table.service";
import {
  EmployeeManagementLevel
} from "../../../services/employee/employee-management-level.enum";
import {
  EmployeeState,
  IEmployeeService
} from "../../../services/employee";
import {
  IAuthService
} from "../../../services/account/iauth.service";
import {
  EmployeeTitle
} from "../../../services/employee/employee-title.enum";

@Component({
  selector: 'employee-create-basic-details-step',
  templateUrl: './employee-create-basic-details-step.component.html',
})
export class EmployeeCreateBasicDetailsStepComponent {
  private readonly employeeCreateService: IEmployeeCreateService;
  private readonly employeeService: IEmployeeService;
  private readonly authService: IAuthService;

  stepIdentifier: number = EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_BASIC_DETAILS;

  wizardSteps: WizardHeaderStepElement[] = [];
  masterDataFields: PmInputFieldData[] = [];
  headingTitle: string = $localize`enterMasterData|enter master data`;
  headingText: string = $localize`enter employee master data|enter employee master data`;
  imageUploadText: string = $localize`employeeImage|employeeImage`;
  imageUploadLabel: string = $localize`upload employee image|upload employee image`;
  base64Image: string = "";
  imageDataHidden: boolean = true;
  imageName: string = "";
  imageSize: number = 0;
  isSupplierCheckData: PmCheckboxFieldData = {
    label: $localize`isSupplier|Supplier`,
    state: PmCheckboxState.DEFAULT,
    disabled: false,
    type: PmCheckboxType.INPUT
  }
  nextButtonDisabled: boolean = true;
  stepDisabled: boolean = false;

  constructor(
    employeeCreateService: IEmployeeCreateService,
    employeeService: IEmployeeService,
    authService: IAuthService,
  ) {
    this.employeeCreateService = employeeCreateService;
    this.employeeService = employeeService;
    this.authService = authService;
  }

  @Input()
    wizardHeaderSteps: WizardHeaderStepElement[] = [];

  @Input()
    employeeToBeCreated: IEmployeeCreateOrUpdateDto = {
      title: EmployeeTitle.NotSpecified,
      firstName: "",
      lastName: "",
      email: "",
      state:EmployeeState.Inactive,
      isSupplier: false,
      number: "",
      isCheckedTermsAndConditions: false,
      photo: "",
      departmentFunction: "",
      locationCns: [],
      appPermissions: [],
      managementLevel: EmployeeManagementLevel.GeneralManagement,
      phoneNumber: "",
      mobileNumber: "",
      sector: "",
      delAdminLocationCns: []
    };

  @Input()
    loading: boolean = false;

  @Output()
    employeeToBeCreatedChange = new EventEmitter<IEmployeeCreateOrUpdateDto>();

  onEmployeeToBeCreatedChange() {
    this.employeeToBeCreatedChange.emit(this.employeeToBeCreated);
  }

  @Output()
    onBackClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
    onNextClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
    onBasicDetailsUpdated: EventEmitter<IEmployeeCreateOrUpdateDto> = new EventEmitter<IEmployeeCreateOrUpdateDto>();

  ngOnInit(): void {
    this.masterDataFields = this.employeeCreateService.getMasterDataFields();
    this.setSupplierCheckData();
  }

  fillEmployeeData(employeeData: IEmployeeCreateOrUpdateDto) {
    this.employeeToBeCreated = employeeData;
    this.preFillMasterData();
    this.checkNextBtnDisable();
  }

  setDisabled() {
    this.stepDisabled = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fields']) {
      this.checkNextBtnDisable();
    }
  }

  preFillMasterData() {
    this.masterDataFields = this.masterDataFields.map(field => ({
      ...field,
      value: this.mapFieldNameToInputValue(field.fieldName)
    }));
    this.base64Image = this.employeeToBeCreated.photo;

    this.isSupplierCheckData.state = this.employeeToBeCreated.isSupplier ? PmCheckboxState.ACTIVE : PmCheckboxState.DEFAULT;
  }

  // eslint-disable-next-line complexity
  mapFieldNameToInputValue(fieldName: string): string {
    switch (fieldName) {
    case EMPLOYEE_FIELDS_MASTERDATA.TITLE:
      return this.mapEmployeeTitleValue();
    case EMPLOYEE_FIELDS_MASTERDATA.FISTNAME:
      return this.employeeToBeCreated.firstName;
    case EMPLOYEE_FIELDS_MASTERDATA.LASTNAME:
      return this.employeeToBeCreated.lastName;
    case EMPLOYEE_FIELDS_MASTERDATA.EMAIL:
      return this.employeeToBeCreated.email;
    case EMPLOYEE_FIELDS_MASTERDATA.PHONE_NUMBER:
      return this.employeeToBeCreated.phoneNumber;
    case EMPLOYEE_FIELDS_MASTERDATA.MOBILE_NUMBER:
      return this.employeeToBeCreated.mobileNumber;
    case EMPLOYEE_FIELDS_MASTERDATA.EMPLOYEE_STATE:
      return this.mapEmployeeStateValue();
    case EMPLOYEE_FIELDS_MASTERDATA.SECTOR:
      return this.employeeToBeCreated.sector;
    case EMPLOYEE_FIELDS_MASTERDATA.DEPARTMENT_FUNCTION:
      return this.employeeToBeCreated.departmentFunction;
    default:
      return "";
    }
  }

  mapEmployeeStateValue() {
    for (const [key, value] of localizedValueMapping) {
      if (value.toLowerCase() === this.employeeToBeCreated.state.toLowerCase()) {
        return key;
      }
    }
    return this.employeeToBeCreated.state;
  }

  mapEmployeeTitleValue() {
    for (const [key, value] of localizedValueMapping) {
      if (value.toLowerCase() === this.employeeToBeCreated.title.toLowerCase()) {
        return key;
      }
    }
    return this.employeeToBeCreated.title;
  }

  imageSelected(file: File) {
    this.employeeCreateService.convertImageBase64(file).subscribe(base64 => {
      this.base64Image = base64;
      this.imageName = file.name;
      this.imageSize = file.size;
      this.imageDataHidden = false;
    })
  }

  onInputChanged(inputFieldData: [PmInputFieldData, string]) {
    const inputElement: PmInputFieldData = inputFieldData[0];
    const inputValue: string = inputFieldData[1];
    const foundField: PmInputFieldData | undefined = this.masterDataFields.find(field => field.fieldName === inputElement.fieldName);
    if (foundField) {
      foundField.value = inputValue;
    }
    this.createAndEmitEmployeeDetails();
    this.checkNextBtnDisable();
  }

  onNextButtonClicked(){
    this.onNextClicked.emit(this.stepIdentifier);
    this.createAndEmitEmployeeDetails();
  }

  // eslint-disable-next-line complexity
  createAndEmitEmployeeDetails(){
    this.employeeToBeCreated = {
      ...this.employeeToBeCreated,
      title: this.employeeCreateService.mapStringToEmployeeTitle(this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.TITLE)?.value ?? ''),
      firstName: this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.FISTNAME)?.value ?? '',
      lastName: this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.LASTNAME)?.value ?? '',
      email: this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.EMAIL)?.value.trim() ?? '',
      state: this.employeeCreateService.mapStringToEmployeeState(this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.EMPLOYEE_STATE)?.value ?? ''),
      departmentFunction: this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.DEPARTMENT_FUNCTION)?.value ?? '',
      photo: this.base64Image,
      isSupplier: this.isSupplierCheckData.state === PmCheckboxState.ACTIVE,
      phoneNumber: this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.PHONE_NUMBER)?.value ?? '',
      mobileNumber: this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.MOBILE_NUMBER)?.value ?? '',
      sector: this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.SECTOR)?.value ?? ''
    };
    this.onEmployeeToBeCreatedChange();
  }

  private setSupplierCheckData(): void {
    this.isSupplierCheckData['disabled'] = this.authService.isDelAdminOnly() || this.authService.isServiceAdmin();
  }

  private checkNextBtnDisable() {
    const email: string = this.masterDataFields.find(field => field.fieldName === EMPLOYEE_FIELDS_MASTERDATA.EMAIL)?.value.trim() ?? '';
    this.nextButtonDisabled = !this.isValidEmail(email) ||this.masterDataFields.filter(field => field.required).some(field => !field.value);
  }

  private isValidEmail(email: string) {
    return !/[ \n\t]/.test(email) && email.includes("@");
  }
}

import {
  z
} from "zod";
import {
  createPaginatedResponseSchema
} from "../../network/api/pagination-api.service";

export const MainLocationHistoryDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  date: z.string(),
  value: z.string(),
  action: z.string(),
  editor: z.string()
})

export type IMainLocationHistoryDto = z.infer<typeof MainLocationHistoryDtoSchema>;

export const PaginatedMainLocationHistoryDtoSchema = createPaginatedResponseSchema(MainLocationHistoryDtoSchema) // eslint-disable-line @typescript-eslint/typedef
export type IPaginatedMainLocationHistoryDto = z.infer<typeof PaginatedMainLocationHistoryDtoSchema>;

import {
  NgModule
} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {
  LocationsComponent
} from "./location/locations.component";
import {
  LocationDetailsComponent
} from "./location/location-details.component";
import {
  EmployeesComponent
} from "./employee/employees.component";
import {
  EmployeeDetailsComponent
} from "./employee/employee-details.component";
import {
  ContractsComponent
} from "./contract/contracts.component";
import {
  ContractDetailsComponent
} from "./contract/contract-details.component";
import {
  LoginComponent
} from "./account/login/login.component";
import {
  AccessGuard,
  RoleGuard,
  PendingChangesGuard
} from "../services/navigation";
import {
  PasswordResetRequestComponent
} from "./account/password/password-reset-request.component";
import {
  PasswordResetComponent
} from "./account/password/password-reset.component";
import {
  PasswordResetRequestedComponent
} from "./account/password/password-reset-requested.component";
import {
  LogoutComponent
} from "./account/logout/logout.component";
import {
  EmployeeCreateComponent
} from "./employee-create/employee-create.component"
import {
  PasswordRenewComponent
} from "./account/password/password-renew.component";
import {
  LoginImprintComponent
} from "./account/login/login-imprint.component";
import {
  LoginPrivacyComponent
} from "./account/login/login-privacy.component";
import {
  LoginPopupComponent
} from "./account/login/login-popup/login-popup.component";
import {
  SecurityComponent
} from "./security/security.component";
import {
  ROLES
} from "../services/account/auth/auth.constants";

export enum Path {
  EMPLOYEE_OVERVIEW = 'employees',
  PING_REDIRECT = 'de/#/employees',
  EMPLOYEE_DETAILS_VIEW = 'employees/view/:id',
  EMPLOYEE_CREATE_VIEW = 'employees/create',
  EMPLOYEE_EDIT_VIEW = 'employees/edit/:id',
  CONTRACT_OVERVIEW = 'contracts',
  CONTRACT_DETAILS_VIEW = 'contracts/view/:id',
  CONTRACT_DETAILS_EDIT = 'contracts/edit/:id',
  LOCATION_OVERVIEW = 'locations',
  LOCATION_DETAILS_VIEW = 'locations/view/:id',
  LOCATION_DETAILS_EDIT = 'locations/edit/:id',
  LOGIN = 'account/login',
  LOGIN_POPUP = 'account/login-popup',
  LOGOUT = 'account/logout',
  PASSWORD_RESET_REQUEST = 'account/password-reset-request',
  PASSWORD_RESET_REQUESTED = 'account/password-reset-requested',
  PASSWORD_RESET = 'account/password-reset',
  PASSWORD_RENEW = 'account/password-renew/:email',
  IMPRINT = 'account/imprint',
  PRIVACY = 'account/privacy',
  SECURITY = 'security'
}

interface RouteData {
  fullScreen: boolean,
  requiredRoles: string[]
}

const allAdminRoles: string[] = Object.values(ROLES);

const routes: Routes = [
  {
    path: '',
    redirectTo: Path.EMPLOYEE_OVERVIEW,
    pathMatch: 'full'
  },
  {
    path: Path.EMPLOYEE_OVERVIEW,
    component: EmployeesComponent,
    title: $localize`PageTitle` + ' - ' + $localize`employees|employees`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: allAdminRoles
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.EMPLOYEE_DETAILS_VIEW,
    component: EmployeeDetailsComponent,
    title: $localize`PageTitle` + ' - ' + $localize`employee|employee`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: allAdminRoles
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard],
  },
  {
    path: Path.EMPLOYEE_CREATE_VIEW,
    component: EmployeeCreateComponent,
    title: $localize`PageTitle` + ' - ' + $localize`employee|employee`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: allAdminRoles
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard],
  },
  {
    path: Path.EMPLOYEE_EDIT_VIEW,
    component: EmployeeCreateComponent,
    title: $localize`PageTitle` + ' - ' + $localize`employee|employee`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: allAdminRoles
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard],
  },
  {
    path: Path.CONTRACT_OVERVIEW,
    component: ContractsComponent,
    title: $localize`PageTitle` + ' - ' + $localize`contracts|contracts`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: allAdminRoles
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard],
  },
  {
    path: Path.CONTRACT_DETAILS_EDIT,
    component: ContractDetailsComponent,
    title: $localize`PageTitle` + ' - ' + $localize`contract|contract`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: [ROLES.ADMIN, ROLES.GLOBAL_ADMIN, ROLES.SUPER_ADMIN]
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: Path.CONTRACT_DETAILS_VIEW,
    component: ContractDetailsComponent,
    title: $localize`PageTitle` + ' - ' + $localize`location|location`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: allAdminRoles
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard],
  },
  {
    path: Path.LOCATION_OVERVIEW,
    component: LocationsComponent,
    title: $localize`PageTitle` + ' - ' + $localize`locations|locations`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: allAdminRoles
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.LOCATION_DETAILS_EDIT,
    component: LocationDetailsComponent,
    title: $localize`PageTitle` + ' - ' + $localize`location|location`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: [ROLES.ADMIN, ROLES.GLOBAL_ADMIN, ROLES.SUPER_ADMIN]
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard],
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: Path.LOCATION_DETAILS_VIEW,
    component: LocationDetailsComponent,
    title: $localize`PageTitle` + ' - ' + $localize`location|location`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: allAdminRoles
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.LOGIN,
    component: LoginComponent,
    title: $localize`PageTitle` + ' - ' + $localize`login|login`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: true,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard],
  },
  {
    path: Path.LOGIN_POPUP,
    component: LoginPopupComponent,
    title: $localize`PageTitle` + ' - ' + $localize`login|login`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: []
    } as RouteData,
  },
  {
    path: Path.LOGOUT,
    component: LogoutComponent,
    title: $localize`PageTitle` + ' - ' + $localize`logout|logout`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: true,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.PASSWORD_RESET_REQUEST,
    component: PasswordResetRequestComponent,
    title: $localize`PageTitle` + ' - ' + $localize`password-reset-request|password-reset-request`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: true,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.PASSWORD_RENEW,
    component: PasswordRenewComponent,
    title: $localize`PageTitle` + ' - ' + $localize`password renew|Renew password`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: true,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.PASSWORD_RESET_REQUESTED,
    component: PasswordResetRequestedComponent,
    title: $localize`PageTitle` + ' - ' + $localize`password-reseted|password-reseted`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: true,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.PASSWORD_RESET,
    component: PasswordResetComponent,
    title: $localize`PageTitle` + ' - ' + $localize`password-reset|password-reset`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: true,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.IMPRINT,
    component: LoginImprintComponent,
    title: $localize`PageTitle` + '-' + $localize`imprint|imprint`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: true,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.PRIVACY,
    component: LoginPrivacyComponent,
    title: $localize`PageTitle` + '-' + 'privacy',
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: true,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  },
  {
    path: Path.SECURITY,
    component: SecurityComponent,
    title: $localize`PageTitle` + ' - ' + $localize`security|security`,
    // this is needed by angular
    // eslint-disable-next-line id-denylist
    data: {
      fullScreen: false,
      requiredRoles: []
    } as RouteData,
    canActivate: [AccessGuard, RoleGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

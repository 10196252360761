<pm-employee-create-administration
  [wizardHeaderSteps]="wizardHeaderSteps"
  [identifier]="stepIdentifier"
  headingTitle="employeeAdministrationTitle|Configure Administration"
  i18n-headingTitle="employeeAdministrationTitle|Configure Administration"
  headingText="employeeAdministrationText|Configure the administration rights of current employee here"
  i18n-headingText="employeeAdministrationText|Configure the administration rights of current employee here"
  [selectionCardData]="selectionCardData"
  [showTable]="showTable"
  [columnNames]="columnNames"
  [rows]="delAdminLocationRows"
  [loadingDeladminLocation]="loading[HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS_FOR_DELADMINS]"
  backButtonLabel="back|Back"
  i18n-backButtonLabel="back|Back"
  nextButtonLabel="submitBtn|submitBtn"
  i18n-nextButtonLabel="submitBtn|submitBtn"
  (onBackClicked)="onBackButtonClicked()"
  (onNextClicked)="onNextButtonClicked()"
  (onCardSelectionChange)="onCardSelectionChange($event)"
  (onCheckboxChecked)="onLocationCheckboxChange($event)"
>
</pm-employee-create-administration>

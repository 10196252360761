import {
  Injectable
} from "@angular/core";
import {
  IEmployeeImportService
} from "./iemployee-import.service";
import {
  from,
  fromEvent,
  Observable
} from "rxjs";
import {
  IEmployeeRowOfFileDto
} from "./iemployee-import.dto";
import {
  switchMap,
  take
} from "rxjs/operators";
import {
  ImportData
} from "hagebau-coremedia";
import {
  EmployeeState,
  IEmployeeApi
} from "../employee";
import {
  IEmployeeBulkCreateResponseDto
} from "./iemployee-bulk-create-response.dto";
import {
  ICreateEmployeeResponseDto,
  ImportStatus
} from "./iemployee-import-response.dto";
import {
  ILocationApi
} from "../location/ilocation-api.service";
import {
  EmployeeManagementLevel
} from "./employee-management-level.enum";
import {
  EmployeeTitle
} from "./employee-title.enum";
import {
  IEmployeeCreateOrUpdateDto
} from "../employee-create/i-employee-create-or-update.dto";
import {
  AppSettings
} from "../appSettings/appSettings";
import {
  DELIMITER,
  VALUES_DELIMITER,
  KEY_VALUE_DELIMITER,
  SUB_VALUES_DELIMITER,
  TRUTHY_CSV_VALUES
} from "../shared/import-data";
import {
  IEmployeeAppPermissionDto
} from "../appPermission/IEmployeeAppPermissionDto";

export type ConvertLineToLocationCallback = (line: string, colNames: string[]) => IEmployeeCreateOrUpdateDto;

export enum CSV_IDENTIFIERS {
  TITLE = 'Titel',
  GIVEN_NAME = 'Vorname',
  SURNAME = 'Nachname',
  EMAIL = 'Email',
  STATE = 'Status',
  IS_SUPPLIER = 'Lieferant',
  MANAGEMENT_LEVEL = 'Management Ebene',
  PHONE_NUMBER = 'Telefonnummer',
  MOBILE_NUMBER = 'Mobilnummer',
  DEPARTMENT_FUNCTION = 'Jobtitel',
  SECTOR = 'Sektor',
  LOCATIONS = 'Standorte',
  RESULT = 'Ergebnis'
}

export enum EXTRA_CSV_IDENTIFIERS {
  DELADMIN_LOCATIONS = 'DelAdmin Standorte',
  APP_PERMISSIONS = 'App',
}


export class ImportFileError extends Error {
  constructor(errorMessage: string) {
    super(errorMessage);
  }
}

@Injectable()
export class EmployeeImportService extends IEmployeeImportService {
  // services
  private readonly locationApi: ILocationApi;
  private readonly employeeApi: IEmployeeApi;

  // const
  private readonly RESPONSE_HEADER: CSV_IDENTIFIERS[] = Object.values(CSV_IDENTIFIERS);
  private readonly REQUIRED_FIELDS: CSV_IDENTIFIERS[] = [CSV_IDENTIFIERS.TITLE, CSV_IDENTIFIERS.GIVEN_NAME, CSV_IDENTIFIERS.SURNAME,
    CSV_IDENTIFIERS.EMAIL, CSV_IDENTIFIERS.STATE, CSV_IDENTIFIERS.IS_SUPPLIER, CSV_IDENTIFIERS.MANAGEMENT_LEVEL,
    CSV_IDENTIFIERS.PHONE_NUMBER, CSV_IDENTIFIERS.MOBILE_NUMBER, CSV_IDENTIFIERS.DEPARTMENT_FUNCTION, CSV_IDENTIFIERS.SECTOR];
  private readonly GENERAL_MANAGEMENT: string = "GENERAL_MANAGEMENT";
  private readonly MIDDLE_MANAGEMENT: string = "MIDDLE_MANAGEMENT";
  private readonly TOP_MANAGEMENT: string = "TOP_MANAGEMENT";
  private readonly MISS: string = "FRAU";
  private readonly MISTER: string = "HERR";
  private readonly DIVERSE: string = "DIVERSE";
  private readonly ACTIVE: string = "ACTIVE";

  constructor(
    locationApi: ILocationApi,
    employeeApi: IEmployeeApi) {
    super();
    this.locationApi = locationApi;
    this.employeeApi = employeeApi;
  }

  public getEmployeesFromFile(file: File): Observable<IEmployeeRowOfFileDto> {
    const reader: FileReader = new FileReader();
    reader.readAsText(file, AppSettings.CSV_IMPORT_ANSI_ENCODING);

    return fromEvent(reader, 'load').pipe(
      take(1),
      switchMap(_=> from(this.convertFileToEmployees(<string>reader.result, this.convertLineToEmployee.bind(this))))
    );
  }

  public importEmployees(employees: IEmployeeCreateOrUpdateDto[]): Observable<IEmployeeBulkCreateResponseDto> {
    return this.employeeApi.createEmployees({
      Employees: employees
    });
  }

  public convertResponseToFile(response: IEmployeeBulkCreateResponseDto): void {
    const fileContent: string[] = this.convertEmployeesToFileContent(response.employees);
    const doc: HTMLAnchorElement = document.createElement('a');
    const blobOptions: BlobPropertyBag = {
      type: 'text/csv'
    };
    const blob: Blob = new Blob(fileContent, blobOptions);

    doc.href = URL.createObjectURL(blob);
    doc.download = "ImportResult.csv";
    doc.click();
  }

  public getDefaultImportData(): ImportData {
    const possibleCSVHeaders: string[] = Object.values(CSV_IDENTIFIERS).filter(identifier => identifier !== CSV_IDENTIFIERS.RESULT);
    const possibleCSVHeadersString: string = possibleCSVHeaders.join(", ");
    const csvUploadText: string = $localize`Text upload-area: Use drag & drop or click to choose csv file to upload|Text upload-area: Use drag & drop or click to choose csv file to upload.`;
    const csvHeadersHint: string = $localize`LocationImportFieldsText|The CSV can have the values for: ${possibleCSVHeadersString}`;
    return ({
      isModalOpen: false,
      file: <File>{
      },
      fileName: "test.csv",
      fileSize: 0,
      uploadProgress: 0,
      progressBarHidden: true,
      uploadText: csvUploadText + " " + csvHeadersHint,
      uploadDisabled: false,
      saveDisabled: true,
    })
  }

  public getImportDataByFile(template: ImportData, file: File): ImportData {
    template.file = file;
    template.fileName = file.name;
    template.fileSize = file.size;
    template.progressBarHidden = false;
    template.saveDisabled = false;
    template.uploadProgress = 0;
    return template;
  }

  private convertEmployeesToFileContent(employees: ICreateEmployeeResponseDto[]): string[] {
    const fileContent: string[] = [];

    fileContent.push(this.convertHeadline(this.RESPONSE_HEADER) + "\n");
    employees.forEach(employee =>
      fileContent.push(
        [
          employee.title,
          employee.givenName,
          employee.surName,
          employee.email,
          employee.status,
          employee.isSupplier,
          employee.managementLevel,
          employee.phoneNumber,
          employee.mobileNumber,
          employee.departmentFunction,
          employee.sector,
          employee.locationCns,
          this.convertStatusToString(employee.response)
        ].join(DELIMITER) + "\n")
    );

    return fileContent;
  }

  private convertFileToEmployees(fileContent: string, convertLineFunction: ConvertLineToLocationCallback): IEmployeeRowOfFileDto[] {
    const csvRows: string[] = fileContent.split(/\r\n|\r|\n/g);
    const headLine: string = csvRows[0].trim();
    const colNames: string[] = headLine.split(";");

    const missingColumns: CSV_IDENTIFIERS[] = this.REQUIRED_FIELDS.filter(identifier => !colNames.includes(identifier));
    if (missingColumns.length > 0) {
      throw new ImportFileError($localize`ImportFileMissingData|The given file is missing the fields: ${missingColumns}`);
    }

    const employees: IEmployeeCreateOrUpdateDto[] = csvRows
      .filter(row => row.length > 0)
      .filter(row => !row.includes(headLine))
      .map(row => convertLineFunction(row, colNames));

    return employees.map((employeeData: IEmployeeCreateOrUpdateDto, index: number) =>
    {
      return {
        amountOfEntriesInFile: employees.length,
        employeeRow: employeeData,
        currentRow: index
      };
    });
  }

  // convert all import statuses
  // eslint-disable-next-line complexity
  private convertStatusToString(status: ImportStatus): string {
    switch(status) {
    case ImportStatus.Success:
      return $localize`Import successful|Import successful`;
    case ImportStatus.SuccessUpdate:
      return $localize`UpdateSuccessful|Update successful`;
    case ImportStatus.InProgress:
      return $localize`InProgress|Import couldn't be finished or is still in progress.`;
    case ImportStatus.LdapError:
      return $localize`Ldap error|Ldap error`;
    case ImportStatus.CreateEmployeeError:
      return $localize`Create employee error |Create employee error`;
    case ImportStatus.UpdateEmployeeError:
      return $localize`Could not update employee|Employee update error`;
    case ImportStatus.AssignLocationError:
      return $localize`Assign location error|An Error occurred while assigning locations`;
    case ImportStatus.AssignAppPermissionError:
      return $localize`Assign app permission error|An Error occurred while assigning app permissions`;
    case ImportStatus.AssignRolesError:
      return $localize`Assign roles error|An Error occurred while assigning roles`;
    case ImportStatus.NoName:
      return $localize`No name given|No ${CSV_IDENTIFIERS.GIVEN_NAME} given`;
    case ImportStatus.NoSurname:
      return $localize`No surname given|No ${CSV_IDENTIFIERS.SURNAME} given`;
    case ImportStatus.NoEmail:
      return $localize`No email given|No ${CSV_IDENTIFIERS.EMAIL} given`;
    case ImportStatus.InvalidEmail:
      return $localize`Invalid Email|${CSV_IDENTIFIERS.EMAIL} is invalid`;
    case ImportStatus.MultipleExistingEmailsFound:
      return $localize`Multiple employees found with this email|Employee not unique error`;
    case ImportStatus.UnknownError:
      return $localize`UnknownError|An Error occurred during import`;
    }
  }

  private convertLineToEmployee(line: string, colNames: string[]): IEmployeeCreateOrUpdateDto {
    const cols: string[] = line.split(";");
    return  {
      title: this.mapFileValueToTitle(cols[colNames.indexOf((CSV_IDENTIFIERS.TITLE))]),
      firstName: cols[colNames.indexOf(CSV_IDENTIFIERS.GIVEN_NAME)],
      lastName: cols[colNames.indexOf(CSV_IDENTIFIERS.SURNAME)],
      email: cols[colNames.indexOf(CSV_IDENTIFIERS.EMAIL)],
      state: cols[colNames.indexOf(CSV_IDENTIFIERS.STATE)].toUpperCase() === this.ACTIVE ? EmployeeState.Active : EmployeeState.Inactive,
      isSupplier: this.mapFileValueToBoolean(cols[colNames.indexOf(CSV_IDENTIFIERS.IS_SUPPLIER)]),
      number: "",
      isCheckedTermsAndConditions: false,
      photo: "",
      departmentFunction: cols[colNames.indexOf(CSV_IDENTIFIERS.DEPARTMENT_FUNCTION)],
      locationCns: this.mapFileValueToLocations(cols[colNames.indexOf(CSV_IDENTIFIERS.LOCATIONS)]),
      appPermissions: this.mapFileValueToAppPermissions(cols[colNames.indexOf(EXTRA_CSV_IDENTIFIERS.APP_PERMISSIONS)]),
      managementLevel: this.mapFileValueToManagementLevel(cols[colNames.indexOf(CSV_IDENTIFIERS.MANAGEMENT_LEVEL)]),
      phoneNumber: cols[colNames.indexOf(CSV_IDENTIFIERS.PHONE_NUMBER)],
      mobileNumber: cols[colNames.indexOf(CSV_IDENTIFIERS.MOBILE_NUMBER)],
      sector: cols[colNames.indexOf(CSV_IDENTIFIERS.SECTOR)],
      delAdminLocationCns: this.mapFileValueToDelAdminLocations(cols[colNames.indexOf(EXTRA_CSV_IDENTIFIERS.DELADMIN_LOCATIONS)]),
    }
  }

  private convertHeadline(header: string[]) {
    return header.join(DELIMITER)
  }

  private createDefaultImportData(isGlobalAdmin: boolean): ImportData {
    let possibleCSVHeaders: string[] = Object.values(CSV_IDENTIFIERS).filter(identifier => identifier !== CSV_IDENTIFIERS.RESULT);
    if (isGlobalAdmin) {
      possibleCSVHeaders = possibleCSVHeaders.concat(Object.values(CSV_IDENTIFIERS));
    }
    const possibleCSVHeadersString: string = possibleCSVHeaders.join(", ");
    const csvUploadText: string = $localize`Text upload-area: Use drag & drop or click to choose csv file to upload|Text upload-area: Use drag & drop or click to choose csv file to upload.`;
    const csvHeadersHint: string = $localize`LocationImportFieldsText|The CSV can have the values for: ${possibleCSVHeadersString}`;
    return ({
      isModalOpen: false,
      file: <File>{
      },
      fileName: "test.csv",
      fileSize: 0,
      uploadProgress: 0,
      progressBarHidden: true,
      uploadText: csvUploadText + " " + csvHeadersHint,
      uploadDisabled: false,
      saveDisabled: true,
    })
  }

  private mapFileValueToBoolean(csvValue: string|undefined, defaultValue: boolean = false): boolean {
    return csvValue ? TRUTHY_CSV_VALUES.includes(csvValue.toLowerCase()) : defaultValue;
  }

  private mapFileValueToManagementLevel(csvValue: string | undefined): EmployeeManagementLevel {
    if (csvValue) {
      switch (csvValue) {
      case this.GENERAL_MANAGEMENT:
        return EmployeeManagementLevel.GeneralManagement;
      case this.MIDDLE_MANAGEMENT:
        return EmployeeManagementLevel.MiddleManagement;
      case this.TOP_MANAGEMENT:
        return EmployeeManagementLevel.TopManagement;
      default:
        return EmployeeManagementLevel.GeneralManagement;
      }
    } else {
      return EmployeeManagementLevel.GeneralManagement;
    }
  }

  private mapFileValueToTitle(csvValue: string | undefined): EmployeeTitle {
    if (csvValue) {
      switch (csvValue) {
      case this.MISS:
        return EmployeeTitle.Miss;
      case this.MISTER:
        return EmployeeTitle.Mister;
      case this.DIVERSE:
        return EmployeeTitle.Diverse;
      default:
        return EmployeeTitle.NotSpecified;
      }
    } else {
      return EmployeeTitle.NotSpecified;
    }
  }

  private mapFileValueToLocations(csvValue: string | undefined): string[] {
    if (csvValue) {
      return csvValue.split(VALUES_DELIMITER);
    }
    return [];
  }

  private mapFileValueToDelAdminLocations(csvValue: string | undefined): string[] {
    if (csvValue) {
      return csvValue.split(VALUES_DELIMITER);
    }
    return [];
  }

  private mapFileValueToAppPermissions(csvValue: string | undefined): IEmployeeAppPermissionDto[] {
    if (!csvValue) {
      return [];
    }

    // app-permissions format on CSV: payment_advice.Read:100100|100112,creditor.Read:109087
    return csvValue.split(VALUES_DELIMITER).map(app => {
      const [appCn, locations] = app.split(KEY_VALUE_DELIMITER);
      const locationCns: string[] = locations.split(SUB_VALUES_DELIMITER);

      return {
        AppCn: appCn,
        LocationCns: locationCns
      }
    });
  }
}

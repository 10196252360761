import {
  Injectable
} from "@angular/core";
import {
  IBreadcrumbsService
} from "./ibreadcrumbs.service";
import {
  Path
} from "../../app/app-routing.module";
import {
  IHeaderService
} from "./iheader.service";
import {
  BreadcrumbElement,
  BreadcrumbElementUrl
} from "hagebau-coremedia";
import {
  ILocationDetailDto
} from "../location";
import {
  IContractDetailDto
} from "../contract";
import {
  IEmployeeDetailDto
} from "../employee/iemployee-detail.dto";

@Injectable()
export class BreadcrumbsService extends IBreadcrumbsService {
  private readonly headerService: IHeaderService;

  constructor(headerService: IHeaderService) {
    super();
    this.headerService = headerService;
  }

  // LOCATIONS

  public setLocationDetailsBreadcrumbs(location: ILocationDetailDto): void {
    this.headerService.emitBreadcrumbs(
      [
        <BreadcrumbElement>{
          text: $localize`locations|locations`, url: {
            path: Path.LOCATION_OVERVIEW
          }
        },
        <BreadcrumbElement>{
          text: $localize`Details View| Details View` + " " + location.description,
          url: <BreadcrumbElementUrl>{
            path: Path.LOCATION_OVERVIEW,
            breadcrumbVariables: new Map<string, string>([["id", location.number]])
          }
        }
      ]
    );
    this.headerService.emitTitle($localize`Details Of Location|Details Of Location` + " " + location.description) ;
  }

  public setLocationOverviewBreadcrumbs(): void {
    this.headerService.emitBreadcrumbs([
      <BreadcrumbElement>{
        text: $localize`locations|locations`,
        url: <BreadcrumbElementUrl>{
          path: Path.LOCATION_OVERVIEW,
          breadcrumbVariables: new Map<string, string>()
        }
      }
    ]);
    this.headerService.emitTitle($localize`locations|locations`);
  }

  // EMPLOYEES

  public setEmployeeOverviewBreadcrumbs(): void {
    this.headerService.emitBreadcrumbs([
      <BreadcrumbElement>{
        text: $localize`employees|employees`,
        url: {
          path: Path.EMPLOYEE_OVERVIEW,
          breadcrumbVariables: new Map<string, string>()
        }
      }
    ]);
    this.headerService.emitTitle($localize`employees|employees`);
  }

  public setEmployeeDetailsBreadcrumbs(employee: IEmployeeDetailDto): void {
    this.headerService.emitBreadcrumbs(
      [
        {
          text: $localize`employees|employees`, url: {
            path: Path.EMPLOYEE_OVERVIEW,
            breadcrumbVariables: new Map<string, string>()
          }
        },
        {
          text: $localize`Details View| Details View`,
          url: <BreadcrumbElementUrl>{
            path: Path.EMPLOYEE_OVERVIEW,
            breadcrumbVariables: new Map<string, string>([["id", employee.hgbUserNumber]])
          }
        }
      ]
    );
    this.headerService.emitTitle(employee.firstName + ' ' + employee.surname) ;
  }

  // CONTRACTS

  public setContractOverviewBreadcrumbs(): void {
    this.headerService.emitBreadcrumbs([
      <BreadcrumbElement>{
        text: $localize`contracts|contracts`,
        url: {
          path: Path.CONTRACT_OVERVIEW,
          breadcrumbVariables: new Map<string, string>()
        }
      }
    ]);
    this.headerService.emitTitle($localize`contracts|contracts`);
  }

  public setContractDetailsBreadcrumbs(contract:IContractDetailDto): void {
    this.headerService.emitBreadcrumbs(
      [
        <BreadcrumbElement>{
          text: $localize`contracts|contracts`, url: {
            path: Path.CONTRACT_OVERVIEW
          }
        },
        <BreadcrumbElement>{
          text: $localize`Details View| Details View` + " " + contract.name,
          url: <BreadcrumbElementUrl>{
            path: Path.CONTRACT_OVERVIEW,
            breadcrumbVariables: new Map<string, string>([["id", contract.cn]])
          }
        }
      ]
    );
    this.headerService.emitTitle($localize`Details Of Contract|Details Of Contract` + " " + contract.name);
  }

  public setSecurityBreadcrumbs(): void {
    this.headerService.emitBreadcrumbs([
      <BreadcrumbElement>{
        text: $localize`security|security`,
        url: {
          path: Path.SECURITY,
          breadcrumbVariables: new Map<string, string>()
        }
      }
    ]);
    this.headerService.emitTitle($localize`security|security`);
  }
}

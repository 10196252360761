import {
  IContractDetailDto,
  IContractBulkCreateResponseDto,
  IContractCreateBulkRequestDto,
  IPaginatedContractDto,
  PaginatedContractDtoSchema,
  ContractDetailDtoSchema,
  ContractBulkModifyResponseDtoSchema,
  ContractBulkCreateResponseDtoSchema
} from "../../services/contract";
import {
  Injectable
} from "@angular/core";
import {
  SwaggerClientService
} from "./http/swagger-client.service";
import {
  environment
} from "../../environments/environment";
import {
  Observable
} from "rxjs";
import {
  RequestOptions
} from "./http/request-options";
import {
  IContractApi,
  IcontractBulkModifyResponseDto,
  IContractRequestDto,
  IModifyContractInput
} from "../../services/contract";
import {
  ILdapResponseDto,
  LdapResponseDtoSchema
} from "../../services/ildapResponse.dto";
import {
  ISearchTermsDto,
  SearchTermsDtoSchema
} from "../../services/search/isearch-terms.dto";
import {
  IContractStateUpdateDto
} from "../../services/contract/IContractStateUpdateDto";
import {
  catchError,
  map
} from "rxjs/operators";

@Injectable()
export class ContractApi extends IContractApi {
  private swaggerClient: SwaggerClientService;

  private readonly baseUrl: string = environment.url;

  constructor(swaggerClient: SwaggerClientService) {
    super();
    this.swaggerClient = swaggerClient;
  }

  createContracts(requestDto: IContractCreateBulkRequestDto): Observable<IContractBulkCreateResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Contract/CreateContracts";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IContractCreateBulkRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => ContractBulkCreateResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ContractBulkCreateResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }
  getContracts(requestDto: IContractRequestDto): Observable<IPaginatedContractDto> {
    let url: string = this.baseUrl + "/api/services/app/Contract/Contracts";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IContractRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => PaginatedContractDtoSchema.parse(response)),
      catchError(error => {
        console.error("IPaginatedContractDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getContract(contractNumber: string): Observable<IContractDetailDto> {
    let url: string = this.baseUrl + `/api/services/app/Contract/GetOneContract?contractNumber=${contractNumber}`;
    url = url.replace(/[?&]$/, "");
    return this.swaggerClient.request("get", url, new RequestOptions(), this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => ContractDetailDtoSchema.parse(response)),
      catchError(error => {
        console.error("IContractDetailDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  updateContract(modifyInput: IModifyContractInput): Observable<ILdapResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Contract/UpdateContract";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IModifyContractInput> = new RequestOptions()
    requestOptions.body = modifyInput;

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LdapResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILdapResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  deleteContracts(ids: string[]): Observable<IcontractBulkModifyResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Contract/DeleteContracts";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<string[]> = new RequestOptions()
    requestOptions.body = ids;
    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => ContractBulkModifyResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("IcontractBulkModifyResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  search(term: string): Observable<ISearchTermsDto> {
    let url: string = this.baseUrl + "/api/services/app/Contract/Search";
    url = url.replace(/[?&]$/, "");
    url += "?terms=" + term;

    const requestOptions: RequestOptions<string> = new RequestOptions();
    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => SearchTermsDtoSchema.parse(response)),
      catchError(error => {
        console.error("ISearchTermsDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  searchFiltered(requestDto: IContractRequestDto): Observable<ISearchTermsDto> {
    let url: string = this.baseUrl + "/api/services/app/Contract/SearchFiltered";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IContractRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }
    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => SearchTermsDtoSchema.parse(response)),
      catchError(error => {
        console.error("ISearchTermsDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  updateContractsState(stateUpdate: IContractStateUpdateDto): Observable<ILdapResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Contract/UpdateContractsState";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IContractStateUpdateDto> = new RequestOptions();
    requestOptions.body = {
      ...stateUpdate
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LdapResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILdapResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }
}

import {
  NgModule
} from '@angular/core';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
  BrowserModule
} from '@angular/platform-browser';
import {
  AppRoutingModule
} from './app-routing.module';
import {
  EmployeesComponent
} from './employee/employees.component';
import {
  HagebauCoremediaModule
} from 'hagebau-coremedia';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';
import {
  EmployeeService
} from '../services/employee/employee.service';
import {
  EmployeeApi
} from '../network/api/employee-api.service';
import {
  SwaggerClientService
} from '../network/api/http/swagger-client.service';
import {
  EmployeeTableService
} from '../services/employee/employee-table.service';
import {
  NavigationService
} from '../services/navigation/navigation.service';
import {
  HeaderService
} from '../services/header/header.service';
import {
  LocationsComponent
} from './location/locations.component';
import {
  LocationDetailsComponent
} from './location/location-details.component';
import {
  LocationService
} from '../services/location/location.service';
import {
  LocationApi
} from '../network/api/location-api.service';
import {
  LocationTableService
} from '../services/location/location-table.service';
import {
  ILocationService
} from '../services/location';
import {
  ILocationApi
} from '../services/location/ilocation-api.service';
import {
  ILocationImportService
} from '../services/location/ilocation-import.service';
import {
  LocationImportService
} from '../services/location/location-import.service';
import {
  AppComponent
} from './app.component';
import {
  LoginComponent
} from './account/login/login.component';
import {
  LogoutComponent
} from './account/logout/logout.component';
import {
  AuthApi
} from '../network/api/auth-api.service';
import {
  AuthService
} from '../services/account/auth/auth.service';
import {
  AuthInterceptor
} from '../services/account/auth/auth.interceptor';
import {
  AccessGuard,
  RoleGuard,
  PendingChangesGuard,
  INavigationService,
} from '../services/navigation';
import {
  IHeaderService
} from '../services/header';
import {
  IAuthService
} from '../services/account/iauth.service';
import {
  IEmployeeService
} from '../services/employee';
import {
  IAuthApi
} from '../services/account/auth/iauth-api.service';
import {
  IEmployeeApi
} from '../services/employee';
import {
  PasswordResetRequestComponent
} from './account/password/password-reset-request.component';
import {
  PasswordResetComponent
} from './account/password/password-reset.component';
import {
  PasswordRenewComponent
} from './account/password/password-renew.component';
import {
  PasswordResetRequestedComponent
} from './account/password/password-reset-requested.component';
import {
  QueryParameterService
} from '../services/navigation';
import {
  ILocationTableService
} from '../services/location';
import {
  BreadcrumbsService
} from '../services/header/breadcrumbs.service';
import {
  IBreadcrumbsService
} from '../services/header';
import {
  ExceptionTranslatorService
} from '../services/exception/exception-translator.service';
import {
  IExceptionTranslatorService
} from '../services/exception/iexception-translator.service';
import {
  IEmployeeTableService
} from '../services/employee';
import {
  IContractService,
  IContractTableService,
  IContractApi
} from '../services/contract';
import {
  ContractService
} from '../services/contract/contract.service';
import {
  ContractTableService
} from '../services/contract/contract-table.service';
import {
  ContractApi
} from '../network/api/contract-api.service';
import {
  IPaginationService
} from '../services/pagination/ipagination.service';
import {
  PaginationService
} from '../services/pagination/pagination.service';
import {
  ContractsComponent
} from './contract/contracts.component';
import {
  ContractDetailsComponent
} from './contract/contract-details.component';
import {
  ValidationService
} from '../services/validation/validation.service';
import {
  PageFacadeService
} from '../services/page-facade.service';
import {
  IPageFacadeService
} from '../services/ipage-facade';
import {
  SearchService
} from '../services/search/search.service';
import {
  ISearchService
} from '../services/search/isearch.service';
import {
  TableSwitchService
} from '../services/table-switch/table-switch.service';
import {
  ITableSwitchService
} from '../services/table-switch/itable-switch.service';
import {
  IContractImportService
} from '../services/contract/icontract-import.service';
import {
  ContractImportService
} from '../services/contract/contract-import.service';
import {
  AllianceService
} from '../services/alliance/alliance.service';
import {
  IAllianceService
} from '../services/alliance/ialliance.service';
import {
  IAllianceApi
} from '../services/alliance/ialliance-api.service';
import {
  AllianceApi
} from '../network/api/alliance-api.service';
import {
  EmployeeDetailsComponent
} from './employee/employee-details.component';
import {
  EmployeeCreateService
} from '../services/employee-create/employee-create.service';
import {
  IEmployeeCreateService
} from '../services/employee-create/iemployee-create.service';
import {
  AppPermissionService
} from '../services/appPermission/appPermission.service';
import {
  IAppPermissionService
} from '../services/appPermission/iappPermission.service';
import {
  IAppPermissionApi
} from '../services/appPermission/iappPermission-api.service';
import {
  AppPermissionApi
} from '../network/api/appPermission-api.service';
import {
  EmployeeCreateComponent
} from './employee-create/employee-create.component';
import {
  EmployeeCreateLocationsStepComponent
} from './employee-create/steps/employee-create-locations-step.component';
import {
  EmployeeCreateBasicDetailsStepComponent
} from './employee-create/steps/employee-create-basic-details-step.component';
import {
  EmployeeCreateApplicationsStepComponent
} from './employee-create/steps/employee-create-applications-step.component';
import {
  EmployeeCreateTableService
} from '../services/employee-create/employee-create-table.service';
import {
  IEmployeeCreateTableService
} from '../services/employee-create/iemployee-create-table.service';
import {
  EmployeeCreateHierarchyStepComponent
} from './employee-create/steps/employee-create-hierarchy-step.component';
import {
  EmployeeCreateAdministrationStepComponent
} from './employee-create/steps/employee-create-administration-step.component';
import {
  IEmployeeImportService
} from '../services/employee/iemployee-import.service';
import {
  EmployeeImportService
} from '../services/employee/employee-import.service';
import {
  LoginImprintComponent
} from './account/login/login-imprint.component';
import {
  LoginPrivacyComponent
} from './account/login/login-privacy.component';
import {
  OAuthModule
} from 'angular-oauth2-oidc';
import {
  LoginPopupComponent
} from './account/login/login-popup/login-popup.component';
import {
  SecurityComponent
} from './security/security.component';
import {EmployeePipeService} from '../services/employee/employee-pipe.service';
import {IEmployeePipeService} from '../services/employee/i-employee-pipe.service';

@NgModule({
  declarations: [
    ContractsComponent,
    ContractDetailsComponent,
    EmployeesComponent,
    EmployeeDetailsComponent,
    EmployeeCreateComponent,
    EmployeeCreateAdministrationStepComponent,
    EmployeeCreateHierarchyStepComponent,
    EmployeeCreateLocationsStepComponent,
    EmployeeCreateBasicDetailsStepComponent,
    EmployeeCreateApplicationsStepComponent,
    LocationsComponent,
    LocationDetailsComponent,
    LoginComponent,
    LogoutComponent,
    LoginImprintComponent,
    LoginPrivacyComponent,
    AppComponent,
    PasswordResetRequestComponent,
    PasswordResetComponent,
    PasswordRenewComponent,
    PasswordResetRequestedComponent,
    LoginPopupComponent,
    SecurityComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HagebauCoremediaModule,
    OAuthModule.forRoot()
  ],
  providers: [
    AllianceService,
    {
      provide: IAllianceService,
      useExisting: AllianceService
    },
    AllianceApi,
    {
      provide: IAllianceApi,
      useExisting: AllianceApi
    },
    AppPermissionService,
    {
      provide: IAppPermissionService,
      useExisting: AppPermissionService
    },
    AppPermissionApi,
    {
      provide: IAppPermissionApi,
      useExisting: AppPermissionApi
    },
    EmployeeService,
    {
      provide: IEmployeeService,
      useExisting: EmployeeService
    },
    EmployeeTableService,
    {
      provide: IEmployeeTableService,
      useExisting: EmployeeTableService
    },
    EmployeeApi,
    {
      provide: IEmployeeApi,
      useExisting: EmployeeApi
    },
    ContractService,
    {
      provide: IContractService,
      useExisting: ContractService
    },
    ContractTableService,
    {
      provide: IContractTableService,
      useExisting: ContractTableService
    },
    ContractApi,
    {
      provide: IContractApi,
      useExisting: ContractApi
    },
    ContractImportService,
    {
      provide: IContractImportService,
      useExisting: ContractImportService
    },
    LocationService,
    {
      provide: ILocationService,
      useExisting: LocationService
    },
    LocationTableService,
    {
      provide: ILocationTableService,
      useExisting: LocationTableService
    },
    LocationApi,
    {
      provide: ILocationApi,
      useExisting: LocationApi
    },
    LocationImportService,
    {
      provide: ILocationImportService,
      useExisting: LocationImportService
    },
    AuthService,
    {
      provide: IAuthService,
      useExisting: AuthService
    },
    AuthApi,
    {
      provide: IAuthApi,
      useExisting: AuthApi
    },
    NavigationService,
    {
      provide: INavigationService,
      useExisting: NavigationService
    },
    PaginationService,
    {
      provide: IPaginationService,
      useExisting: PaginationService
    },
    PageFacadeService,
    {
      provide: IPageFacadeService,
      useExisting: PageFacadeService
    },
    ValidationService,
    SearchService,
    {
      provide: ISearchService,
      useExisting: SearchService
    },
    TableSwitchService,
    {
      provide: ITableSwitchService,
      useExisting: TableSwitchService
    },
    AccessGuard,
    RoleGuard,
    PendingChangesGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    HeaderService,
    {
      provide: IHeaderService,
      useExisting: HeaderService
    },
    BreadcrumbsService,
    {
      provide: IBreadcrumbsService,
      useExisting: BreadcrumbsService
    },
    ExceptionTranslatorService,
    {
      provide: IExceptionTranslatorService,
      useExisting: ExceptionTranslatorService
    },
    EmployeeCreateService,
    {
      provide: IEmployeeCreateService,
      useExisting: EmployeeCreateService
    },
    EmployeeCreateTableService,
    {
      provide: IEmployeeCreateTableService,
      useExisting: EmployeeCreateTableService
    },
    EmployeeImportService,
    {
      provide: IEmployeeImportService,
      useExisting: EmployeeImportService
    },
    EmployeePipeService,
    {
      provide: IEmployeePipeService,
      useExisting: EmployeePipeService
    },
    SwaggerClientService,
    QueryParameterService,
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
}

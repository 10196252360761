<pm-password-renew
  [logo]="logo"
  [error]="formError"
  [errorMessage]="errorMessage"
  [banner]="banner"
  [loading]="loading"
  (onRenew)="renewClickSubject$.next($event)"
  (onChange)="onInputChange()"
  (onRedirectToLogin)="redirectToLogin()"
></pm-password-renew>

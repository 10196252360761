import {
  Row,
  PaginationControlData,
  PmInputData,
  Filter,
  PmMenuActionData,
  Cell,
  ContractDetailEditData,
  FilterData
} from "hagebau-coremedia";
import {
  IContractDto, IContractRequestDto,
} from "./index";
import {
  IContractDetailDto
} from "./icontract-detail.dto";
import {
  ContractEditViewData
} from "./contract-table.service";
import {
  ILocationDto
} from "../location";
import {
  IPaginationService
} from "../pagination/ipagination.service";

export abstract class IContractTableService {
  public abstract mapContractsToRows(contracts: IContractDto[]): Row[];
  public abstract mapContractLocationsToRows(locations: ILocationDto[]): Row[];
  public abstract mapLocationsToRows(locations: ILocationDto[]): Row[];
  public abstract mapContractLocationToRow(location: ILocationDto): Cell[];
  public abstract mapLocationToRow(location: ILocationDto): Cell[];
  public abstract mapContractToEditViewData(contract: IContractDetailDto): ContractEditViewData;
  public abstract mapEditDataToContract(cn: string, editData: ContractDetailEditData, assignedLocations: ILocationDto[]): IContractDetailDto;
  public abstract getPagination(): PaginationControlData;
  public abstract getPageSizes(): PmInputData[];
  public abstract getFilters(): Filter[];
  public abstract getMenuActions(): PmMenuActionData[];
  public abstract getPaginationService(): IPaginationService;
  public abstract createSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): IContractRequestDto;
}

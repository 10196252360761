import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  GridHeader,
  NavigationElement,
  BreadcrumbElement,
  AlertType
} from 'hagebau-coremedia';
import {
  INavigationService
} from '../services/navigation';
import {
  IHeaderService,
  AlertElement
} from '../services/header';
import {
  takeUntil
} from 'rxjs/operators';
import {
  IAuthService
} from '../services/account/iauth.service';
import {
  Path
} from './app-routing.module';
import {
  WithRx
} from './mixins';
import {
  AppSettings
} from '../services/appSettings/appSettings';
import {
  animate,
  style,
  transition,
  trigger
} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger('alertAnimation', [
      transition(':leave', [
        style({
          opacity: 1
        }),
        animate('500ms ease-out', style({
          opacity: 0
        }))
      ])
    ])
  ]
})
export class AppComponent extends WithRx() implements OnInit, OnDestroy {
  //services
  private authService: IAuthService;
  private navigationService: INavigationService;
  private headerService: IHeaderService;

  readonly AlertType: typeof AlertType = AlertType;

  //grid
  header: GridHeader = {
    title: '',
    breadcrumbs: []
  };
  alerts: AlertElement[] = [];
  logoPath: string = '';
  navigation: NavigationElement[] = [];
  isFullScreen: boolean;

  constructor(
    navigationService: INavigationService,
    headerService: IHeaderService,
    authService: IAuthService,
  ) {
    super();
    this.navigationService = navigationService;
    this.headerService = headerService;
    this.authService = authService;
    this.isFullScreen = true;
  }

  ngOnInit(): void {
    this.headerService.getBreadcrumbs().pipe(
      takeUntil(this.componentIsDestroyed$)
    ).subscribe(breadcrumbs => {
      this.header.breadcrumbs = breadcrumbs;
    });
    this.headerService.getTitle().pipe(
      takeUntil(this.componentIsDestroyed$)
    ).subscribe(title => {
      this.header.title = title;
    });
    this.headerService.getAlert().pipe(
      takeUntil(this.componentIsDestroyed$)
    ).subscribe(alertData => {
      this.alerts.push(alertData);
      // remove alert after few seconds
      setTimeout(() => {
        this.alerts = this.alerts.filter(alert => alert !== alertData);
      }, AppSettings.ERROR_POPUP_VISIBILITY_TIME);
    });
    this.navigationService.getNavigation().pipe(
      takeUntil(this.componentIsDestroyed$)
    ).subscribe(navigation => {
      this.navigation = navigation;
    });
    this.navigationService.shouldFullscreen().pipe(
      takeUntil(this.componentIsDestroyed$)
    ).subscribe(isFullScreen => {
      this.isFullScreen = isFullScreen;
    });
    this.logoPath = this.navigationService.getLogoPath();
  }

  navigate(elem: NavigationElement) {
    const path: Path = Path[elem.path as keyof typeof Path]
    // clear all query params when navigation menu item is clicked
    this.navigationService.clearQueryParameters().subscribe(() =>
      this.navigationService.navigate(path)
    );
  }

  navigateCrumb(crumb: BreadcrumbElement) {
    crumb.url.breadcrumbVariables?.forEach((key, value) => {
      crumb.url.path += `/:${value}`
    });

    const pathKey: string|undefined = Object.keys(Path).find((key) => Path[key as keyof typeof Path] === crumb.url.path);
    if (pathKey !== undefined) {
      this.navigationService.navigateWithParams(Path[pathKey as keyof typeof Path], crumb.url.breadcrumbVariables);
    }
  }

  logout(_: Event) {
    this.navigationService.navigate(Path.LOGOUT);
  }
}

import {
  z
} from "zod";

export const LocationMinimalDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  number: z.string(),
  description: z.string(),
  country: z.string()
})

export type IlocationMinimalDto = z.infer<typeof LocationMinimalDtoSchema>;

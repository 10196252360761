import {
  PaginationControlData,
  PmCheckboxState,
  PmInputData,
  TableData
} from 'hagebau-coremedia';

export const defaultPagination: PaginationControlData = {
  current: 1,
  nextAmount: 2,
  previousAmount: 0,
  resultRange: '1-10',
  resultTotal: '500',
  nextDisabled: false,
  prevDisabled: true,
}

export const defaultPageSizes: PmInputData[] =  [
  {
    key: '1',
    value: '10'
  },
  {
    key: '2',
    value: '20'
  },
  {
    key: '3',
    value: '50'
  }
];

export const defaultTableData: TableData = {
  alwaysShowMoreButton: false,
  columnHeaders: [],
  disabled: false,
  interactive: false,
  loading: false,
  loadingSearch: false,
  pageSizes: defaultPageSizes,
  pagination: defaultPagination,
  resetHeaderCheckboxWithPagination: false,
  rows: [],
  selectAllCheckbox: {
    key: '',
    value: '',
    checked: false
  },
  selectedPageSize: 0,
  showCheckboxOnDesktopOnly: false,
  tableHeaderCheckboxState: PmCheckboxState.DEFAULT,
  usePagination: false
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  WizardHeaderStepElement,
  PmSelectionCardInputData,
  Icon
} from 'hagebau-coremedia';
import {
  EMPLOYEE_CREATE_STEP_IDENTIFIERS
} from '../employee-create.component';
import {
  EmployeeManagementLevel
} from '../../../services/employee/employee-management-level.enum';

@Component({
  selector: 'employee-create-hierarchy-step',
  templateUrl: './employee-create-hierarchy-step.component.html'
})
export class EmployeeCreateHierarchyStepComponent implements OnChanges{

  stepIdentifier: number = EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_HIERARCHY;
  @Input()
    selectedEmployeeManagementLevel: EmployeeManagementLevel = EmployeeManagementLevel.GeneralManagement;
  selectionCardData: PmSelectionCardInputData[] = [
    {
      title: $localize`General Management|General Management`,
      value: $localize`General Management|General Management`,
      checked: true,
      showIcon: false,
      disabled: false,
      icon: Icon.CLOSE
    },
    {
      title: $localize`Middle Management|Middle Management`,
      value: $localize`Middle Management|Middle Management`,
      checked: false,
      showIcon: false,
      disabled: false,
      icon: Icon.CLOSE
    },
    {
      title: $localize`Top Management|Top Management`,
      value: $localize`Top Management|Top Management`,
      checked: false,
      showIcon: false,
      disabled: false,
      icon: Icon.CLOSE
    }]

    @Input()
      wizardHeaderSteps: WizardHeaderStepElement[] = [];

    @Output()
      onBackClicked: EventEmitter<number> = new EventEmitter<number>();

    @Output()
      onNextClicked: EventEmitter<number> = new EventEmitter<number>();

    @Output()
      onHierarchyUpdated: EventEmitter<EmployeeManagementLevel> = new EventEmitter<EmployeeManagementLevel>();

    ngOnChanges(changes: SimpleChanges) {
      if (changes['selectedEmployeeManagementLevel']) { // when employee data has been loaded, update the input
        this.selectionCardData.forEach(option => {
          option.checked = this.mapManagementLevelStringToEnum(option.value) === this.selectedEmployeeManagementLevel;
        })
      }
    }

    onNextButtonClicked() {
      this.onNextClicked.emit(this.stepIdentifier);
      this.setAndEmitManagementLevel();
    }

    setAndEmitManagementLevel() {
      this.onHierarchyUpdated.emit(this.selectedEmployeeManagementLevel);
    }

    onBackButtonClicked() {
      this.onBackClicked.emit(this.stepIdentifier);
    }

    onHierarchyInputChanged(newManagementLevel: string) {
      this.selectedEmployeeManagementLevel = this.mapManagementLevelStringToEnum(newManagementLevel);
    }

    mapManagementLevelStringToEnum(managementLevelString: string) {
      switch (managementLevelString) {
      case $localize`General Management|General Management`:
        return EmployeeManagementLevel.GeneralManagement;
      case $localize`Middle Management|Middle Management`:
        return EmployeeManagementLevel.MiddleManagement;
      case $localize`Top Management|Top Management`:
        return EmployeeManagementLevel.TopManagement;
      default:
        return EmployeeManagementLevel.GeneralManagement;
      }
    }
}

import {
  Observable,
} from "rxjs";
import {
  IPaginationResponseDto
} from "../pagination/IPaginationResponseDto";
import {
  IContractDto
} from "../contract";
import {
  ChipData,
  FilterData,
  PaginationControlData,
  PmInputData,
  Row
} from "hagebau-coremedia";
import {
  WithRx
} from "../../app/mixins";
import {
  PaginationSearchData,
  TableSwitchPaginationData
} from "./table-switch.service";
import {
  ILocationDto
} from "../location";
import {
  Filter
} from "hagebau-coremedia";

export abstract class ITableSwitchService extends WithRx() {

  abstract loadContracts(selectedSearchTerms: ChipData[],
                            currentPage: number,
                            currentPageSize: number,
                            contractCns: string[],
                            excludeCns: boolean): Observable<[Row[], IPaginationResponseDto<IContractDto>]>;

  abstract loadLocations(selectedSearchTerms: ChipData[],
                            currentPage: number,
                            currentPageSize: number,
                            locationCns: string[],
                            excludeCns: boolean,
                            activeFilters: FilterData[]): Observable<[Row[], IPaginationResponseDto<ILocationDto>]>;

  abstract loadSupplierLocations(selectedSearchTerms: ChipData[],
                         currentPage: number,
                         currentPageSize: number,
                         locationCns: string[],
                         excludeCns: boolean,
                         activeFilters: FilterData[]): Observable<[Row[], IPaginationResponseDto<ILocationDto>]>;

  abstract loadLocationsForContracts(selectedSearchTerms: ChipData[],
                         currentPage: number,
                         currentPageSize: number,
                         locationCns: string[],
                         excludeCns: boolean): Observable<[Row[], IPaginationResponseDto<ILocationDto>]>;

  abstract createCnFilter(entityCns: string[], isNotFilter: boolean): FilterData[];

  abstract getUpdateTableData(selectedSearchTerms: ChipData[], paginationData: TableSwitchPaginationData): PaginationSearchData;

  abstract mapPaginationData(paginationData: PaginationControlData, pageSizes: PmInputData[]): TableSwitchPaginationData;

  abstract getFiltersAll(): Filter[];

  abstract getFiltersAssigned(): Filter[];
}

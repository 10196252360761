import {
  IFilterDto
} from "../IFilterDto";

export interface IEmployeeFilterDto {
  Mail: IFilterDto[],
  Status: IFilterDto[],
  MainLocation: IFilterDto[],
  Locations: IFilterDto[][],
  ManagementLevel: IFilterDto[],
  Admin: IFilterDto[],
  AppPermission: IFilterDto[],
}

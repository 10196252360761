import {IPaginationService} from './ipagination.service';
import {Injectable} from '@angular/core';
import {
  PaginationControlData,
  PmInputData
} from 'hagebau-coremedia';
import {IPaginationInfoDto} from './IPaginationInfoDto';
import {AppSettings} from '../appSettings/appSettings';


@Injectable()
export class PaginationService extends IPaginationService {

  public static readonly PAGINATION_DEFAULT: PaginationControlData = {
    current: 1,
    nextAmount: 2,
    previousAmount: 0,
    resultRange: '1-10',
    resultTotal: '500',
    nextDisabled: false,
    prevDisabled: true,
  }

  public getMaxPages(totalAmount: number, pageSize: number): number {
    return Math.ceil(totalAmount / pageSize)
  }

  public parseCurrentPageSize(pageSize: number, pageSizes: PmInputData[]): number {
    return Math.min(
      Math.max(
        parseInt(pageSizes[0].value),
        pageSize ?? parseInt(pageSizes[0].value)
      ),
      parseInt(pageSizes[pageSizes.length - 1].value)
    );
  }

  public parseCurrentPageNumber(pageNumber: number, pageSize: number, totalAmount: number): number {
    return Math.max(
      Math.min(pageNumber ?? 1, this.getMaxPages(totalAmount, pageSize)),
      1
    );
  }

  public applyPaginationAttributes(pagination: PaginationControlData, paginationInfo: IPaginationInfoDto): PaginationControlData {
    if (!paginationInfo.pageNumber || !paginationInfo.pageSize) {
      return pagination;
    }

    const currentPage: number = paginationInfo.pageNumber;
    const maxPages: number = this.getMaxPages(paginationInfo.totalAmount, paginationInfo.pageSize);
    const currentOffset: number = (currentPage * paginationInfo.pageSize)
    const nmbNextPages: number = maxPages - currentPage;
    const from: number = paginationInfo.totalAmount === 0 ? 0 : currentOffset - paginationInfo.pageSize + 1;
    const upTo: number = currentOffset > paginationInfo.totalAmount ? paginationInfo.totalAmount : currentOffset;

    pagination.current = currentPage;
    pagination.nextAmount = Math.min(AppSettings.MAX_PAGINATION_ARROW_AMOUNT, nmbNextPages);
    pagination.previousAmount = Math.min(AppSettings.MAX_PAGINATION_ARROW_AMOUNT + 1, currentPage) - 1;
    pagination.nextDisabled = nmbNextPages === 0;
    pagination.prevDisabled = currentPage === 1;
    pagination.resultRange = from + '-' + upTo;
    pagination.resultTotal = paginationInfo.totalAmount.toString();

    return pagination;
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Filter,
  FilterData,
  PmInputData,
  WizardHeaderStepElement,
  ChipData,
  Row,
  ColumnNamesEmployeeLocation,
  HeadingTexts,
  AlertType
} from 'hagebau-coremedia';
import {
  Observable,
  OperatorFunction,
  Subject,
  Subscription
} from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  tap
} from 'rxjs/operators';
import {
  HgbLoadingKeys,
  WithModal,
  WithRx
} from '../../mixins';
import {
  ILocationDto,
  ILocationRequestDto,
  ILocationService,
  IMainLocationDto
} from '../../../services/location';
import {
  EMPLOYEE_CREATE_STEP_IDENTIFIERS
} from '../employee-create.component';
import {
  IPaginationResponseDto
} from '../../../services/pagination/IPaginationResponseDto';
import {
  ValidationException
} from '../../../network/api/http/api-exception';
import {IExceptionTranslatorService} from '../../../services/exception/iexception-translator.service';
import {WithLoading} from '../../mixins';
import {
  PaginationSearchData,
  TableSwitchService,
  TableSwitchTableData
} from '../../../services/table-switch/table-switch.service';
import {TableSettings} from '../../../services/tableSettings/tableSettings';
import {ITableSwitchService} from '../../../services/table-switch/itable-switch.service';
import {IPageFacadeService} from '../../../services/ipage-facade';
import {AppSettings} from '../../../services/appSettings/appSettings';
import {LocationTableService} from '../../../services/location/location-table.service';
import {
  IContractDto,
  IContractRequestDto,
  IContractService,
  IContractTableService
} from '../../../services/contract';
import {IAuthService} from '../../../services/account/iauth.service';

const FILTER_NUMBER_INDEX: number = 0;

@Component({
  selector: 'employee-create-locations-step',
  templateUrl: './employee-create-locations-step.component.html',
})
export class EmployeeCreateLocationsStepComponent extends WithRx(WithModal(WithLoading())) implements OnInit, OnChanges {
  // services
  private readonly locationService: ILocationService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly pageFacade: IPageFacadeService;
  private readonly tableSwitchService: ITableSwitchService;
  private readonly contractTableService: IContractTableService;
  private readonly contractService: IContractService;
  private readonly authService: IAuthService;

  // rxjs
  private allLocationsSearchPreviewSubject$: Subject<string> = new Subject<string>();
  private assignedLocationsSearchPreviewSubject$: Subject<string> = new Subject<string>();
  private mainLocationAllFilterSuggestionsSubject$: Subject<FilterData> = new Subject<FilterData>();
  private mainLocationAssignedFilterSuggestionsSubject$: Subject<FilterData> = new Subject<FilterData>();
  private contractAllFilterSuggestionsSubject$: Subject<FilterData> = new Subject<FilterData>();
  private contractAssignedFilterSuggestionsSubject$: Subject<FilterData> = new Subject<FilterData>();
  private numberAllFilterSuggestionsSubject$: Subject<FilterData> = new Subject<FilterData>();
  private numberAssignedFilterSuggestionsSubject$: Subject<FilterData> = new Subject<FilterData>();

  // wizard
  stepIdentifier: number = EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_LOCATIONS
  headingTitle: string = $localize`selectLocations|Select Locations`;
  headingText: string = $localize`selectEmployeeLocationsText|Select which main locations and which locations the new user belongs to. Different permissions apply to each location.`;

  // table-switch
  columnNames: ColumnNamesEmployeeLocation = {
    checkBoxColumn: 'Checkbox',
    numberColumn: $localize`number|Number`,
    locationNameColumn: $localize`location name|Location name`,
    mainLocationNameColumn: $localize `mainLocation|Main Location`,
    permissionColumn: $localize`contracts|contracts`,
  };
  selectedSearchTerms: ChipData[] = [];
  showSearchInput: boolean = false;

  allLocations: TableSwitchTableData = TableSwitchService.getDefaultTableData();
  assignedLocations: TableSwitchTableData = TableSwitchService.getDefaultTableData();
  allLocationsTableText: HeadingTexts = TableSettings.ALL_LOCATIONS_TABLE_NAMES;
  assignedLocationsTableText: HeadingTexts = TableSettings.ASSIGNED_LOCATIONS_TABLE_NAMES;

  // state
  locationsSelectable: ILocationDto[] = [];
  assignedLocationCns: string[] = [];
  isUserServiceAdmin: boolean = false;

  // filters
  filtersAll: Filter[] = [];
  filtersAssigned: Filter[] = [];
  activeFiltersAll: FilterData[] = [];
  activeFiltersAssigned: FilterData[] = [];
  currentFilterIdentifier: keyof ILocationDto = 'cn';
  currentContractFilterIdentifier: keyof IContractDto = 'cn';

  //subscriptions
  allLocationsSearchPreviewSubscription: Subscription = new Subscription();
  assignedLocationsSearchPreviewSubscription: Subscription = new Subscription();
  mainLocationAllFilterSuggestionsSubscription: Subscription = new Subscription();
  mainLocationAssignedFilterSuggestionsSubscription: Subscription = new Subscription();
  contractAllFilterSuggestionsSubscription: Subscription = new Subscription();
  contractAssignedFilterSuggestionsSubscription: Subscription = new Subscription();
  numberAllFilterSuggestionsSubscription: Subscription = new Subscription();
  numberAssignedFilterSuggestionsSubscription: Subscription = new Subscription();

  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  /*eslint max-params: ["warn", 6]*/
  constructor(
    locationService: ILocationService,
    pageFacade: IPageFacadeService,
    exceptionTranslatorService: IExceptionTranslatorService,
    tableSwitchService: ITableSwitchService,
    contractTableService: IContractTableService,
    contractService: IContractService,
    authService: IAuthService
  ) {
    super();
    this.locationService = locationService;
    this.pageFacade = pageFacade;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.tableSwitchService = tableSwitchService;
    this.contractTableService = contractTableService;
    this.contractService = contractService;
    this.authService = authService;
  }

  @Input()
    wizardHeaderSteps: WizardHeaderStepElement[] = [];

  @Input()
    initialLocationCns: string[] = [];

  @Output()
    onBackClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
    onNextClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
    onLocationsUpdated: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
    onLocationRowsSelected: EventEmitter<Row[]> = new EventEmitter<Row[]>();

  ngOnInit(): void {
    this.allLocations.paginationData = this.tableSwitchService.mapPaginationData(this.locationService.getTableStructure().getPagination(), this.locationService.getTableStructure().getPageSizes());
    this.assignedLocations.paginationData = this.tableSwitchService.mapPaginationData(this.locationService.getTableStructure().getPagination(), this.locationService.getTableStructure().getPageSizes());
    this.filtersAll = this.tableSwitchService.getFiltersAll();
    this.filtersAssigned = this.tableSwitchService.getFiltersAssigned();
    this.isUserServiceAdmin = this.authService.isServiceAdmin();
    this.activateAllLocationsSearchPreviewSubject();
    this.activateAssignedLocationsSearchPreviewSubject();
    this.activateMainLocationAllFilterSuggestionsSubject();
    this.activateMainLocationAssignedFilterSuggestionsSubject();
    this.activateContractAllFilterSuggestionsSubject();
    this.activateContractAssignedFilterSuggestionsSubject();
    this.activateNumberAllFilterSuggestionsSubject();
    this.activateNumberAssignedFilterSuggestionsSubject();

    this.loadLocations(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['initialLocationCns']) { //when employee data is loaded, update tables
      this.assignedLocationCns = this.initialLocationCns;
      this.loadLocations();
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.allLocationsSearchPreviewSubscription.unsubscribe();
    this.assignedLocationsSearchPreviewSubscription.unsubscribe();
    this.mainLocationAllFilterSuggestionsSubscription.unsubscribe();
    this.mainLocationAssignedFilterSuggestionsSubscription.unsubscribe();
    this.contractAllFilterSuggestionsSubscription.unsubscribe();
    this.contractAssignedFilterSuggestionsSubscription.unsubscribe();
    this.numberAllFilterSuggestionsSubject$.unsubscribe();
    this.numberAssignedFilterSuggestionsSubject$.unsubscribe();
  }

  onNextButtonClicked(){
    this.onNextClicked.emit(this.stepIdentifier);
    this.createAndEmitLocations();
  }

  onBackButtonClicked(){
    this.onBackClicked.emit(this.stepIdentifier);
  }

  createAndEmitLocations(){
    this.onLocationsUpdated.emit(this.assignedLocationCns);
    this.onLocationRowsSelected.emit(this.assignedLocations.rows);
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }

  onAllLocationsFilterChanged(filters: FilterData[]) {
    this.activeFiltersAll = filters;
    this.updateAllLocationsResult();
  }

  onAssignedLocationsFilterChanged(filters: FilterData[]) {
    this.activeFiltersAssigned = filters;
    this.updateAssignedLocationsResult();
  }

  onAllFilterInputChanged(filterSnippet: FilterData) {
    switch (filterSnippet.identifier) {
    case 'mainLocation':
      this.mainLocationAllFilterSuggestionsSubject$.next(filterSnippet);
      break;
    case 'contract':
      this.contractAllFilterSuggestionsSubject$.next(filterSnippet);
      break;
    case 'number':
      this.numberAllFilterSuggestionsSubject$.next(filterSnippet);
      break;
    }
  }

  onAssignedFilterInputChanged(filterSnippet: FilterData) {
    switch (filterSnippet.identifier) {
    case 'mainLocation':
      this.mainLocationAssignedFilterSuggestionsSubject$.next(filterSnippet);
      break;
    case 'contract':
      this.contractAssignedFilterSuggestionsSubject$.next(filterSnippet);
      break;
    case 'number':
      this.numberAssignedFilterSuggestionsSubject$.next(filterSnippet);
      break;
    }
  }

  /** -------------------- Table Switch Handlers ------------------- **/

  addLocationsHandler(rows: Row[]) {
    this.assignedLocations.rows = this.assignedLocations.rows.concat(rows);

    this.assignedLocationCns = this.assignedLocationCns.concat(
      this.locationsSelectable
        .filter(location => rows.map(row => row.rowId).includes(location.cn))
        .map(location => location.cn)
    );

    this.loadLocations();
  }

  removeLocationsHandler(rows: Row[]) {
    this.assignedLocations.rows = this.assignedLocations.rows.filter((rowAssigned: Row) =>
      !rows.some((rowRemove: Row) => rowAssigned.rowId === rowRemove.rowId)
    );

    this.assignedLocationCns = this.assignedLocationCns.filter(
      locationCn => !rows.map(row => row.rowId).includes(locationCn)
    );

    this.loadLocations();
  }

  /** -------------------- Pagination Handlers ------------------- **/

  allLocationShowPage(pageNumber: number) {
    this.allLocations.paginationData.currentPage = pageNumber;
    this.updateAllLocationsResult(true);
  }

  assignedLocationShowPage(pageNumber: number) {
    this.assignedLocations.paginationData.currentPage = pageNumber;
    this.updateAssignedLocationsResult();
  }

  private updateAllLocationsResult(loadLocationOverviewOnly: boolean = false) {
    const updateData: PaginationSearchData = this.tableSwitchService.getUpdateTableData(this.allLocations.searchTerms, this.allLocations.paginationData);
    this.allLocations.searchTerms = updateData.selectedSearchTerms;
    this.getAllLocations(updateData.page, updateData.pageSize, loadLocationOverviewOnly);
  }

  private updateAssignedLocationsResult() {
    const updateData: PaginationSearchData = this.tableSwitchService.getUpdateTableData(this.assignedLocations.searchTerms, this.assignedLocations.paginationData);
    this.assignedLocations.searchTerms = updateData.selectedSearchTerms;
    this.getAssignedLocations(updateData.page, updateData.pageSize);
  }

  allLocationPageAmountChanged(pageAmount: PmInputData) {
    this.allLocations.paginationData.currentPageSize = parseInt(pageAmount.value);
    this.updateAllLocationsResult();
  }

  assignedLocationPageAmountChanged(pageAmount: PmInputData) {
    this.assignedLocations.paginationData.currentPageSize = parseInt(pageAmount.value);
    this.updateAssignedLocationsResult();
  }

  /** -------------------- Search Handlers ------------------- **/

  allLocationsSearchInputChangeHandler(searchInput: string)  {
    this.allLocationsSearchPreviewSubject$.next(searchInput);
  }

  assignedLocationsSearchInputChangeHandler(searchInput: string)  {
    this.assignedLocationsSearchPreviewSubject$.next(searchInput);
  }

  allLocationSearchChipsChangedHandler(chips: ChipData[]) {
    this.allLocations.paginationData.currentPage = 0;
    this.allLocations.searchTerms = chips;
    this.updateAllLocationsResult(true);
  }

  assignedLocationsSearchChipsChangedHandler(chips: ChipData[]) {
    this.assignedLocations.paginationData.currentPage = 0;
    this.assignedLocations.searchTerms = chips;
    this.updateAssignedLocationsResult();
  }

  /** ---------- Table-Switch Locations ----------- **/
  private loadLocations(loadLocationsOverviewOnly: boolean = false) {
    this.getAllLocations(this.allLocations.paginationData.currentPage, this.allLocations.paginationData.currentPageSize, loadLocationsOverviewOnly);
    this.getAssignedLocations(this.assignedLocations.paginationData.currentPage, this.assignedLocations.paginationData.currentPageSize);
  }

  private getAllLocations(currentPage: number, currentPageSize: number, loadLocationsOverviewOnly: boolean = false) {
    const loadables: HgbLoadingKeys[] = loadLocationsOverviewOnly ? [HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW] : [HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW, HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS];

    this.createCancelableSwitchMap(_ => this.isUserServiceAdmin ?
      this.tableSwitchService.loadSupplierLocations(this.allLocations.searchTerms, currentPage, currentPageSize, this.assignedLocationCns, true, this.activeFiltersAll) :
      this.tableSwitchService.loadLocations(this.allLocations.searchTerms, currentPage, currentPageSize, this.assignedLocationCns, true, this.activeFiltersAll)).pipe(
      this.useLoadingAnimation(...loadables),
      this.catchValidationException(this.translateError.bind(this)),
      tap(this.resetAllLocationSearch.bind(this))
    ).subscribe((locationData: [Row[], IPaginationResponseDto<ILocationDto>]) => {
      this.setAllLocationResult(locationData);
    }, fetchError => {
      this.allLocations.rows = [];
      this.locationsSelectable = [];
      this.pageFacade.emitAlert({
        label: fetchError.message ? fetchError.message : $localize`Data Loading Error|Couldn't load data.`,
        alertType: AlertType.ERROR
      });
    });
  }

  private getAssignedLocations(currentPage: number, currentPageSize: number) {
    const loadables: HgbLoadingKeys[] = [HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS];

    if(this.assignedLocationCns.length === 0) {
      this.setAssignedLocationResult([[], {
        pageResult: [],
        paginationInfo: {
          pageSize: this.assignedLocations.paginationData.currentPageSize, pageNumber: 1, totalAmount: 0
        }
      }]);
      return;
    }

    this.createCancelableSwitchMap(_ => this.tableSwitchService.loadLocations(this.assignedLocations.searchTerms, currentPage, currentPageSize, this.assignedLocationCns, false, this.activeFiltersAssigned))
      .pipe(
        this.useLoadingAnimation(...loadables),
        this.catchValidationException(this.translateError.bind(this)),
        tap(this.resetAssignedLocationSearch.bind(this)),
      ).subscribe((locationData: [Row[], IPaginationResponseDto<ILocationDto>]) => {
        this.setAssignedLocationResult(locationData);
      }, fetchError => {
        this.assignedLocations.rows = [];
        this.pageFacade.emitAlert({
          label: fetchError.message ? fetchError.message : $localize`Data Loading Error|Couldn't load data.`,
          alertType: AlertType.ERROR
        });
      }
      );
  }

  private setAllLocationResult(locationData: [Row[], IPaginationResponseDto<ILocationDto>]): void {
    this.allLocations.rows = locationData[0];
    this.locationsSelectable = Array.from(locationData[1].pageResult);
    this.locationService.getTableStructure().getPaginationService().applyPaginationAttributes(this.allLocations.paginationData.pagination, locationData[1].paginationInfo);
    this.allLocations.paginationData.currentPageSize = locationData[1].paginationInfo.pageSize;
  }

  private setAssignedLocationResult(locationData: [Row[], IPaginationResponseDto<ILocationDto>]): void {
    this.assignedLocations.rows = locationData[0];
    this.locationService.getTableStructure().getPaginationService().applyPaginationAttributes(this.assignedLocations.paginationData.pagination, locationData[1].paginationInfo);
    this.assignedLocations.paginationData.currentPageSize = locationData[1].paginationInfo.pageSize;
  }

  private resetAllLocationSearch(): void {
    this.allLocations.inputData = [];
    this.allLocations.searchSuggestionsVisible = false;
  }

  private resetAssignedLocationSearch(): void {
    this.assignedLocations.inputData = [];
    this.assignedLocations.searchSuggestionsVisible = false;
  }

  /** ---------- Table-Switch Search Suggestions ----------- **/

  private activateAllLocationsSearchPreviewSubject() {
    this.allLocationsSearchPreviewSubscription = this.allLocationsSearchPreviewSubject$
      .pipe(this.getLocationSearchSuggestions(true))
      .subscribe(suggestions => {
        this.allLocations.inputData = suggestions;
        this.allLocations.searchSuggestionsVisible = suggestions.length > 0;
      });
  }

  private activateAssignedLocationsSearchPreviewSubject() {
    this.assignedLocationsSearchPreviewSubscription = this.assignedLocationsSearchPreviewSubject$
      .pipe(this.getLocationSearchSuggestions(false))
      .subscribe(suggestions => {
        this.assignedLocations.inputData = suggestions;
        this.assignedLocations.searchSuggestionsVisible = suggestions.length > 0;
      });
  }

  private getLocationSearchSuggestions(excludeLocationCn: boolean): OperatorFunction<string, PmInputData[]> {
    return (source: Observable<string>): Observable<PmInputData[]> =>
      source.pipe(
        this.locationService.preProcessSearchInput(AppSettings.MIN_SEARCH_LENGTH_DEFAULT),
        map(searchTerm => this.locationService.getTableStructure().mapLocationMinimalRequestDto(
          [searchTerm], AppSettings.LOCATION_SEARCH_FIELDS_ASSIGN_TABLE, this.tableSwitchService.createCnFilter(this.assignedLocationCns, excludeLocationCn))),
        this.switchMapCancelable(this.locationService.searchFiltered.bind(this.locationService)),
        map(this.locationService.convertSearchResultsFromTerms.bind(this.locationService)),
      );
  }

  /** ---------- Table-Switch Filter Suggestions ----------- **/

  private activateMainLocationFilterSuggestions(
    subject: Subject<FilterData>
  ): Observable<PmInputData[]> {
    return subject
      .pipe(
        map<FilterData, FilterData>(filterData => ({
          ...filterData,
          identifier: filterData.identifier.trim(),
          value: filterData.value.trim()
        })),
        tap((filterData: FilterData) => this.currentFilterIdentifier = filterData.identifier as keyof ILocationDto),
        filter((filterSnippet: FilterData) => !!filterSnippet.value && filterSnippet.value.length > AppSettings.MIN_FILTER_LENGTH),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY),
        map(this.createSearchRequestDtoForMainLocationFilterSuggestions.bind(this)),
        this.switchMapCancelable(this.locationService.getMainLocationListWithoutDummy.bind(this.locationService)),
        map(locationDto => this.mapMainLocationToFilterSuggestions(locationDto, 'description')),
        finalize(this.resetCurrentFilterIdentifier.bind(this))
      )
  }


  private activateMainLocationAllFilterSuggestionsSubject(): void {
    this.mainLocationAllFilterSuggestionsSubscription = this.activateMainLocationFilterSuggestions(this.mainLocationAllFilterSuggestionsSubject$)
      .subscribe(
        (filterSuggestions: PmInputData[]) => {
          this.filtersAll.forEach((filterData: Filter) => {
            const isActive: boolean = filterData.identifier === this.currentFilterIdentifier;
            filterData.filterSuggestions = isActive ? filterSuggestions : [];
            filterData.filterSuggestionsVisible = isActive;
          });
        });
  }

  private activateMainLocationAssignedFilterSuggestionsSubject(): void {
    this.mainLocationAssignedFilterSuggestionsSubscription = this.activateMainLocationFilterSuggestions(this.mainLocationAssignedFilterSuggestionsSubject$)
      .subscribe(
        (filterSuggestions: PmInputData[]) => {
          this.filtersAssigned.forEach((filterData: Filter) => {
            const isActive: boolean = filterData.identifier === this.currentFilterIdentifier;
            filterData.filterSuggestions = isActive ? filterSuggestions : [];
            filterData.filterSuggestionsVisible = isActive;
          });
        });
  }

  private activateContractFilterSuggestions(
    subject: Subject<FilterData>
  ): Observable<PmInputData[]> {
    return subject
      .pipe(
        map<FilterData, FilterData>(filterData => ({
          ...filterData,
          identifier: filterData.identifier.trim(),
          value: filterData.value.trim()
        })),
        tap((filterData: FilterData) =>
          this.currentContractFilterIdentifier = this.getFilterIdentifier(filterData.identifier)
        ),
        filter(filterSnippet => !!filterSnippet.value && filterSnippet.value.length > AppSettings.MIN_FILTER_LENGTH),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY),
        map(this.createContractSearchRequestDtoForFilterSuggestions.bind(this)),
        this.switchMapCancelable(contractRequestDto => this.contractService.getListWithoutDummy(contractRequestDto, 'cn')),
        map(this.mapToContractFilterSuggestions.bind(this)),
        finalize(this.resetCurrentFilterIdentifier.bind(this))
      )
  }

  private activateContractAllFilterSuggestionsSubject(): void {
    this.contractAllFilterSuggestionsSubscription = this.activateContractFilterSuggestions(this.contractAllFilterSuggestionsSubject$)
      .pipe(
        map(this.setActiveContractAllFilter.bind(this))
      )
      .subscribe((result: Filter[]) => {
        this.filtersAll = result;
      });
  }

  private activateContractAssignedFilterSuggestionsSubject(): void {
    this.contractAssignedFilterSuggestionsSubscription = this.activateContractFilterSuggestions(this.contractAssignedFilterSuggestionsSubject$)
      .pipe(
        map(this.setActiveContractAssignedFilter.bind(this))
      )
      .subscribe((result: Filter[]) => {
        this.filtersAssigned = result;
      });
  }


  private activateNumberFilterSuggestions(
    subject: Subject<FilterData>,
  ): Observable<PmInputData[]> {
    return subject
      .pipe(
        map<FilterData, FilterData>(filterData => ({
          ...filterData,
          identifier: filterData.identifier.trim(),
          value: filterData.value.trim()
        } as FilterData)),
        tap((filterData: FilterData) => this.currentFilterIdentifier = filterData.identifier as keyof ILocationDto),
        filter((filterSnippet: FilterData) => !!filterSnippet.value && filterSnippet.value.length > AppSettings.MIN_FILTER_LENGTH),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY),
        map(this.createNumberSearchRequestDtoForFilterSuggestions.bind(this)),
        this.switchMapCancelable(locationDto => this.locationService.getListWithoutDummy(locationDto, this.currentFilterIdentifier)),
        map(dto => this.mapToFilterSuggestions(dto)),
        finalize(this.resetCurrentFilterIdentifier.bind(this))
      )
  }

  private activateNumberAllFilterSuggestionsSubject(): void {
    this.numberAllFilterSuggestionsSubscription = this.activateNumberFilterSuggestions(this.numberAllFilterSuggestionsSubject$)
      .subscribe((filterSuggestions: PmInputData[]) => {
        this.filtersAll.forEach((filterData: Filter) => {
          const isActive: boolean = filterData.identifier === this.currentFilterIdentifier;
          filterData.filterSuggestions = !isActive ? [] : filterSuggestions;
          filterData.filterSuggestionsVisible = isActive;
        });
      });
  }

  private activateNumberAssignedFilterSuggestionsSubject(): void {
    this.numberAssignedFilterSuggestionsSubscription = this.activateNumberFilterSuggestions(this.numberAssignedFilterSuggestionsSubject$)
      .subscribe((filterSuggestions: PmInputData[]) => {
        this.filtersAssigned.forEach((filterData: Filter) => {
          const isActive: boolean = filterData.identifier === this.currentFilterIdentifier;
          filterData.filterSuggestions = !isActive ? [] : filterSuggestions;
          filterData.filterSuggestionsVisible = isActive;
        });
      });
  }

  /** ---------- Table-Switch Filter Suggestions Helper Functions ----------- **/

  private createSearchRequestDtoForMainLocationFilterSuggestions(filterSnippet: FilterData): ILocationRequestDto {
    return LocationTableService.createSearchRequestDto([filterSnippet.value], AppSettings.MAIN_LOCATION_SEARCH_FIELDS);
  }

  private mapMainLocationToFilterSuggestions(searchResult: IMainLocationDto[], valueField: keyof IMainLocationDto): PmInputData[] {
    return searchResult.map(location => {
      return {
        key: location.cn, value: location[valueField] // cn is required for memberOf filters
      }
    });
  }

  private mapToFilterSuggestions(searchResult: ILocationDto[]): PmInputData[] {
    return searchResult.map(location => {
      return {
        key: location.cn, value: location.number // cn is required for memberOf filters
      }
    });
  }

  private mapToContractFilterSuggestions(searchResult: IContractDto[]): PmInputData[] {
    return searchResult.map(contract => {
      return {
        key: contract.cn, value: contract.name // we choose cn for maintaining relation between contract and suggestion in case we need to debug
      } as PmInputData
    });
  }

  private resetCurrentFilterIdentifier(): void {
    this.currentFilterIdentifier = this.currentContractFilterIdentifier = 'cn';
  }

  private setActiveContractFilters(filters: Filter[], filterSuggestions: PmInputData[]): Filter[] {
    return filters.map(contractFilter => {
      if (!contractFilter.isDropdown) {
        const isActive: boolean = this.getFilterIdentifier(contractFilter.identifier) === this.currentContractFilterIdentifier;
        contractFilter.filterSuggestions = isActive ? filterSuggestions : [];
        contractFilter.filterSuggestionsVisible = isActive;
      }
      return contractFilter;
    });
  }

  private setActiveContractAllFilter(filterSuggestions: PmInputData[]): Filter[] {
    return this.setActiveContractFilters(this.filtersAll, filterSuggestions);
  }

  private setActiveContractAssignedFilter(filterSuggestions: PmInputData[]): Filter[] {
    return this.setActiveContractFilters(this.filtersAssigned, filterSuggestions);
  }

  private getFilterIdentifier(filterIdentifier: string): keyof IContractDto {
    const contractIdentifierMapping: Map<string, keyof IContractDto> = new Map<string, keyof IContractDto>([['contract', 'name']]);
    return contractIdentifierMapping.get(filterIdentifier) || 'cn';
  }

  private createContractSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): IContractRequestDto {
    filterSnippet.identifier = this.getFilterIdentifier(filterSnippet.identifier);
    return this.contractTableService.createSearchRequestDtoForFilterSuggestions(filterSnippet);
  }

  private createNumberSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): ILocationRequestDto {
    return this.locationService.createNumberSearchRequestDto(filterSnippet, FILTER_NUMBER_INDEX);
  }

}

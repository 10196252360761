import {
  Constructor,
  CONSTRUCTOR_PARAM
} from "./constructor.mixin";
import {
  Observable
} from "rxjs";
import {
  tap
} from "rxjs/operators";

export interface IWithModal {
  showModal(): void;
  hideModal(): void;
  setModalTexts(header: string, text: string): void;
}

//eslint disabled because mixins always inherit from an empty class
// eslint-disable-next-line max-lines-per-function
export function WithModal<T extends Constructor<{}>>(Base: T = (class {} as never)) { // eslint-disable-line @typescript-eslint/ban-types
  return class extends Base implements IWithModal {
    isModalOpen: boolean = false;
    modalText = {
      header: "",
      text: ""
    }
    buttonLabel = {
      negative: "",
      positive: ""
    }

    constructor(...args: CONSTRUCTOR_PARAM[]) {
      super(...args);
    }

    public showModal(): void {
      this.isModalOpen = true;
    }

    public hideModal(): void {
      this.isModalOpen = false;
    }

    public setModalTexts(header: string, text: string): void {
      this.modalText.header = header;
      this.modalText.text = text;
    }

    public setButtonLabels(negative: string, positive: string): void {
      this.buttonLabel.negative = negative;
      this.buttonLabel.positive = positive;
    }

    hideModalOperator<I>(): (source$: Observable<I>) => Observable<I> {
      return source$ =>
        source$.pipe(
          tap(this.hideModal.bind(this)),
        );
    }
  }
}

import {
  BaseEmployeeDtoSchema,
} from "../employee";
import {
  z
} from "zod";
import {
  BaseLocationDtoSchema
} from "./ilocationBase.dto";
import {
  createPaginatedResponseSchema
} from "../../network/api/pagination-api.service";
import {
  ContractState
} from "../contract/contract-state.enum";

export const LocationDtoSchema = BaseLocationDtoSchema.extend({ // eslint-disable-line @typescript-eslint/typedef
  contracts: z.array(z.object({
    cn: z.string(),
    name: z.string(),
    description: z.string(),
    category: z.string(),
    status: z.nativeEnum(ContractState),
    createdAt: z.string(),
    lastEditedAt:  z.string()
  })),
  delAdmins:  z.array(BaseEmployeeDtoSchema).nullable(),
})
export const PaginatedLocationDtoSchema = createPaginatedResponseSchema(LocationDtoSchema) // eslint-disable-line @typescript-eslint/typedef

export const PaginatedMinimalLocationDtoSchema = createPaginatedResponseSchema(BaseLocationDtoSchema) // eslint-disable-line @typescript-eslint/typedef

export type ILocationDto = z.infer<typeof LocationDtoSchema>;
export type IPaginatedLocationDto = z.infer<typeof PaginatedLocationDtoSchema>;
export type IPaginatedMinimalLocationDto = z.infer<typeof PaginatedMinimalLocationDtoSchema>;

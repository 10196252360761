import {
  BreadcrumbElement,
  AlertType
} from 'hagebau-coremedia';
import {
  AlertElement,
  IHeaderService
} from './iheader.service';
import {
  Router
} from '@angular/router';
import {
  EventEmitter,
  Injectable,
  OnDestroy
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject
} from 'rxjs';
import {
  AppSettings
} from '../appSettings/appSettings';
import {
  distinctUntilChanged,
  takeUntil
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HeaderService extends IHeaderService implements OnDestroy {
  private readonly router: Router;
  private readonly breadcrumbObserver$: BehaviorSubject<BreadcrumbElement[]>;
  private readonly titleObserver$: BehaviorSubject<string>;
  private readonly alertObserver$: BehaviorSubject<AlertElement>;

  public breadCrumbEmitter: EventEmitter<BreadcrumbElement[]>;
  public titleEmitter: EventEmitter<string>;
  public alertEmitter: EventEmitter<AlertElement>;


  private serviceIsDestroyed$ = new Subject<boolean>();

  constructor(router: Router) {
    super();
    this.router = router
    this.breadcrumbObserver$ = new BehaviorSubject([{
      text: '', url: {
        path: '',
        breadcrumbVariables: new Map<string, string>()
      }
    }]);
    this.titleObserver$ = new BehaviorSubject('');
    this.alertObserver$ = new BehaviorSubject(<AlertElement>{
      label: '', alertType: AlertType.SUCCESS
    });
    this.breadCrumbEmitter = new EventEmitter();
    this.alertEmitter = new EventEmitter();
    this.titleEmitter = new EventEmitter();
    this.emitNewValues();
  }

  getBreadcrumbs(): Observable<BreadcrumbElement[]> {
    return this.breadcrumbObserver$.asObservable();
  }

  getTitle(): Observable<string> {
    return this.titleObserver$.asObservable();
  }

  getAlert(): Observable<AlertElement> {
    return this.alertObserver$.asObservable();
  }

  public emitBreadcrumbs(breadcrumbs: BreadcrumbElement[]) {
    this.breadCrumbEmitter.emit(breadcrumbs);
  }

  public emitTitle(headerTitle: string) {
    this.titleEmitter.emit(headerTitle);
  }

  public emitAlert(alert: AlertElement) {
    this.alertEmitter.emit(alert);
  }

  ngOnDestroy() {
    this.serviceIsDestroyed$.next(true);
    this.serviceIsDestroyed$.complete();
  }

  private emitNewValues() {
    this.breadCrumbEmitter.pipe(
      takeUntil(this.serviceIsDestroyed$),
      distinctUntilChanged((prev, curr) =>
        this.areBreadcrumbElementsEqual(prev, curr)
      )
    )
      .subscribe(breadcrumbs => {
        this.breadcrumbObserver$.next(breadcrumbs)
      });

    this.titleEmitter.pipe(
      takeUntil(this.serviceIsDestroyed$),
      distinctUntilChanged((prev, curr) =>
        prev === curr
      )
    ).subscribe(title => {
      this.titleObserver$.next(title)
    });

    this.alertEmitter.pipe(
      takeUntil(this.serviceIsDestroyed$)
    ).subscribe(alertData => {
      this.alertObserver$.next(alertData)
    });
  }

  private areBreadcrumbElementsEqual(first: BreadcrumbElement[], second: BreadcrumbElement[]): boolean {
    if(first.length !== second.length)
      return false;
    return JSON.stringify(first) === JSON.stringify(second);
  }
}

import {
  Injectable
} from "@angular/core";
import {
  IEmployeeCreateService
} from "./iemployee-create.service";
import {
  WizardHeaderStepElement,
  PmInputFieldData,
  Icon,
  PmInputType,
  PmDropdownInputFieldData
} from "hagebau-coremedia";
import {
  Observable,
  ReplaySubject
} from "rxjs";
import {
  IEmployeeCreateOrUpdateDto
} from "./i-employee-create-or-update.dto";
import {
  EmployeeState,
  IEmployeeApi
} from "../employee";
import {
  ILdapResponseDto
} from "../ildapResponse.dto";
import {
  ILocationTableService
} from "../location";
import {
  IEmployeeCreateTableService
} from "./iemployee-create-table.service";
import {
  EmployeeTitle
} from "../employee/employee-title.enum";
import {
  IEmployeeUpdateDto
} from "../employee/iemployee-update.dto";
import {
  IAuthService
} from "../account/iauth.service";


export enum EMPLOYEE_FIELDS_MASTERDATA {
  TITLE = 'title',
  FISTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  MOBILE_NUMBER = 'mobileNumber',
  EMPLOYEE_STATE = 'employeeState',
  DEPARTMENT_FUNCTION = 'departmentFunction',
  SECTOR = 'sector'
}

@Injectable()
export class EmployeeCreateService extends IEmployeeCreateService {
  private readonly employeeApi: IEmployeeApi;
  private readonly employeeCreateTableService: IEmployeeCreateTableService;
  private readonly locationTableService: ILocationTableService;
  private readonly authService: IAuthService;

  constructor(
    employeeApi: IEmployeeApi,
    employeeCreateTableService: IEmployeeCreateTableService,
    locationTableService: ILocationTableService,
    authService: IAuthService
  ) {
    super();
    this.employeeApi = employeeApi;
    this.employeeCreateTableService = employeeCreateTableService;
    this.locationTableService = locationTableService;
    this.authService = authService;
  }

  public override getWizardHeaderSteps(): WizardHeaderStepElement[] {
    return [
      {
        title: $localize`masterData|masterData`,
        icon: Icon.FILE
      },
      {
        title: $localize`locations|locations`,
        icon: Icon.LOCATION
      },
      {
        title: $localize`hierarchy|hierarchy`,
        icon: Icon.POLICE
      },
      {
        title: $localize`applications|applications`,
        icon: Icon.SCREEN
      },
      {
        title: $localize`administration|administration`,
        icon: Icon.SETTINGS
      },
    ];
  }

  // eslint-disable-next-line max-lines-per-function
  public getMasterDataFields(): PmInputFieldData[] {
    return [
      {
        type: PmInputType.DROPDOWN,
        label: $localize`title|title`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.TITLE,
        value: "",
        editable: true,
        required: false,
        inputData: [
          {
            "key": "0",
            "value": $localize`Miss|Miss`,
          },
          {
            "key": "1",
            "value": $localize`Mister|Mister`,
          },
          {
            "key": "2",
            "value": $localize`Diverse|Diverse`,
          },
          {
            "key": "3",
            "value": $localize`Not specified|Not specified`,
          },
        ]
      },
      {
        type: PmInputType.DEFAULT,
        label: $localize`firstName|firstName`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.FISTNAME,
        value: "",
        editable: true,
        required: true,
        inputData: []
      },
      {
        type: PmInputType.DEFAULT,
        label: $localize`lastName|lastName`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.LASTNAME,
        value: "",
        editable: true,
        required: true,
        inputData: []
      },
      {
        type: PmInputType.DEFAULT,
        label: $localize`email|Email`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.EMAIL,
        value: "",
        editable: true,
        required: true,
        inputData: []
      },
      {
        type: PmInputType.DEFAULT,
        label: $localize`phoneNumber|PhoneNumber`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.PHONE_NUMBER,
        value: "",
        editable: true,
        required: false,
        inputData: []
      },
      {
        type: PmInputType.DEFAULT,
        label: $localize`mobileNumber|MobileNumber`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.MOBILE_NUMBER,
        value: "",
        editable: true,
        required: false,
        inputData: []
      },
      {
        label: $localize`status|Status`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.EMPLOYEE_STATE,
        value: "",
        editable: true,
        identifier: "status",
        type: PmInputType.DROPDOWN,
        required: true,
        inputData: [
          {
            "key": "0",
            "value": $localize`inactive|Inactive`,
          },
          {
            "key": "1",
            "value": $localize`active|Active`,
          },
          {
            "key": "2",
            "value": $localize`locked|Locked`,
          },
        ]
      } as PmDropdownInputFieldData,
      {
        type: PmInputType.DEFAULT,
        label: $localize`branch|Branch`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.SECTOR,
        value: "",
        editable: true,
        required: false,
        inputData: []
      },
      {
        type: PmInputType.DEFAULT,
        label: $localize`departmentFunction|departmentFunction`,
        fieldName: EMPLOYEE_FIELDS_MASTERDATA.DEPARTMENT_FUNCTION,
        value: "",
        editable: true,
        required: false,
        inputData: []
      },
    ];
  }

  public convertImageBase64(image: File): Observable<string> {
    const convertedImage: ReplaySubject<string> = new ReplaySubject<string>(1);
    const reader: FileReader = new FileReader();

    reader.readAsBinaryString(image);
    reader.onload = () => {
      convertedImage.next(btoa(reader.result as string))
    }

    return convertedImage;
  }

  public mapStringToEmployeeState(input: string): EmployeeState {
    switch (input) {
    case $localize`active|Active`:
      return EmployeeState.Active;
    case $localize`inactive|Inactive`:
      return EmployeeState.Inactive;
    case $localize`locked|Locked`:
      return EmployeeState.Locked;
    default:
      return EmployeeState.Inactive;
    }
  }

  public mapStringToEmployeeTitle(input: string): EmployeeTitle {
    switch (input) {
      case $localize`Miss|Miss`:
        return EmployeeTitle.Miss;
      case $localize`Mister|Mister`:
        return EmployeeTitle.Mister;
      case $localize`Diverse|Diverse`:
        return EmployeeTitle.Diverse;
      case $localize`Not specified|Not specified`:
        return EmployeeTitle.NotSpecified;
      default:
        return EmployeeTitle.NotSpecified;
    }
  }

  public createEmployee(employee: IEmployeeCreateOrUpdateDto): Observable<ILdapResponseDto> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.createEmployee(employee)
    );
  }

  public updateEmployee(employee: IEmployeeUpdateDto): Observable<ILdapResponseDto> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.updateEmployee(employee)
    );
  }

  public getLocationTableService(): ILocationTableService {
    return this.locationTableService;
  }

  public getTableStructure(): IEmployeeCreateTableService {
    return this.employeeCreateTableService;
  }

  public isDelAdminOnly(): boolean {
    return this.authService.isDelAdminOnly();
  }
}

import {
  Observable
} from "rxjs";
import {
  IAuthenticationDto
} from "./iauthentication.dto";
import {
  IPasswordRenewInputDto
} from "../password/ipassword-renew-input.dto";
import {
  IPasswordResetRequestDto
} from "../password/ipassword-reset-request.dto";
import {
  IPasswordResetOutputDto
} from "../password/ipassword-reset-output.dto";
import {
  IExternalLoginProviderInfoModel
} from "./iexternal-login-provider-info.dto";
import {
  IPingAuthenticateModel
} from "./iping-authenticate-model.dto";

export abstract class IAuthApi {
  abstract requestPasswordReset(email: string): Observable<IPasswordResetRequestDto>;
  abstract passwordReset(password: string, code: string): Observable<IPasswordResetOutputDto[]>;
  abstract passwordRenew(renewData: IPasswordRenewInputDto): Observable<IPasswordResetOutputDto[]>;
  abstract getExternalAuthenticationProviders(): Observable<IExternalLoginProviderInfoModel[]>;
  abstract pingAuthenticate(body: IPingAuthenticateModel): Observable<IAuthenticationDto>;
}


import {
  Component, Output
} from "@angular/core";
import {
  INavigationService
} from "../../../services/navigation";
import {
  Banner,
  Logo
} from "../imageData";
import {
  Path
} from "../../app-routing.module";
import {
  ImageData,
  PrivacySection
} from "hagebau-coremedia";

const LINEBREAK: string = '';

@Component({
  selector: 'login-privacy',
  templateUrl: './login-privacy.component.html'
})
export class LoginPrivacyComponent {
  //services
  navigationService : INavigationService;

  //data
  banner:ImageData = Banner;
  logo :ImageData = Logo;

  @Output()
    privacySections: PrivacySection[] = [
      {
        headingLevel: 1,
        heading: $localize`privacyResponsibleForYourData`,
        paragraphs: [
          $localize`privacyDataHandlingResponsibility`,
          LINEBREAK,
          $localize`privacyHagebauDescription`,
          LINEBREAK,
          $localize`privacyContactInformation`,
        ]
      },
      {
        headingLevel: 1,
        heading: $localize`privacyDataSecurity`,
        paragraphs: [
          $localize`privacySecurityMeasures`,
          LINEBREAK,
          $localize`privacySystemResilience`,
          LINEBREAK,
          $localize`privacyDataEncryption`,
          LINEBREAK,
          $localize`privacyContinuousImprovement`,
          LINEBREAK,
          $localize`privacyEmployeeConfidentiality`,
        ]
      },
      {
        headingLevel: 1,
        heading: $localize`privacyMandatoryFields`,
        paragraphs: [
          $localize`privacyMandatoryInformation`
        ]
      },
      {
        headingLevel: 1,
        heading: $localize`generalDataProcessing`,
        paragraphs: [
          $localize`privacyRegistrationProcessing`,
          LINEBREAK,
          $localize`privacyTechnicalServiceNotification`,
          LINEBREAK,
          $localize`privacyRegistrationDataRetention`,
          LINEBREAK,
          $localize`privacyPortalFunctionDetails`,
        ]
      },
      {
        headingLevel: 2,
        heading: $localize`privacyContactInquiries`,
        paragraphs: [
          $localize`privacyInquiryProcessing`,
          LINEBREAK,
          $localize`privacyInquiryDataRetention`,
        ]
      },
      {
        headingLevel:2,
        heading: $localize`privacyEmployeeDirectory`,
        paragraphs: [
          $localize`privacyEmployeeDirectoryDetails`
        ]
      },
      {
        headingLevel: 2,
        heading: $localize`privacyDiscussionForumPosts`,
        paragraphs: [
          $localize`privacyForumPostProcessing`,
          LINEBREAK,
          $localize`privacyForumPostAccessibility`,
          '',
        ]
      },
      {
        headingLevel: 2,
        heading: $localize`privacyOrdersAndInquiriesProcessing`,
        paragraphs: [
          $localize`privacyOrderDataTransmission`,
          LINEBREAK,
          $localize`privacyMandatoryOrderData`,
          LINEBREAK,
          $localize`privacyOrderDataRetention`,
        ]
      },
      {
        headingLevel: 2,
        heading: $localize`privacyExternalPortalAndShopUsage`,
        paragraphs: [
          $localize`privacyExternalPortalAndShopOperators`,
          LINEBREAK,
          $localize`privacyExternalPortalAndShopRevocation`,
          LINEBREAK,
          $localize`privacyExternalPortalAndShopRevocationDetails`,
        ]
      },
      {
        headingLevel: 2,
        heading: $localize`privacyInformationViaMail`,
        paragraphs: [
          $localize`privacyNewsletterSubscriptions`,
          LINEBREAK,
          $localize`privacyNewsletterDeliveryTracking`,
          LINEBREAK,
          $localize`privacyNewsletterDoubleOptIn`,
          LINEBREAK,
          $localize`privacyNewsletterDataRetention`
        ]
      },
      {
        headingLevel: 2,
        heading: $localize`privacyExtendedRetentionPeriods`,
        paragraphs: [
          $localize`privacyNewsletterRetentionExtension`
        ]
      },
      {
        headingLevel: 1,
        heading: $localize`privacyCookies`,
        paragraphs: [
          $localize`privacyCookiesDefinition`
        ]
      },
      {
        headingLevel: 2,
        heading: $localize`privacyUseOfCookies`,
        paragraphs: [
          $localize`privacyCookiesUsage`
        ]
      },
      {
        headingLevel: 2,
        heading: $localize`privacyCookiesRejection`,
        paragraphs: [
          $localize`privacyCookiesRejectionDetails`
        ]
      },
      {
        headingLevel: 1,
        heading: $localize`privacyRevocationAdvertising`,
        paragraphs: [
          $localize`privacyRightToObject`,
          LINEBREAK,
          $localize`privacyRevocationAndObjections`,
          LINEBREAK,
          $localize`privacyLegalityStatement`
        ]
      },
      {
        headingLevel: 1,
        heading: $localize`privacyDataProtectionRights`,
        paragraphs: [
          $localize`privacyInfoProcessingData`,
          LINEBREAK,
          $localize`privacyRights`,
          LINEBREAK,
          $localize`privacyContactDataProtectionOfficer`,
          LINEBREAK,
          $localize`privacyComplaintRight`
        ]
      },
      {
        headingLevel: 1,
        heading: $localize`privacyChanges`,
        paragraphs: [
          $localize`privacyUpdatesInfo`,
          LINEBREAK,
          $localize`privacyLastModified`
        ]
      }
    ];

  constructor(
    navigationService: INavigationService
  ) {
    this.navigationService = navigationService;
  }

  redirectToLogin(_: void) {
    this.navigationService.navigate(Path.LOGIN);
  }

  redirectToImprint(_: void) {
    this.navigationService.navigate(Path.IMPRINT);
  }
}

import {
  z
} from "zod";

export const ExternalLoginProviderInfoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  name: z.string(),
  clientId: z.string(),
  additionalParams: z.record(z.string(), z.string())
});

export type IExternalLoginProviderInfoModel = z.infer<typeof ExternalLoginProviderInfoSchema>;

import {
  z
} from "zod";
import {
  EmployeeManagementLevel
} from "./employee-management-level.enum";
import {
  EmployeeState
} from "./employee-state.enum";

export enum ImportStatus
{
  Success,
  SuccessUpdate,
  InProgress,
  LdapError,
  CreateEmployeeError,
  AssignLocationError,
  AssignAppPermissionError,
  AssignRolesError,
  UpdateEmployeeError,
  NoName,
  NoSurname,
  NoEmail,
  InvalidEmail,
  MultipleExistingEmailsFound,
  UnknownError
}

export const CreateEmployeeResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  title: z.string(),
  givenName: z.string(),
  surName: z.string(),
  email: z.string(),
  status: z.nativeEnum(EmployeeState),
  isSupplier: z.boolean(),
  managementLevel: z.nativeEnum(EmployeeManagementLevel),
  phoneNumber: z.string(),
  mobileNumber: z.string(),
  departmentFunction: z.string(),
  sector: z.string(),
  locationCns: z.array(z.string()),
  response: z.nativeEnum(ImportStatus)
})

export type ICreateEmployeeResponseDto = z.infer<typeof CreateEmployeeResponseDtoSchema>;

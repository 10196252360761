import {
  LdapSortDirection
} from "../LdapSortDirection";

export interface ILocationSortDto {
  SortKey: LocationSortKeys,
  SortDirection: LdapSortDirection
}

export enum LocationSortKeys {
  None, //default
  Branch,
  Description,
  Number,
  MainLocation
}

import {
  PmMenuActionData,
  Row
} from 'hagebau-coremedia';
import {
  IEmployeeDto
} from './index';
import {
  EmployeeEditViewData
} from './employee-table.service';
import {
  IEmployeeDetailDto
} from './iemployee-detail.dto';
import {
  ILocationDto
} from '../location';
import {
  IAppPermissionDto
} from '../appPermission/IAppPermissionDto';
import {
  CurrentEmployeeWithRoles
} from '../account/auth/auth.service';

export abstract class IEmployeeTableService {
  public abstract getMenuActions(): PmMenuActionData[];
  public abstract mapEmployeesToRows(employees: IEmployeeDto[], currentEmployee: CurrentEmployeeWithRoles): Row[]
  public abstract mapEmployeeToEditViewData(employee: IEmployeeDetailDto): EmployeeEditViewData;
  public abstract mapEmployeeLocationsToRows(locations: ILocationDto[]): Row[];
  public abstract mapEmployeeDetailLocationsToRows(locations: ILocationDto[]): Row[];
  public abstract mapEmployeeAppPermissionToRows(appPermissions: IAppPermissionDto[]): Row[];
}

import {
  PmInputData,
  SearchInputEvent
} from "hagebau-coremedia";
import {
  ISearchTermsDto
} from "./isearch-terms.dto";
import {
  WithRx
} from "../../app/mixins";
import {
  OperatorFunction
} from "rxjs";

export abstract class ISearchService extends WithRx() {
  abstract convertSearchResults(termsDto: ISearchTermsDto): PmInputData[];
  abstract preProcessSearchInput(minSearchLength: number): OperatorFunction<string, string>;
  abstract preProcessSearchInputEvent(minSearchLength: number): OperatorFunction<SearchInputEvent, SearchInputEvent>;
}

import {
  ISearchService
} from "./isearch.service";
import {
  PmInputData,
  SearchInputEvent
} from "hagebau-coremedia";
import {
  ISearchTermsDto
} from "./isearch-terms.dto";
import {
  Observable,
  OperatorFunction
} from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map
} from "rxjs/operators";
import {
  AppSettings
} from "../appSettings/appSettings";

export interface ISearchInfo {
  searchTerms: string[],
  searchFields: string[],
}

export class SearchService extends ISearchService {
  public convertSearchResults(termsDto: ISearchTermsDto): PmInputData[] {
    return termsDto.terms.map((term, index) => {
      return {
        key: index.toString(),
        value: term
      }
    })
  }

  public preProcessSearchInput(minSearchLength: number): OperatorFunction<string, string> {
    return (source: Observable<string>): Observable<string> =>
      source.pipe(
        map(searchTerm => searchTerm.trim()),
        distinctUntilChanged(),
        filter(searchTerm => searchTerm.length > minSearchLength),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY)
      );
  }

  public preProcessSearchInputEvent(minSearchLength: number): OperatorFunction<SearchInputEvent, SearchInputEvent> {
    return (source: Observable<SearchInputEvent>): Observable<SearchInputEvent> =>
      source.pipe(
        map(searchInput => {
          return {
            text: searchInput.text.trim(),
            rowId: searchInput.rowId
          }
        }),
        distinctUntilChanged(),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY),
        filter(searchInput => searchInput.text.length > minSearchLength),
      );
  }
}

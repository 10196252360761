import {
  Observable
} from "rxjs";
import {
  ILocationImportDto,
  ILocationRowOfFileDto,
} from "./ilocation-import.dto";
import {
  ImportData
} from "hagebau-coremedia";
import {
  ILocationBulkCreateResponseDto
} from "./ilocation-bulk-create-response.dto";

export abstract class ILocationImportService {
  abstract getLocationsFromFile(file: File): Observable<ILocationRowOfFileDto>;
  abstract getFullLocationsFromFile(file: File): Observable<ILocationRowOfFileDto>;
  abstract importLocations(locations: ILocationImportDto[]): Observable<ILocationBulkCreateResponseDto>;
  abstract importFullLocations(locations: ILocationImportDto[]): Observable<ILocationBulkCreateResponseDto>;
  abstract convertResponseToFile(response: ILocationBulkCreateResponseDto): void;
  abstract getDefaultImportData(): Observable<ImportData>;
  abstract getImportDataByFile(template: ImportData, file: File): ImportData;
  abstract shouldUseFullLocationImport(): Observable<boolean>;
}




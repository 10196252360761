import {
  z
} from "zod";
import {
  BaseEmployeeDtoSchema
} from "./iemployeeBase.dto";
import {
  BaseLocationDtoSchema
} from "../location/ilocationBase.dto";
import {
  createPaginatedResponseSchema
} from "../../network/api/pagination-api.service";

export const EmployeeDtoSchema = BaseEmployeeDtoSchema.extend({ // eslint-disable-line @typescript-eslint/typedef
  mainLocations: z.array(BaseLocationDtoSchema),
  locations: z.array(BaseLocationDtoSchema),
});
export const PaginatedEmployeeDtoSchema = createPaginatedResponseSchema(EmployeeDtoSchema) // eslint-disable-line @typescript-eslint/typedef

export type IEmployeeDto = z.infer<typeof EmployeeDtoSchema>;
export type IPaginatedEmployeeDto = z.infer<typeof PaginatedEmployeeDtoSchema>;

export enum ApplicationName {
  DEBTOR = "debtor",
  CREDITOR = "creditor",
  SERVICE_BONUS = "service_bonus",
  SERVICE_BONUS_WRITE = "service_bonus.Write",
  PAYMENT_ADVICE = "payment_advice",
  SUPPLIER_DIRECTORY = "supplier_directory",
  SUPPLIER_DIRECTORY_MARKETING_PROFIT = "supplier_directory.marketingprofit",
  VALUATION_OVERVIEW = "valuation_overview",
}

import {
  Observable
} from 'rxjs';
import {
  Injectable
} from '@angular/core';
import {
  ILocationBulkDeleteResponseDto,
  IModifyLocationInput,
  ILocationCreateBulkRequestDto,
  ILocationBulkCreateResponseDto,
  ILocationRequestDto,
  ILocationDetailDto,
  IPaginatedLocationDto,
  PaginatedLocationDtoSchema,
  LocationDetailDtoSchema,
  LocationBulkCreateResponseDtoSchema,
  LocationBulkDeleteResponseDtoSchema,
  IPaginatedMainLocationDto,
  PaginatedMainLocationDtoSchema,
  IPaginatedMinimalLocationDto,
  PaginatedMinimalLocationDtoSchema,
} from "../../services/location";
import {
  ILocationApi
} from "../../services/location/ilocation-api.service";
import {
  SwaggerClientService
} from "./http/swagger-client.service";
import {
  environment
} from "../../environments/environment";
import {
  RequestOptions
} from "./http/request-options";
import {
  ILdapResponseDto,
  LdapResponseDtoSchema
} from "../../services/ildapResponse.dto";
import {
  ISearchTermsDto,
  SearchTermsDtoSchema
} from "../../services/search/isearch-terms.dto";
import {
  catchError,
  map
} from "rxjs/operators";
import {
  ILocationStateUpdateDto
} from "../../services/location/ILocationStateUpdateDto";
import {
  IlocationMinimalDto,
  LocationMinimalDtoSchema
} from "../../services/location/ilocation-minimal.dto";
import {
  IModifyMainLocationInput
} from "../../services/location/imodifyMainLocationInput";
import {
  IMainLocationHistoryRequestDto
} from "../../services/location/IMainLocationHistoryRequestDto";
import {
  IPaginatedMainLocationHistoryDto,
  PaginatedMainLocationHistoryDtoSchema
} from "../../services/location/IMainLocationHistoryDto";

@Injectable()
export class LocationApi extends ILocationApi {
  private swaggerClient: SwaggerClientService;

  private readonly baseUrl: string = environment.url;

  constructor(swaggerClient: SwaggerClientService) {
    super();
    this.swaggerClient = swaggerClient;
  }

  createLocations(requestDto: ILocationCreateBulkRequestDto): Observable<ILocationBulkCreateResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/CreateLocations";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationCreateBulkRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LocationBulkCreateResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILocationBulkCreateResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  createFullLocations(requestDto: ILocationCreateBulkRequestDto): Observable<ILocationBulkCreateResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/CreateFullLocations";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationCreateBulkRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LocationBulkCreateResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILocationBulkCreateResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  deleteLocations(ids: string[]): Observable<ILocationBulkDeleteResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/DeleteLocations";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<string[]> = new RequestOptions()
    requestOptions.body = ids;
    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LocationBulkDeleteResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILocationBulkDeleteResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getLocations(requestDto: ILocationRequestDto): Observable<IPaginatedLocationDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/Locations";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => PaginatedLocationDtoSchema.parse(response)),
      catchError(error => {
        console.error("IPaginatedLocationDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getSupplierLocations(requestDto: ILocationRequestDto): Observable<IPaginatedLocationDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/SupplierLocations";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => PaginatedLocationDtoSchema.parse(response)),
      catchError(error => {
        console.error("IPaginatedLocationDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  // get Locations without employees and contracts
  getSimplifiedLocations(requestDto: ILocationRequestDto): Observable<IPaginatedMinimalLocationDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/GetMinimalLocations";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }


    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => PaginatedMinimalLocationDtoSchema.parse(response)),
      catchError(error => {
        console.error("IPaginatedMinimalLocationDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getSimplifiedLocationsIgnoringRoles(requestDto: ILocationRequestDto): Observable<IPaginatedMinimalLocationDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/GetMinimalLocationsIgnoringRoles";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => PaginatedMinimalLocationDtoSchema.parse(response)),
      catchError(error => {
        console.error("IPaginatedMinimalLocationDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getMainLocationHistory(requestDto: IMainLocationHistoryRequestDto): Observable<IPaginatedMainLocationHistoryDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/GetMainLocationHistory";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IMainLocationHistoryRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => PaginatedMainLocationHistoryDtoSchema.parse(response)),
      catchError(error => {
        console.error("IPaginatedMainLocationHistoryDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getMainLocations(requestDto: ILocationRequestDto): Observable<IPaginatedMainLocationDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/MainLocations";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => PaginatedMainLocationDtoSchema.parse(response)),
      catchError(error => {
        console.error("IPaginatedLocationDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getChildLocationsForSecurity(mainLocationCn: string): Observable<IlocationMinimalDto[]> {
    let url: string = this.baseUrl + `/api/services/app/Location/GetChildLocationsForSecurity?mainLocationCn=${mainLocationCn}`;
    url = url.replace(/[?&]$/, "");

    return this.swaggerClient.request("get", url, new RequestOptions(), this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => LocationMinimalDtoSchema.parse(dto))),      catchError(error => {
        console.error("IPaginatedLocationDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  search(term: string): Observable<ISearchTermsDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/Search";
    url = url.replace(/[?&]$/, "");
    url += "?terms=" + term;

    const requestOptions: RequestOptions<string> = new RequestOptions();
    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => SearchTermsDtoSchema.parse(response)),
      catchError(error => {
        console.error("ISearchTermsDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  searchForAll(term: string): Observable<IlocationMinimalDto[]> {
    let url: string = this.baseUrl + "/api/services/app/Location/SearchForAllHgbNumbers";
    url = url.replace(/[?&]$/, "");
    url += "?terms=" + term;

    const requestOptions: RequestOptions<string> = new RequestOptions();
    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => LocationMinimalDtoSchema.parse(dto))),
      catchError(error => {
        console.error("ILocationDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }


  searchFiltered(requestDto: ILocationRequestDto): Observable<ISearchTermsDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/SearchFiltered";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }
    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => SearchTermsDtoSchema.parse(response)),
      catchError(error => {
        console.error("ISearchTermsDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  updateLocation(modifyInput: IModifyLocationInput): Observable<ILdapResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/UpdateLocation";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IModifyLocationInput> = new RequestOptions()
    requestOptions.body = {
      "number": modifyInput.number,
      "newLocation": modifyInput.newLocation
    };

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LdapResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILdapResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  updateMainLocationEmailsAndDomains(modifyInput: IModifyMainLocationInput): Observable<ILdapResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/UpdateMainLocationEmailsAndDomains";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<IModifyMainLocationInput> = new RequestOptions()
    requestOptions.body = {
      "mainLocationNumber": modifyInput.mainLocationNumber,
      "domains": modifyInput.domains,
      "addresses": modifyInput.addresses
    };

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LdapResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILdapResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getLocation(locationNumber: string): Observable<ILocationDetailDto> {
    let url: string = this.baseUrl + `/api/services/app/Location/GetOneLocation?locationNumber=${locationNumber}`;
    url = url.replace(/[?&]$/, "");

    return this.swaggerClient.request("get", url, new RequestOptions(), this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LocationDetailDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILocationDetailDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  updateLocationsState(stateUpdate: ILocationStateUpdateDto): Observable<ILdapResponseDto> {
    let url: string = this.baseUrl + "/api/services/app/Location/UpdateLocationsState";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<ILocationStateUpdateDto> = new RequestOptions();
    requestOptions.body = {
      ...stateUpdate
    }

    return this.swaggerClient.request("post", url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LdapResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error("ILdapResponseDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }
}

import {
  ImageSize,
  ImageType,
  ImageData
} from "hagebau-coremedia";

export const Banner: ImageData = {
  size: ImageSize.COVER,
  type: ImageType.DEFAULT,
  imageSources: [{
    path: "/assets/team-700.jpg",
    width: '700w'
  },{
    path: "/assets/team-1080.jpg",
    width: '1080w'
  }],
  imageSizeRules: [
    '50vw'
  ]
}

export const Logo: ImageData = {
  size: ImageSize.S,
  type: ImageType.DEFAULT,
  imageSources: [{
    path: "/assets/logo-wide.svg",
    width: '900w'
  }],
  imageSizeRules: [
    '25vw'
  ]
}

import {
  z
} from "zod";

export const AuthenticationDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  accessToken: z.string(),
  userRoles: z.array(z.string()),
  shouldResetPassword: z.boolean()
});

export type IAuthenticationDto = z.infer<typeof AuthenticationDtoSchema>;

import {
  Observable
} from "rxjs";
import {
  IContractBulkCreateResponseDto,
  IcontractBulkModifyResponseDto,
  IContractCreateBulkRequestDto,
  IContractRequestDto,
  IModifyContractInput,
  IPaginatedContractDto
} from "./index";
import {
  IContractDetailDto
} from "./icontract-detail.dto";
import {
  ILdapResponseDto
} from "../ildapResponse.dto";
import {
  ISearchTermsDto
} from "../search/isearch-terms.dto";
import {
  IContractStateUpdateDto
} from "./IContractStateUpdateDto";

export abstract class IContractApi {
  abstract createContracts(requestDto: IContractCreateBulkRequestDto): Observable<IContractBulkCreateResponseDto>;

  abstract getContracts(requestDto: IContractRequestDto): Observable<IPaginatedContractDto>

  abstract getContract(contractNumber: string): Observable<IContractDetailDto> ;

  abstract updateContract(modifyInput: IModifyContractInput): Observable<ILdapResponseDto>;

  abstract deleteContracts(ids: string[]): Observable<IcontractBulkModifyResponseDto>;

  abstract search(term: string): Observable<ISearchTermsDto>;

  abstract searchFiltered(requestDto: IContractRequestDto): Observable<ISearchTermsDto>;

  abstract updateContractsState(stateUpdate: IContractStateUpdateDto): Observable<ILdapResponseDto>
}

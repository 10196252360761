import {
  Component
} from '@angular/core';
import {
  ImageData
} from "hagebau-coremedia";
import {
  IAuthService
} from "../../../services/account/iauth.service";
import {
  INavigationService
} from "../../../services/navigation";
import {
  Banner,
  Logo
} from "../imageData";
import {
  Path
} from "../../app-routing.module";
import {
  HgbLoadingKeys,
  WithLoading,
  WithRx
} from "../../mixins";
import {
  IHeaderService
} from "../../../services/header";
import {
  LoginService
} from "./login.service";
import {
  ActivatedRoute
} from "@angular/router";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends WithRx(WithLoading()) {
  //services
  private authService: IAuthService;
  private navigationService: INavigationService;
  private headerService: IHeaderService;
  private loginService: LoginService;
  private route: ActivatedRoute;

  //data
  banner: ImageData = Banner;
  logo: ImageData = Logo
  email: string = "";

  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  constructor(
    authService: IAuthService,
    navigationService: INavigationService,
    headerService: IHeaderService,
    loginService: LoginService,
    route: ActivatedRoute
  ) {
    super();
    this.authService = authService;
    this.navigationService = navigationService;
    this.headerService = headerService;
    this.loginService = loginService;
    this.route = route;
  }


  onExternalLogin(): void {
    this.loginService.startOpenIdConnectLogin();
  }

  forgotPassword(_: void) {
    this.navigationService.navigate(Path.PASSWORD_RESET_REQUEST)
  }

  navigateToImprint(_: void) {
    this.navigationService.navigate(Path.IMPRINT)
  }

  navigateToPrivacy(_: void) {
    this.navigationService.navigate(Path.PRIVACY);
  }
}

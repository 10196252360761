import {
  ILocationDto
} from "./ilocation.dto";
import {
  Cell,
  PmMenuActionData,
  Row,
  FilterData,
  LocationDetailEditData,
  PaginationControlData,
  PmInputData
} from "hagebau-coremedia";
import {
  ILocationRequestDto
} from "./ILocationRequestDto";
import {
  ILocationSortDto
} from "./ILocationSortDto";
import {
  ILocationDetailDto
} from "./ilocation-detail.dto";
import {
  IBaseContractDto,
  IContractDto
} from "../contract";
import {
  LocationEditViewData
} from "./location-table.service";
import {
  IFilterDto
} from "../IFilterDto";
import {
  IPaginationService
} from "../pagination/ipagination.service";
import {
  IMainLocationDto
} from "./imain-location.dto";
import {
  ISearchInfo
} from "../search/search.service";
import {
  ICurrentPageInfo
} from "../pagination/IPaginationInfoDto";

export abstract class ILocationTableService {
  public abstract mapLocationsToRows(locations: ILocationDto[]): Row[];
  public abstract getMenuActions(): PmMenuActionData[];
  public abstract mapLocationRequestDto(
    filters: FilterData[],
    searchInfo: ISearchInfo,
    sortInfo: ILocationSortDto,
    pageInfo: ICurrentPageInfo
  ): ILocationRequestDto
  public abstract mapLocationMinimalRequestDto(searchTerms: string[], searchFields: string[], filters: FilterData[]): ILocationRequestDto;
  public abstract mapLocationContractsToRows(contracts: IBaseContractDto[]): Row[];
  public abstract mapLocationToEditViewData(location: ILocationDetailDto): LocationEditViewData;
  public abstract formatMainLocation(locationNumber: string | undefined, description: string | undefined): string;
  public abstract mapEditDataToLocation(editData: LocationDetailEditData, assignedContracts: IBaseContractDto[], searchSuggestions: IMainLocationDto[]): ILocationDetailDto;
  public abstract mapLocationToRow(location: ILocationDto): Cell[];
  public abstract mapContractToRow(contract: IContractDto): Cell[];
  public abstract mapLocationsToMinimalRows(locations: ILocationDto[]): Row[];
  public abstract toFilterDto(filterValue: string, isNotFilter:boolean): IFilterDto;
  public abstract getPagination(): PaginationControlData;
  public abstract getPageSizes(): PmInputData[];
  public abstract getPaginationService(): IPaginationService;
}

import {
  Observable
} from 'rxjs';
import {
  Injectable
} from '@angular/core';
import {
  EmployeeDtoSchema,
  IEmployeeApi,
  IEmployeeDto,
  IEmployeeRequestDto,
  IPaginatedEmployeeDto,
  PaginatedEmployeeDtoSchema
} from '../../services/employee';
import {
  SwaggerClientService
} from './http/swagger-client.service';
import {
  environment
} from '../../environments/environment';
import {
  RequestOptions
} from './http/request-options';
import {
  IEmployeeStateUpdateDto
} from '../../services/employee/IEmployeeStateUpdateDto';
import {
  EmployeeDetailDtoSchema,
  IEmployeeDetailDto
} from '../../services/employee/iemployee-detail.dto';
import {
  ISearchTermsDto, SearchTermsDtoSchema
} from '../../services/search/isearch-terms.dto';
import {
  IResetUserPasswordsDto
} from '../../services/employee/IResetUserPasswordsDto';
import {
  IEmployeeCreateOrUpdateDto
} from '../../services/employee-create/i-employee-create-or-update.dto';
import {
  catchError,
  map,
} from 'rxjs/operators';
import {
  ILdapResponseDto,
  LdapResponseDtoSchema
} from '../../services/ildapResponse.dto';
import {
  IEmployeeUpdateDto
} from '../../services/employee/iemployee-update.dto';
import {
  IEmployeeCreateBulkRequestDto
} from '../../services/employee/iemployee-import.dto';
import {
  EmployeeBulkCreateResponseDtoSchema,
  IEmployeeBulkCreateResponseDto
} from '../../services/employee/iemployee-bulk-create-response.dto';
import {
  EmployeeBulkDeleteResponseDtoSchema,
  IEmployeeBulkDeleteResponseDto
} from '../../services/employee/iemployee-bulk-delete-response.dto';
import {
  BaseLocationDtoSchema,
  IBaseLocationDto,
} from '../../services/location';

@Injectable()
export class EmployeeApi extends IEmployeeApi {
  private swaggerClient: SwaggerClientService;

  private readonly baseUrl: string = environment.url;

  constructor(swaggerClient: SwaggerClientService) {
    super();
    this.swaggerClient = swaggerClient;
  }

  getEmployees(requestDto: IEmployeeRequestDto): Observable<IPaginatedEmployeeDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/Employees';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<IEmployeeRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => PaginatedEmployeeDtoSchema.parse(response)),
      catchError(error => {
        console.error('IPaginatedEmployeeDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  isGlobalAdmin(): Observable<boolean> {
    let url: string = this.baseUrl + '/api/services/app/Employee/IsGlobalAdmin';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<string> = new RequestOptions();

    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsBool.bind(this.swaggerClient));
  }

  updateEmployeesState(stateUpdate: IEmployeeStateUpdateDto): Observable<ILdapResponseDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/UpdateEmployeesState';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<IEmployeeStateUpdateDto> = new RequestOptions();
    requestOptions.body = {
      ...stateUpdate
    }

    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LdapResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error('ILdapResponseDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  search(term: string): Observable<ISearchTermsDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/Search';
    url = url.replace(/[?&]$/, '');
    url += '?term=' + term;

    const requestOptions: RequestOptions<string> = new RequestOptions();
    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => SearchTermsDtoSchema.parse(response)),
      catchError(error => {
        console.error('ISearchTermsDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  searchFiltered(requestDto: IEmployeeRequestDto): Observable<ISearchTermsDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/SearchFiltered';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<IEmployeeRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => SearchTermsDtoSchema.parse(response)),
      catchError(error => {
        console.error('ISearchTermsDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  getEmployee(employeeNumber: string): Observable<IEmployeeDetailDto> {
    let url: string = this.baseUrl + `/api/services/app/Employee/GetOneEmployee?employeeUserNumber=${employeeNumber}`;
    url = url.replace(/[?&]$/, '');

    return this.swaggerClient.request('get', url, new RequestOptions(), this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => EmployeeDetailDtoSchema.parse(response)),
      catchError(error => {
        console.error('IEmployeeDetailDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  GetCurrentEmployee(): Observable<IEmployeeDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/GetCurrentEmployee';
    url = url.replace(/[?&]$/, '');

    return this.swaggerClient.request('get', url, new RequestOptions(), this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => EmployeeDtoSchema.parse(response)),
      catchError(error => {
        console.error('IEmployeeDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  GetCurrentEmployeeMainLocations(searchTerm: string = ''): Observable<IBaseLocationDto[]> {
    let url: string = this.baseUrl + `/api/services/app/Location/GetCurrentEmployeeMainLocations?searchTerm=${searchTerm}`;
    url = url.replace(/[?&]$/, '');

    return this.swaggerClient.request('get', url, new RequestOptions(), this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => BaseLocationDtoSchema.parse(dto))),
      catchError(error => {
        console.error('ILocationDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  resetUsersPasswords(requestDto: IResetUserPasswordsDto): Observable<boolean> {
    let url: string = this.baseUrl + '/api/services/app/Employee/ResetUserPasswords';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<IResetUserPasswordsDto> = new RequestOptions<IResetUserPasswordsDto>();
    requestOptions.body = {
      ...requestDto
    }

    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsBool.bind(this.swaggerClient));
  }

  createEmployee(employee: IEmployeeCreateOrUpdateDto): Observable<ILdapResponseDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/Create';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<IEmployeeCreateOrUpdateDto> = new RequestOptions<IEmployeeCreateOrUpdateDto>();
    requestOptions.body = {
      ...employee
    }

    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LdapResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error('ILdapResponseDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  createEmployees(employees: IEmployeeCreateBulkRequestDto): Observable<IEmployeeBulkCreateResponseDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/CreateEmployees';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<IEmployeeCreateBulkRequestDto> = new RequestOptions();
    requestOptions.body = {
      ...employees
    }

    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => EmployeeBulkCreateResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error('IEmployeeBulkCreateResponseDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  updateEmployee(employee: IEmployeeUpdateDto): Observable<ILdapResponseDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/Update';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<IEmployeeUpdateDto> = new RequestOptions<IEmployeeUpdateDto>();
    requestOptions.body = {
      ...employee
    }

    return this.swaggerClient.request('put', url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => LdapResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error('ILdapResponseDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

  deleteEmployees(ids: string[]): Observable<IEmployeeBulkDeleteResponseDto> {
    let url: string = this.baseUrl + '/api/services/app/Employee/DeleteEmployees';
    url = url.replace(/[?&]$/, '');

    const requestOptions: RequestOptions<string[]> = new RequestOptions()
    requestOptions.body = ids;
    return this.swaggerClient.request('post', url, requestOptions, this.swaggerClient.processResponseAsObject.bind(this.swaggerClient)).pipe(
      map(response => EmployeeBulkDeleteResponseDtoSchema.parse(response)),
      catchError(error => {
        console.error('IEmployeeBulkDeleteResponseDto', error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }

}

import {
  ITableSwitchService
} from "./itable-switch.service";
import {
  Observable
} from "rxjs";
import {
  IPaginationResponseDto
} from "../pagination/IPaginationResponseDto";
import {
  ContractSortKeys,
  IContractDto,
  IContractService
} from "../contract";
import {
  ContractTableService
} from "../contract/contract-table.service";
import {
  AppSettings
} from "../appSettings/appSettings";
import {
  LdapSortDirection
} from "../LdapSortDirection";
import {
  map
} from "rxjs/operators";
import {
  ChipData,
  Filter,
  FilterData,
  PaginationControlData,
  PmInputData,
  Row
} from "hagebau-coremedia";
import {
  ILocationDto,
  ILocationRequestDto,
  ILocationService,
  LocationSortKeys
} from "../location";
import {
  Injectable
} from "@angular/core";
import {
  QueryParameters
} from "../navigation";
import {
  PaginationService
} from "../pagination/pagination.service";
import {
  IPaginationService
} from "../pagination/ipagination.service";
import {
  ValidationException
} from "../../network/api/http/api-exception";
import {
  IExceptionTranslatorService
} from "../exception/iexception-translator.service";

export interface PaginationSearchData {
  page: number,
  pageSize: number,
  selectedSearchTerms: ChipData[]
}

export interface TableSwitchPaginationData {
  pagination: PaginationControlData,
  pageSizes: PmInputData[],
  currentPageSize: number,
  currentPage: number
}

export interface TableSwitchTableData {
  paginationData: TableSwitchPaginationData,
  rows: Row[],
  inputData: PmInputData[],
  searchSuggestionsVisible: boolean,
  searchTerms: ChipData[]
}

@Injectable()
export class TableSwitchService extends ITableSwitchService {

  private readonly contractService: IContractService;
  private readonly locationService: ILocationService;
  private readonly paginationService: IPaginationService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;

  constructor(contractService: IContractService,
    locationService: ILocationService,
    paginationService: IPaginationService,
    exceptionTranslatorService: IExceptionTranslatorService) {
    super();
    this.contractService = contractService;
    this.locationService = locationService;
    this.paginationService = paginationService;
    this.exceptionTranslatorService = exceptionTranslatorService;
  }

  public loadContracts(selectedSearchTerms: ChipData[], currentPage: number, currentPageSize: number, contractCns: string[], excludeCns: boolean): Observable<[Row[], IPaginationResponseDto<IContractDto>]> {
    return this.contractService.getList(
      ContractTableService.mapContractRequestDto(
        this.createCnFilter(contractCns, excludeCns),
        {
          searchTerms: selectedSearchTerms.map(chip => chip.text),
          searchFields: AppSettings.CONTRACT_SEARCH_FIELDS
        },
        {
          SortKey : ContractSortKeys.None,
          SortDirection: LdapSortDirection.Ascending
        },
        {
          currentPage: currentPage,
          currentPageSize: currentPageSize
        }
      )
    )
      .pipe(this.catchValidationException(this.translateError.bind(this)))
      .pipe(
        map(paginatedContracts => [this.locationService.getTableStructure().mapLocationContractsToRows(Array.from(paginatedContracts.pageResult)), paginatedContracts]),
      )
  }

  public loadLocationsForContracts(selectedSearchTerms: ChipData[], currentPage: number, currentPageSize: number, locationCns: string[], excludeCns: boolean): Observable<[Row[], IPaginationResponseDto<ILocationDto>]> {
    const locationRequest: ILocationRequestDto = this.getLocationRequestData(selectedSearchTerms, currentPage, currentPageSize, locationCns, excludeCns);
    return this.locationService.getList(locationRequest).pipe(
      map(paginatedLocations => [this.contractService.getTableStructure().mapContractLocationsToRows(Array.from(paginatedLocations.pageResult)), paginatedLocations]),
    )
  }

  public loadLocations(selectedSearchTerms: ChipData[], currentPage: number, currentPageSize: number, locationCns: string[], excludeCns: boolean, activeFilters: FilterData[]): Observable<[Row[], IPaginationResponseDto<ILocationDto>]> {
    const locationRequest: ILocationRequestDto = this.getLocationRequestData(selectedSearchTerms, currentPage, currentPageSize, locationCns, excludeCns, activeFilters);
    return this.locationService.getList(locationRequest).pipe(
      map(paginatedLocations => [this.contractService.getTableStructure().mapLocationsToRows(Array.from(paginatedLocations.pageResult)), paginatedLocations]),
    )
  }

  public loadSupplierLocations(selectedSearchTerms: ChipData[], currentPage: number, currentPageSize: number, locationCns: string[], excludeCns: boolean, activeFilters: FilterData[]): Observable<[Row[], IPaginationResponseDto<ILocationDto>]> {
    const locationRequest: ILocationRequestDto = this.getLocationRequestData(selectedSearchTerms, currentPage, currentPageSize, locationCns, excludeCns, activeFilters);
    return this.locationService.getSupplierList(locationRequest).pipe(
      map(paginatedLocations => [this.contractService.getTableStructure().mapLocationsToRows(Array.from(paginatedLocations.pageResult)), paginatedLocations]),
    )
  }

  public createCnFilter(entityCns: string[], isNotFilter: boolean): FilterData[] {
    return  entityCns.map(cn => {
      return { //relevant values for later mapping are identifier, value and isNotFilter
        filterId: 1,
        name: "cn",
        value: cn,
        identifier: "cn",
        key: 1,
        text: "",
        isNotFilter: isNotFilter
      }
    });
  }

  public getUpdateTableData(selectedSearchTerms: ChipData[], paginationData: TableSwitchPaginationData): PaginationSearchData {
    const requestParams: QueryParameters<LocationSortKeys> = {
      filters: [],
      search: selectedSearchTerms.length > 0 ? selectedSearchTerms.map(chip => chip.text) : [],
      page: paginationData.currentPage > 1 ? paginationData.currentPage : 0,
      pageSize: paginationData.currentPageSize !== parseInt(paginationData.pageSizes[0].value) ? paginationData.currentPageSize : 0
    }

    const updatedPageSize: number = this.paginationService.parseCurrentPageSize(requestParams.pageSize, paginationData.pageSizes);
    const updatedPage: number = this.paginationService.parseCurrentPageNumber(requestParams.page, paginationData.currentPageSize, parseInt(paginationData.pagination.resultTotal));
    const updatedSelectedSearchTerms: ChipData[] = requestParams.search?.map((term, index): ChipData => ({
      key: index,
      text: term
    })) ?? [];

    return {
      page: updatedPage,
      pageSize: updatedPageSize,
      selectedSearchTerms: updatedSelectedSearchTerms
    }
  }

  public static getDefaultTableData(): TableSwitchTableData {
    return {
      paginationData: {
        pagination: PaginationService.PAGINATION_DEFAULT,
        pageSizes: [],
        currentPageSize: AppSettings.INITIAL_PAGE_SIZE,
        currentPage: 1
      },
      rows: [],
      inputData: [],
      searchSuggestionsVisible: false,
      searchTerms: []
    }
  }

  public mapPaginationData(paginationData: PaginationControlData, pageSizes: PmInputData[]): TableSwitchPaginationData {
    return {
      pagination: paginationData,
      pageSizes: pageSizes,
      currentPageSize: parseInt(pageSizes[0].value),
      currentPage: paginationData.current
    }
  }

  public getFiltersAll(): Filter[] {
    return [
      {
        filterId: 0,
        name: $localize`number|Number`,
        identifier: "number",
        text: "",
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      },
      {
        filterId: 1,
        name: $localize`mainLocation|Main Location`,
        identifier: "mainLocation",
        text: "",
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      },
      {
        filterId: 2,
        name: $localize`contracts|contracts`,
        identifier: "contract",
        text: "",
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      }
    ];
  }

  public getFiltersAssigned(): Filter[] {
    return [
      {
        filterId: 0,
        name: $localize`number|Number`,
        identifier: "number",
        text: "",
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      },
      {
        filterId: 1,
        name: $localize`mainLocation|Main Location`,
        identifier: "mainLocation",
        text: "",
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      },
      {
        filterId: 2,
        name: $localize`contracts|contracts`,
        identifier: "contract",
        text: "",
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      }
    ];
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }

  private getLocationRequestData(
    selectedSearchTerms: ChipData[],
    currentPage: number,
    currentPageSize: number,
    locationCns: string[],
    excludeCns: boolean,
    activeFilters: FilterData[] = []
  ) {
    const combinedFilters: FilterData[] = [
      ...activeFilters,
      ...this.createCnFilter(locationCns, excludeCns)
    ];

    return this.locationService.getTableStructure().mapLocationRequestDto(
      combinedFilters,
      {
        searchTerms: selectedSearchTerms.map(chip => chip.text),
        searchFields: AppSettings.LOCATION_SEARCH_FIELDS_ASSIGN_TABLE
      },
      {
        SortKey: LocationSortKeys.None,
        SortDirection: LdapSortDirection.Ascending
      },
      {
        currentPage: currentPage,
        currentPageSize: currentPageSize
      }
    );
  }
}

import {
  IFilterDto
} from "../IFilterDto";

export interface ILocationFilterDto {
  Cn: IFilterDto[],
  Branch: IFilterDto[],
  MainLocation: IFilterDto[],
  Description: IFilterDto[],
  Number: IFilterDto[],
  Contract: IFilterDto[]
}

import {
  HttpHeaders
} from "@angular/common/http";

export class RequestOptions<T> {
  observe: string = "response";
  responseType: string = "blob";
  headers: HttpHeaders = new HttpHeaders({
    "Accept": "text/plain"
  });
  body: T = <T>{
  };
}

import {
  LdapSortDirection
} from "../LdapSortDirection";

export interface IContractSortDto {
  SortKey: ContractSortKeys,
  SortDirection: LdapSortDirection
}

export enum ContractSortKeys {
  None,
  Name,
  Description,
  Category,
  CreatedAt,
  LastEditedAt
}

<div class="alert-container">
  <ng-container *ngFor="let alert of alerts">
    <pm-alert
      *ngIf="alert.label"
      [absolute]="false"
      [label]="alert.label"
      [type]="alert.alertType"
      @alertAnimation
    ></pm-alert>
  </ng-container>
</div>
<pm-grid
  [header]="header"
  [navigation]="navigation"
  [logoPath]="logoPath"
  [fullscreen]="isFullScreen"
  (onNavigationClicked)="navigate($event)"
  (onBreadcrumbClicked)="navigateCrumb($event)"
  (onLogoutClicked)="logout($event)"
>
  <router-outlet>
  </router-outlet>
</pm-grid>

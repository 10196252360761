import {
  IContractDto
} from "./icontract.dto";

export interface IContractCreateBulkRequestDto {
  Contracts: IContractDto[]
}

export interface IContractRowOfFileDto {
  amountOfEntriesInFile: number,
  contractRow: IContractDto,
  currentRow: number
}

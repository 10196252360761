import {
  z
} from "zod";

export const BaseLocationDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  number: z.string(),
  description: z.string(),
  branch: z.string(),
  mainLocation: z.string(),
  employeeCount: z.number(),
  isIncomplete: z.boolean(),
  isActive: z.boolean(),
  allowedEmailDomains: z.array(z.string()),
  allowedEmailAddresses: z.array(z.string())
})

export type IBaseLocationDto = z.infer<typeof BaseLocationDtoSchema>;

<pm-contract-details
  [editMode]="editMode"
  [hideEditBtn]="isDelAdminOnly || isServiceAdmin"
  [fields]="contractFields"
  [detailsLoading]="loading[HgbLoadingKeys.LOAD_DETAILS]"
  [locationsOverviewLoading]="loading[HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW]"
  [locationsAssignedLoading]="loading[HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS]"
  [assignedLocationTableTextView]="assignedLocationsTableTextView"
  [allLocationTableText]="allLocationsTableText"
  [assignedLocationTableTextEdit]="assignedLocationsTableTextEdit"
  [columnNamesLocation]="columnNamesLocation"
  [rowsLocationAll]="allLocations.rows"
  [rowsLocationAssigned]="assignedLocations.rows"
  [allLocationPagination]="allLocations.paginationData.pagination"
  [assignedLocationPagination]="assignedLocations.paginationData.pagination"
  [allLocationPageSizes]="allLocations.paginationData.pageSizes"
  [assignedLocationPageSizes]="assignedLocations.paginationData.pageSizes"
  [selectedAllLocationPageSize]="allLocations.paginationData.currentPageSize"
  [selectedAssignedLocationPageSize]="assignedLocations.paginationData.currentPageSize"
  [searchSuggestionsAllVisible]="allLocations.searchSuggestionsVisible"
  [searchSuggestionsAssignedVisible]="assignedLocations.searchSuggestionsVisible"
  [searchSuggestionsAll]="allLocations.inputData"
  [searchSuggestionsAssigned]="assignedLocations.inputData"
  [activeSearchTermsAll]="allLocations.searchTerms"
  [activeSearchTermsAssigned]="assignedLocations.searchTerms"
  [desktopLocationEditNotice]="desktopEditNotice"
  [showCheckboxOnDesktopOnly]="showCheckboxOnDesktopOnly"
  [resetTableHeaderCheckboxWithPagination]="resetTableHeaderCheckboxWithPagination"
  [activeBox]="activeBox"
  (onAllLocationPageAmountChanged)="allLocationPageAmountChanged($event)"
  (onAssignedLocationPageAmountChanged)="assignedLocationPageAmountChanged($event)"
  (onAllLocationShowPage)="allLocationShowPage($event)"
  (onAssignedLocationShowPage)="assignedLocationShowPage($event)"
  (onAllLocationsSearchInputChanged)="allLocationsSearchInputChangeHandler($event)"
  (onAssignedLocationsSearchInputChanged)="assignedLocationsSearchInputChangeHandler($event)"
  (onSelectedAllLocationSearchTermsChanged)="allLocationSearchChipsChangedHandler($event)"
  (onSelectedAssignedLocationSearchTermsChanged)="assignedLocationsSearchChipsChangedHandler($event)"
  (onAddLocations)="addLocationsHandler($event)"
  (onRemoveLocations)="removeLocationsHandler($event)"
  (onExit)="exitHandler($event)"
  (onEditModeChanged)="editModeHandler($event)"
  *ngIf="!error"
></pm-contract-details>

<pm-modal
  *ngIf="isModalOpen"
  (onCloseClicked)="hideModal()"
  (onConfirmClicked)="onConfirm()"
  (onCancelClicked)="hideModal()"
  [headerText]="modalText.header"
  [subText]="modalText.text"
  [labelNo]="buttonLabel.negative"
  [labelYes]="buttonLabel.positive">
</pm-modal>

<pm-error
  *ngIf="error"
></pm-error>

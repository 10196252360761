import {
  Icon,
  NavigationElement
} from "hagebau-coremedia";
import {
  INavigationService
} from "./inavigation.service";
import {
  Injectable,
  OnDestroy
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from "@angular/router";
import {
  BehaviorSubject,
  Observable,
  Subject,
  throwError
} from "rxjs";
import {
  filter,
  map,
  takeUntil
} from "rxjs/operators";
import {
  Path
} from "../../app/app-routing.module";
import {
  QueryParameterService,
  QueryParameterSubscriptionData
} from "./query-parameter.service";
import {
  IAuthService
} from "../account/iauth.service";
import {
  IBreadcrumbsService
} from "../header";

@Injectable()
export class NavigationService extends INavigationService implements OnDestroy {
  private readonly router: Router;

  private readonly queryParameterService: QueryParameterService;

  private readonly breadcrumbsService: IBreadcrumbsService;

  private readonly activatedRoute: ActivatedRoute;

  private activeUrl: string = '';

  private readonly navigationElementObserver$: BehaviorSubject<NavigationElement[]>;

  private readonly fullScreenObserver$: BehaviorSubject<boolean>;

  private serviceIsDestroyed$ = new Subject<boolean>();

  private readonly authService: IAuthService;

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    queryParameterService: QueryParameterService,
    authService: IAuthService,
    breadcrumbsService: IBreadcrumbsService
  ) {
    super();
    this.router = router;
    this.queryParameterService = queryParameterService;
    this.activatedRoute = activatedRoute;
    this.authService = authService;
    this.breadcrumbsService = breadcrumbsService;
    this.fullScreenObserver$ = new BehaviorSubject(<boolean>true);
    this.navigationElementObserver$ = new BehaviorSubject(this.calculateNavigation());
    this.subscribeToRouteChanges();
  }

  getLogoPath(): string {
    return "/assets/logo.png";
  }

  getNavigation(): Observable<NavigationElement[]> {
    return this.navigationElementObserver$.asObservable();
  }

  shouldFullscreen(): Observable<boolean> {
    return this.fullScreenObserver$.asObservable();
  }

  navigate(path: Path): void {
    this.navigateWithParams(path, new Map<string, string>());
  }

  navigateWithParams(path: Path, urlParams: Map<string, string>): void {
    // append additional passed urlParams to the Path
    let fullPath: string = path.toString();
    urlParams?.forEach((value: string, key: string) => {
      if(!fullPath.includes(key)) {
        throwError(new Error(`Passed urlParam ${key} not part of target Path ${fullPath}`))
      } else {
        fullPath = path.replace(':' + key, value)
      }
    })
    this.router
      .navigate([fullPath])
      .catch(_ => {
        if (path !== Path.EMPLOYEE_OVERVIEW)
          this.navigateWithParams(Path.EMPLOYEE_OVERVIEW, new Map())
      });
  }

  updateQueryParameters<T>(queryParams: T): Observable<boolean> {
    return this.queryParameterService.updateQueryParameters(queryParams);
  }

  clearQueryParameters(): Observable<boolean> {
    return this.queryParameterService.updateQueryParameters({
    });
  }

  getQueryParameters<T>(): Observable<QueryParameterSubscriptionData<T>> {
    return this.queryParameterService.getQueryParameters();
  }

  ngOnDestroy() {
    this.serviceIsDestroyed$.next(true);
    this.serviceIsDestroyed$.complete();
  }

  private subscribeToRouteChanges() {
    this.router.events.pipe(
      takeUntil(this.serviceIsDestroyed$),
      filter(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd).urlAfterRedirects)
    ).subscribe((url) => {
      this.activeUrl = url;
      this.emitRoutingData();
      this.navigationElementObserver$.next(this.calculateNavigation());
    });
  }

  private emitRoutingData() {
    if (this.router.routerState.root.children.length < 1)
      return;
    this.router.routerState.root.children[0].data.subscribe(routerData => {
      this.fullScreenObserver$.next(routerData['fullScreen']);
    })
  }

  private calculateNavigation(): NavigationElement[] {
    return [{
      icon: Icon.USERS,
      text: $localize`employees|employees`,
      active: this.activeUrl.split('?')[0].includes(Path.EMPLOYEE_OVERVIEW),
      path: 'EMPLOYEE_OVERVIEW'
    }, {
      icon: Icon.FILE_FILLED,
      text: $localize`contracts|contracts`,
      active: this.activeUrl.split('?')[0].includes(Path.CONTRACT_OVERVIEW),
      path: 'CONTRACT_OVERVIEW'
    }, {
      icon: Icon.MAP,
      text: $localize`locations|locations`,
      active: this.activeUrl.split('?')[0].includes(Path.LOCATION_OVERVIEW),
      path: 'LOCATION_OVERVIEW'
    }, {
      icon: Icon.LOCK,
      text: $localize`security|security`,
      active: this.activeUrl.split('?')[0].includes(Path.SECURITY),
      path: 'SECURITY'
    }
    ].filter(navElement => this.authService.isRouteValid(
      this.router
        .config
        .find(conf => conf.path === Path[navElement.path as keyof typeof Path])
        ?.data
        ?.['requiredRoles'] ?? []
    ));
  }
}

import {
  IContractFilterDto
} from "./icontract-filter.dto";
import {
  IContractSortDto
} from "./icontract-sort.dto";
import {
  IPaginationRequestDto
} from "../pagination/IPaginationRequestDto";

export interface IContractRequestDto {
  Filters: IContractFilterDto,
  SearchTerms: string[],
  SearchFields: string[],
  SortParams: IContractSortDto,
  Pagination: IPaginationRequestDto
}

<pm-location-details
  [editMode]="editMode"
  [fields]="locationFields"
  [hideEditBtn]="isServiceAdmin"
  [displayOnlyFields]="locationFieldsDisplayOnly"
  [singleLineFields]="locationFieldsSingleLine"
  [detailsLoading]="loading[HgbLoadingKeys.LOAD_DETAILS]"
  [contractsOverviewLoading]="loading[HgbLoadingKeys.LOAD_CONTRACTS_OVERVIEW]"
  [contractsAssignedLoading]="loading[HgbLoadingKeys.LOAD_ASSIGNED_CONTRACTS]"
  [assignedContractTableTextView]="assignedContractsTableTextView"
  [allContractTableText]="allContractsTableText"
  [assignedContractTableTextEdit]="assignedContractsTableTextEdit"
  [columnNamesDelAdmins]="columnNamesDelAdmins"
  [columnNamesContract]="columnNamesContract"
  [rowsDelAdmins]="rowsDelAdmins"
  [rowsContractAll]="allContracts.rows"
  [rowsContractAssigned]="assignedContracts.rows"
  [allContractPagination]="allContracts.paginationData.pagination"
  [allContractPageSizes]="allContracts.paginationData.pageSizes"
  [selectedAllContractPageSize]="allContracts.paginationData.currentPageSize"
  [assignedContractPagination]="assignedContracts.paginationData.pagination"
  [assignedContractPageSizes]="assignedContracts.paginationData.pageSizes"
  [selectedAssignedContractPageSize]="assignedContracts.paginationData.currentPageSize"
  [searchSuggestionsAllVisible]="allContracts.searchSuggestionsVisible"
  [searchSuggestionsAssignedVisible]="assignedContracts.searchSuggestionsVisible"
  [searchSuggestionsAll]="allContracts.inputData"
  [searchSuggestionsAssigned]="assignedContracts.inputData"
  [activeSearchTermsAll]="allContracts.searchTerms"
  [activeSearchTermsAssigned]="assignedContracts.searchTerms"
  [desktopContractEditNotice]="desktopEditNotice"
  [supplierBox]="supplierBox"
  [showCheckboxOnDesktopOnly]="showCheckboxOnDesktopOnly"
  [resetTableHeaderCheckboxWithPagination]="resetTableHeaderCheckboxWithPagination"
  [activeBox]="activeBox"
  [shouldBeShownInKbaBox]="shouldBeShownInKbaBox"
  [shouldBeShownInDlbBox]="shouldBeShownInDlbBox"
  [shouldBeShownInLvBox]="shouldBeShownInLvBox"
  [shouldBeShownInGvBox]="shouldBeShownInGvBox"
  [legallyIndependentZ000025Box]="legallyIndependentZ000025Box"
  [isDelAdminOnly]="isDelAdminOnly"
  (onAllContractPageAmountChanged)="allContractPageAmountChanged($event)"
  (onAssignedContractPageAmountChanged)="assignedContractPageAmountChanged($event)"
  (onAllContractsShowPage)="allContractShowPage($event)"
  (onAssignedContractsShowPage)="assignedContractShowPage($event)"
  (onSearchInputChanged)="mainLocationSearchInputChangeHandler($event)"
  (onAllContractsSearchInputChanged)="allContractsSearchInputChangeHandler($event)"
  (onAssignedContractsSearchInputChanged)="assignedContractsSearchInputChangeHandler($event)"
  (onCountriesSearchInputChanged)="countriesSearchInputChangeHandler($event)"
  (onSelectedAllContractsSearchTermsChanged)="contractSearchChipsChangedHandler($event)"
  (onAssignedSelectedContractsSearchTermsChanged)="assignedContractSearchChipsChangedHandler($event)"
  (onAddContracts)="addContractsHandler($event)"
  (onRemoveContracts)="removeContractsHandler($event)"
  (onExit)="exitHandler($event)"
  (onEditModeChanged)="editModeHandler($event)"
  *ngIf="!error"
></pm-location-details>

<pm-modal
  *ngIf="isModalOpen"
  (onCloseClicked)="hideModal()"
  (onConfirmClicked)="onConfirm()"
  (onCancelClicked)="hideModal()"
  [headerText]="modalText.header"
  [subText]="modalText.text"
  [labelNo]="buttonLabel.negative"
  [labelYes]="buttonLabel.positive"
>
</pm-modal>

<pm-error
  *ngIf="error"
></pm-error>

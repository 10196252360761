import {
  EmployeeColumnNames,
  Filter,
  Icon,
  ImportData,
  PmInputData,
  PmMenuActionData,
  PmMenuActionType
} from 'hagebau-coremedia';
import {IEmployeeDto} from './iemployee.dto';
import {EmployeeManagementLevel} from './employee-management-level.enum';
import {EmployeeState} from './employee-state.enum';
import {CurrentEmployeeWithRoles} from '../account/auth/auth.service';

export enum EMPLOYEE_FILTER_IDENTIFIERS {
  STATUS = 'status',
  MANAGEMENT_LEVEL = 'managementlevel',
  ADMIN = 'admin',
  APP_PERMISSION = 'apppermission',
  EMAIL = 'email',
  MAIN_LOCATION = 'mainlocation',
  LOCATIONS = 'locations',
}

export const pageSizes: PmInputData[] =  [
  {
    key: '1',
    value: '10'
  },
  {
    key: '2',
    value: '20'
  },
  {
    key: '3',
    value: '50'
  }
];

export const employeeStatusFilterSuggestions: PmInputData[] = [
  {
    'key': '0',
    'value': $localize`inactive|Inactive`,
  },
  {
    'key': '1',
    'value': $localize`active|Active`,
  },
  {
    'key': '2',
    'value': $localize`locked|Locked`,
  },
]

export const employeeManagementLevelFilterSuggestions: PmInputData[] = [
  {
    'key': '0',
    'value': $localize`General Management|General Management`
  },
  {
    'key': '1',
    'value': $localize`Middle Management|Middle Management`
  },
  {
    'key': '2',
    'value': $localize`Top Management|Top Management`
  },
]

export const employeeAdminStatusFilterSuggestions: PmInputData[] = [
  {
    'key': '0',
    'value': $localize`false|False`
  },
  {
    'key': '1',
    'value': $localize`true|True`
  },
]

export const menuActions: PmMenuActionData[] = [
  {
    actionName: $localize`Activate|Activate`,
    icon: Icon.CIRCLE_CHECK,
    type: PmMenuActionType.ACTIVATE
  },
  {
    actionName: $localize`Deactivate|Deactivate`,
    icon: Icon.CIRCLE_CLOSE,
    type: PmMenuActionType.DEACTIVATE
  },
  {
    actionName: $localize`password reset|Reset password`,
    icon: Icon.LOCK,
    type: PmMenuActionType.RESET_PASSWORD
  },
  {
    actionName: $localize`Delete|Delete`,
    icon: Icon.TRASH,
    type: PmMenuActionType.DELETE
  }
]

export const employeeFilters: Filter[] = [
  {
    filterId: 0,
    name: $localize`employee-status|Status`,
    identifier: EMPLOYEE_FILTER_IDENTIFIERS.STATUS,
    text: '',
    filterSuggestions: employeeStatusFilterSuggestions,
    filterSuggestionsVisible: false,
    isDropdown: true
  },
  {
    filterId: 1,
    name: $localize`admin-status|Admin Status`,
    identifier: EMPLOYEE_FILTER_IDENTIFIERS.ADMIN,
    text: '',
    filterSuggestions: employeeAdminStatusFilterSuggestions,
    filterSuggestionsVisible: false,
    isDropdown: true
  },
  {
    filterId: 2,
    name: $localize`appPermission|App Permission`,
    identifier: EMPLOYEE_FILTER_IDENTIFIERS.APP_PERMISSION,
    text: '',
    filterSuggestions: [],
    filterSuggestionsVisible: false,
    isDropdown: true
  },
  {
    filterId: 3,
    name: $localize`mainLocation|Main Location`,
    identifier: EMPLOYEE_FILTER_IDENTIFIERS.MAIN_LOCATION,
    text: '',
    filterSuggestions: [],
    filterSuggestionsVisible: false,
    isDropdown: false
  },
  {
    filterId: 4,
    name: $localize`locations|locations`,
    identifier: EMPLOYEE_FILTER_IDENTIFIERS.LOCATIONS,
    text: '',
    filterSuggestions: [],
    filterSuggestionsVisible: false,
    isDropdown: false
  },
  {
    filterId: 5,
    name: $localize`management-level|Management Level`,
    identifier: EMPLOYEE_FILTER_IDENTIFIERS.MANAGEMENT_LEVEL,
    text: '',
    filterSuggestions: employeeManagementLevelFilterSuggestions,
    filterSuggestionsVisible: false,
    isDropdown: true
  }
]

export const employeeOverviewTableColumnNames: EmployeeColumnNames = {
  checkBoxColumn: '',
  nameColumn: $localize`employee-name|Name`,
  mailColumn: $localize`email|Email`,
  statusColumn: $localize`employee-status|Status`,
  mainLocationColumn: $localize`mainLocation|Main Location`,
  locationsColumn: $localize`locations|locations`,
  managementColumn: $localize`management-level|Management Level`,
  actionColumn: '',
};

export const employeeImportData: ImportData = {
  isModalOpen: false,
  file: <File>{
  },
  fileName: '',
  fileSize: 0,
  uploadProgress: 0,
  progressBarHidden: true,
  uploadText: '',
  uploadDisabled: false,
  saveDisabled: true,
}

export const employeeDefaultData: IEmployeeDto = {
  email: '',
  givenName: '',
  locations: [],
  mainLocations: [],
  managementLevel: EmployeeManagementLevel.GeneralManagement,
  number: '',
  photo: '',
  status: EmployeeState.Inactive,
  surName: '',
  uid: '',
  isSupplier: true
}

export const currentEmployeeDefaultData: CurrentEmployeeWithRoles = {
  employee: employeeDefaultData,
  roles: []
}

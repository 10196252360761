import {
  Injectable
} from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate
} from "@angular/router";
import {
  INavigationService
} from "./inavigation.service";
import {
  IAuthService
} from "../account/iauth.service";
import {
  Path
} from "../../app/app-routing.module";

@Injectable()
export class AccessGuard implements CanActivate {
  private navigationService: INavigationService;

  private authService: IAuthService;

  constructor(
    navigationService: INavigationService,
    authService: IAuthService,
  ) {
    this.navigationService = navigationService;
    this.authService = authService;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredRoles: string[] = route.data['requiredRoles'] || [];
    const requiresLogin: boolean = requiredRoles.length > 0;
    if (requiresLogin && !this.authService.isTokenValid()) {
      this.navigationService.navigate(Path.LOGIN);
    }
    return true;
  }
}

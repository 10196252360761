import {
  Injectable
} from "@angular/core";
import {
  Observable,
} from "rxjs";
import {
  IAllianceDto
} from "./ialliance.dto";
import {
  IAllianceApi
} from "./ialliance-api.service";
import {
  IAllianceService
} from "./ialliance.service";

@Injectable()
export class AllianceService extends IAllianceService {
  private readonly allianceApi: IAllianceApi;


  constructor(
    allianceApi: IAllianceApi,

  ) {
    super();
    this.allianceApi = allianceApi;
  }


  public getList(): Observable<IAllianceDto[]> {
    return this.checkAndThrowDefaultError(
      this.allianceApi.getAlliances()
    );
  }

}

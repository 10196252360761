<pm-contracts-overview
  [menuActions]="menuActions"
  [actionMenuDisabled]="!(this.selectedContracts.length > 0)"
  [hideActionMenu]="isDelAdminOnly || isServiceAdmin"
  [hideImportBtn]="isDelAdminOnly || isServiceAdmin"
  [rows]="rows"
  [loading]="loading[HgbLoadingKeys.LOAD_CONTRACTS_OVERVIEW]"
  [columnNames]="columnNames"
  [searchSuggestionsVisible]="searchSuggestionsVisible"
  [searchSuggestions]="searchSuggestions"
  [activeSearchTerms]="selectedSearchTerms"
  [pagination]="pagination"
  [pageSizes]="pageSizes"
  [activeFilters]="activeFilters"
  [filterVisible]="activeFilters.length > 0"
  [filters]="contractFilters"
  [selectedPageSize]="currentPageSize"
  [alwaysShowMoreButton]="alwaysShowMoreButton"
  [resetTableHeaderCheckboxWithPagination]="resetTableHeaderCheckboxWithPagination"
  (onSearchInputChanged)="searchPreviewHandler($event)"
  (onSelectedSearchTermsChanged)="searchChipsChangedHandler($event)"
  (onActionClicked)="rowActionHandler($event)"
  (onMenuActionClicked)="menuActionHandler($event)"
  (onCheckboxChecked)="selectionHandler($event)"
  (onFilterChanged)="onFilterChanged($event)"
  (onFilterInputChanged)="onFilterInputChanged($event)"
  (onSortKeyChanged)="onSortKeyChanged($event)"
  (onPageAmountChanged)="onPageAmountChanged($event)"
  (onShowPage)="onShowPage($event)"
  (onBulkImportClicked)="onBulkImportClicked()"
  (onResetSearchSuggestions)="onResetSearchSuggestions()"
></pm-contracts-overview>

<pm-modal
  labelYes="Confirm|Confirm"
  i18n-labelYes="Confirm|Confirm"
  labelNo="Cancel|Cancel"
  i18n-labelNo="Cancel|Cancel"
  *ngIf="isModalOpen"
  [actionName]="currentModalAction"
  (onCloseClicked)="hideModal()"
  (onConfirmClicked)="onConfirmClick($event)"
  (onCancelClicked)="hideModal()"
  [headerText]="modalText.header"
  [subText]="modalText.text">
</pm-modal>

<pm-modal-bulk-import
  headerText="Upload data|Upload data"
  i18n-headerText="Upload data|Upload data"
  subText="Upload the relevant files for import|Upload files"
  i18n-subText="Upload the relevant files for import|Upload files"
  *ngIf="importData.isModalOpen"
  (onCloseClicked)="hideImportModal()"
  (onCancelClicked)="hideImportModal()"
  (onSaveClicked)="importFile()"
  (onFileSelectedEvent)="fileSelected($event)"
  (onUploadDropped)="fileSelected($event)"
  [fileName]="importData.fileName"
  [fileSize]="importData.fileSize"
  [uploadProgress]="importData.uploadProgress"
  [progressBarHidden]="importData.progressBarHidden"
  [uploadText]="importData.uploadText"
  [uploadDisabled]="importData.uploadDisabled"
  [saveDisabled]="importData.saveDisabled">
</pm-modal-bulk-import>

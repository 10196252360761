import {
  Component,
  OnDestroy
} from '@angular/core';
import {
  AlertType,
  ImageData
} from 'hagebau-coremedia';
import {
  IAuthService
} from '../../../services/account/iauth.service';
import {
  INavigationService
} from '../../../services/navigation';
import {
  Subject
} from 'rxjs';
import {
  finalize,
  take
} from 'rxjs/operators';
import {
  Banner,
  Logo
} from '../imageData';
import {
  Path
} from '../../app-routing.module';
import {
  HgbLoadingKeys,
  WithLoading,
  WithRx
} from '../../mixins';
import {
  ValidationException
} from '../../../network/api/http/api-exception';
import {
  IExceptionTranslatorService
} from '../../../services/exception/iexception-translator.service';
import {
  IPageFacadeService
} from '../../../services/ipage-facade';

@Component({
  selector: 'password-reset-request',
  templateUrl: './password-reset-request.component.html',
})
export class PasswordResetRequestComponent extends WithRx(WithLoading()) implements OnDestroy {
  //services
  private authService : IAuthService;
  private navigationService : INavigationService;
  private exceptionTranslatorService: IExceptionTranslatorService;
  private pageFacade: IPageFacadeService;

  //data
  banner: ImageData = Banner;
  logo: ImageData = Logo

  //state
  error: boolean = false;

  //types
  readonly AlertType: typeof AlertType = AlertType;

  //rxjs
  requestClickSubject$ = new Subject<string>();

  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  constructor(
    authService: IAuthService,
    navigationService: INavigationService,
    exceptionTranslatorService: IExceptionTranslatorService,
    pageFacade: IPageFacadeService,
  ) {
    super();
    this.authService = authService;
    this.navigationService = navigationService;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.pageFacade = pageFacade;
    this.activateRequestClickObserver();
  }

  redirectToLogin(_: void) {
    this.navigationService.navigate(Path.LOGIN)
  }

  redirectToImprint(_: void) {
    this.navigationService.navigate(Path.IMPRINT);
  }

  redirectToPrivacy(_: void) {
    this.navigationService.navigate(Path.PRIVACY);
  }

  private activateRequestClickObserver(): void {
    this.requestClickSubject$
      .pipe(
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_PASSWORD_RESET_REQUEST),
        this.switchMapCancelable(this.authService.requestPasswordReset.bind(this.authService)),
        take(1),
        finalize(this.activateRequestClickObserver.bind(this)),
      )
      .pipe(
        this.catchValidationException(this.translateError.bind(this))
      )
      .subscribe(_ => {
        this.error = false;
        this.navigationService.navigate(Path.PASSWORD_RESET_REQUESTED)
      }, error => {
        this.error = true;
        this.pageFacade.emitAlert({
          label: error.message === $localize`UnknownValidationError|An error occurred`
            ? $localize`password reset request error|Your email is not known.`
            : $localize`own password reset request inactive error|Your account is not active.`,
          alertType: AlertType.ERROR
        });
      })
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }
}

import {
  z
} from "zod";
import {
  CreateEmployeeResponseDtoSchema
} from "./iemployee-import-response.dto";

export const EmployeeBulkCreateResponseDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  employees: z.array(CreateEmployeeResponseDtoSchema),
})

export type IEmployeeBulkCreateResponseDto = z.infer<typeof EmployeeBulkCreateResponseDtoSchema>;

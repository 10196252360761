import {
  ActionCell,
  Cell,
  CellType,
  CheckboxCell,
  ContractDetailEditData,
  CountableTextCell,
  Filter,
  FilterData,
  Icon,
  MoreButtonElement,
  MoreButtonElementType,
  PaginationControlData,
  PmDropdownInputFieldData,
  PmInputData,
  PmInputFieldData,
  PmInputType,
  PmMenuActionData,
  PmMenuActionType,
  Row,
  TextCell
} from 'hagebau-coremedia';
import {
  Injectable
} from '@angular/core';
import {
  LdapSortDirection
} from '../LdapSortDirection';
import {
  ContractSortKeys,
  IContractDto,
  IContractFilterDto,
  IContractRequestDto,
  IContractSortDto,
  IContractTableService
} from './index';
import {
  ILocationDto,
} from '../location';
import {
  IContractDetailDto
} from './icontract-detail.dto';
import {
  IAuthService
} from '../account/iauth.service';
import {
  IFilterDto
} from '../IFilterDto';
import {
  IPaginationService
} from '../pagination/ipagination.service';
import {
  AppSettings
} from '../appSettings/appSettings';
import {
  ContractState
} from './contract-state.enum';
import {
  ISearchInfo
} from '../search/search.service';
import {
  ICurrentPageInfo
} from '../pagination/IPaginationInfoDto';

const MAX_LOCATION_STR_LENGTH: number = 18;
const DATE_PARSE_LENGTH: number = 8;
const DATE_YEAR_LAST_CHARACTER_POSITION: number = 4;

const FILTER_NAME_INDEX: number = 0;
const FILTER_DESCRIPTION_INDEX: number = 1;
const FILTER_CATEGORY_INDEX: number = 2;
const FILTER_STATUS_INDEX: number = 3;

export const LOCATION_NUMBER_CELL_ID: string = 'LocationNumberId';

export interface ContractEditViewData {
    basicInfo: PmInputFieldData[],
    locations: ILocationDto[],
    isActive: boolean
}

@Injectable()
export class ContractTableService extends IContractTableService {
  private readonly authService: IAuthService;
  private readonly paginationService: IPaginationService;

  constructor(
    authService: IAuthService,
    paginationService: IPaginationService
  ) {
    super();
    this.authService = authService;
    this.paginationService = paginationService;
  }

  public static mapContractRequestDto(
    filters: FilterData[],
    searchInfo: ISearchInfo,
    sortInfo: IContractSortDto,
    pageInfo: ICurrentPageInfo
  ): IContractRequestDto {
    return {
      Filters: ContractTableService.mapActiveFilters(filters),
      SearchTerms: searchInfo.searchTerms,
      SearchFields: searchInfo.searchFields,
      SortParams: {
        SortKey: sortInfo.SortKey,
        SortDirection: sortInfo.SortDirection
      },
      Pagination: {
        Page: pageInfo.currentPage,
        PageAmount: pageInfo.currentPageSize
      }
    };
  }

  public static mapContractMinimalRequestDto(searchTerms: string[], searchFields: string[] = AppSettings.CONTRACT_SEARCH_FIELDS, filters: FilterData[] = []): IContractRequestDto {
    return ContractTableService.mapContractRequestDto(
      filters,
      {
        searchTerms: searchTerms,
        searchFields: searchFields
      },
      {
        SortKey: ContractSortKeys.None,
        SortDirection: LdapSortDirection.Ascending
      },
      {
        currentPage: -1, currentPageSize: -1
      }
    )
  }

  public static toFilterDto(filterValue: string, isNotFilter: boolean = false): IFilterDto {
    // map filter values to strings that the backend understands
    const filterValueMapping: Map<string, string> = new Map<string, string>([
      [$localize`inactive|Inactive`, 'FALSE'],
      [$localize`active|Active`, 'TRUE'],
    ]);

    const mappedFilterValue: string = filterValueMapping.get(filterValue) || '';
    return {
      Value: mappedFilterValue || filterValue,
      IsNotFilter: isNotFilter
    };
  }

  public getPaginationService(): IPaginationService {
    return this.paginationService
  }

  private static mapActiveFilters(filters: FilterData[]): IContractFilterDto {
    return {
      Cn: filters.filter(filter => filter.identifier.toLowerCase() === 'cn').map(filter => ContractTableService.toFilterDto(filter.value, filter.isNotFilter)),
      Name: filters.filter(filter => filter.identifier.toLowerCase() === 'name').map(filter => ContractTableService.toFilterDto(filter.value, filter.isNotFilter)),
      Description: filters.filter(filter => filter.identifier.toLowerCase() === 'description').map(filter => ContractTableService.toFilterDto(filter.value, filter.isNotFilter)),
      Category: filters.filter(filter => filter.identifier.toLowerCase() === 'category').map(filter => ContractTableService.toFilterDto(filter.value, filter.isNotFilter)),
      Locations: filters.filter(filter => filter.identifier.toLowerCase() === 'location').map(filter => ContractTableService.toFilterDto(filter.value, filter.isNotFilter)),
      Status: filters.filter(filter => filter.identifier.toLowerCase() === 'status').map(filter => ContractTableService.toFilterDto(filter.value, filter.isNotFilter)),
    };
  }

  public createSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): IContractRequestDto {
    const defaultFilters: Filter[] = this.getFilters();
    return {
      Filters: {
        Cn: [],
        Name: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_NAME_INDEX].identifier.toLowerCase() ? [ContractTableService.toFilterDto(filterSnippet.value, false)] : [],
        Description: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_DESCRIPTION_INDEX].identifier.toLowerCase() ? [ContractTableService.toFilterDto(filterSnippet.value, false)] : [],
        Category: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_CATEGORY_INDEX].identifier.toLowerCase() ? [ContractTableService.toFilterDto(filterSnippet.value, false)] : [],
        Locations: [],
        Status: filterSnippet.identifier.toLowerCase() === defaultFilters[FILTER_STATUS_INDEX].identifier.toLowerCase() ? [ContractTableService.toFilterDto(filterSnippet.value, false)] : [],
      },
      SearchTerms: [],
      SearchFields: [],
      SortParams: <IContractSortDto>{
      },
      Pagination: {
        Page: 1,
        PageAmount: AppSettings.MAX_FILTER_SUGGESTIONS
      }
    }
  }

  private static convertLdapStrToDateStr(ldapDateStr: string): string {
    const dateStr: string = ldapDateStr.substring(0, DATE_PARSE_LENGTH);
    const year: string = dateStr.slice(0, DATE_YEAR_LAST_CHARACTER_POSITION) + '-' + dateStr.slice(DATE_YEAR_LAST_CHARACTER_POSITION);
    const date: string = year.slice(0, DATE_PARSE_LENGTH - 1) + '-' + year.slice(DATE_PARSE_LENGTH - 1);

    return new Date(date).toLocaleDateString('de', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  private static getLocationStr(locations: { number: string }[]): string {
    const locationNumbers: string[] = locations.map(location => location.number);
    const locationStr: string = locationNumbers.join(', ');

    return locationStr.length >= MAX_LOCATION_STR_LENGTH ? locationStr.substring(0, MAX_LOCATION_STR_LENGTH - 1) + '...' : locationStr + ' ';
  }

  private mapContractToRow(contract: IContractDto): Cell[] {
    return [
            {
              value: false,
              type: CellType.CHECKBOX_CELL
            } as CheckboxCell,
            {
              text: contract.name,
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              text: contract.description,
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              text: contract.category,
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              text: ContractTableService.getLocationStr(contract.locations),
              count: '(' + contract.locations.length + ')',
              type: CellType.COUNTABLE_TEXT_CELL
            } as CountableTextCell,
            {
              text: ContractTableService.convertLdapStrToDateStr(contract.lastEditedAt),
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              text: ContractTableService.convertLdapStrToDateStr(contract.createdAt),
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              actions: this.getRowActions(contract),
              type: CellType.ACTION_CELL
            } as ActionCell
    ];
  }

  public mapContractLocationsToRows(locations: ILocationDto[]): Row[] {
    return locations.map((location: ILocationDto) => {
      return {
        rowId: String(location.cn),
        checked: false,
        cells: this.mapContractLocationToRow(location),
        warning: false,
        inactive: false
      };
    });
  }

  public mapLocationsToRows(locations: ILocationDto[]): Row[] {
    return locations.map((location: ILocationDto) => {
      return {
        rowId: String(location.cn),
        checked: false,
        cells: this.mapLocationToRow(location),
        warning: false,
        inactive: false
      };
    });
  }

  public mapLocationToRow(location: ILocationDto): Cell[] {
    return [
            {
              value: false,
              type: CellType.CHECKBOX_CELL
            } as CheckboxCell,
            {
              text: location.number,
              type: CellType.TEXT_CELL,
              cellId: LOCATION_NUMBER_CELL_ID
            } as TextCell,
            {
              text: location.description,
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              text: location.mainLocation,
              type: CellType.TEXT_CELL
            } as TextCell,
            {
              text: location.contracts.map(contract => contract.name).join(', '),
              type: CellType.TEXT_CELL
            } as TextCell
    ];
  }

  public mapContractLocationToRow(location: ILocationDto): Cell[] {
    return [
      {
        value: false,
        type: CellType.CHECKBOX_CELL
      } as CheckboxCell,
      {
        text: location.number,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.description,
        type: CellType.TEXT_CELL
      } as TextCell,
      {
        text: location.contracts.map(contract => contract.name).join(', '),
        type: CellType.TEXT_CELL
      } as TextCell
    ];
  }

  public mapEditDataToContract(cn: string, editData: ContractDetailEditData, assignedLocations: ILocationDto[]): IContractDetailDto {
    return {
      cn: cn,
      name: editData.basicInfo.find(field => field.fieldName === 'name')?.value || '',
      description: editData.basicInfo.find(field => field.fieldName === 'description')?.value || '',
      category: (<PmDropdownInputFieldData>editData.basicInfo.find(field => field.fieldName === 'category')).value || '',
      locations: assignedLocations,
      status: editData.isActive ? ContractState.Active : ContractState.Inactive,
    };
  }

  public mapContractsToRows(contracts: IContractDto[]): Row[] {
    return contracts.map((contract: IContractDto) => {
      return {
        rowId: contract.cn,
        checked: false,
        cells: this.mapContractToRow(contract),
        warning: false,
        inactive: contract.status !== ContractState.Active
      };
    });
  }

  // eslint-disable-next-line max-lines-per-function
  public mapContractToEditViewData(contract: IContractDetailDto): ContractEditViewData {
    return {
      basicInfo: [
        {
          label: $localize`name`,
          fieldName: 'name',
          value: contract.name,
          editable: true,
          type: PmInputType.DEFAULT,
          required: true,
          inputData: []
        },
        {
          label: $localize`description`,
          fieldName: 'description',
          value: contract.description,
          editable: true,
          type: PmInputType.DEFAULT,
          required: true,
          inputData: []
        },
                {
                  type: PmInputType.DROPDOWN,
                  label: $localize`category`,
                  fieldName: 'category',
                  value: contract.category,
                  editable: true,
                  identifier: 'category',
                  required: true,
                  inputData: [
                    {
                      key: '0',
                      value: $localize`retailCategory|Retail`
                    },
                    {
                      key: '1',
                      value: $localize`specialistCategory|Specialist`
                    }
                  ]
                } as PmDropdownInputFieldData
      ],
      locations: contract.locations,
      isActive: contract.status === ContractState.Active
    };
  }

  // eslint-disable-next-line max-lines-per-function
  public getFilters(): Filter[] {
    return [
      {
        filterId: 0,
        name: $localize`name`,
        identifier: 'name',
        text: '',
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      },
      {
        filterId: 1,
        name: $localize`description|Description`,
        identifier: 'description',
        text: '',
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      },
      {
        filterId: 2,
        name: $localize`category`,
        identifier: 'category',
        text: '',
        filterSuggestions: [],
        filterSuggestionsVisible: false,
        isDropdown: false
      },
      {
        filterId: 3,
        name: $localize`status|Status`,
        identifier: 'status',
        text: '',
        filterSuggestions: [
          {
            'key': '0',
            'value': $localize`inactive|Inactive`,
          },
          {
            'key': '1',
            'value': $localize`active|Active`,
          }
        ],
        filterSuggestionsVisible: false,
        isDropdown: true
      },
    ];
  }

  public getPagination(): PaginationControlData {
    return {
      current: 1,
      nextAmount: 2,
      previousAmount: 0,
      resultRange: '1-10',
      resultTotal: '500',
      nextDisabled: false,
      prevDisabled: true
    };
  }

  public getMenuActions(): PmMenuActionData[] {
    return [
      {
        actionName: $localize`Activate|Activate`,
        icon: Icon.CIRCLE_CHECK,
        type: PmMenuActionType.ACTIVATE
      },
      {
        actionName: $localize`Deactivate|Deactivate`,
        icon: Icon.CIRCLE_CLOSE,
        type: PmMenuActionType.DEACTIVATE
      },
      {
        actionName: $localize`Delete|Delete`,
        icon: Icon.TRASH,
        type: PmMenuActionType.DELETE
      }
    ];
  }

  private getRowActions(contract: IContractDto): MoreButtonElement[] {
    const isServiceAdmin: boolean = this.authService.isServiceAdmin();
    return [
      {
        moreBtnId: '1',
        icon: Icon.CIRCLE_INFO,
        text: $localize`View|View`,
        link: '',
        type: MoreButtonElementType.VIEW,
        disabled: false
      },
      ...!this.authService.isDelAdminOnly() ?
        [
          {
            moreBtnId: '2',
            icon: Icon.PEN,
            text: $localize`Edit|Edit`,
            link: '',
            type: MoreButtonElementType.EDIT,
            disabled: isServiceAdmin
          },
          {
            moreBtnId: '3',
            icon: contract.status === ContractState.Active ? Icon.CIRCLE_CLOSE : Icon.CIRCLE_CHECK,
            text: contract.status === ContractState.Active ? $localize`Deactivate|Deactivate` : $localize`Activate|Activate`,
            link: '',
            type: contract.status === ContractState.Active ? MoreButtonElementType.DEACTIVATE : MoreButtonElementType.ACTIVATE,
            disabled: isServiceAdmin
          },
          {
            moreBtnId: '4',
            icon: Icon.TRASH,
            text: $localize`Delete|Delete`,
            link: '',
            type: MoreButtonElementType.DELETE,
            disabled: isServiceAdmin
          }
        ] : [],
    ];
  }

  public getPageSizes(): PmInputData[] {
    return [
      {
        key: '1', value: '10'
      },
      {
        key: '2', value: '20'
      },
      {
        key: '3', value: '50'
      }
    ];
  }
}

import {
  AlertElement
} from "./header";
import {
  Observable
} from "rxjs";
import {
  QueryParameterSubscriptionData
} from "./navigation";
import {
  Path
} from "../app/app-routing.module";
import {
  IContractDetailDto
} from "./contract";
import {
  ILocationDetailDto
} from "./location";
import {
  IEmployeeDetailDto
} from "./employee/iemployee-detail.dto";

export abstract class IPageFacadeService {
  abstract setContractOverviewBreadcrumbs(): void;
  abstract setContractDetailsBreadcrumbs(contract: IContractDetailDto): void;
  abstract setLocationOverviewBreadcrumbs(): void;
  abstract setLocationDetailsBreadcrumbs(location: ILocationDetailDto): void;
  abstract setEmployeeOverviewBreadcrumbs(): void;
  abstract setEmployeeDetailsBreadcrumbs(employee: IEmployeeDetailDto): void;
  abstract emitAlert(alertData: AlertElement): void;
  abstract updateQueryParameters<T>(queryParams : T): Observable<boolean>;
  abstract getQueryParameters<T>(): Observable<QueryParameterSubscriptionData<T>>;
  abstract navigateWithParams(path: Path, urlParams: Map<string, string>): void;
  abstract setSecurityBreadcrumbs(): void;
}

import {OperatorFunction} from 'rxjs';
import {FilterData, PmInputData} from 'hagebau-coremedia';
import {WithRx} from '../../app/mixins';
import {IAppPermissionMinimalDto} from '../appPermission/IAppPermissionMinimalDto';

export abstract class IEmployeePipeService extends WithRx() {

  public abstract locationFilterSuggestionsPipe(): OperatorFunction<FilterData, PmInputData[]>;

  public abstract mainLocationFilterSuggestionPipe(): OperatorFunction<FilterData, PmInputData[]>;

  public abstract createAppPermissionFilterSuggestions(permissions: IAppPermissionMinimalDto[]): PmInputData[];

}

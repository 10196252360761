<pm-password-reset-request
  [logo]="logo"
  [banner]="banner"
  [loading]="loading[HgbLoadingKeys.LOAD_PASSWORD_RESET_REQUEST]"
  (onRequest)="requestClickSubject$.next($event)"
  (onRedirectToLogin)="redirectToLogin()"
  (onRedirectToImprint)="redirectToImprint()"
  (onRedirectToPrivacy)="redirectToPrivacy()"
></pm-password-reset-request>

<pm-error
  *ngIf="error"
></pm-error>

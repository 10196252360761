import {
  PaginationControlData,
  PmInputData
} from "hagebau-coremedia";
import {
  IPaginationInfoDto
} from "./IPaginationInfoDto";

export abstract class IPaginationService {

  abstract getMaxPages(totalAmount: number, pageSize: number): number;

  abstract parseCurrentPageSize(pageSize: number, pageSizes: PmInputData[]): number;

  abstract parseCurrentPageNumber(pageNumber: number, pageSize: number, totalAmount: number): number;

  abstract applyPaginationAttributes(pagination: PaginationControlData, paginationInfo: IPaginationInfoDto): PaginationControlData;
}

import {
  Constructor,
  CONSTRUCTOR_PARAM
} from "./constructor.mixin";
import {
  Observable
} from "rxjs";
import {
  finalize,
  tap
} from "rxjs/operators";
import {
  HgbLoadingKeys
} from "./hgbLoadingKeys";

export interface IWithLoading<K> {
  showLoading(...indices: K[]): void;
  hideLoading(...indices: K[]): void;
  stopLoading(): void;
}

//eslint disabled because mixins always inherit from an empty class
// eslint-disable-next-line max-lines-per-function
export function WithGenericLoading<T extends Constructor<{}>, K extends string>(Base: T = (class {} as never)) { // eslint-disable-line @typescript-eslint/ban-types
  return class extends Base implements IWithLoading<K> {
    loading: Record<K, boolean>;

    constructor(...args: CONSTRUCTOR_PARAM[]) {
      super(...args);
      this.loading = {
      } as Record<K, boolean>
    }

    showLoading(...indices: K[]): void {
      indices.forEach(index => {
        this.loading[index] = true;
      });
    }

    hideLoading(...indices: K[]): void {
      indices.forEach(index => {
        this.loading[index] = false;
      });
    }

    stopLoading(): void {
      let property: keyof typeof this.loading;
      for (property in this.loading) {
        this.loading[property] = false;
      }
    }

    useLoadingAnimation<I>(...indices: K[]): (source$: Observable<I>) => Observable<I> {
      const showLoadingInternal: () => void = () => {
        this.showLoading(...indices);
      }

      return source$ =>
        source$.pipe(
          tap(showLoadingInternal),
          finalize(this.hideLoading.bind(this, ...indices)),
        );
    }
  }
}

export function WithLoading<T extends Constructor<{}>>(Base: T = (class {} as never)) { // eslint-disable-line @typescript-eslint/ban-types
  return WithGenericLoading<T, HgbLoadingKeys>(Base)
}


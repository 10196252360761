import {
  Observable
} from "rxjs";
import {
  IAppPermissionMinimalDto
} from "./IAppPermissionMinimalDto";

export abstract class IAppPermissionApi {
  abstract getFullAppPermissions(): Observable<IAppPermissionMinimalDto[]>;

  abstract getAppPermissions(): Observable<IAppPermissionMinimalDto[]>;
}

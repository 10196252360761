import {Injectable} from '@angular/core';
import {
  merge,
  Observable,
  of,
  OperatorFunction
} from 'rxjs';
import {
  EmployeeState,
  IEmployeeApi,
  IEmployeeDto,
  IEmployeeRequestDto,
  IEmployeeService,
  IEmployeeTableService
} from './index';
import {IPaginationResponseDto} from '../pagination/IPaginationResponseDto';
import {PaginationInfoDto} from '../../network/api/pagination-api.service';
import {
  IBaseLocationDto,
  ILocationDto,
  ILocationRequestDto,
  IMainLocationDto
} from '../location';
import {EmployeeManagementLevel} from './employee-management-level.enum';
import {IEmployeeDetailDto} from './iemployee-detail.dto';
import {ISearchService} from '../search/isearch.service';
import {ISearchTermsDto} from '../search/isearch-terms.dto';
import {
  filter,
  map,
  switchMap
} from 'rxjs/operators';
import {
  PmInputData,
  Filter,
  FilterData
} from 'hagebau-coremedia';
import {IResetUserPasswordsDto} from './IResetUserPasswordsDto';
import {IAppPermissionDto} from '../appPermission/IAppPermissionDto';
import {IExceptionTranslatorService} from '../exception/iexception-translator.service';
import {ValidationException} from '../../network/api/http/api-exception';
import {ILdapResponseDto} from '../ildapResponse.dto';
import {AppSettings} from '../appSettings/appSettings';
import {EmployeeTitle} from './employee-title.enum';
import {IEmployeeImportService} from './iemployee-import.service';
import * as locationConstants from '../location/location-data';
import {EmployeeTableService} from './employee-table.service';
import {IAppPermissionMinimalDto} from '../appPermission/IAppPermissionMinimalDto';
import {IEmployeeBulkDeleteResponseDto} from './iemployee-bulk-delete-response.dto';
import {CurrentEmployeeWithRoles} from '../account/auth/auth.service';
import {IAuthService} from '../account/iauth.service';
import {ValidationService} from '../validation/validation.service';
import {EMPLOYEE_FILTER_IDENTIFIERS} from './data';

const DUMMY_AMOUNT: number = 50;
const FILTER_LOCATIONS_INDEX: number = 0;

export class EmployeeDummy implements IEmployeeDto {
  uid: string = '';
  number: string = '';
  email: string = '';
  isSupplier: boolean = true;
  mainLocations: ILocationDto[] = [];
  locations: ILocationDto[] = [];
  givenName: string = '';
  surName: string = '';
  status: EmployeeState = EmployeeState.Inactive;
  photo: string = '';
  managementLevel: EmployeeManagementLevel = EmployeeManagementLevel.GeneralManagement;
  admin: boolean = false;
}
class EmployeeDetailDummy implements IEmployeeDetailDto {
  title: EmployeeTitle = EmployeeTitle.NotSpecified;
  firstName: string = '';
  surname: string = '';
  email: string = '';
  status: EmployeeState = EmployeeState.Inactive;
  photo: string = '';
  isSupplier: boolean = false;
  phoneNumber: string = '';
  mobileNumber: string = '';
  managementLevel: EmployeeManagementLevel = EmployeeManagementLevel.GeneralManagement;
  isCheckedTermsAndConditions: boolean = false;
  departmentFunction: string = '';
  sector: string = '';
  hgbUserNumber: string = '';
  mainLocations: ILocationDto[] = [];
  isDelAdmin: boolean = false;
  locations: ILocationDto[];
  appPermissions: IAppPermissionDto[] = [];
  delAdminLocations: ILocationDto[];

  constructor(locations: ILocationDto[] = [], delAdminLocations: ILocationDto[] = []) {
    this.locations = locations;
    this.delAdminLocations = delAdminLocations;
  }
}

@Injectable()
export class EmployeeService extends IEmployeeService {
  private readonly employeeApi: IEmployeeApi;
  private readonly employeeTableService: IEmployeeTableService;
  private readonly searchService: ISearchService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly employeeImportService: IEmployeeImportService;
  private readonly authService: IAuthService;

  constructor(
    employeeApi: IEmployeeApi,
    employeeTableService: IEmployeeTableService,
    searchService: ISearchService,
    exceptionTranslatorService: IExceptionTranslatorService,
    employeeImportService: IEmployeeImportService,
    authService: IAuthService
  ) {
    super();
    this.employeeApi = employeeApi;
    this.employeeTableService = employeeTableService;
    this.searchService = searchService;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.employeeImportService = employeeImportService;
    this.authService = authService;
  }

  public getList(requestDto: IEmployeeRequestDto): Observable<IPaginationResponseDto<IEmployeeDto>> {
    return this.checkAndThrowDefaultError(
      merge(
        EmployeeService.createEmployeeDummyData(requestDto.Pagination.PageAmount > 0 ? requestDto.Pagination.PageAmount : DUMMY_AMOUNT),
        this.loadEmployees(requestDto)
      )
    );
  }

  public getTableStructure(): IEmployeeTableService {
    return this.employeeTableService;
  }
  public updateEmployeesState(uids: string[], state: EmployeeState): Observable<ILdapResponseDto> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.updateEmployeesState({
        uids: uids,
        state: state
      })
    );
  }

  public getEmployee(employeeNumber: string): Observable<IEmployeeDetailDto> {
    return this.checkAndThrowDefaultError(
      merge(
        EmployeeService.createDetailDummyData(),
        this.employeeApi.getEmployee(employeeNumber)
      )
    );
  }

  public GetCurrentEmployee(): Observable<CurrentEmployeeWithRoles> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.GetCurrentEmployee().pipe(
        map(employee => ({
          employee: employee,
          roles: this.authService.getUserRoles()
        })
        )
      )
    );
  }

  public GetCurrentEmployeeMainLocations(searchTerm: string = ''): Observable<IBaseLocationDto[]> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.GetCurrentEmployeeMainLocations(searchTerm)
    );
  }
  public preProcessSearchInput(): OperatorFunction<string, string> {
    return this.searchService.preProcessSearchInput(AppSettings.MIN_SEARCH_LENGTH_DEFAULT);
  }

  public search(term: string): Observable<ISearchTermsDto> {
    return this.checkAndThrowDefaultError(
      of(term)
        .pipe(
          filter(ValidationService.isLdapConform),
          switchMap(this.employeeApi.search.bind(this.employeeApi))
        )
    )
  }

  public convertSearchResults(termsDto: ISearchTermsDto): PmInputData[] {
    return this.searchService.convertSearchResults(termsDto);
  }

  public searchFiltered(requestDto: IEmployeeRequestDto): Observable<ISearchTermsDto> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.searchFiltered(requestDto)
    )
  }

  public resetUserPasswords(requestDto: IResetUserPasswordsDto): Observable<boolean> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.resetUsersPasswords(requestDto)
    );
  }

  public getTranslateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }

  public mapToMainLocationFilterSuggestions(searchResult: IMainLocationDto[], valueField: keyof IMainLocationDto): PmInputData[] {
    return searchResult.map((location: IMainLocationDto) => {
      return {
        key: location.cn,
        value: location[valueField]?.toString() || ''
      }
    });
  }

  public mapToLocationFilterSuggestions(searchResult: ILocationDto[], valueField: keyof ILocationDto): PmInputData[] {
    return searchResult.map((location: ILocationDto) => {
      return {
        key: location.cn,
        value: location[valueField]?.toString() || ''
      }
    });
  }

  // Identifier mapping because of the different naming in the employee and location component
  public getLocationFilterIdentifier(filterIdentifier: string): keyof ILocationDto {
    const locationFilterIdentifierMapping: Map<string, keyof ILocationDto> = new Map<string, keyof ILocationDto>([
      [EMPLOYEE_FILTER_IDENTIFIERS.LOCATIONS , 'number'],
      [EMPLOYEE_FILTER_IDENTIFIERS.MAIN_LOCATION , 'mainLocation'],
    ]);

    const mappedFilterIdentifier: keyof ILocationDto = locationFilterIdentifierMapping.get(filterIdentifier) || 'number';

    return mappedFilterIdentifier || filterIdentifier;
  }

  public createLocationSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): ILocationRequestDto {
    const defaultFilters: Filter[] = locationConstants.FILTERS;
    const mappedFilterSnippet: string = this.getLocationFilterIdentifier(filterSnippet.identifier);

    return {
      Filters: {
        Cn: [],
        Branch: [],
        MainLocation: [],
        Description: [],
        Number: mappedFilterSnippet.toLowerCase() === defaultFilters[FILTER_LOCATIONS_INDEX].identifier.toLowerCase() ? [EmployeeTableService.toFilterDto(filterSnippet.value, false)] : [],
        Contract: [],
      },
      SearchTerms: [],
      SearchFields: [],
      SortParams: {
        SortKey: 0,
        SortDirection: 0
      },
      Pagination: {
        Page: 1,
        PageAmount: AppSettings.MAX_FILTER_SUGGESTIONS
      }
    }
  }

  public sortPermissions(resultArray: IAppPermissionMinimalDto[], fromIndex: number, toIndex: number): void {
    const element: IAppPermissionMinimalDto = resultArray[fromIndex];
    resultArray.splice(fromIndex, 1);
    resultArray.splice(toIndex, 0, element);
  }

  public delete(ids: string[]): Observable<IEmployeeBulkDeleteResponseDto> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.deleteEmployees(ids)
    );
  }

  public transformFilterData(filterData: FilterData): FilterData {
    return {
      ...filterData,
      identifier: filterData.identifier.trim(),
      value: filterData.value.trim()
    };
  }

  public isEmailSecurityViolatedForStakeholders(employees: IEmployeeDto[]): boolean {
    return employees.every(employee => {
      const employeeEmailDomain: string = EmployeeService.extractDomainFromEmail(employee.email);
      const stakeholderMainLocations: IBaseLocationDto[] = employee.mainLocations.filter(
        mainLocation => !AppSettings.isSupplierLocation(mainLocation.number)
      );
      return !stakeholderMainLocations.every(mainLocation => {
        return mainLocation.allowedEmailDomains.includes(employeeEmailDomain)
          || mainLocation.allowedEmailAddresses.includes(employee.email);
      });
    });
  }

  public isEmailSecurityViolatedForSuppliers(employees: IEmployeeDto[]): boolean {
    return employees.every(employee => {
      const employeeEmailDomain: string = EmployeeService.extractDomainFromEmail(employee.email);
      return !employee.mainLocations.every(mainLocation => {
        return mainLocation.allowedEmailDomains.includes(employeeEmailDomain)
          || mainLocation.allowedEmailAddresses.includes(employee.email);
      });
    });
  }

  private loadEmployees(requestDto: IEmployeeRequestDto): Observable<IPaginationResponseDto<IEmployeeDto>> {
    return this.checkAndThrowDefaultError(
      this.employeeApi.getEmployees(requestDto)
    );
  }

  private static createEmployeeDummyData(amount: number = DUMMY_AMOUNT): Observable<IPaginationResponseDto<IEmployeeDto>> {
    return of(<IPaginationResponseDto<IEmployeeDto>>{
      pageResult: [...Array(amount).keys()]
        .map(_ => new EmployeeDummy()),
      paginationInfo: new PaginationInfoDto().init({
        pageSize: amount, pageNumber: 1, totalAmount: amount, init: undefined
      })
    });
  }

  private static createDetailDummyData(): Observable<IEmployeeDetailDto> {
    return of(new EmployeeDetailDummy())
  }

  private static extractDomainFromEmail(email: string): string {
    const atIndex: number = email.lastIndexOf('@');
    return atIndex > -1 ? email.substring(atIndex) : '';
  }
}

import {
  LocationDtoSchema
} from "../location";
import {
  ContractState
} from "./contract-state.enum";
import {
  z
} from "zod";

export const ContractDetailDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  name: z.string(),
  description: z.string(),
  category: z.string(),
  locations: z.array(LocationDtoSchema),
  status: z.nativeEnum(ContractState)
});

export type IContractDetailDto = z.infer<typeof ContractDetailDtoSchema>;

import {
  ColumnNamesAssignedLocation,
  employeeState,
  IImageData,
  ImageSize,
  ImageType,
  LabelType,
  PmCheckboxFieldData,
  PmCheckboxState,
  PmCheckboxType,
  PmInputFieldData,
  ColumnNamesAppPermission,
  HeadingTexts,
  AlertType
} from 'hagebau-coremedia';
import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  finalize,
  map,
  tap
} from 'rxjs/operators';
import {
  Observable,
} from 'rxjs';
import {
  HgbLoadingKeys,
  WithLoading,
  WithModal,
  WithRx
} from '../mixins';
import {
  ValidationException
} from '../../network/api/http/api-exception';
import {
  IExceptionTranslatorService
} from '../../services/exception/iexception-translator.service';
import {
  IPageFacadeService
} from '../../services/ipage-facade';
import {
  ActivatedRoute
} from '@angular/router';
import {
  IEmployeeDetailDto
} from '../../services/employee/iemployee-detail.dto';
import {
  EmployeeState,
  IEmployeeService,
  IEmployeeTableService
} from '../../services/employee';
import {
  EmployeeEditViewData
} from '../../services/employee/employee-table.service';
import {
  TableSwitchService,
  TableSwitchTableData
} from '../../services/table-switch/table-switch.service';
import {
  ILocationDto
} from '../../services/location';
import {
  TableSettings
} from '../../services/tableSettings/tableSettings';
import {
  EmployeeManagementLevel
} from '../../services/employee/employee-management-level.enum';
import {
  EmployeeTitle
} from '../../services/employee/employee-title.enum';

@Component({
  selector: 'employee-details',
  templateUrl: './employee-details.component.html',
})

export class EmployeeDetailsComponent extends WithRx(WithModal(WithLoading())) implements OnInit, OnDestroy {
  private readonly employeeTableService: IEmployeeTableService;

  //state
  employee: IEmployeeDetailDto = {
    title: EmployeeTitle.NotSpecified,
    firstName: '',
    surname: '',
    email: '',
    status: EmployeeState.Inactive,
    photo: '',
    isSupplier: false,
    phoneNumber: '',
    mobileNumber: '',
    isCheckedTermsAndConditions: false,
    sector: '',
    hgbUserNumber: '',
    departmentFunction: '',
    mainLocations: [],
    isDelAdmin: false,
    locations: [],
    delAdminLocations: [],
    managementLevel: EmployeeManagementLevel.GeneralManagement,
    appPermissions: []
  }
  employeeFieldsDisplayOnly: PmInputFieldData[] = [];
  status: LabelType = LabelType.INACTIVE;
  managementLevel: string = ''
  error: boolean = false;
  editMode: boolean = false;
  hasTermsAndConditionsChecked: PmCheckboxFieldData = {
    label: $localize`hasAcceptedTermsAndConditions|Accepted Terms And Conditions`,
    state: PmCheckboxState.DEFAULT,
    disabled: true,
    type: PmCheckboxType.INPUT
  };
  isDelegatedAdmin: boolean = false;
  photo: IImageData = {
    size: ImageSize.M,
    type: ImageType.ROUND,
    imageSources: [{
      path: '/assets/default_employee.jpg',
      width: '900w'
    }],
    base64ImageData: undefined,
    imageSizeRules: ['100vw']
  };

  //table
  assignedLocationsTableTextView: HeadingTexts = TableSettings.ASSIGNED_LOCATIONS_TABLE_NAMES;
  assignedAppPermissionsTableTextView: HeadingTexts = TableSettings.ASSIGNED_APP_PERMISSIONS_TABLE_NAMES
  mainLocations: ILocationDto[] = []

  //locations table
  assignedLocations: TableSwitchTableData = TableSwitchService.getDefaultTableData();
  assignedAppPermissions: TableSwitchTableData = TableSwitchService.getDefaultTableData();


  columnNamesLocation: ColumnNamesAssignedLocation = {
    numberColumn: $localize`number|Number`,
    locationNameColumn: $localize`location name|Location name`,
    permissionColumn: $localize`permission|Permission`,
  };

  columnNamesAppPermission: ColumnNamesAppPermission = {
    checkBoxColumn: '',
    nameColumn: $localize`appName|App name`,
    appLocationsColumn: $localize`appLocations|App locations`,
    appPermissionColumn: $localize`appPermission|App Permission`
  }

  employeeStateTranslations: employeeState = {
    active: $localize`active|Active`,
    inactive: $localize`inactive|Inactive`,
    locked: $localize`locked|Locked`
  }

  //locations delAdmin table
  assignedDelAdminLocations: TableSwitchTableData = TableSwitchService.getDefaultTableData();

  /**
   * @ignore
   */

  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  //services
  private readonly employeeService: IEmployeeService;
  private readonly pageFacade: IPageFacadeService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly activatedRoute: ActivatedRoute;

  constructor(
    employeeService: IEmployeeService,
    employeeTableService: IEmployeeTableService,
    pageFacade: IPageFacadeService,
    activatedRoute: ActivatedRoute,
    exceptionTranslatorService: IExceptionTranslatorService,
  ) {
    super();
    this.employeeService = employeeService;
    this.employeeTableService = employeeTableService;
    this.pageFacade = pageFacade;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.activatedRoute = activatedRoute;
  }

  ngOnInit(): void {
    let employeeNumber: string = '';
    this.activatedRoute.url.subscribe(
      url => {
        this.editMode = false;
        employeeNumber = url[url.length - 1].path
      }
    )
    this.initializeEmployee(employeeNumber);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  private initializeEmployee(employeeNumber: string) {
    this.showLoading(HgbLoadingKeys.LOAD_DETAILS);
    this.createCancelableSwitchMap(this.getEmployee.bind(this, employeeNumber))
      .pipe(
        this.catchValidationException(this.translateError.bind(this)),
        finalize(() => {
          this.hideLoading(HgbLoadingKeys.LOAD_DETAILS);
        })
      )
      .subscribe((employeeData: EmployeeEditViewData) => {
        this.employeeFieldsDisplayOnly = employeeData.displayOnlyInfo;
        this.status = employeeData.status;
        this.assignedLocations.rows = this.employeeTableService.mapEmployeeDetailLocationsToRows(employeeData.locations);
        this.assignedDelAdminLocations.rows = this.employeeTableService.mapEmployeeDetailLocationsToRows(employeeData.delAdminLocations);
        this.assignedLocations.rows = this.employeeTableService.mapEmployeeLocationsToRows(employeeData.locations);
        this.assignedAppPermissions.rows = this.employeeTableService.mapEmployeeAppPermissionToRows(employeeData.appPermissions)
        this.hasTermsAndConditionsChecked.state = employeeData.hasTermsAndConditionsChecked ? PmCheckboxState.ACTIVE : PmCheckboxState.DEFAULT;
        this.isDelegatedAdmin = employeeData.isDelegatedAdmin;
        this.managementLevel = employeeData.managementLevel;
        this.mainLocations = employeeData.mainLocations;
      }, _ => {
        this.error = true;
        this.pageFacade.emitAlert({
          label: $localize`Data Loading Error|Couldn't load data.`,
          alertType: AlertType.ERROR
        });
      }
      );
  }

  private getEmployee(employeeNumber: string): Observable<EmployeeEditViewData> {
    return this.employeeService.getEmployee(employeeNumber)
      .pipe(
        tap(result => this.employee = result),
        tap(this.pageFacade.setEmployeeDetailsBreadcrumbs.bind(this.pageFacade)),
        map(this.employeeService.getTableStructure().mapEmployeeToEditViewData.bind(this.employeeService.getTableStructure())),
      )
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(validationException.validationErrors[0].message, validationException.validationErrors[0].members ?? []);
  }
}

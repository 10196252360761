export enum HgbLoadingKeys {
  LOAD_LOGIN= "LOAD_LOGIN",
  LOAD_PASSWORD_RESET_REQUEST = "LOAD_PASSWORD_RESET_REQUEST",
  LOAD_DETAILS = "LOAD_DETAILS",
  LOAD_EMPLOYEE_OVERVIEW = "LOAD_EMPLOYEE_OVERVIEW",
  LOAD_LOCATIONS_OVERVIEW = "LOAD_LOCATIONS_OVERVIEW",
  LOAD_CONTRACTS_OVERVIEW = "LOAD_CONTRACTS_OVERVIEW",
  LOAD_ASSIGNED_LOCATIONS = "LOAD_ASSIGNED_LOCATIONS",
  LOAD_ASSIGNED_CONTRACTS = "LOAD_ASSIGNED_CONTRACTS",
  LOAD_PERMISSIONS = "LOAD_PERMISSIONS",
  LOAD_PERMISSION_LOCATIONS = "LOAD_PERMISSION_LOCATIONS",
  LOAD_PERMISSION_LOCATIONS_FROM_SEARCH = "LOAD_PERMISSION_LOCATIONS_FROM_SEARCH",
  LOAD_ASSIGNED_LOCATIONS_FOR_DELADMINS = "LOAD_ASSIGNED_LOCATIONS_FOR_DELADMINS"
}

import {
  ApplicationName
} from "../appPermission/application-name.enum";

//pagination
const MAX_PAGINATION_ARROW_AMOUNT: number = 2;
const INITIAL_PAGE_SIZE: number = 10;

//search and filter
const SEARCH_INPUT_KEY_DELAY: number = 100;
const MAX_FILTER_SUGGESTIONS: number = 20;
const MIN_FILTER_LENGTH: number = 2;
const MIN_SEARCH_LENGTH_DEFAULT: number = 2;
const MIN_SEARCH_LENGTH_ZERO: number = 0;
const CONTRACT_SEARCH_FIELDS: string[] = ['hgbname', 'hgbdescription', 'hgbcategory'];
const LOCATION_SEARCH_FIELDS: string[] = ['hgbNumber', 'hgbDescription', 'hgbSector'];
const LOCATION_SEARCH_FIELDS_ASSIGN_TABLE: string[] = ['hgbNumber', 'hgbDescription', 'hgbName'];
const MAIN_LOCATION_SEARCH_FIELDS: string[] = ['hgbnumber', 'hgbDescription'];
const EMPLOYEE_SEARCH_FIELDS: string[] = ['givenname', 'sn', 'hgbUserEmail'];

//misc
const ERROR_POPUP_VISIBILITY_TIME: number = 5000;
const IMPORT_CHUNK_SIZE: number = 10;
const IMPORT_REQUEST_CONCURRENCY: number = 1;
const CSV_IMPORT_ANSI_ENCODING: string = "windows-1252";

//supplier identifier
const SUPPLIER_LOCATION_IDENTIFIER: string = '9';

//applications
const NON_SUPPLIER_APPS: string[] = [
  ApplicationName.DEBTOR,
  ApplicationName.SUPPLIER_DIRECTORY,
  ApplicationName.SUPPLIER_DIRECTORY_MARKETING_PROFIT,
  ApplicationName.VALUATION_OVERVIEW,
  ApplicationName.SERVICE_BONUS_WRITE
];

export class AppSettings {
  public static readonly SEARCH_INPUT_KEY_DELAY: number = SEARCH_INPUT_KEY_DELAY;
  public static readonly MAX_FILTER_SUGGESTIONS: number = MAX_FILTER_SUGGESTIONS;
  public static readonly MIN_FILTER_LENGTH: number = MIN_FILTER_LENGTH;
  public static readonly MIN_SEARCH_LENGTH_DEFAULT: number = MIN_SEARCH_LENGTH_DEFAULT;
  public static readonly MIN_SEARCH_LENGTH_ZERO: number = MIN_SEARCH_LENGTH_ZERO;
  public static readonly MAX_PAGINATION_ARROW_AMOUNT: number = MAX_PAGINATION_ARROW_AMOUNT;
  public static readonly INITIAL_PAGE_SIZE: number = INITIAL_PAGE_SIZE;
  public static readonly ERROR_POPUP_VISIBILITY_TIME: number = ERROR_POPUP_VISIBILITY_TIME;
  public static readonly IMPORT_CHUNK_SIZE: number = IMPORT_CHUNK_SIZE;
  public static readonly IMPORT_REQUEST_CONCURRENCY: number = IMPORT_REQUEST_CONCURRENCY;
  public static readonly CONTRACT_SEARCH_FIELDS: string[] = CONTRACT_SEARCH_FIELDS;
  public static readonly LOCATION_SEARCH_FIELDS: string[] = LOCATION_SEARCH_FIELDS;
  public static readonly LOCATION_SEARCH_FIELDS_ASSIGN_TABLE: string[] = LOCATION_SEARCH_FIELDS_ASSIGN_TABLE;
  public static readonly MAIN_LOCATION_SEARCH_FIELDS: string[] = MAIN_LOCATION_SEARCH_FIELDS;
  public static readonly EMPLOYEE_SEARCH_FIELDS: string[] = EMPLOYEE_SEARCH_FIELDS;
  public static readonly CSV_IMPORT_ANSI_ENCODING: string = CSV_IMPORT_ANSI_ENCODING;
  public static readonly SUPPLIER_LOCATION_IDENTIFIER: string = SUPPLIER_LOCATION_IDENTIFIER;
  public static isSupplierLocation(hgbNumber: string): boolean {
    return hgbNumber.startsWith(this.SUPPLIER_LOCATION_IDENTIFIER);
  }
  public static readonly NON_SUPPLIER_APPS: string[] = NON_SUPPLIER_APPS;
}



import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  HgbLoadingKeys,
  WithLoading,
  WithModal,
  WithRx
} from '../mixins';
import {
  Row,
  WizardHeaderStepElement,
  AlertType
} from 'hagebau-coremedia';
import {
  IEmployeeCreateOrUpdateDto
} from '../../services/employee-create/i-employee-create-or-update.dto';
import {
  EmployeeState,
  IEmployeeService
} from '../../services/employee';
import {
  IEmployeeCreateService
} from '../../services/employee-create/iemployee-create.service';
import {
  IPageFacadeService
} from '../../services/ipage-facade';
import {
  ILdapResponseDto
} from '../../services/ildapResponse.dto';
import {
  Path
} from '../app-routing.module';
import {
  EmployeeManagementLevel
} from '../../services/employee/employee-management-level.enum';
import {
  INavigationService
} from '../../services/navigation';
import {
  IEmployeeAppPermissionDto
} from '../../services/appPermission/IEmployeeAppPermissionDto';
import {
  ActivatedRoute, UrlSegment
} from '@angular/router';
import {
  IEmployeeDetailDto
} from '../../services/employee/iemployee-detail.dto';
import {
  EmployeeCreateBasicDetailsStepComponent
} from './steps/employee-create-basic-details-step.component';
import {
  EmployeeCreateAdministrationStepComponent
} from './steps/employee-create-administration-step.component';
import {
  EmployeeTitle
} from '../../services/employee/employee-title.enum';
import {
  map,
  mergeMap
} from 'rxjs/operators';
import {
  combineLatest, iif, Observable, of
} from 'rxjs';

export enum EMPLOYEE_CREATE_STEP_IDENTIFIERS {
  STEP_BASIC_DETAILS,
  STEP_LOCATIONS,
  STEP_HIERARCHY,
  STEP_APPLICATIONS,
  STEP_ADMINISTRATION
}

@Component({
  selector: 'employee-create',
  templateUrl: './employee-create.component.html',
})
export class EmployeeCreateComponent extends WithRx(WithModal(WithLoading())) implements OnInit {
  @ViewChild(EmployeeCreateBasicDetailsStepComponent) basicDetailsStep: EmployeeCreateBasicDetailsStepComponent | undefined;
  @ViewChild(EmployeeCreateAdministrationStepComponent) administrationStep: EmployeeCreateAdministrationStepComponent | undefined;
  currentStepIdentifier: number = EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_BASIC_DETAILS;
  wizardHeaderSteps: WizardHeaderStepElement[] = [];
  selectedLocationRows: Row[] = []; // show selected location rows on administration step
  employeeToBeCreatedOrEdited: IEmployeeCreateOrUpdateDto = {
    departmentFunction: '',
    email: '',
    title: EmployeeTitle.NotSpecified,
    firstName: '',
    isSupplier: false,
    lastName: '',
    number: '',
    isCheckedTermsAndConditions: false,
    photo: '',
    state: EmployeeState.Inactive,
    locationCns: [],
    appPermissions: [],
    managementLevel: EmployeeManagementLevel.GeneralManagement,
    phoneNumber: '',
    mobileNumber: '',
    sector: '',
    delAdminLocationCns: []
  };
  originalEmployeeData: IEmployeeCreateOrUpdateDto = this.employeeToBeCreatedOrEdited;
  editEmployeeNumber: string = '';
  protected readonly STEP_IDENTIFIERS = EMPLOYEE_CREATE_STEP_IDENTIFIERS;
  private readonly employeeCreateService: IEmployeeCreateService;
  private readonly employeeService: IEmployeeService;
  private readonly pageFacade: IPageFacadeService;
  private readonly navigationService: INavigationService;
  private readonly activatedRoute: ActivatedRoute;

  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  constructor(
    employeeCreateService: IEmployeeCreateService,
    employeeService: IEmployeeService,
    pageFacade: IPageFacadeService,
    navigationService: INavigationService,
    activatedRoute: ActivatedRoute
  ) {
    super();
    this.employeeCreateService = employeeCreateService;
    this.employeeService = employeeService;
    this.pageFacade = pageFacade;
    this.navigationService = navigationService;
    this.activatedRoute = activatedRoute;
  }

  ngOnInit() {
    this.wizardHeaderSteps = this.employeeCreateService.getWizardHeaderSteps();
    this.initEmployeeData();
  }

  initEmployeeData() {
    const editHandlingObservable: Observable<void> = this.activatedRoute.url.pipe(
      mergeMap(url => iif(
        () => this.activatedRoute.routeConfig?.path === Path.EMPLOYEE_EDIT_VIEW,
        this.employeeService.GetCurrentEmployee().pipe(
          map(currentEmployee => currentEmployee.employee.number !== url[url.length - 1].path
            ? this.initializeEmployee(url[url.length - 1].path)
            : this.handleSelfEdit())
        ),
        of())
      )
    );

    combineLatest([this.activatedRoute.url, editHandlingObservable])
      .subscribe((result: [UrlSegment[], void]) => {
        if (this.activatedRoute.routeConfig?.path === Path.EMPLOYEE_EDIT_VIEW) {
          this.editEmployeeNumber = result[0][result[0].length - 1].path;
        }
      });
  }

  initializeEmployee(employeeNumber: string) {
    const loadables: HgbLoadingKeys[] = [HgbLoadingKeys.LOAD_DETAILS]

    this.createCancelableSwitchMap(this.employeeService.getEmployee.bind(this.employeeService, employeeNumber))
      .pipe(
        this.useLoadingAnimation(...loadables)
      )
      .subscribe((employeeData: IEmployeeDetailDto) => {
        this.mapResponseToEmployeeObject(employeeData);
        this.basicDetailsStep?.fillEmployeeData(this.employeeToBeCreatedOrEdited);
        this.administrationStep?.setInitialDeladminLocations(this.employeeToBeCreatedOrEdited.delAdminLocationCns);
      });
  }

  handleSelfEdit() {
    this.basicDetailsStep?.setDisabled();
    this.pageFacade.emitAlert({
      label: $localize`EmployeeMustNotEditThemselves|You must not edit yourself.`,
      alertType: AlertType.ERROR
    });
  }

  mapResponseToEmployeeObject(employeeData: IEmployeeDetailDto) {
    this.employeeToBeCreatedOrEdited = {
      title: employeeData.title,
      firstName: employeeData.firstName,
      lastName: employeeData.surname,
      email: employeeData.email,
      state: employeeData.status,
      departmentFunction: employeeData.departmentFunction,
      isSupplier: employeeData.isSupplier,
      number: employeeData.hgbUserNumber,
      isCheckedTermsAndConditions: employeeData.isCheckedTermsAndConditions,
      sector: employeeData.sector,
      photo: employeeData.photo,
      locationCns: employeeData.locations.map(location => location.cn),
      appPermissions: employeeData.appPermissions.map((permission) => ({
        AppCn: permission.appIds + '.' + permission.permission,
        LocationCns: permission.locationIds
      })),
      managementLevel: employeeData.managementLevel,
      phoneNumber: employeeData.phoneNumber,
      mobileNumber: employeeData.mobileNumber,
      delAdminLocationCns: employeeData.delAdminLocations.map(location => location.cn)
    };
    this.originalEmployeeData = this.employeeToBeCreatedOrEdited; //needed for modify check
    this.selectedLocationRows = this.employeeCreateService.getLocationTableService().mapLocationsToMinimalRows(employeeData.locations);
  }

  handleStepBackButtonClick() {
    if (this.currentStepIdentifier === EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_BASIC_DETAILS) { // first step
      // check if employee is modified
      if (this.isEmployeeModified()) {
        this.openCancelModal();
      } else {
        this.routeToEmployeeOverview();
      }
    } else {
      this.currentStepIdentifier -= 1;
    }
  }

  handleStepNextButtonClick() {
    if (this.currentStepIdentifier !== EMPLOYEE_CREATE_STEP_IDENTIFIERS.STEP_ADMINISTRATION) { // last step
      this.currentStepIdentifier = this.currentStepIdentifier + 1;
    }
  }

  updateEmployeeBasicDetails(employeeBasicData: IEmployeeCreateOrUpdateDto) {
    // populate local employee object with basic data
    this.employeeToBeCreatedOrEdited = employeeBasicData;
    this.employeeCreateService.getTableStructure().setEmployeeSupplierState(employeeBasicData.isSupplier);
  }

  updateEmployeeLocations(locationCns: string[]) {
    this.employeeToBeCreatedOrEdited.locationCns = locationCns;
  }

  updateEmployeeSelectedLocationRows(locationRows: Row[]) {
    this.selectedLocationRows = locationRows;
  }

  updateEmployeeManagementLevel(managementLevel: EmployeeManagementLevel) {
    this.employeeToBeCreatedOrEdited.managementLevel = managementLevel;
  }

  updateEmployeeAppPermissions(appPermissions: IEmployeeAppPermissionDto[]) {
    this.employeeToBeCreatedOrEdited.appPermissions = appPermissions;
  }

  updateEmployeeAdministration(delAdminLocationCns: string[]) {
    this.employeeToBeCreatedOrEdited.delAdminLocationCns = delAdminLocationCns;
    this.finishCreateOrEdit();
  }

  finishCreateOrEdit() {
    if (this.editEmployeeNumber) { //edit
      this.updateEmployee();
      return;
    }
    this.createEmployee()
  }

  updateEmployee() {
    this.employeeCreateService.updateEmployee({
      number: this.editEmployeeNumber,
      employee: this.employeeToBeCreatedOrEdited
    })
      .pipe(
        this.catchValidationException(this.employeeService.getTranslateError.bind(this.employeeService))
      )
      .subscribe((_: ILdapResponseDto) => {
        this.pageFacade.emitAlert({
          label: $localize`EmployeeUpdateSuccessful|Employee successfully updated.`,
          alertType: AlertType.SUCCESS
        });
        this.navigationService.navigate(Path.EMPLOYEE_OVERVIEW);
      }, (error: Error) => {
        this.pageFacade.emitAlert({
          label: error.message,
          alertType: AlertType.ERROR
        })
      });
  }

  createEmployee() {
    this.employeeCreateService.createEmployee(this.employeeToBeCreatedOrEdited)
      .pipe(
        this.catchValidationException(this.employeeService.getTranslateError.bind(this.employeeService))
      )
      .subscribe((_: ILdapResponseDto) => {
        this.pageFacade.emitAlert({
          label: $localize`EmployeeCreateSuccess|Employee successfully created.`,
          alertType: AlertType.SUCCESS
        });
        this.navigationService.navigate(Path.EMPLOYEE_OVERVIEW);
      }, (error: Error) => {
        this.pageFacade.emitAlert({
          label: error.message,
          alertType: AlertType.ERROR
        })
      });
  }

  isEmployeeModified(): boolean {
    return JSON.stringify(this.employeeToBeCreatedOrEdited) !== JSON.stringify(this.originalEmployeeData);
  }

  onConfirm() {
    this.hideModal();
    this.routeToEmployeeOverview();
  }

  private openCancelModal() {
    this.setModalTexts(
      $localize`Really cancel changes|Really cancel changes`,
      $localize`Are you sure you want to discard the changes you made?`
    );
    this.setButtonLabels($localize`Cancel|Cancel`, $localize`Discard|Discard`);

    this.showModal();
  }

  private routeToEmployeeOverview() {
    this.pageFacade.navigateWithParams(Path.EMPLOYEE_OVERVIEW, new Map<string, string>([['', '']]));
  }
}

import {
  Component
} from '@angular/core';
import {
  ImageData
} from "hagebau-coremedia";
import {
  IAuthService
} from "../../../services/account/iauth.service";
import {
  INavigationService
} from "../../../services/navigation";
import {
  Banner,
  Logo
} from "../imageData";
import {
  Path
} from "../../app-routing.module";

@Component({
  selector: 'password-reseted',
  templateUrl: './password-reset-requested.component.html',
})
export class PasswordResetRequestedComponent {
  //services
  private authService : IAuthService;
  private navigationService : INavigationService;

  //data
  banner: ImageData = Banner;
  logo: ImageData = Logo

  //state
  loading: boolean = false;

  constructor(
    authService: IAuthService,
    navigationService: INavigationService
  ) {
    this.authService = authService;
    this.navigationService = navigationService;
  }

  redirectToLogin(_: void) {
    this.navigationService.navigate(Path.LOGIN)
  }
}

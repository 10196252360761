import {
  Observable
} from "rxjs";
import {
  IPasswordResetOutputDto
} from "./password/ipassword-reset-output.dto";
import {
  AuthenticationStatus
} from "./auth/auth.service";
import {
  IPasswordRenewInputDto
} from "./password/ipassword-renew-input.dto";
import {
  IPingAuthenticateModel
} from "./auth/iping-authenticate-model.dto";
import {
  Roles
} from "./auth/auth.constants";

export abstract class IAuthService {

  abstract authenticateWithPingIdToken(authData: IPingAuthenticateModel): Observable<AuthenticationStatus>;

  abstract requestPasswordReset(email: string): Observable<string>;

  abstract resetPassword(password: string, code: string): Observable<IPasswordResetOutputDto>;

  abstract renewPassword(renewData: IPasswordRenewInputDto): Observable<IPasswordResetOutputDto>;

  abstract isTokenValid(): boolean;

  abstract userHasSomeRoles(requiredRoles: Roles[]): boolean;

  abstract isRouteValid(requiredRoles: string[]): boolean;

  abstract logout(): void;

  abstract isDelAdminOnly(): boolean;

  abstract isServiceAdmin(): boolean;

  abstract isSuperAdmin(): boolean;

  abstract isGlobalAdmin(): boolean;

  abstract getUserRoles(): string[];
}

import {
  ChipData,
  ColumnNamesLocation,
  ContractDetailEditData,
  ExitType,
  HeadingTexts,
  PmInputData,
  PmInputFieldData,
  Row,
  AlertType,
  PmCheckboxFieldData,
  PmCheckboxState,
  PmCheckboxType
} from 'hagebau-coremedia';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  finalize,
  map,
  tap
} from 'rxjs/operators';
import {
  Observable,
  OperatorFunction,
  Subject,
  Subscription
} from 'rxjs';
import {
  Path
} from '../app-routing.module';
import {
  HgbLoadingKeys,
  WithLoading,
  WithModal,
  WithRx
} from '../mixins';
import {
  ILdapResponseDto
} from '../../services/ildapResponse.dto';
import {
  ComponentCanDeactivate
} from '../../services/navigation';
import {
  IExceptionTranslatorService
} from '../../services/exception/iexception-translator.service';
import {
  ContractEditViewData
} from '../../services/contract/contract-table.service';
import {
  IContractDetailDto,
  IContractService,
  IModifyContractInput
} from '../../services/contract';
import {
  ValidationException
} from '../../network/api/http/api-exception';
import {
  ILocationDto,
  ILocationService
} from '../../services/location';
import {
  IPaginationResponseDto
} from '../../services/pagination/IPaginationResponseDto';
import {
  LocationService
} from '../../services/location/location.service';
import {
  AppSettings
} from '../../services/appSettings/appSettings';
import {
  IPageFacadeService
} from '../../services/ipage-facade';
import {
  ActivatedRoute
} from '@angular/router';
import {
  TableSettings
} from '../../services/tableSettings/tableSettings';
import {
  ITableSwitchService
} from 'src/services/table-switch/itable-switch.service';
import {
  PaginationSearchData,
  TableSwitchService,
  TableSwitchTableData
} from '../../services/table-switch/table-switch.service';
import {
  ContractState
} from '../../services/contract/contract-state.enum';
import {IAuthService} from '../../services/account/iauth.service';

const URL_MODUS_PART: number = 2;

@Component({
  selector: 'contract-details',
  templateUrl: './contract-details.component.html',
})
export class ContractDetailsComponent extends WithRx(WithModal(WithLoading())) implements OnInit, OnDestroy, ComponentCanDeactivate {

  //services
  private readonly contractService: IContractService;
  private readonly locationService: ILocationService;
  private readonly pageFacade: IPageFacadeService;
  private readonly exceptionTranslatorService: IExceptionTranslatorService;
  private readonly activatedRoute: ActivatedRoute;
  private readonly tableSwitchService: ITableSwitchService;
  private readonly authService: IAuthService;

  //rxjs
  private confirmUpdateClickSubject$ = new Subject<ContractDetailEditData>();
  private allLocationsSearchPreviewSubject$ = new Subject<string>();
  private assignedLocationsSearchPreviewSubject$ = new Subject<string>();

  //subscriptions
  updateSubscription: Subscription = new Subscription();
  allLocationsSearchPreviewSubscription: Subscription = new Subscription();
  assignedLocationsSearchPreviewSubscription: Subscription = new Subscription();

  //table
  assignedLocationsTableTextView: HeadingTexts = TableSettings.ASSIGNED_LOCATIONS_TABLE_NAMES;

  allLocationsTableText: HeadingTexts = TableSettings.ALL_LOCATIONS_TABLE_NAMES;

  assignedLocationsTableTextEdit: HeadingTexts = TableSettings.ASSIGNED_LOCATIONS_TABLE_EDIT_NAMES;

  desktopEditNotice: string = $localize`desktop-location-table-edit-info`;

  columnNamesLocation: ColumnNamesLocation = {
    checkBoxColumn: '',
    numberColumn: $localize`number|Number`,
    locationNameColumn: $localize`location name|Location name`,
    permissionColumn: $localize`permission|Permission`,
  };

  //tableSwitchData
  allLocations: TableSwitchTableData = TableSwitchService.getDefaultTableData();
  assignedLocations: TableSwitchTableData = TableSwitchService.getDefaultTableData();

  showCheckboxOnDesktopOnly: boolean = true;
  resetTableHeaderCheckboxWithPagination: boolean = true;

  //state
  editMode: boolean = false;
  exitMode: ExitType = ExitType.SAVE;
  contract: IContractDetailDto = {
    cn: '',
    name: '',
    description: '',
    category: '',
    locations: [],
    status: ContractState.Inactive
  }
  contractFields: PmInputFieldData[] = [];
  error = false;
  isDelAdminOnly: boolean = true;
  isServiceAdmin: boolean = false;
  locationsSelectable: ILocationDto[] = [];
  locationsAssigned: ILocationDto[] = [];
  updatedContract: ContractDetailEditData = {
    basicInfo: [],
    locations: [],
    exitMode: this.exitMode,
    routeBackToTableBtn: false,
    isActive: true
  };
  activeBox: PmCheckboxFieldData = {
    label: $localize`Active|Active`,
    state: PmCheckboxState.DEFAULT,
    disabled: this.editMode,
    type: PmCheckboxType.INPUT
  };
  isNavPromptDisabled: boolean = false;
  routeBackToTable: boolean = false;

  /**
   * @ignore
   */
  readonly HgbLoadingKeys: typeof HgbLoadingKeys = HgbLoadingKeys;

  /*eslint max-params: ["warn", 6]*/
  constructor(
    contractService: IContractService,
    locationService: ILocationService,
    pageFacade: IPageFacadeService,
    activatedRoute: ActivatedRoute,
    exceptionTranslatorService: IExceptionTranslatorService,
    tableSwitchService: ITableSwitchService,
    authService: IAuthService
  ) {
    super();
    this.contractService = contractService;
    this.locationService = locationService;
    this.pageFacade = pageFacade;
    this.exceptionTranslatorService = exceptionTranslatorService;
    this.activatedRoute = activatedRoute;
    this.tableSwitchService = tableSwitchService;
    this.authService = authService;
  }

  ngOnInit(): void {
    this.activateConfirmUpdateClickObserver();
    this.activateAllLocationsSearchPreviewSubject();
    this.activateAssignedLocationsSearchPreviewSubject();
    this.isDelAdminOnly = this.authService.isDelAdminOnly();
    this.isServiceAdmin = this.authService.isServiceAdmin();

    this.allLocations.paginationData = this.tableSwitchService.mapPaginationData(this.contractService.getTableStructure().getPagination(), this.contractService.getTableStructure().getPageSizes());
    this.assignedLocations.paginationData = this.tableSwitchService.mapPaginationData(this.contractService.getTableStructure().getPagination(), this.contractService.getTableStructure().getPageSizes());

    let contractCn: string = '';

    this.activatedRoute.url.subscribe(
      url => {
        this.editMode = url[url.length - URL_MODUS_PART].path === 'edit';
        contractCn = url[url.length - 1].path
      }
    )

    this.initializeContract(contractCn);
    if (this.editMode)
      this.initLocationsTable();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.updateSubscription.unsubscribe();
    this.allLocationsSearchPreviewSubscription.unsubscribe();
    this.assignedLocationsSearchPreviewSubscription.unsubscribe();
  }

  // @HostListener body listens for nav inside angular app
  @HostListener('body:beforeunload')
  canDeactivate(): boolean {
    // Prevent second prompt after save or cancel prompt confirmation
    if (this.isNavPromptDisabled)
      return true;

    const editedContract: ContractDetailEditData = {
      basicInfo: this.contractFields,
      locations: this.assignedLocations.rows,
      isActive: this.activeBox.state === PmCheckboxState.ACTIVE,
      exitMode: ExitType.CANCEL,
      routeBackToTableBtn: false
    }

    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return !this.isContractModified(editedContract);
  }


  /** -------------------- Edit Mode Handler ------------------- **/

  editModeHandler(editMode: boolean) {
    const path: Path = editMode ? Path.CONTRACT_DETAILS_EDIT : Path.CONTRACT_DETAILS_VIEW;
    this.pageFacade.navigateWithParams(path, new Map<string, string>([['id', this.contract.cn]]));
  }


  /** -------------------- Modal Handlers ------------------- **/

  openConfirmSaveModal(updatedContract: ContractDetailEditData) {
    const modalText: string = this.assignedLocations.searchTerms.length > 0
      ? $localize`WarnSaveAssignedLocationsActiveSearch|Are you sure you want to save the changes? There is an active search for the assigned locations and not all assigned locations may be displayed.`
      : $localize`Are you sure you want to save the changes you made?`;
    this.setModalTexts(
      $localize`Really save changes|Really save changes`,
      modalText
    );
    this.setButtonLabels(
      $localize`Cancel|Cancel`,
      $localize`Save|Save`
    );

    this.showModal();
    this.updatedContract = updatedContract;
    this.exitMode = updatedContract.exitMode;
  }

  openConfirmCancelModal(updatedContract: ContractDetailEditData) {
    this.setModalTexts(
      $localize`Really cancel changes|Really cancel changes`,
      $localize`Are you sure you want to discard the changes you made?`
    );
    this.setButtonLabels($localize`Cancel|Cancel`, $localize`Discard|Discard`);

    this.showModal();
    this.exitMode = updatedContract.exitMode;
    this.routeBackToTable = updatedContract.routeBackToTableBtn;
  }

  onConfirm() {
    this.isNavPromptDisabled = true;
    if (this.exitMode === ExitType.SAVE)
      this.confirmUpdateClickSubject$.next(this.updatedContract);
    else {
      this.hideModal();
      if (this.routeBackToTable) {
        this.routeToTable();
      } else {
        this.editModeHandler(false);
      }
    }
  }


  /** -------------------- Table Switch Handlers ------------------- **/

  addLocationsHandler(rows: Row[]) {
    this.assignedLocations.rows = this.assignedLocations.rows.concat(rows);

    this.locationsAssigned = this.locationsAssigned.concat(
      this.locationsSelectable.filter(
        location => rows.map(row => row.rowId).includes(location.cn)
      )
    );

    this.getAllLocations(this.allLocations.paginationData.currentPage, this.allLocations.paginationData.currentPageSize);
    this.getAssignedLocations(this.assignedLocations.paginationData.currentPage, this.assignedLocations.paginationData.currentPageSize);
  }

  removeLocationsHandler(rows: Row[]) {
    this.assignedLocations.rows = this.assignedLocations.rows.filter((rowAssigned: Row) =>
      !rows.some((rowRemove: Row) => rowAssigned.rowId === rowRemove.rowId)
    );

    this.locationsAssigned = this.locationsAssigned.filter(
      location => !rows.map(row => row.rowId).includes(location.cn)
    );

    this.getAllLocations(this.allLocations.paginationData.currentPage, this.allLocations.paginationData.currentPageSize);
    this.getAssignedLocations(this.assignedLocations.paginationData.currentPage, this.assignedLocations.paginationData.currentPageSize);
  }


  /** -------------------- Search Handlers ------------------- **/

  allLocationsSearchInputChangeHandler(searchInput: string)  {
    this.allLocationsSearchPreviewSubject$.next(searchInput);
  }

  assignedLocationsSearchInputChangeHandler(searchInput: string)  {
    this.assignedLocationsSearchPreviewSubject$.next(searchInput);
  }

  allLocationSearchChipsChangedHandler(chips: ChipData[]) {
    this.allLocations.paginationData.currentPage = 0;
    this.allLocations.searchTerms = chips;
    this.updateAllLocationsResult(true);
  }

  assignedLocationsSearchChipsChangedHandler(chips: ChipData[]) {
    this.assignedLocations.paginationData.currentPage = 0;
    this.assignedLocations.searchTerms = chips;
    this.updateAssignedLocationsResult();
  }


  /** -------------------- Pagination Handlers ------------------- **/

  allLocationShowPage(pageNumber: number) {
    this.allLocations.paginationData.currentPage = pageNumber;
    this.updateAllLocationsResult(true);
  }

  assignedLocationShowPage(pageNumber: number) {
    this.assignedLocations.paginationData.currentPage = pageNumber;
    this.updateAssignedLocationsResult();
  }

  allLocationPageAmountChanged(pageAmount: PmInputData) {
    this.allLocations.paginationData.currentPageSize = parseInt(pageAmount.value);
    this.updateAllLocationsResult();
  }

  assignedLocationPageAmountChanged(pageAmount: PmInputData) {
    this.assignedLocations.paginationData.currentPageSize = parseInt(pageAmount.value);
    this.updateAssignedLocationsResult();
  }

  private updateAllLocationsResult(loadLocationOverviewOnly: boolean = false) {
    const updateData: PaginationSearchData = this.tableSwitchService.getUpdateTableData(this.allLocations.searchTerms, this.allLocations.paginationData);
    this.allLocations.searchTerms = updateData.selectedSearchTerms;
    this.getAllLocations(updateData.page, updateData.pageSize, loadLocationOverviewOnly);
  }

  private updateAssignedLocationsResult() {
    const updateData: PaginationSearchData = this.tableSwitchService.getUpdateTableData(this.assignedLocations.searchTerms, this.assignedLocations.paginationData);
    this.assignedLocations.searchTerms = updateData.selectedSearchTerms;
    this.getAssignedLocations(updateData.page, updateData.pageSize);
  }

  private initLocationsTable() {
    LocationService.createLocationDummyData(AppSettings.INITIAL_PAGE_SIZE)
      .subscribe(paginationData => {
        const locationData: [Row[], IPaginationResponseDto<ILocationDto>] = [this.contractService.getTableStructure().mapContractLocationsToRows(Array.from(paginationData.pageResult)), paginationData];
        this.setAllLocationResult(locationData);
      });
  }

  private initializeContract(contractCn: string) {
    this.showLoading(HgbLoadingKeys.LOAD_DETAILS)
    this.createCancelableSwitchMap(this.getContract.bind(this, contractCn))
      .pipe(
        this.catchValidationException(this.translateError.bind(this)),
        finalize(() => {
          this.hideLoading(HgbLoadingKeys.LOAD_DETAILS);
        })
      )
      .subscribe((contractData: ContractEditViewData) => {
        this.contractFields = contractData.basicInfo;
        this.locationsAssigned = contractData.locations;
        this.activeBox.state = contractData.isActive ? PmCheckboxState.ACTIVE : PmCheckboxState.DEFAULT;
        this.assignedLocations.rows = this.contractService.getTableStructure().mapContractLocationsToRows(contractData.locations);
        if (this.editMode &&
            !this.locationService.isDummyData(contractData.locations)) { //dont fetch on dummy data
          this.getAllLocations(this.allLocations.paginationData.currentPage, this.allLocations.paginationData.currentPageSize, true);
          this.getAssignedLocations(this.assignedLocations.paginationData.currentPage, this.assignedLocations.paginationData.currentPageSize);
        }
      },
      (error: Error) => {
        this.error = true;
        this.pageFacade.emitAlert({
          label: error.message,
          alertType: AlertType.ERROR
        });
      }
      );
  }



  private getContract(contractId: string): Observable<ContractEditViewData> {
    return this.contractService.getContract(contractId)
      .pipe(
        tap(contract => this.contract = contract),
        tap(this.pageFacade.setContractDetailsBreadcrumbs.bind(this.pageFacade)),
        map(this.contractService.getTableStructure().mapContractToEditViewData.bind(this.contractService.getTableStructure())),
      )
  }

  private getAllLocations(currentPage: number, currentPageSize: number, loadLocationsOverviewOnly: boolean = false) {
    const loadables: HgbLoadingKeys[] = loadLocationsOverviewOnly
      ? [HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW]
      : [HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW, HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS];

    this.createCancelableSwitchMap(_ => this.tableSwitchService.loadLocationsForContracts(this.allLocations.searchTerms, currentPage, currentPageSize, this.locationsAssigned.map(loc => loc.cn), true))
      .pipe(
        this.useLoadingAnimation(...loadables),
        this.catchValidationException(this.translateError.bind(this)),
        tap(this.resetAllLocationSearch.bind(this))
      ).subscribe((locationData: [Row[], IPaginationResponseDto<ILocationDto>]) => {
        this.setAllLocationResult(locationData);
      }, fetchError => {
        this.allLocations.rows = [];
        this.locationsSelectable = [];
        this.pageFacade.emitAlert({
          label: fetchError.message ? fetchError.message : $localize`Data Loading Error|Couldn't load data.`,
          alertType: AlertType.ERROR
        });
      });
  }

  private getAssignedLocations(currentPage: number, currentPageSize: number) {
    const loadables: HgbLoadingKeys[] = [HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS];
    const includeCns: string[] = this.locationsAssigned.map(contract => contract.cn);
    if(includeCns.length === 0) { //edge case for no applicable filters, no result is possible
      this.setAssignedLocationResult([[], {
        pageResult: [],
        paginationInfo: {
          pageSize: this.assignedLocations.paginationData.currentPageSize, pageNumber: 1, totalAmount: 0
        }
      }]);
      return;
    }

    this.createCancelableSwitchMap(_ => this.tableSwitchService.loadLocationsForContracts(this.assignedLocations.searchTerms, currentPage, currentPageSize, this.locationsAssigned.map(loc => loc.cn), false))
      .pipe(
        this.useLoadingAnimation(...loadables),
        this.catchValidationException(this.translateError.bind(this)),
        tap(this.resetAssignedLocationSearch.bind(this))
      ).subscribe((locationData: [Row[], IPaginationResponseDto<ILocationDto>]) => {
        this.setAssignedLocationResult(locationData);
      }, fetchError => {
        this.assignedLocations.rows = [];
        this.pageFacade.emitAlert({
          label: fetchError.message ? fetchError.message : $localize`Data Loading Error|Couldn't load data.`,
          alertType: AlertType.ERROR
        });
      }
      );
  }

  public exitHandler(modifiedContract: ContractDetailEditData) {
    if (this.isContractModified(modifiedContract)) {
      modifiedContract.exitMode === ExitType.CANCEL ? this.openConfirmCancelModal(modifiedContract) : this.openConfirmSaveModal(modifiedContract);
    } else if (modifiedContract.exitMode === ExitType.SAVE) {
      this.confirmUpdateClickSubject$.next(modifiedContract);
    } else {
      if (modifiedContract.routeBackToTableBtn) {
        this.routeToTable();
      } else {
        this.editModeHandler(false);
      }
    }
  }

  private isContractModified(modifiedContract: ContractDetailEditData): boolean {
    return this.isLocationsModified()
      || modifiedContract.locations.some(modifiedContractLocation => !this.contract.locations.map(initialContractLocation => initialContractLocation.cn).includes(modifiedContractLocation.rowId))
      || modifiedContract.basicInfo.find(field => field.fieldName === 'name')?.value !== this.contract.name
      || modifiedContract.basicInfo.find(field => field.fieldName === 'category')?.value !== this.contract.category
  }

  private isLocationsModified() {
    return this.locationsAssigned.length !== this.contract.locations.length
      || this.locationsAssigned.some(location => !this.contract.locations.map(initialContractLocation => initialContractLocation.cn).includes(location.cn));
  }

  private activateConfirmUpdateClickObserver(): void {
    this.updateSubscription = this.confirmUpdateClickSubject$
      .pipe(
        this.hideModalOperator(),
        this.useLoadingAnimation(HgbLoadingKeys.LOAD_DETAILS, HgbLoadingKeys.LOAD_LOCATIONS_OVERVIEW),
        this.cancelOnDestroy(),
      )
      .pipe(
        map(contract => this.contractService.getTableStructure().mapEditDataToContract(this.contract.cn, contract, this.locationsAssigned)),
        map(this.createModifyInput.bind(this)),
        this.switchMapCancelable(this.contractService.update.bind(this.contractService)),
        finalize(this.activateConfirmUpdateClickObserver.bind(this)),
      )
      .pipe(
        this.catchValidationException(this.translateError.bind(this))
      )
      .subscribe((_: ILdapResponseDto) => {
        const number: string = this.contractFields.find(field => field.fieldName === 'cn')?.value || this.contract.cn;
        this.pageFacade.navigateWithParams(Path.CONTRACT_DETAILS_VIEW, new Map<string, string>([['id', number]]));
        this.pageFacade.emitAlert({
          label: $localize`Data Update Success|Update successful.`,
          alertType: AlertType.SUCCESS
        })
      }, (error: Error) => {
        this.pageFacade.emitAlert({
          label: error.message,
          alertType: AlertType.ERROR
        })
      }
      );
  }

  private activateAllLocationsSearchPreviewSubject() {
    this.allLocationsSearchPreviewSubscription = this.allLocationsSearchPreviewSubject$
      .pipe(this.getLocationSearchSuggestions(true))
      .subscribe(suggestions => {
        this.allLocations.inputData = suggestions;
        this.allLocations.searchSuggestionsVisible = suggestions.length > 0;
      });
  }

  private activateAssignedLocationsSearchPreviewSubject() {
    this.assignedLocationsSearchPreviewSubscription = this.assignedLocationsSearchPreviewSubject$
      .pipe(this.getLocationSearchSuggestions(false))
      .subscribe(suggestions => {
        this.assignedLocations.inputData = suggestions;
        this.assignedLocations.searchSuggestionsVisible = suggestions.length > 0;
      });
  }


  private getLocationSearchSuggestions(excludeContractCn: boolean): OperatorFunction<string, PmInputData[]> {
    return (source: Observable<string>): Observable<PmInputData[]> =>
      source.pipe(
        this.locationService.preProcessSearchInput(AppSettings.MIN_SEARCH_LENGTH_DEFAULT),
        map(searchTerm => this.locationService.getTableStructure().mapLocationMinimalRequestDto(
          [searchTerm], AppSettings.LOCATION_SEARCH_FIELDS_ASSIGN_TABLE, this.tableSwitchService.createCnFilter(this.locationsAssigned.map(location => location.cn), excludeContractCn))),
        this.switchMapCancelable(this.locationService.searchFiltered.bind(this.locationService)),
        map(this.locationService.convertSearchResultsFromTerms.bind(this.locationService)),
      );
  }

  private setAllLocationResult(locationData: [Row[], IPaginationResponseDto<ILocationDto>]): void {
    this.allLocations.rows = locationData[0];
    this.locationsSelectable = Array.from(locationData[1].pageResult);
    this.contractService.getTableStructure().getPaginationService().applyPaginationAttributes(this.allLocations.paginationData.pagination, locationData[1].paginationInfo);
    this.allLocations.paginationData.currentPageSize = locationData[1].paginationInfo.pageSize;
  }

  private setAssignedLocationResult(locationData: [Row[], IPaginationResponseDto<ILocationDto>]): void {
    this.assignedLocations.rows = locationData[0];
    this.contractService.getTableStructure().getPaginationService().applyPaginationAttributes(this.assignedLocations.paginationData.pagination, locationData[1].paginationInfo);
    this.assignedLocations.paginationData.currentPageSize = locationData[1].paginationInfo.pageSize;
  }

  private resetAllLocationSearch(): void {
    this.allLocations.inputData = [];
    this.allLocations.searchSuggestionsVisible = false;
  }

  private resetAssignedLocationSearch(): void {
    this.assignedLocations.inputData = [];
    this.assignedLocations.searchSuggestionsVisible = false;
  }

  private translateError(validationException: ValidationException): string {
    return this.exceptionTranslatorService.translate(
      validationException.validationErrors[0].message,
      validationException.validationErrors[0].members ?? []
    );
  }

  private createModifyInput(result: IContractDetailDto): IModifyContractInput {
    return {
      cn: this.contract.cn,
      newContract: result
    }
  }

  routeToTable() {
    this.pageFacade.navigateWithParams(Path.CONTRACT_OVERVIEW, new Map<string, string>([['', '']]))
  }
}

import {
  Constructor
} from "./constructor.mixin";
import {
  Observable,
  ObservableInput,
  ObservedValueOf,
  of,
  OperatorFunction,
  Subject
} from "rxjs";
import {
  OnDestroy
} from "@angular/core";
import {
  catchError,
  switchMap,
  takeUntil
} from "rxjs/operators";
import {
  Validation,
  ValidationException
} from "../../network/api/http/api-exception";

//eslint disabled because mixins always inherit from an empty class
// eslint-disable-next-line max-lines-per-function
export function WithRx<C extends Constructor<{}>>(Base: C = (class {} as never)) { // eslint-disable-line @typescript-eslint/ban-types
  return class extends Base implements OnDestroy {
    protected componentIsDestroyed$ = new Subject<boolean>();

    ngOnDestroy() {
      this.componentIsDestroyed$.next(true);
      this.componentIsDestroyed$.complete();
    }

    cancelOnDestroy<T>(): (source$: Observable<T>) => Observable<T> {
      return source$ =>
        source$
          .pipe(
            takeUntil(this.componentIsDestroyed$),
          );
    }

    switchMapCancelable<T, I, O extends ObservableInput<I>>(project: (value: T, index: number) => O,
    ): OperatorFunction<T, ObservedValueOf<O>> {
      return (source: Observable<T>) => source.pipe(
        this.cancelOnDestroy(),
        switchMap(project),
        this.cancelOnDestroy()
      );
    }

    createCancelableSwitchMap<I, O extends ObservableInput<I>>(project: (value: boolean, index: number) => O,
    ): Observable<ObservedValueOf<O>> {
      return of(true)
        .pipe(
          this.switchMapCancelable(project),
        );
    }

    catchValidationException<T>(checker: (validationException: ValidationException) => string): (source$: Observable<T>) => Observable<T> {
      return source$ =>
        source$
          .pipe(
            catchError((error: Error, _: Observable<T>) => {
              if(error instanceof ValidationException && (error as ValidationException).validationErrors.length > 0)
                throw new Error(checker(error as ValidationException))
              throw new Error(checker(new ValidationException([new Validation("0", [])])))
            })
          );
    }
  }
}

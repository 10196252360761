export type HeaderType = {
  [key: string]: string
};

export class Validation {
  message: string

  members: string[] | null

  constructor(message: string, members: string[] | null) {
    this.message = message;
    this.members = members;
  }
}

export class ValidationException extends Error {
  validationErrors: Validation[];

  constructor(validationErrors: Validation[]) {
    super();

    this.validationErrors = validationErrors;
  }
}

export class AuthorizationException extends Error {
}

export class ApiException extends Error {
  override message: string;
  status: number;
  response: string;
  headers: HeaderType;
  result: string;
  constructor(message: string, status: number, response: string, headers: HeaderType, result: string) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }
}

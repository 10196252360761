<pm-employee-details
  [displayOnlyFields]="employeeFieldsDisplayOnly"
  [status]="status"
  [photo]="photo"
  [hasTermsAndConditionsChecked]="hasTermsAndConditionsChecked"
  [isDelegatedAdmin]="isDelegatedAdmin"
  [detailsLoading]="loading[HgbLoadingKeys.LOAD_DETAILS]"
  [editMode]="editMode"
  [assignedAppPermissionTableTextView]="assignedAppPermissionsTableTextView"
  [rowsLocationAssigned]="assignedLocations.rows"
  [rowsAppPermissionsAssigned]="assignedAppPermissions.rows"
  [locationsAssignedLoading]="loading[HgbLoadingKeys.LOAD_ASSIGNED_LOCATIONS]"
  [columnNamesLocation]="columnNamesLocation"
  [columnNamesAppPermission]="columnNamesAppPermission"
  [employeeStateTranslations]="employeeStateTranslations"
  [managementLevel]="managementLevel"
  [rowsDelAdminLocationAssigned]="assignedDelAdminLocations.rows"
  *ngIf="!error"
></pm-employee-details>

<pm-error
  *ngIf="error"
></pm-error>

import {
  z
} from "zod";
import {
  createPaginatedResponseSchema
} from "../../network/api/pagination-api.service";

export const MainLocationDtoSchema = z.object({ // eslint-disable-line @typescript-eslint/typedef
  cn: z.string(),
  number: z.string(),
  description: z.string()
})
export const PaginatedMainLocationDtoSchema = createPaginatedResponseSchema(MainLocationDtoSchema) // eslint-disable-line @typescript-eslint/typedef

export type IMainLocationDto = z.infer<typeof MainLocationDtoSchema>;
export type IPaginatedMainLocationDto = z.infer<typeof PaginatedMainLocationDtoSchema>;

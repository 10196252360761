import {Injectable} from '@angular/core';
import {IEmployeePipeService} from './i-employee-pipe.service';
import {
  combineLatest,
  Observable,
  of,
  OperatorFunction
} from 'rxjs';
import {
  FilterData,
  PmInputData
} from 'hagebau-coremedia';
import {
  debounceTime,
  filter,
  map
} from 'rxjs/operators';
import {AppSettings} from '../appSettings/appSettings';
import {
  ILocationRequestDto,
  ILocationService,
  IMainLocationDto
} from '../location';
import {IEmployeeService} from './iemployee.service';
import {LocationTableService} from '../location/location-table.service';
import {IAppPermissionMinimalDto} from '../appPermission/IAppPermissionMinimalDto';
import {IAppPermissionService} from '../appPermission/iappPermission.service';
import {FullApplicationName} from '../appPermission/full-application-name.enum';

@Injectable({
  providedIn: 'root'
})

export class EmployeePipeService extends IEmployeePipeService {

  private readonly employeeService: IEmployeeService;
  private readonly locationService: ILocationService;
  private readonly appPermissionService: IAppPermissionService;
  constructor(
    employeeService: IEmployeeService,
    locationService: ILocationService,
    appPermissionService: IAppPermissionService
  ) {
    super();
    this.employeeService = employeeService;
    this.locationService = locationService;
    this.appPermissionService = appPermissionService;
  }

  public locationFilterSuggestionsPipe(): OperatorFunction<FilterData, PmInputData[]> {
    return (source: Observable<FilterData>): Observable<PmInputData[]> =>
      source.pipe(
        map<FilterData, FilterData>(this.employeeService.transformFilterData),
        filter(filterSnippet => !!filterSnippet.value && filterSnippet.value.length > AppSettings.MIN_FILTER_LENGTH),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY),
        map((filterSnippet: FilterData) => ({
          filterIdentifier: this.employeeService.getLocationFilterIdentifier(filterSnippet.identifier),
          dto: this.employeeService.createLocationSearchRequestDtoForFilterSuggestions(filterSnippet)
        })),
        this.switchMapCancelable(obj => combineLatest([of(obj.filterIdentifier), this.locationService.getListWithoutDummy(obj.dto, obj.filterIdentifier)])),
        map(requestData => this.employeeService.mapToLocationFilterSuggestions(requestData[1], requestData[0])),
      );
  }

  public mainLocationFilterSuggestionPipe(): OperatorFunction<FilterData, PmInputData[]> {
    return (source: Observable<FilterData>): Observable<PmInputData[]> =>
      source.pipe(
        this.preProcessFilterData(),
        map(this.createMainLocationSearchRequestDtoForFilterSuggestions.bind(this)),
        this.switchMapCancelable(this.locationService.getMainLocationListWithoutDummy.bind(this.locationService)),
        map((locationDto: IMainLocationDto[]) => this.employeeService.mapToMainLocationFilterSuggestions(locationDto, 'description'))
      )
  }

  public createAppPermissionFilterSuggestions(allAppPermissions: IAppPermissionMinimalDto[]): PmInputData[] {
    const serviceBonusReadIndex: number = allAppPermissions.findIndex(permission => permission.permission.toLowerCase() === FullApplicationName.SERVICE_BONUS_DEBTOR.toLowerCase());
    const serviceBonusWriteIndex: number = allAppPermissions.findIndex(permission => permission.permission.toLowerCase() === FullApplicationName.SERVICE_BONUS_CREDITOR.toLowerCase());

    let keyCounter: number = 0;

    if (serviceBonusReadIndex !== -1 && serviceBonusWriteIndex !== -1 && serviceBonusWriteIndex !== serviceBonusReadIndex + 1) {

      // If not consecutive, rearrange the list
      const rearrangedResult: IAppPermissionMinimalDto[] = [...allAppPermissions];
      this.employeeService.sortPermissions(rearrangedResult, serviceBonusWriteIndex, serviceBonusReadIndex + 1);

      return rearrangedResult.map(appPermission => ({
        key: (keyCounter++).toString(),
        value: this.appPermissionService.getLocalizedFullAppLabel(appPermission.permission)
      }));
    }
    return allAppPermissions.map(appPermission => ({
      key: (keyCounter++).toString(),
      value: this.appPermissionService.getLocalizedFullAppLabel(appPermission.permission)
    }));
  }

  private preProcessFilterData(): OperatorFunction<FilterData, FilterData> {
    return (source: Observable<FilterData>): Observable<FilterData> =>
      source.pipe(
        map<FilterData, FilterData>(this.employeeService.transformFilterData),
        filter(filterSnippet => !!filterSnippet.value && filterSnippet.value.length > AppSettings.MIN_FILTER_LENGTH),
        debounceTime(AppSettings.SEARCH_INPUT_KEY_DELAY)
      );
  }

  private createMainLocationSearchRequestDtoForFilterSuggestions(filterSnippet: FilterData): ILocationRequestDto {
    return LocationTableService.createSearchRequestDto([filterSnippet.value], AppSettings.MAIN_LOCATION_SEARCH_FIELDS);
  }

}

import {
  Observable,
  OperatorFunction
} from 'rxjs';
import {
  IBaseContractDto,
  IcontractBulkModifyResponseDto,
  IContractDetailDto,
  IContractDto,
  IContractRequestDto,
  IContractTableService,
  IModifyContractInput
} from './index';
import {
  IPaginationResponseDto
} from '../pagination/IPaginationResponseDto';
import {
  ILdapResponseDto
} from '../ildapResponse.dto';
import {
  IexceptionHandlerService
} from '../exception/iexception-handler.service';
import {
  PmInputData,
  AlertType
} from 'hagebau-coremedia';
import {
  ISearchTermsDto
} from '../search/isearch-terms.dto';
import {
  ContractStatusUpdateRequestDto
} from './icontract-status-update-request.dto';
import {
  IAuthService
} from '../account/iauth.service';

export abstract class IContractService extends IexceptionHandlerService{
  abstract getList(requestDto: IContractRequestDto): Observable<IPaginationResponseDto<IContractDto>>;

  abstract getContract(contractNumber: string): Observable<IContractDetailDto>;

  abstract update(modifyInput: IModifyContractInput): Observable<ILdapResponseDto>;

  abstract getListWithoutDummy(requestDto: IContractRequestDto, distinctKey: keyof IContractDto): Observable<IContractDto[]>;

  abstract delete(ids: string[]): Observable<IcontractBulkModifyResponseDto>;

  abstract search(term: string): Observable<ISearchTermsDto>;

  abstract searchFiltered(requestDto: IContractRequestDto): Observable<ISearchTermsDto>;

  abstract getTableStructure(): IContractTableService;

  abstract preProcessSearchInput(): OperatorFunction<string, string>;

  abstract convertSearchResults(termsDto: ISearchTermsDto): PmInputData[];

  abstract getAuthService(): IAuthService;

  abstract isDummyData(locationdata: IBaseContractDto[]): boolean;

  abstract updateContractsState(updateData: ContractStatusUpdateRequestDto): Observable<ILdapResponseDto>;

  abstract emitAlert(message: string, alertType: AlertType): void;
}

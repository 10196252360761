import {
  LdapSortDirection
} from "../LdapSortDirection";

export interface IEmployeeSortDto {
  SortKey: EmployeeSortKeys,
  SortDirection: LdapSortDirection
}

export enum EmployeeSortKeys {
    None,
    Name,
    Email,
    Status,
    MainLocation,
    ManagementLevel
}

import {
  Inject,
  Injectable
} from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate
} from "@angular/router";
import {
  INavigationService
} from "./inavigation.service";
import {
  NavigationService
} from "./navigation.service";
import {
  AuthService
} from "../account/auth/auth.service";
import {
  IAuthService
} from "../account/iauth.service";
import {
  Path
} from "../../app/app-routing.module";
import {
  Roles
} from "../account/auth/auth.constants";

@Injectable()
export class RoleGuard implements CanActivate {
  private navigationService: INavigationService;

  private authService: IAuthService;

  constructor(
    @Inject(NavigationService) navigationService: INavigationService,
    @Inject(AuthService) authService: IAuthService,
  ) {
    this.navigationService = navigationService;
    this.authService = authService;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredRoles: Roles[] = route.data['requiredRoles'] || [];
    const requiresLogin: boolean = requiredRoles.length > 0;
    if (requiresLogin && !this.authService.userHasSomeRoles(requiredRoles)) {
      this.navigationService.navigate(Path.LOGIN);
    }
    return true;
  }
}

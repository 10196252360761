<pm-employee-create-master-data
  [headingText]="headingText"
  [headingTitle]="headingTitle"
  [identifier]="stepIdentifier"
  [masterDataFields]="masterDataFields"
  [wizardHeaderSteps]="wizardHeaderSteps"
  [imageUploadText]="imageUploadText"
  [imageUploadLabel]="imageUploadLabel"
  [isSupplierCheck]="isSupplierCheckData"
  [imageDataHidden]="imageDataHidden"
  [imageName]="imageName"
  [imageSize]="imageSize"
  [loading]="loading"
  [requiredFieldsMissing]="nextButtonDisabled"
  [stepDisabled]="stepDisabled"
  backButtonLabel="back|Back"
  i18n-backButtonLabel="back|Back"
  nextButtonLabel="nextBtn|nextBtn"
  i18n-nextButtonLabel="nextBtn|nextBtn"
  (onImageSelectedEvent)="imageSelected($event)"
  (onBackClicked)="onBackClicked.emit(stepIdentifier)"
  (onNextClicked)="onNextButtonClicked()"
  (onInputChanged)="onInputChanged($event)"
></pm-employee-create-master-data>

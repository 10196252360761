import {
  IEmployeeFilterDto
} from "./IEmployeeFilterDto";
import {
  IEmployeeSortDto
} from "./IEmployeeSortDto";
import {
  IPaginationRequestDto
} from "../pagination/IPaginationRequestDto";

export interface IEmployeeRequestDto {
  Filters: IEmployeeFilterDto,
  SearchTerms: string[],
  SearchFields: string[],
  SortParams: IEmployeeSortDto,
  Pagination: IPaginationRequestDto
}

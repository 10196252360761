import {
  Observable,
} from "rxjs";
import {
  IexceptionHandlerService
} from "../exception/iexception-handler.service";
import {
  IAppPermissionMinimalDto
} from "./IAppPermissionMinimalDto";
import {
  Icon
} from "hagebau-coremedia";

export abstract class IAppPermissionService extends IexceptionHandlerService {
  abstract getFullList(): Observable<IAppPermissionMinimalDto[]>;
  abstract getList(): Observable<IAppPermissionMinimalDto[]>;
  abstract getLocalizedAppLabel(appName: string) : string;
  abstract getLocalizedFullAppLabel(appName: string): string;
  abstract getLocalizedPermissionLabel(permissionName: string) : string;
  abstract getIcon(appName: string): Icon;
}

import {
  Observable
} from "rxjs";
import {
  catchError
} from "rxjs/operators";
import {
  ValidationException
} from "../../network/api/http/api-exception";
import {
  WithRx
} from "../../app/mixins";

export abstract class IexceptionHandlerService extends WithRx() {
  checkAndThrowDefaultError<T>(observable: Observable<T>): Observable<T> {
    return observable
      .pipe(
        catchError((error): Observable<T> => {
          if (error instanceof ValidationException && (error as ValidationException).validationErrors.length > 0) {
            throw error;
          }
          throw new Error($localize`Data Loading Error|Couldn't load data.`);
        })
      );
  }
}

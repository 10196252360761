export const TOKEN_KEY: string = "token";
export const USER_ROLES: string = "roles";

export const ROLES: {
  readonly SUPER_ADMIN: "SuperAdmin";
  readonly DELEGATED_ADMIN: "DelegatedAdmin";
  readonly ADMIN: "Admin";
  readonly GLOBAL_ADMIN: "GlobalAdmin";
  readonly SERVICE_ADMIN: "ServiceAdmin"
} = {
  DELEGATED_ADMIN : 'DelegatedAdmin',
  SERVICE_ADMIN : 'ServiceAdmin',
  SUPER_ADMIN : 'SuperAdmin',
  ADMIN : 'Admin',
  GLOBAL_ADMIN : 'GlobalAdmin'
} as const;

type OV<T> = T[keyof T]
export type Roles = OV<typeof ROLES>

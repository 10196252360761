import {
  Injectable
} from '@angular/core';
import {
  catchError,
  map
} from "rxjs/operators";
import {
  SwaggerClientService
} from "./http/swagger-client.service";
import {
  environment
} from "../../environments/environment";
import {
  AllianceDtoSchema,
  IAllianceDto
} from "../../services/alliance/ialliance.dto";
import {
  IAllianceApi
} from "../../services/alliance/ialliance-api.service";
import {
  Observable
} from "rxjs";
import {
  RequestOptions
} from "./http/request-options";


@Injectable()
export class AllianceApi extends IAllianceApi {
  private swaggerClient: SwaggerClientService;

  private readonly baseUrl: string = environment.url;

  constructor(swaggerClient: SwaggerClientService) {
    super();
    this.swaggerClient = swaggerClient;
  }

  getAlliances(): Observable<IAllianceDto[]> {
    let url: string = this.baseUrl + "/api/services/app/Alliance/GetAlliancesList";
    url = url.replace(/[?&]$/, "");

    const requestOptions: RequestOptions<string> = new RequestOptions();

    return this.swaggerClient.request("get", url, requestOptions, this.swaggerClient.processResponseAsArray.bind(this.swaggerClient)).pipe(
      map(response => response.map(dto => AllianceDtoSchema.parse(dto))),
      catchError(error => {
        console.error("IAllianceDto", error)  // eslint-disable-line no-console
        throw error;
      })
    );
  }
}

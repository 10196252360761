import {
  Observable
} from "rxjs";
import {
  IEmployeeRowOfFileDto,
} from "./iemployee-import.dto";
import {
  ImportData
} from "hagebau-coremedia";
import {
  IEmployeeBulkCreateResponseDto
} from "./iemployee-bulk-create-response.dto";
import {
  IEmployeeCreateOrUpdateDto
} from "../employee-create/i-employee-create-or-update.dto";

export abstract class IEmployeeImportService {
  abstract getEmployeesFromFile(file: File): Observable<IEmployeeRowOfFileDto>;
  abstract importEmployees(employees: IEmployeeCreateOrUpdateDto[]): Observable<IEmployeeBulkCreateResponseDto>;
  abstract convertResponseToFile(response: IEmployeeBulkCreateResponseDto): void;
  abstract getDefaultImportData(): ImportData;
  abstract getImportDataByFile(template: ImportData, file: File): ImportData;
}

import {
  Injectable
} from '@angular/core';
import {
  ActivatedRoute,
  Params,
  Router
} from "@angular/router";
import {
  from,
  Observable
} from "rxjs";
import {
  map
} from "rxjs/operators";
import {
  FilterData
} from "hagebau-coremedia";
import {
  LdapSortDirection
} from "../LdapSortDirection";

type PropertiesToString<T> = {
  [K in keyof T]: string
}

type Keys<T> = Array<keyof T>

export interface QueryParameterSubscriptionData<T> {
  url: string,
  queryParams: T
}

export interface QueryParameters<T> {
  filters: FilterData[];
  search: string[];
  page: number;
  pageSize: number;
  sortKey?: T;
  sortDirection?: LdapSortDirection;
}

@Injectable()
export class QueryParameterService {
  private readonly router: Router;

  private readonly activatedRoute: ActivatedRoute;

  constructor(router: Router, activatedRoute: ActivatedRoute) {
    this.activatedRoute = activatedRoute;
    this.router = router
  }

  updateQueryParameters<T>(queryParams : T): Observable<boolean> {
    return from(this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: QueryParameterService.encodeData(queryParams)
      }));
  }

  getQueryParameters<T>(): Observable<QueryParameterSubscriptionData<T>> {
    return this.activatedRoute
      .queryParams
      .pipe(
        map(routeData => { return <QueryParameterSubscriptionData<T>>{
            url: this.router.url,
            queryParams: QueryParameterService.decodeData<T>(routeData)
          }
        })
      )
  }

  private static encodeData<T>(queryParams : T): PropertiesToString<T> {
      const encodedParams: PropertiesToString<T> = {
  } as PropertiesToString<T>;
    (Object.keys(queryParams) as Keys<T>).forEach((key) => {
      if(queryParams[key])
        encodedParams[key] = encodeURI(JSON.stringify(queryParams[key]));
    });
    return encodedParams;
  }

  private static decodeData<T>(queryData: Params): T {
      const decodedParams: T = {
  } as T;
    (Object.keys(queryData) as Keys<T>).forEach((key) => {
      decodedParams[key] = JSON.parse(decodeURI(queryData[key as string]))
    });
    return decodedParams;
  }
}

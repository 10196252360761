import {
  IPaginationRequestDto
} from "../pagination/IPaginationRequestDto";
import {
  ILocationFilterDto
} from "./ILocationFilterDto";
import {
  ILocationSortDto
} from "./ILocationSortDto";

export interface ILocationRequestDto {
  Filters: ILocationFilterDto,
  SearchTerms: string[],
  SortParams: ILocationSortDto,
  Pagination: IPaginationRequestDto,
  SearchFields: string[]
}
